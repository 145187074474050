import {
  Component, ElementRef, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild
} from '@angular/core';
import {EditTextRowService} from '../../../services/common/edit-text-row.service';
import {NgFor, NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {clearTextRow} from '../../../traits/string-tools';
import {ThemeServices} from '../../../services/common/theme.services';
import { ClipboardModule } from 'ngx-clipboard';
import {ScrollPanelModule} from 'primeng/scrollpanel';

@Component({
  selector: 'app-similars-cards',
  standalone:true,
  imports:[NgIf,NgFor,FormsModule,ClipboardModule, ScrollPanelModule],
  templateUrl: './similars-cards.component.html',

})
export class SimilarsCardsComponent implements OnInit {
  @Input() nameRow: any = 'name';
  @Input() typeRow: any = 'text';
  @Input() cards: any = [];
  @Input() similar: any = [];
  @Output() onFavorite = new EventEmitter();
  @Output() onClick = new EventEmitter();
 public theme:any = inject(ThemeServices);
  constructor(public  editTextRowService: EditTextRowService) {

  }
  public ngOnInit() {
    this.initSimilarTextInRow();
  }


  public initSimilarTextInRow(arraysEdits: any = [], name: any = 'name',) {
    this.cards.forEach((modelItem: any) => {
      // console.log(modelItem)
      this.similar.push({name: modelItem[this.nameRow]});
    });
    // this.similar = this.cd.array.uniq(this.similar, 'name');

    console.log(this.similar);
  }

  select(similarRow: any) {
    // model[nameRow] =similarRow['name'];
    this.onClick.emit(similarRow['name'])

  }
}
