import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  JsonPipe,
  NgClass,
  NgFor,
  NgIf,
  NgStyle
} from "@angular/common";
import {SidebarComponent} from "../../common/sidebar/sidebar.component";
import {VideoReaderCourseComponent} from "../video-reader-course/video-reader-course.component";
import {ProgressBarModule} from "primeng/progressbar";
import {CardLessonComponent} from "../card-lesson/card-lesson.component";
import {ByMeACoffeComponent} from "../../payment/by-me-a-coffe/by-me-a-coffe.component";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {BoxServices} from "../../../services/box/box.services";

@Component({
  selector: 'app-course-video-viewer',
  templateUrl: './course-video-viewer.component.html',
  standalone: true,
  providers: [],
  imports: [
    NgIf,
    NgFor,
    NgStyle,
    NgClass,
    SidebarComponent,
    VideoReaderCourseComponent,
    ProgressBarModule,
    CardLessonComponent,
    ByMeACoffeComponent,
    ScrollPanelModule,
    JsonPipe,
  ],
})
export class CourseVideoViewerComponent  {

}
