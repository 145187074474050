import {Injectable} from '@angular/core';
import {
  ServerImageServices,
} from '../servers/server-image.services';

@Injectable({
  providedIn: 'root'
})
export class EditTextRowService {
  constructor(public serverImage: ServerImageServices) {
  }

  init() {
    /* if ((this.typeRow == 'srcPreview' || this.typeRow == 'srcIcon' || this.typeRow == 'srcGif')) {
       this.showText = false;
       this.showImage = true;


     }
     this.initSimilarTextInRow();*/
  }


  public initSimilarTextInRow(arraysEdits: any = [], name: any = 'name',) {
    /* /!* let findRow = arraysEdits.find((rowText: any) => {
        return rowText.name === name;
      });*!/

     this.models.forEach((modelItem: any) => {
       // console.log(modelItem)
       this.similar.push({name: modelItem[this.nameRow]});
     });
     this.similar = this.cd.array.uniq(this.similar, 'name');*/
  }

  public addCloneModel(model: any, models: any) {
    /*this.cd.alert.confirmationService.confirm({
      message: `Clone? ${model.name}`,
      accept: () => {
        let modelClone = cloneModel(model, true);
        models.push(modelClone);
        this.onSave.emit(true);

      }
    });*/

  }

  public baseToSrc(model: any, sizeMode: any = 'middle') {
    this.serverImage.toImageJpg(model, sizeMode);
   /* this.cd.alert.confirmationService.confirm({
      message: `BASE64 TO SRC (${sizeMode})? ${model.name}`,
      accept: () => {
        this.cd.toImageJpg(model, sizeMode);
        // this.onSave.emit(true);
      }
    });*/

  }

  public isBase64(model: any): boolean {
    return model.srcPreview && this.serverImage.checkSrc(model.srcPreview).name === 'BASE64';
  }
  public isNotBase64(model: any): boolean {
  return   model.srcPreview && this.serverImage.checkSrc(model.srcPreview).name !== 'BASE64'
  }

  public deleteModel(model: any, models: any) {
    // deleteItem(model, models);
    // this.deleteImageFromServer(model);
  }

  public setPreviewBtn(model: any) {
    /*
        this.cd.alert.confirmationService.confirm({
          message: `SET PREVIEW`,
          accept: () => {
            this.onSetPreview.emit(model);
            this.onSave.emit(true);

          }
        });*/
  }

  public setFavoriteBtn(model: any) {

    /*this.cd.alert.confirmationService.confirm({
      message: `ADD TO FAVORITE`,
      accept: () => {
        this.onFavorite.emit(model);
        this.onSave.emit(true);


      }
    });*/
  }

  public deleteImageFromServer(model: any) {
    if (this.isNotBase64(model)) {
      this.serverImage.deleteSrc(model, 'jpg');
    }


  }


}
