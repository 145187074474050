<app-hammer-wrapper
  (onClick)="onClick.emit(elementItem)"
  (onDblclick)="onDblclick.emit(elementItem)"
  (onPress)="onPress.emit(elementItem);"
>
  <p-scrollPanel [style]="{height:'160px'}">
    <div class="flex items-center py-1 bg-gray-700 ">
                    <textarea
                      style="padding: 2px!important;font-size: 12px !important; "
                      pInputTextarea
                      [autofocus]="false"
                      class= "focus:outline-none border border-transparent g-gray-800  shadow-lg p-2"
                      [placeholder]="'Your Text'"
                      [(ngModel)]="elementItem.text"
                      (ngModelChange)="save()"
                      [style.min-height.px]="600"
                      [style.height.px]="600"
                      [style.min-width.px]="200"
                    ></textarea>
    </div>
  </p-scrollPanel>

</app-hammer-wrapper>

