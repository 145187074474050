import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild
} from '@angular/core';
import {
  NgFor,
  NgIf
} from "@angular/common";
import {saveFile} from "../../traits/file-tools";
@Component({
  selector: 'app-brushes-photoshop',
  standalone: true,
  imports: [
    NgIf,
    NgFor,],
  templateUrl: './brushes-photoshop.component.html',

})
export class BrushesPhotoshopComponent  {
  @Input() model: any;
  public box: any;
  public brushPath: any = '/assets/brushes/';
  public brushesPhotoshopStandard: any = [];
  constructor(
  ) {

  }
  ngOnInit() {
    this.initBrushesFolder();
  }
  public initBrushesFolder(){
    this.brushesPhotoshopStandard = [
      this.brushRow('Sketch'),
      this.brushRow('Line'),
      this.brushRow('Fill'),
      this.brushRow('Round'),
      this.brushRow('Square'),
      this.brushRow('Sponge'),
    ];


    // const testFolder = './assets/brushes/';
    // const fs = require('fs');
    //
    // fs.readdirSync(testFolder).forEach((file:any) => {
    //   console.log(file);
    // });
  }

  downloadBtn(brushItem:any){
    brushItem.download = true;
    saveFile(brushItem.srcFile,brushItem.name+'.abr')

  }
  brushRow(name:any = "Line"){
     return  {
       name: name,
       srcPreview: this.brushPath + name + '.jpg',
       srcIcon: this.brushPath + name + '.jpg',
       srcFile: this.brushPath + name + '.abr'
     };
  }

}
