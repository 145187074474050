<app-sidebar [show]="preloadService.isShow()"
                  [showClose]="false"
                  [height]="100"
                  [zIndex]="1000000"
>
  <ng-container *ngIf="(preloadService.isShow())">
  <div class=" bg-zinc-900 container mx-auto w-full h-[500px] z-20">
    <!-- <p-progressBar *ngIf="cd.preloadGlobalShow"
                    mode="indeterminate"
                    [style]="{ height: '25px' }"
     ></p-progressBar>-->

    <div class="content">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </div>

    <div class=" wrapper-preload">
      <div class="circle-preload"></div>
      <div class="circle-preload"></div>
      <div class="circle-preload"></div>
      <div class="shadow-preload"></div>
      <div class="shadow-preload"></div>
      <div class="shadow-preload"></div>
      <span class="text-zinc-100">{{preloadService.textLoading}}</span>
    </div>


      <!-- <hr>
       <p *ngIf="cd.preloadGlobalModel.name">{{cd.preloadGlobalModel.name}}</p>
       <hr>
       <p *ngIf="cd.preloadGlobalModel.desc"> {{cd.preloadGlobalModel.desc}}</p>
       <hr>
       <p *ngIf="cd.preloadGlobalModel.text"> {{cd.preloadGlobalModel.text}}</p>-->
  </div>
  </ng-container>

</app-sidebar>
