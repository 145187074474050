<span class=" p-inputgroup">
<ng-container *ngIf="showAdds">
  <span *ngIf="(!model[listName])" class="p-inputgroup-addon" (click)="addTags()">
   <i  class="fas fa-ellipsis-h"></i>{{ listName }}
  </span>
  <span class="p-inputgroup-addon" (click)="addToStart() "><i [class]="theme.icon.add"></i></span>
  <span class="p-inputgroup-addon" (click)="addToEnd();"><i [class]="theme.icon.minus"></i> </span>
</ng-container>


  <!--<span *ngIf="model[listName]"
        (click)="showArchiveList  =true; cd.selectArchiveModels  = model[listName] " class=p-inputgroup-addon>
              <i class="fas fa-archive"></i>
  </span>-->
  <span
    (click)="onView.emit('list')" class="p-inputgroup-addon">
 <i class="fa-solid fa-list"></i>
  </span>
  <span
    (click)="onView.emit('grid')" class="p-inputgroup-addon">
<i class="fa-solid fa-grip"></i>
  </span>

  <span *ngIf="(model[listName] && !model.readonly)" class=p-inputgroup-addon>
    {{ countHidden(model[listName]) }}
    /
    {{ countShowed(model[listName]) }}
    /
    {{ countAll(model[listName]) }}
  </span>


   <!--<span  *ngIf="(model[listName])"  class=p-inputgroup-addon>
              <p-toggleButton  onIcon="pi pi-check" offIcon="pi pi-times"
                               [(ngModel)]="model['show'+listName]"
              ></p-toggleButton>
   </span>
  <span   *ngIf="(model[listName] && !model.readonly)"
          (click)="clear(model[listName])"
        class="p-inputgroup-addon">
    <i [class]="theme.icon.delete"></i>
 </span>-->
  <!-- <span   *ngIf="(model[listName] && !model.readonly)"
           (click)="cd.array.checkToArchive(model[listName]); cd.saveChangeSite =false;"
         class="p-inputgroup-addon">
     <i class="fas fa-boxes"></i>
  </span>-->

  <!--  <span   *ngIf="(model[listName] && !model.readonly)"
            (click)="cd.array.checkedInvert(model[listName]); cd.saveChangeSite =false;"
          class="p-inputgroup-addon">
      <i class="far fa-square"></i>
   </span>-->

  <!--<app-tool-filters-btn
    *ngIf="showCatFilter"
    [row]="'cat'"
    [label]="'cat'"
    [models]="model[listName]"
    (onSearchKeyword)="onSearchKeyword.emit($event);onFilterRow.emit('cat')"
  ></app-tool-filters-btn>-->


  <!--<app-tool-scroll-row
    *ngIf="showCatFilter"
    (onClick)="cd.debug($event)"
    [models]="model[listName]"
    [showFilter]="true"
    [filterByRow]="'cat'"
    [height]="75"
    [width]="50"
  >
              </app-tool-scroll-row>-->

</span>
