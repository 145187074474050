<app-product-list-right *ngIf="showCoursesList && !showVideoWatch"
  [cards]="dataPage.getCards()"
  (onSelectCard)="goToCourse($event)"
></app-product-list-right>
<!--<app-product-list-right *ngIf="showVideoWatch && lessons.length>1"
                        [cards]="lessons"
                        (onSelectCard)="setSelectLesson($event)"
></app-product-list-right>-->
<app-navbar-top></app-navbar-top>
<app-sidebar [show]="showResources"
                  (onSave)="showResources = false;showCoursesList=true"
                  [showClose]="true"
                  [height]="100"
>
  <app-course-resourses *ngIf="showResources"
    [srcImage]="selectCourse.srcPreview"
  ></app-course-resourses>
</app-sidebar>
<ng-container  *ngIf="selectCourse">
  <app-sidebar [show]="showVideoWatch"
             (onSave)="closeLesson()"
             [showClose]="true"
             [height]="100"
>
    <p-breadcrumb *ngIf="showBreadChumbs"
                  class="z-30 w-auto"
                  [model]="itemsBreadCrumbs"
                  [home]="home"
    >
    </p-breadcrumb>

    <div
    class=" flex flex-col justify-center items-start row-start-1 sm:row-start-1">
    <div *ngIf="showVideoWatch" class="w-full h-full">

      <app-video-reader-course
        [videoItem]="selectLesson"
        [timeList]="selectLesson.steps"
        [videosList]="lessons"
        [selectVideo]="selectLesson"
        [autoplay]="true"
        [controls]="false"
        (onNextVideo)="setSelectLesson($event)"
        [options]="{
                      controls: false,
                      sources: {
                        src: selectLesson.srcVideo,
                        type: 'video/mp4'
                        }
                     }"
      >
      </app-video-reader-course>
    </div>

  </div>

</app-sidebar>


<div
  class="relative overflow-hidden rounded-lg shadow-lg cursor-pointer">

  <img class="object-cover w-full h-[150px]"
       [src]="selectCourse.srcPreview"
       [alt]="selectCourse.name"/>

  <div class="absolute top-0 left-0 px-6 py-4">
    <h4 class="mb-3 text-xl font-semibold tracking-tight text-white">
      {{selectCourse.name}}
    </h4>
    <p class="leading-normal text-zinc-100">
      {{selectCourse.name}}
    </p>
    <button class="btn-custom"
            (click)="statusPlay =  true;"
    >
      <i class="fa-regular fa-circle-play"></i>
    </button>
  </div>
</div>
<p-progressBar *ngIf="showSpinner"
               mode="indeterminate"
               [style]="{ height: '15px' }"
></p-progressBar>
  <p-breadcrumb *ngIf="showBreadChumbs"
                class="z-30 w-auto"
                [model]="itemsBreadCrumbs"
                [home]="home"
  >
  </p-breadcrumb>
<div
  class="mx-auto bg-gradient-to-r from-zinc-900 via-zinc-900 to-zinc-900 flex justify-center items-center ">

  <div class="flex w-full">
    <div class="h-full w-full" style="opacity: 1; transform: none;">

      <div class=" w-full items-center justify-center">
        <!--        <p-tabView>
                  <p-tabPanel header="Lessons">-->

        <p-scrollPanel [style]="{height:640+'px'}">

          <ul class="flex flex-col bg-zinc-800 p-1">
            <ng-container *ngFor="let  lessonItem  of lessons; let i = index;">

              <ng-container *ngIf="lessonItem.sw === true ">
                <section [id]="lessonItem.id">
                  <app-card-lesson
                    [lessonItem]="lessonItem"
                    [modelParent]="selectCourse"
                    [lessons]="lessons"
                    (onSelectLesson)="setSelectLesson($event)"
                  ></app-card-lesson>
                </section>
              </ng-container>
            </ng-container>
            <li *ngIf="selectCourse.continueEnable"
                class="border-zinc-400  mb-1">
              <div
                class=" animate-pulse animate-infinite animate-ease-in-out  flex-row select-none cursor-pointer bg-zinc-600 text-amber-300 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                <div class="flex flex-col rounded-md w-10 h-10 bg-zinc-800 justify-center items-center mr-4">
                  <!-- <img class="" [src]="continueIcon"
                        [alt]="'continue'">-->
                </div>
                <!--[style.pointer-events]="selectLesson.id == lessonItem.id ?'none':'auto'"-->
                <div
                  class="flex-1 pl-1 mr-16">
                  <div class="font-medium">

                  </div>

                  <div class="text-amber-200 text-sm">
                    to be continued ....
                  </div>
                  <div class="text-amber-400 text-[10px]">
                    Next lesson by public to next week or month!
                  </div>
                </div>
              </div>
            </li>
            <li (click)="showResources = true ; showCoursesList=false"
              class="border-zinc-400  mb-1">
              <div
                class=" flex flex-row  content-center cursor-pointer bg-zinc-600 text-amber-300 rounded-md  flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">

                   <img class="w-[50px] h-[50px]"
                       [src]="'assets/icons/806647.png'"
                       [alt]="'Resources(Palettes|Brushes|Line|act...)'">
                  <span [hidden]="coins.coinService.showCoinsFlag()" class="absolute z-30 -top-4 left-3">
       <app-coins #coins [showCoinMinus]="true"></app-coins>
          </span>
                  <span class="ml-3">
                    Get resources: Palettes | Brushes | Line | Grayscale ...
                  </span>

              </div>

            </li>
          </ul>
        </p-scrollPanel>
      </div>
    </div>
  </div>
</div>
<!--          </p-tabPanel>-->
<!--<p-tabPanel header="About">
  <p>About</p>
</p-tabPanel>-->
<!--<p-tabPanel header="Resources">
  <p>Resources</p>
</p-tabPanel>-->
<!--        </p-tabView>-->
<!--<div class="flex space-x-1 items-center scroll-items">
  <ng-container *ngFor="let  step  of  courseItem.steps; let i = index;">
    <span class="tag scroll-item">
      <span class="min-image">№_{{i+1}}</span> |
      <span class="min-image">{{step.srcPreview}}</span> |
      <span>{{step.name}}</span>
    </span>
  </ng-container>
</div>-->
<!--<app-sidebar [show]="cd.showPainterTest"
                  (onSave)="cd.showPainterTest = false"
                  [opacity]="0.7"
                  [showClose]="false"
                  [height]="100"
>
  <app-painter-test *ngIf="cd.showPainterTest"></app-painter-test>
</app-sidebar>-->

</ng-container>
