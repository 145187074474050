import {dateNow} from "./datetime-tools";

export const CDN_GRADIO: string = 'https://cdn.jsdelivr.net/npm/@gradio/client/dist/index.min.js';
export const SRC_GRADIO: string = 'src/assets/params/js/gradio.min.js';
export const ROUTE_Midjourney: string = "prithivMLmods/Midjourney";
export const ROUTE_DALLE_4K: string = "prithivMLmods/DALLE-4K";
export const ROUTE_Translate: string = "https://gundeep-open-translate.hf.space/--replicas/flqbr/";
export const ROUTE_BackgroundRemove: string = "ECCV2022/dis-background-removal";
export const TOKEN_READ: string = "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";
export const TOKEN_WRITE: string = "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";
export const paramsMidjourney: any = {
  prompt: "Hello!!",
  negative_prompt: "Hello!!",
  use_negative_prompt: true,
  style: "2560 x 1440",
  seed: 0,
  width: 512,
  height: 512,
  guidance_scale: 0.1,
  randomize_seed: true,
};


export async function initClientGradio() {
  const module = await import(/* webpackIgnore: true */ CDN_GRADIO);
  return module.Client;
}

export function getOneImagePrimitive(result): object {
  console.log(result, 'getOneImage')
  return {
    srcPreview: result.data[0][0].image.url
  }
}

export function getOneImage(result) {
  console.log(result, 'getOneImage')
  return result.data[0].url;
}

export function getManyImages(result: any): any[] {
  console.log(result, 'getManyImages')
  let images: any [] = result.data[0].map((item: any) => {
    return {
      srcPreview: item.image.url
    }
  })
  return images;
}

export const apiRequests: any[] = [
  {
    name: 'Photo',
    route: 'runwayml/stable-diffusion-v1-5',
    srcPreview: '',
    sw: true,
    tp: 'api'
  },
  {name:'Real',route:'EnD-Diffusers/lineart-model',srcPreview:'',sw:true ,tp:'api'},
  {name:'Real',route:'fluently/Fluently-XL-Final',srcPreview:'',sw:true ,tp:'api'},
  {name:'Cartoon simple colors',route:'alvdansen/littletinies',srcPreview:'',sw:true,tp:'api'},
  {name:'Cartoon Black And white',route:'alvdansen/BandW-Manga',srcPreview:'',sw:true,tp:'api'},
  {name:'Digital art',route:'stabilityai/stable-diffusion-xl-base-1.0',srcPreview:'',sw:true,tp:'api'},
  {name:'Anime',route:'cagliostrolab/animagine-xl-3.1',srcPreview:'',sw:true,tp:'api'},
  {name:'',route:'yodayo-ai/kivotos-xl-2.0',srcPreview:'',sw:false,tp:'api'},
];
// {name:'Real',route:'playgroundai/playground-v2.5-1024px-aesthetic',srcPreview:'',sw:true ,tp:'api'},


export const apiRequestsGradioMy: any[] = [
  {name:'Real',route:'AliShare/fluently-Fluently-XL-Final',srcPreview:'',sw:true ,tp:'api'},
  {name:'Real',route:'AliShare/stabilityai-stable-diffusion-2-1',srcPreview:'',sw:true ,tp:'api'},
  {name:'Real',route:"AliShare/Corcelio-mobius",srcPreview:'',sw:true ,tp:'api'},
  {name:'Real',route:"AliShare/EnD-Diffusers-lineart-model",srcPreview:'',sw:true ,tp:'api'},
];

export const apiRequestsGradioUsers: any[] = [
  {
    name: 'markmagic/Stable-Diffusion-3-FREE',
    route: 'markmagic/Stable-Diffusion-3-FREE',
    srcPreview: '',
    sw: true,
    tp: 'api',
    many: true,
    predict: '/run',
    params: {
      prompt: "Smile Girl!!",
      negative_prompt: "Hello!!",
      use_negative_prompt: true,
      seed: 0,
      width: 512,
      height: 512,
      guidance_scale: 7,
      randomize_seed: true,
      num_inference_steps: 30,
      NUM_IMAGES_PER_PROMPT: 8,
    }
  },

  {
    name: 'ameerazam08/SD-3-Medium-GPU',
    route: 'ameerazam08/SD-3-Medium-GPU',
    srcPreview: '',
    sw: false,
    tp: 'api',
    many: true,
    predict: '/run',
    params: {
      prompt: "Smile Girl!!",
      negative_prompt: "Hello!!",
      use_negative_prompt: true,
      seed: 0,
      width: 512,
      height: 512,
      guidance_scale: 7,
      randomize_seed: true,
      num_inference_steps: 30,
      NUM_IMAGES_PER_PROMPT: 4,
    }
  },
  {
    name: 'stabilityai/stable-diffusion-3-medium',
    route: 'stabilityai/stable-diffusion-3-medium',
    srcPreview: '',
    sw: false,
    tp: 'api',
    many: false,
    predict: '/infer',
    params: {
      prompt: "Smile girl!!",
      negative_prompt: "Hello!!",
      seed: 0,
      randomize_seed: true,
      width: 512,
      height: 512,
      guidance_scale: 5,
      num_inference_steps: 28,
    }
  },

  {
    name: 'kadirnar/Stable-Diffusion-3',
    route: 'kadirnar/Stable-Diffusion-3',
    srcPreview: '',
    sw: false,
    tp: 'api',
    many: true,
    predict: '/run',
    params: {
      prompt: "Smile Girl!!",
      negative_prompt: "Hello!!",
      use_negative_prompt: true,
      seed: 0,
      width: 512,
      height: 512,
      guidance_scale: 7,
      randomize_seed: true,
      num_inference_steps: 30,
      NUM_IMAGES_PER_PROMPT: 4,
      init_image: null,
    }
  },
  {
    name: 'https://playgroundai-playground-v2-5.hf.space/--replicas/k75tj/',
    route: 'https://playgroundai-playground-v2-5.hf.space/--replicas/k75tj/',
    srcPreview: '',
    sw: false,
    tp: 'api',
    many: false,
    predict: '/run',
    params: [
      "Hello!!", // string  in 'Prompt' Textbox component
      "Hello!!", // string  in 'Negative prompt' Textbox component
      true, // boolean  in 'Use negative prompt' Checkbox component
      0, // number (numeric value between 0 and 2147483647) in 'Seed' Slider component
      256, // number (numeric value between 256 and 1536) in 'Width' Slider component
      256, // number (numeric value between 256 and 1536) in 'Height' Slider component
      0.1, // number (numeric value between 0.1 and 20) in 'Guidance Scale' Slider component
      true, // boolean  in 'Randomize seed' Checkbox component
    ]
  },
];


export async function getRequestImagesGradioMy(prompt: string, callback) {
  for (const item of apiRequestsGradioMy) {
    if (item.sw === true) {
      let result = await getDradioApiMy(prompt, item.route);
      callback && callback(result)
    }
  }
}

export async function getDradioApiMy(prompt: string, route: string) {
  try {
    let client: any = await initClientGradio();
    const clientConnect = await client.connect(route);
    const result = await clientConnect.predict("/predict", {
      param_0: prompt + ' ' + dateNow(),
    });
    return getOneImage(result)
  } catch (error) {
    console.log("Error:", error.message);
  }
}
export async function getDradioApiPrompt(prompt: string) {

  let client: any = await initClientGradio();
  const clientConnect = await client.connect("AliShare/Gustavosta-MagicPrompt-Stable-Diffusion");
  const result = await clientConnect.predict("/predict", {
    param_0: prompt,
  });
  return result.data[0];
}

export async function getRequestImagesGradioUsers(prompt: string,  callback:any, params:any = null) {

  for (const item of apiRequestsGradioUsers) {

    if (item.sw === true) {
      if(params){
        console.log(params)
        item.params = params;
      }
      let result = await getDradioApiUsers(prompt, item);
      callback && callback(result)
    }
  }
}

export async function getDradioApiUsers(prompt: string, item:any) {
    let client: any = await initClientGradio();
    const clientConnect = await client.connect(item.route);
    item.params.prompt?item.params.prompt = prompt:item.params[0] = prompt
    const result = await clientConnect.predict(item.predict, item.params);
    return item.many?getManyImages(result):getOneImage(result);
}

export async function getRequestImages(prompt: string, callback) {
  for (const item of apiRequests) {
    if (item.sw === true) {
      let result = await getImageIaByRequestApi(prompt, item.route);
      callback && callback(result)
    }
  }
}

export async function getImageIaByRequestApi(prompt: string, route: string) {
  const response = await fetch(`https://api-inference.huggingface.co/models/${route}`, {
    headers: {Authorization: "Bearer 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"},
    method: "POST",
    body: JSON.stringify({
      "inputs": prompt + ' ' + dateNow(),
      "options": {
        "use_cache": false,
        "wait_for_mode": true
      }
    }),
  });

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  const blobResult = await response.blob();
  return await blobToBase64(blobResult)
}


/*export async function getAiImagesStableDiffusion_2() {
  try {
    let client: any = await initClientGradio();
    console.log(client)
    const clientConnect = await client.connect("markmagic/Stable-Diffusion-3-FREE");
    const result = await clientConnect.predict("/run", {
      prompt: "Smile Girl!!",
      negative_prompt: "Hello!!",
      use_negative_prompt: true,
      seed: 0,
      width: 512,
      height: 512,
      guidance_scale: 7,
      randomize_seed: true,
      num_inference_steps: 30,
      NUM_IMAGES_PER_PROMPT: 4,
    });
    return getManyImages(result)
  } catch (error) {
    console.log("Error:", error.message);
  }
}*/

/*export async function getAiImagesStableDiffusion_3() {
  try {
    let client: any = await initClientGradio();
    console.log(client)
    const response_0 = await fetch("https://raw.githubusercontent.com/gradio-app/gradio/main/test/test_files/bus.png");
    const exampleFile = await response_0.blob();
    const clientConnect = await client.connect("kadirnar/Stable-Diffusion-3");
    const result = await clientConnect.predict("/run", {
      prompt: "Smile Girl!!",
      negative_prompt: "Hello!!",
      use_negative_prompt: true,
      seed: 0,
      width: 512,
      height: 512,
      guidance_scale: 7,
      randomize_seed: true,
      num_inference_steps: 30,
      NUM_IMAGES_PER_PROMPT: 4,
      init_image: exampleFile,
    });
    return getManyImages(result)

  } catch (error) {
    console.log("Error:", error.message);
  }
}*/

/*
export async function getAiImagesStableDiffusion_4() {
  try {
    let client: any = await initClientGradio();
    console.log(client)
    const clientConnect = await client.connect("ameerazam08/SD-3-Medium-GPU");
    const result = await clientConnect.predict("/run", {
      prompt: "Smile Girl!!",
      negative_prompt: "Hello!!",
      use_negative_prompt: true,
      seed: 0,
      width: 512,
      height: 512,
      guidance_scale: 7,
      randomize_seed: true,
      num_inference_steps: 30,
      NUM_IMAGES_PER_PROMPT: 4,
    });
    return getManyImages(result)
  } catch (error) {
    console.log("Error:", error.message);
  }
}
*/

/*export async function getAiImagesMidjourney(params: any = paramsMidjourney) {
  try {
    let client: any = await initClientGradio();
    console.log(client)
    const clientConnect = await client.connect(ROUTE_Midjourney);
    const result = await clientConnect.predict("/run", params);
    return getOneImagePrimitive(result)
  } catch (error) {
    console.log("Error:", error.message);
  }
}*/

/*export async function getAiImagesDALLE_4K(params: any = paramsMidjourney) {
  try {
    let client: any = await initClientGradio();
    console.log(client)
    const clientConnect = await client.connect(ROUTE_DALLE_4K);
    const result = await clientConnect.predict("/run", params);
    return getManyImages(result)
  } catch (error) {
    console.log("Error:", error.message);
  }
}*/

/*export async function getAiImagesDALLE_COLLAGE(params: any = paramsMidjourney) {
  try {
    let client: any = await initClientGradio();
    console.log(client)
    const clientConnect = await client.connect('prithivMLmods/DALLE-COLLAGE');
    const result = await clientConnect.predict("/run", {
      prompt: "Hello!!",
      negative_prompt: "Hello!!",
      use_negative_prompt: true,
      style: "3840 x 2160",
      collage_style: "Hi-Res",
      filter_name: "Vivid",
      grid_size: "2x1",
      seed: 0,
      width: 512,
      height: 512,
      guidance_scale: 0.1,
      randomize_seed: true,
    });

    console.log(result)
    return result
    /!*let images: any [] = result.data[0].map((item: any) => {
      return {
        name: item.image.name,
        srcPreview: item.image.url
      }
    })
    return images;*!/
  } catch (error) {
    console.log("Error:", error.message);
  }
}*/

/*export async function getAiImagesPlayGround_1() {
  try {
    let client: any = await initClientGradio();
    console.log(client)
    const clientConnect = await client.connect("https://playgroundai-playground-v2-5.hf.space/--replicas/k75tj/");
    const result = await clientConnect.predict("/run", [
      "Hello!!", // string  in 'Prompt' Textbox component
      "Hello!!", // string  in 'Negative prompt' Textbox component
      true, // boolean  in 'Use negative prompt' Checkbox component
      0, // number (numeric value between 0 and 2147483647) in 'Seed' Slider component
      256, // number (numeric value between 256 and 1536) in 'Width' Slider component
      256, // number (numeric value between 256 and 1536) in 'Height' Slider component
      0.1, // number (numeric value between 0.1 and 20) in 'Guidance Scale' Slider component
      true, // boolean  in 'Randomize seed' Checkbox component
    ]);
    console.log(result)
    return result;
    /!*  let res =   [{
          name: result.data[0].name,
            srcPreview: result.data[0].url
        }];
        return res*!/
    // console.log( res)
    /!* let images: any [] = result.data[0].map((item: any) => {
       return {
         name: item.image.name,
         srcPreview: item.image.url
       }
     })
     return images;*!/
  } catch (error) {
    console.log("Error:", error.message);
  }
}*/

/*export async function getAiImagesStableDiffusion_1() {
  try {
    let client: any = await initClientGradio();
    console.log(client)
    const clientConnect = await client.connect("stabilityai/stable-diffusion-3-medium");
    const result = await clientConnect.predict("/infer", {
      prompt: "Smile girl!!",
      negative_prompt: "Hello!!",
      seed: 0,
      randomize_seed: true,
      width: 512,
      height: 512,
      guidance_scale: 5,
      num_inference_steps: 28,
    });
    let res = [
      {
        name: result.data[0].name,
        srcPreview: result.data[0].url
      }
    ];
    return res
  } catch (error) {
    console.log("Error:", error.message);
  }
}*/

/*export function base64ToBlob(base64, contentType = 'image/png') {
  const byteCharacters = atob(base64.split(',')[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    byteArrays.push(new Uint8Array(byteNumbers));
  }

  return new Blob(byteArrays, {type: contentType});
}*/
/*export async function  getAiIBackgroundRemove(base64:string) {
  const response_0 = await fetch("https://raw.githubusercontent.com/gradio-app/gradio/main/test/test_files/bus.png");
  const exampleImage = await response_0.blob();
  console.log(exampleImage)
  // let imageBlob:any = base64ToBlob(base64)
  //    console.log(imageBlob)
  let client:any = await initClientGradio();
  const clientConnect = await client.connect("schirrmacher/ormbg");
  console.log(clientConnect)
  const result = await clientConnect.predict("/predict", [
    exampleImage,
  ]);

  console.log(result);
 /!* try {



  }catch (error){
    console.log("Error:",error.message);
  }*!/
}*/


/*
export async function getAiICLITE (base64:string) {
  const response_0 = await fetch("https://raw.githubusercontent.com/gradio-app/gradio/main/test/test_files/bus.png");
  const exampleImage = await response_0.blob();
  console.log(exampleImage)
  // let imageBlob:any = base64ToBlob(base64)
  //    console.log(imageBlob)
  let client:any = await initClientGradio();
  const clientConnect = await client.connect("lllyasviel/IC-Light",{hf_token:TOKEN_READ});
  const result = await clientConnect.predict("/process_relight", [
    exampleImage, 	// blob in 'Image' Image component
    "Hello!!", // string  in 'Prompt' Textbox component
    256, // number (numeric value between 256 and 1024) in 'Image Width' Slider component
    256, // number (numeric value between 256 and 1024) in 'Image Height' Slider component
    1, // number (numeric value between 1 and 12) in 'Images' Slider component
    3, // number  in 'Seed' Number component
    1, // number (numeric value between 1 and 100) in 'Steps' Slider component
    "Hello!!", // string  in 'Added Prompt' Textbox component
    "Hello!!", // string  in 'Negative Prompt' Textbox component
    1, // number (numeric value between 1.0 and 32.0) in 'CFG Scale' Slider component
    1, // number (numeric value between 1.0 and 3.0) in 'Highres Scale' Slider component
    0.1, // number (numeric value between 0.1 and 1.0) in 'Highres Denoise' Slider component
    0.1, // number (numeric value between 0.1 and 1.0) in 'Lowres Denoise (for initial latent)' Slider component
    "None", // string  in 'Lighting Preference (Initial Latent)' Radio component
  ]);

}
export async function getGPT4o (base64:string) {
  const response_0 = await fetch("https://raw.githubusercontent.com/gradio-app/gradio/main/test/test_files/bus.png");
  const exampleImage = await response_0.blob();
  console.log(exampleImage)
  // let imageBlob:any = base64ToBlob(base64)
  //    console.log(imageBlob)
  let client:any = await initClientGradio();
  const clientConnect = await client.connect("KingNish/OpenGPT-4o");
  const result = await clientConnect.predict("/chat", {
    image3: exampleImage, 	// blob in 'Upload Image' Image component
    prompt3: "Hello!!",
  });
  console.log(result)
}
export async function  getAiUpscale(base64:string) {
/!*
  try {
*!/

    const response_0 = await fetch("https://raw.githubusercontent.com/gradio-app/gradio/main/test/test_files/bus.png");
    const exampleImage = await response_0.blob();
    console.log(exampleImage)
 // let imageBlob:any = base64ToBlob(base64)
    let client:any = await initClientGradio();
    const clientConecnt = await client.connect("Fabrice-TIERCELIN/SUPIR",{hf_token:TOKEN_READ});
    const result:any = await clientConecnt.predict("/check_1", {
      noisy_image: exampleImage,
      input_image: exampleImage,
      denoise_image : exampleImage,
      result_slider : 'https://raw.githubusercontent.com/gradio-app/gradio/main/test/test_files/bus.png',
    });

/!*    console.log(result.data);

  }catch (error){
    console.log("Error:",error.message);
  }*!/
}
export async function  getAiITranslate(transateText:string) {
  try {
    let client:any = await initClientGradio();
    const clientConnect = await client.connect(ROUTE_Translate);
    console.log(clientConnect)
    const result = await clientConnect.predict("/translate", [
      transateText, // string  in 'Input Text' Textbox component
      'Auto Detect', // string  in 'Source Language' Dropdown component
      'English', // string  in 'Target Language' Dropdown component
    ]);
    console.log(result.data)
return result.data[0];

  }catch (error){
    console.log("Error:",error.message);
  }
}
export async function  getAiIFaceToAll(transateText:string) {
/!*  try {*!/
    const response_0 = await fetch("https://raw.githubusercontent.com/gradio-app/gradio/main/test/test_files/bus.png");
    const exampleFile = await response_0.blob();
    let client:any = await initClientGradio();
    const clientConnect = await client.connect("AliShare/PhotoMaker-Style",{hf_token:TOKEN_WRITE});

  const result = await clientConnect.predict("/swap_to_gallery", {
    images: exampleFile,
    upload_images: exampleFile,
    prompt: 'img woomen',
  });

  console.log(result.data);
/!*
    const result = await clientConnect.predict("/check_selected_1", [
    ]);
  console.log(result)*!/

    // console.log(result);

/!*  }catch (error){
    console.log("Error:",error.message);
  }*!/
}

*/
/*const convertBlobToJson = (blob) => {
   const reader:any = new FileReader();
   reader.onload = () => {
     console.log(JSON.parse(reader.result));
   };
   reader.readAsText(blob);
 };
 return convertBlobToJson(blobResult);*/
