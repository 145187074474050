import {Injectable} from '@angular/core';

// let _ = require('lodash');

@Injectable({
  providedIn: 'root',
})

export class ArrayServices {
  public lodash: any;

  constructor(
  ) {
    // this.lodash = _;

  }

  public findById(m: any = {}, ms: any = [], relationNameId: any = 'blockId', arrayNameId: any = 'blockId') {
    return ms.find((block: any) => {
      return block[arrayNameId] === m[relationNameId];
    });
  }

  public deletePhoto(m: any) {

    /*this.api.request('folders/delete-file', {src: m.id + '.png', srcPreview: m.id + '-preview.jpg'})
      .subscribe((src: any) => {
      });*/
  }

  public deleteCollection(collection: any) {
  /*  this.api.request('collections/delete', collection)
      .subscribe((r: any) => {
      });*/
  }

  public deleteFile(model: any, row: any = 'pdf', callback: any = null) {
   /* this.api.request('collections/delete-file-brushes', {id: model.id, ext: '.pdf', row: row})
      .subscribe((r: any) => {
        callback && callback(r);
      });*/
  }

  public remove(m: any, ms: any, showModal: any = false, callback: any = null,) {
    console.log('delete', 'delete');
       // ms.splice(this.i(m, ms), 1);
    if (showModal) {
      // this.alert.confirmationService.confirm({
      //   message: `Remove? ${m.name?m.name:''}`,
      //   accept: () => {
      //     callback && callback(m);
      //     ms.splice(this.i(m, ms), 1);
      //   }
      // });
    } else {
      ms.splice(this.i(m, ms), 1);
    }
  }

  public delete(m: any, ms: any = [], showModal: any = false, callback: any = null) { // @ts-ignore
    return this.remove(m, ms, showModal, callback);
    // return ms.includes(m) ? this.remove(m, ms) : false;
  }

  public toArchive(m: any, callback: any = null) { // @ts-ignore

    this.alert.confirmationService.confirm({
      message: `To archive? ${m?m.name:null}`,
      accept: () => {
        callback && callback(false);
        m.sw = false;
      },
    });
    callback && callback(true);
    m.sw = true;
  }
  public clear(m: any, nameArray:any='list', callback: any = null) { // @ts-ignore

    this.alert.confirmationService.confirm({
      message: `Delete All? ${m.name}`,
      accept: () => {
        callback && callback(false);
        m[nameArray] = [];
      },
    });
    callback && callback(true);
  }

  public lockPassword(m: any, showModal: any = false, callback: any = null) { // @ts-ignore
    if (showModal) {
      // this.alert.confirmationService.confirm({
      //   message: `Lock with password?:${m.text}`,
      //   accept: () => {
      //     // callback && callback(m)
      //     m.lock = true;
      //   }
      // });
    } else {
      // m.lock = false;
      // ms.splice(this.i(m, ms), 1);
    }
  }

  public clearText(m: any, row: any = 'text', showModal: any = false, callback: any = null) { // @ts-ignore
    if (showModal) {
      // this.alert.confirmationService.confirm({
      //   message: `Clear?:${m[row]}`,
      //   accept: () => {
      //     callback && callback(m);
      //     m[row] = '';
      //   }
      // });
    } else {
      m[row] = '';
      // ms.splice(this.i(m, ms), 1);
    }
  }

  public copyText(m: any, row: any = 'text', showModal: any = false, callback: any = null) { // @ts-ignore
    if (showModal) {
      // this.alert.custom(`Copy! : ${m[row]}`);
    } else {
      // ms.splice(this.i(m, ms), 1);
    }
  }

  public add(m: any, ms = [], unic: any = true) {
    // this.alert.custom('Добавлен в конец!');
    // console.log(m, 'add Model');
    // console.log(ms, 'in Models');

    if (unic) {
      // @ts-ignore
      return !ms.includes(m) ? ms.push(m) : false;

    } else {
      // @ts-ignore
      return ms.push(m);
    }
  }


  public count(ms = []) {
    return ms.length;
  }

  public first(m: any, ms: any = []) {
    return ms.indexOf(m) === 0 ;

  }
  public firstLeft(m: any, ms: any = []) {
    return ms.length !== 1;

  }

  public last(m: any, ms: any = []) {
    return ms.indexOf(m) !== ms.length+1;

  }
  public lastLeft(m: any, ms: any = []) {
    return ms.indexOf(m)+1 !== ms.length;

  }

  public moveToOtherArray(selectModel: any, selectArrayMoveFrom = [], selectArrayMoveTo: any[]) {
    console.log(selectModel, 'selectModel');
    let mIndex = this.i(selectModel, selectArrayMoveFrom);
    console.log(selectArrayMoveFrom, 'selectArrayMoveFrom');

    selectArrayMoveFrom.splice(mIndex, 1);
    console.log(selectArrayMoveFrom, 'msDelete');
    selectArrayMoveTo.push(selectModel);
    console.log(selectArrayMoveTo, 'arrayResult');

  }

  public next(m: any, ms = []) {
    return ms[this.i(m, ms) + 1];
  }

  public prev(m: any, ms = []) {
    // this.i(m, ms) - 1
    /*let count = ms.length
    count = count-1
    count == 0?count  =ms.length:count = 0
    return ms[this.i(m, ms) - 1];*/
    return ms[this.i(m, ms) - 1];
  }

 public removeArrayByValues(valuesDeleted:any =[], ms:any = []){
   let forDeletion = [{hex:1,},{hex:3}]

   let arr = [
     {hex:1},
     {hex:2},
     {hex:3},
     {hex:4},
     {hex:5},
     {hex:3},
   ]

   arr = arr.filter(item => !forDeletion.includes(item))
// !!! Read below about array.includes(...) support !!!

   console.log(arr)

 /*   ms = ms.filter((item:any) => !valuesDeleted.includes(item))
   console.log(ms,'ms')
  return ms;*/
  }
 public removeObjectsByValues(valuesDeleted:any =[], ms:any = [],valueFind:any ='id'){
 /*  let forDeletion = [{hex:1,},{hex:3}]

   let arr = [
     {hex:1},
     {hex:2},
     {hex:3},
     {hex:4},
     {hex:5},
     {hex:3},
   ]*/

   /*_.forEach(valuesDeleted, (indi:any) => {
     _.remove(ms, (item:any) => {
       return item[valueFind] === indi[valueFind];
     });
   })*/
   return ms;
  }
  public addToStart(m: any, ms = [], unic: any = true) {
    // this.alert.custom('Добавлен в начало!');

    if (unic) {
      // @ts-ignore
      return !ms.includes(m) ? ms.unshift(m) : false;

    } else {
      // @ts-ignore
      return ms.unshift(m);
    }
  }

  public addToElementStart(addM: any, addElement: any, ms = [], unic: any = true) {
    // this.i(addElement,ms)
    // @ts-ignore
    return ms.splice(this.i(addElement, ms), 0, addM);
  }

  public addToElementEnd(addM: any, addElement: any, ms = [], unic: any = true) {
    // this.i(addElement,ms)
    // @ts-ignore
    return ms.splice(this.i(addElement, ms) + 1, 0, addM);
  }

  public exist(m: any, ms: any) {
    return this.i(m, ms) != -1;
    /*.indexOf('jane') >= 0);*/
   return  ms.includes(m);
  }

  public i(m: any, ms: any) {
    if (ms) {
      return ms.indexOf(m);
    }
  }

  public isArray(arr: any) {
    return arr instanceof Array;
  }

  public selectCheck: any = false;

  public selectAll(ms: any = [], $event: any) {
    ms.map(function(el: any) {
      return el.select = $event;
    });
  }


  checkedInvert(ms: any = []) {
    ms.map(function(el: any) {
      return el.check = !el.check;
    });
  }
  checkToArchive(ms: any = [] ) {
    // this.alert.confirmationService.confirm({
    //   message: `Checked to archive?`,
    //   accept: () => {
    //     // @ts-ignore
    //     ms.map((el: any) => {
    //       if(el.check){
    //         return  el.sw = false;
    //       }
    //     });
    //   },
    // });

  }

  // deleteCurrent() {
  //
  //   this.alert.confirmationService.confirm({
  //     message: 'Are you sure that you DELETE?',
  //     accept: () => {
  //       this.cd.deleteMs(this.cd.selectPhoto, this.cd.photoLib);
  //       this.cd.selectPhoto = null;
  //     }
  //   });
  // }
  // public deleteFile(photo: any,photoLib:any) {
  //   this.alert.confirmationService.confirm({
  //     message: 'Are you sure that you DELETE?',
  //     accept: () => {
  //
  //       this.cd.deleteMs(photo,photoLib );
  //       // this.cd.api.setUrl('https://server.artas.org.ua/generator/');
  //       // this.cd.api.custom(photo, 'delete-file')
  //       //   .subscribe((r: any) => {
  //       //   });
  //     }
  //   });
  //
  // }
  //
  // deleteAll() {
  //
  //   this.alert.confirmationService.confirm({
  //     message: 'Are you sure that you DELETE?',
  //     accept: () => {
  //       this.cd.photoLib = [];
  //     }
  //   });
  //
  // }
  //
  public deleteSelect(ms: any = [],) {
    return ms.filter((el: any) => {
      return el.select == false;
    });

    // this.alert.confirmationService.confirm({
    //   message: 'Are you sure that you DELETE?',
    //   accept: () => {
    //     ms = ms.filter((el: any) => {
    //       return el.select == false;
    //     });
    //   }
    // });


  }

  public filterByRow(searchName: any = 'Text', models: any = [], searchRow = 'cat',) {
    return models.filter((el: any) => {
      return el[searchRow] === searchName;
    });
  }

  public uniq(array: any, row: any = 'categoryName') {
   /* let uniqArray = _.uniqBy(array, row);
    return _.remove(uniqArray, (el: any) => {
      return el.name !== '';
    });*/
    /*return    array.reduce((acc:any, cur:any) => [
         ...acc.filter((obj:any) => obj.id !== cur.id), cur
       ], []);*/
    // return array.reduce((acc:any, current:any) => {
    //   current = current.trim();
    //   if (!acc.includes(current)) {
    //     acc.push(current);
    //   }
    //   return acc;
    // }, []);
  }

  deleteUnChecked(ms: any = []) {
    // this.alert.confirmationService.confirm({
    //   message: 'Are you sure that you DELETE ALL?',
    //   accept: () => {
    //     ms = ms.filter((el: any) => {
    //       return el.select == true;
    //     });
    //   }
    // });
  }


  applyParamsToCurrent(params: any) {
    return params.blurRadius;

  }

  applyParamsToAll(ms: any = [], params: any) {
    ms.map(function(el: any) {
      return el.blurRadius = params.blurRadius;
    });
  }

  applyParamsToChecked(ms: any = [], params: any) {
    ms.map(function(el: any) {
      if (el.select === true) {
        el.blurRadius = params.blurRadius;
      }
    });
  }

  applyParamsToUnChecked(ms: any = [], params: any) {
    ms.map(function(el: any) {
      if (el.select === false) {
        el.blurRadius = params.blurRadius;
      }
    });
  }

  private swap(array: any[], x: any, y: any) {
    var b = array[x];
    array[x] = array[y];
    array[y] = b;
  }


  public moveUp(m: any, ms: any) {
    let index = this.i(m, ms);
    if (index >= 1) {
      this.swap(ms, index, index - 1);
    }
  }

  public moveDown(m: any, ms: any,) {
    let index = this.i(m, ms);
    if (index < ms.length - 1) {
      this.swap(ms, index, index + 1);
    }
  }

  moveEnd(m: any, ms: any) {
    let index = this.i(m, ms);
    if (index < ms.length) {
      this.swap(ms, index, ms.length - 1);
    }
  }

  moveStart(m: any, ms: any) {
    let index = this.i(m, ms);
    if (index < ms.length) {
      this.swap(ms, index, 0);
    }
  }

  public reverse(array: any) {
   /* return _.reverse(array)*/;

  }


  public filterCheckbox(selected: any = [], products: any = [],) {
   /* let filteredResult: any = [];
    selected.forEach((select: any) => {
      let filtered = products.filter((product: any) => {
        return product.name === select.name;
      });
      filteredResult.push(filtered);
    });
    return _.flatten(filteredResult);*/
  }


  public sort(array:any,sortRow:any = 'name'){
  /*  return _.sortBy(array,sortRow);*/
  }
  public loadMore(selected: any = [], products: any = []) {

  }

  public sortNumberAsc(layers: any, row: any = 'cost') {
    /*return _.sortBy(layers, [row]);*/
  }

  public sortNumberDesc(layers: any, row: any = 'cost') {
   /* return this.reverse(_.sortBy(layers, [row]));*/
  }


  deleteTest(object: any, row: any = 'tests') {
    delete object[row];
  }


}
