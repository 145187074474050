import {Component} from '@angular/core';
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {
  ActivatedRoute,
  Router,
  RouterLink
} from "@angular/router";
import {
  basketIcon,
  photosIcon,
  telegramIcon
} from 'src/app/traits/icons-tools';
import {PageCoursesPlansServices} from "../../../services/pages/page-courses-plans.services";
import {MyDataServices} from "../../../services/my-data.services";
import {BasketServices} from "../../../services/common/basket.services";
import {OrderByImageFormComponent} from "../page-artas/order-by-image-form/order-by-image-form.component";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {ActionComponent} from "../page-artas/action/action.component";
import {CardArtasComponent} from "../page-artas/card-artas/card-artas.component";
declare const Swiper;
@Component({
  selector: 'app-page-courses-plans.component.html',
  templateUrl: './page-courses-plans.component.html',
  standalone:true,
  imports: [
    NgForOf,
    NgIf,
    RouterLink,
    OrderByImageFormComponent,
    OverlayPanelModule,
    ActionComponent,
    CardArtasComponent,
  ],
})
export class PageCoursesPlansComponent {
  public showOrderByCard: boolean;
  protected readonly basketIcon = basketIcon;
  protected readonly photosIcon = photosIcon;
  constructor(
    public dataPageCursesPlans: PageCoursesPlansServices,
    public myData:MyDataServices,
    public basketServices: BasketServices,
    private router: Router,

  ) {}
  ngAfterViewInit(){
    this.initSwiper()
  }
  initSwiper(){
    var swiper = new Swiper(".mySwiper", {
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: "auto",
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
      pagination: {
        el: ".swiper-pagination",
      },
    });
  }
  setSelect(card: any) {
    // 'zakazat-sharj-po-foto'
    // this.card =card;
    // this.findInBox();
    this.router.navigate(['/',card.id]);
    window.scrollTo(0, 0);

  }
  protected readonly telegramIcon = telegramIcon;
}
