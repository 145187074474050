import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {modelNew} from '../../../traits/model-tools';
import {BoxServices} from '../../../services/box/box.services';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
import {timeOut} from '../../../traits/datetime-tools';
import {ThemeServices} from '../../../services/common/theme.services';
import {theme} from 'src/app/interfaces/theme';
import {BoxPhotosComponent} from '../box-photos/box-photos.component';
import {
  cropIcon,
  deleteIcon,
  favoriteIcon,
  gridIcon,
  imagePanoramaIcon,
  listIcon,
  saveIcon,
  sortIcon,
  zoomIcon
} from "../../../traits/icons-tools";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {SidebarComponent} from "../../common/sidebar/sidebar.component";
import {AvatarModule} from "primeng/avatar";
import {CropRigionComponent} from "../../crop-rigion/crop-rigion.component";
import {
  addToStart,
  deleteFromArray
} from "../../../traits/array-tools";
import {CdServices} from "../../../services/common/cd.services";
import {ServerImageServices} from "../../../services/servers/server-image.services";
import {EditTextRowService} from "../../../services/common/edit-text-row.service";
import {
  checkSrc,
  isBase64
} from 'src/app/traits/image-tools';
import {Box} from "../../../interfaces/Box";

@Component({
  selector: 'app-box-upload',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    BoxPhotosComponent,
    ScrollPanelModule,
    SidebarComponent,
    JsonPipe,
    AvatarModule,
    CropRigionComponent,
  ],
  templateUrl: './box-upload.component.html'

})
export class BoxUploadComponent implements OnInit, OnDestroy {
  @Input() site: any;
  @Input() model: any;
  @Input() modelLast: any;
  @Input() models: any[];
  @Input() showUpload: any = true;
  @Input() showSort: any = true;
  @Input() dragAtherBlock: any = true;
  @Input() rowName: any;
  @Input() view: any = 'grid';
  @Output() onSet:EventEmitter<any> = new EventEmitter();
  @Output() onFavorite:EventEmitter<any> = new EventEmitter();
  @Output() onShowSortOtherCard:EventEmitter<any> = new EventEmitter();
  @Output() onSave:EventEmitter<any> = new EventEmitter();
  public box: Box  =null;
  public saveClick: any = false;
  public showCropRigion: boolean = false;
  public itemSelect: any = null;
  protected readonly deleteIcon = deleteIcon;
  protected readonly imagePanoramaIcon = imagePanoramaIcon;
  protected readonly sortIcon = sortIcon;
  protected readonly cropIcon = cropIcon;
  protected readonly favoriteIcon = favoriteIcon;
  protected readonly deleteFromArray = deleteFromArray;
  protected readonly isBase64 = isBase64;
  constructor(public cd: CdServices,
              public boxServices: BoxServices,
              public serverImage: ServerImageServices,
              public textServices: EditTextRowService,
  ) {
  }

  ngOnInit() {
    this.boxInit()
  }
 public boxInit() {
    if(this.model){
      this.boxServices.boxSearch(this.model, (boxFromServer: Box) => {
        this.box = boxFromServer;
        this.boxServices.createArrayIsNotExist(this.box,this.rowName)
      });
    }

  }

  ngOnDestroy(){
    this.boxSave();
  }
  public boxSave() {
    if ( this.boxServices.isBoxArray(this.box,this.rowName)){
      this.boxServices.boxEdit(this.model, this.box, () => {
        this.model.countBox = this.boxServices.boxArrayCount(this.box[this.rowName]);
        this.onSave.emit();
        this.saveClick = true;
        timeOut(() => {
          this.saveClick = false;
        }, 1500);
      });
    }
  }

  public changeJPG($event: any, box: any): void {
    box[this.rowName][$event.i] = $event.model;
    this.boxSave()
  }


  public addCropImage(base64: string, models: any[]) {
    let modelCrop: any = modelNew()
    modelCrop.srcPreview = base64;
    addToStart(modelCrop, models)
    this.boxSave();
  }
  public baseToSrc(model: any, sizeMode: any = 'middle') {
    this.serverImage.toImageJpg(model, sizeMode);
    this.boxSave();
  }

  public deleteModelFromServer(model: any, models: any) {
    deleteFromArray(model, models);
    this.textServices.deleteImageFromServer(model);
  }

 public itemCheck(selectPhotoItem: any) {
    selectPhotoItem.check  = !selectPhotoItem.check;
    this.boxSave();
  }
  public itemDelete(selectPhotoItem: any) {
    this.deleteModelFromServer(selectPhotoItem,this.box[this.rowName]);
    this.boxSave();
  }

  protected readonly saveIcon = saveIcon;
  protected readonly listIcon = listIcon;
  protected readonly gridIcon = gridIcon;
  protected readonly zoomIcon = zoomIcon;
}

