<div class="card flex justify-content-center">
<p-toast></p-toast>
<p-confirmDialog>
  <ng-template pTemplate="message" let-message>
    <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
      <i class="pi pi-exclamation-circle text-6xl text-primary-500"></i>
      <p>{{ message.message }}</p>
    </div>
  </ng-template>
</p-confirmDialog>
</div>
