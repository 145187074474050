import {
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import {TabViewModule} from 'primeng/tabview';
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {BadgeModule} from "primeng/badge";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {ColorsImageComponent} from "../../colors-image/colors-image.component";
import {UploaderPhotoComponent} from "../admin/uploader-photo/uploader-photo.component";
import {SidebarComponent} from "../common/sidebar/sidebar.component";
import {CircleWithImageComponent} from "../circle-with-image/circle-with-image.component";
import {ShareImageComponent} from "../share-image/share-image.component";
import {CropRigionComponent} from "../crop-rigion/crop-rigion.component";
import {ImagesViewerGridSlidderComponent} from "../pages/images-viewer-grid-slidder/images-viewer-grid-slidder.component";
import {EyeDropperComponent} from "../eye-dropper/eye-dropper.component";
import {ImageMosaicComponent} from "../image-mosaic/image-mosaic.component";
import {GrayscaleComponent} from "../grayscale/grayscale.component";
import {CameraComponent} from "../pages/camera/camera.component";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";
import {AlbumComponent} from "../album/album.component";
import {ZipComponent} from "../zip/zip.component";
import {BrowserCloseAlertComponent} from "../browser-close-alert/browser-close-alert.component";
import {PackPalettesComponent} from "../pack-palettes/pack-palettes.component";
import {deleteFromArray} from 'src/app/traits/array-tools';
import {
  deleteIcon,
  dotesHorizontalIcon,
  dotesVerticalIcon
} from 'src/app/traits/icons-tools';
import {dateNow} from 'src/app/traits/datetime-tools';
import {CircleWithColorComponent} from "../circle-with-color/circle-with-color.component";
import {ColorIroComponent} from "../color-iro/color-iro.component";
import {RectangleWithColorComponent} from "../rectangle-with-color/rectangle-with-color.component";
import {ColorHistoryComponent} from "../color-history/color-history.component";
import {ColorPanelComponent} from "../color-panel/color-panel.component";


@Component({
  selector: 'app-pallete-generator-color',
  standalone: true,
  imports: [
    TabViewModule,
    SidebarComponent,
    CircleWithImageComponent,
    ColorsImageComponent,
    UploaderPhotoComponent,
    NgIf,
    FormsModule,
    BadgeModule,
    ShareImageComponent,
    CropRigionComponent,
    ImagesViewerGridSlidderComponent,
    NgForOf,
    EyeDropperComponent,
    ImageMosaicComponent,
    GrayscaleComponent,
    ButtonModule,
    CameraComponent,
    BtnDownloadComponent,
    AlbumComponent,
    ZipComponent,
    BrowserCloseAlertComponent,
    PackPalettesComponent,
    CircleWithColorComponent,
    ColorIroComponent,
    RectangleWithColorComponent,
    ColorHistoryComponent,
    ColorPanelComponent,
  ],
  templateUrl: './pallete-generator-color.component.html',
})

export class PalleteGeneratorColorComponent {
  @ViewChild('roundPallete') roundPallete: ElementRef<HTMLCanvasElement>;
  @ViewChild('horizontalPallete') horizontalPallete: ElementRef<HTMLCanvasElement>;
  @ViewChild('verticalPallete') verticalPallete: ElementRef<HTMLCanvasElement>;
  @Input() public imageParent: string = null
  public viewPallete: string = 'circle';
  public showUpload: boolean = true;
  public showDownload: boolean = false;
  public selectColor: string = '#b60101';
  public bgImage: string = 'assets/background_logo_small.png';
  public bgColor: string = 'bg-zinc-700';
  public imagePallete: string;
  public showCropRigion: boolean = false;
  public showEyeDropper: boolean = false;
  public showPreload: boolean = false;
  showCircleComponent: boolean = true;
  showVerticalComponent: boolean = true;
  showHorizontalComponent: boolean = true;
  protected readonly deleteFromArray = deleteFromArray;
  protected readonly dateNow = dateNow;
  protected readonly deleteIcon = deleteIcon;


 public setColor(colorHex: string) {
   console.log(colorHex)
    this.selectColor = colorHex;
    this.reInitAllPalletes()
  }

  public switchHorizontal() {
    this.viewSwitcher('horizontal')
  }
  public switchCircle() {
    this.viewSwitcher('circle')
  }
  public switchVertical() {
    this.viewSwitcher('vertical')
  }

  protected viewSwitcher(viewName: string = 'circle') {
    this.viewPallete = viewName;
    this.reInitAllPalletes()
  }

  protected reInitCircle() {
    this.showCircleComponent = false;
    setTimeout(() => {
      this.showCircleComponent = true;
      // @ts-ignore
      // this.imagePallete = this.roundPallete.getImage();
    }, 50)
  }

  protected reInitHorizontal(component: boolean = false) {
    this.showHorizontalComponent = false;
    setTimeout(() => {
      this.showHorizontalComponent = true;
      // @ts-ignore
      // this.imagePallete = this.horizontalPallete.getImage();
    }, 50)
  }

  protected reInitVertical(component: boolean = false) {
    this.showVerticalComponent = false;
    setTimeout(() => {
      this.showVerticalComponent = true;
      // @ts-ignore
      // this.imagePallete = this.verticalPallete.getImage();
    }, 50)
  }

  protected reInitAllPalletes() {
    this.reInitCircle();
    this.reInitVertical();
    this.reInitHorizontal();
  }

  protected readonly dotesVerticalIcon = dotesVerticalIcon;
  protected readonly dotesHorizontalIcon = dotesHorizontalIcon;
}
