<canvas [hidden]="true"  #canvas></canvas>
<div *ngIf="showResult"   [style.zoom]="scaleCanvas"  >
  <img [src]="imgResult"/>
</div>

<app-btn-download *ngIf="showDownload"
                  [srcImage]="imgResult"
                  [disabled]="false"
></app-btn-download>
<!--<label>
  <input type="checkbox" [(ngModel)]="isBlackAndWhite"
         (ngModelChange)="isBlackAndWhite = !isBlackAndWhite;convertImage()"> Черно-белое изображение
</label>-->
