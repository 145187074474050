
// declare const w3color: any;
declare const chroma:any;
export function parseHSL(str: any) {
  let hsl, h, s, l;
  hsl = str.replace(/[^\d,]/g, '').split(',');   // strip non digits ('%')
  h = Number(hsl[0]);                            // convert to number
  s = Number(hsl[1]);
  l = Number(hsl[2]);
  return [h, s, l];                              // return parts
}

export function harmonizeRound(color: any, start: any, end: any, interval: any) {
  const colors = [];
  const [h, s, l] = parseHSL(color);

  for (let i = start; i <= end; i += interval) {
    const h1 = (h + i) % 360;
    const c1 = `hsl(${h1}, ${s}%, ${l}%)`;
  /*  let result = {

    };*/
    colors.push(hslStringToHex(c1));
  }

  return colors;
}

export function colorTypeConverter(color: any) {
  // return w3color(color);
}

export function getAllTypeColor(color: any) {
  // @ts-ignore
/*  let wcolor = colorTypeConverter(selectColor.hex);
  return {
    'nameColor': wcolor.toName(),
    'hex': wcolor.toHexString(),
    'cmyk': wcolor.toCmykString(),
    'nCol': wcolor.toNcolString(),
    'rgb': wcolor.toRgbString(),
    'hsl': wcolor.toHslString(),
    'hwb': wcolor.toHwbString(),
    red: wcolor.red,
    blue: wcolor.blue,
    green: wcolor.green,
    cyan: wcolor.cyan,
    yellow: wcolor.yellow,
    black: wcolor.black,
    blackness: wcolor.blackness,
    magenta: wcolor.lightness,
    hue: wcolor.hue,
    lightness: wcolor.lightness,
    opacity: wcolor.opacity,
    sat: wcolor.sat,
    valid: wcolor.valid,
    whiteness: wcolor.whiteness,
  };*/
}

export function colorHarmony(color: any) {
  let resultHarmony = {
    // complementary: analogous(color),
    split: splitArray(color),
    triadic: triadicArray(color),
    tetradic: tetradicArray(color),
  };
  return resultHarmony;
}

export function complementaryOneGradient(colorStart: any,setColorsCount:any =15) {
  let colorEnd =  harmonizeRound(hexToHSL(colorStart), 180, 180, 1);
  return gradient(colorStart,colorEnd[0], setColorsCount);
}
export function splitOneGradient(colorStart: any,setColorsCount:any =15) {
  let colorEnd =  harmonizeRound(hexToHSL(colorStart), 150, 210, 60);
  return gradient(colorStart,colorEnd[0], setColorsCount);
}
export function splitTwoGradient(colorStart: any,setColorsCount:any =15) {
  let colorEnd =  harmonizeRound(hexToHSL(colorStart), 150, 210, 60);
  return gradient(colorStart,colorEnd[1], setColorsCount);
}
export function tetradicOneGradient(colorStart: any,setColorsCount:any =15) {
  let colorEnd =  harmonizeRound(hexToHSL(colorStart), 90, 270, 90);
  return gradient(colorStart,colorEnd[0], setColorsCount);
}
export function tetradicTwoGradient(colorStart: any,setColorsCount:any =15) {
  let colorEnd =  harmonizeRound(hexToHSL(colorStart), 90, 270, 90);
  return gradient(colorStart,colorEnd[1], setColorsCount);
}
export function tetradicThreeGradient(colorStart: any,setColorsCount:any =15) {
  let colorEnd =  harmonizeRound(hexToHSL(colorStart), 90, 270, 90);
  return gradient(colorStart,colorEnd[2], setColorsCount);
}
export function triadicOneGradient(colorStart: any,setColorsCount:any =15) {
  let colorEnd =  harmonizeRound(hexToHSL(colorStart), 120, 240, 120);
  return gradient(colorStart,colorEnd[0], setColorsCount);
}
export function triadicTwoGradient(colorStart: any,setColorsCount:any =15) {
  let colorEnd =  harmonizeRound(hexToHSL(colorStart), 120, 240, 120);
  return gradient(colorStart,colorEnd[1], setColorsCount);
}
export function complementaryArray(color: any) {
 return harmonizeRound(hexToHSL(color), 180, 180, 1);
}
export function HSLToHex(h, s, l) {
  s /= 100;
  l /= 100;

  const k = n => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = n => l - a * Math.max(Math.min(k(n) - 3, 9 - k(n), 1), -1);
  let r = 255 * f(0);
  let g = 255 * f(8);
  let b = 255 * f(4);

  return `#${(1 << 24) + (Math.round(r) << 16) + (Math.round(g) << 8) + Math.round(b)}`.slice(1, 7);
}
export function hexToHSL2(hex:any) {
  // Convert hex to RGB first
  let r = 0, g = 0, b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex.substring(1, 3), 16);
    g = parseInt(hex.substring(3, 5), 16);
    b = parseInt(hex.substring(5, 7), 16);
  }

  // Then convert RGB to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if(max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch(max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return { h, s, l };
}
function hslToHex2(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}
export function getAnalogousHexColors(hex:any, angle = 30, results = 3) {
  const hsl = hexToHSL2(hex);
  const analogousColors = [];

  for (let i = 1; i <= results; i++) {
    let hue = (hsl.h + i * angle) % 360;
    analogousColors.push(hslToHex2(hue, hsl.s, hsl.l));
  }

  return analogousColors;
}
export function splitArray(color: any):any[] {

  return harmonizeRound(hexToHSL(color), 150, 210, 60);
}

export function triadicArray(color: any):any[] {
  return harmonizeRound(hexToHSL(color), 120, 240, 120);
}

export function tetradicArray(color: any):any[]{
  return harmonizeRound(hexToHSL(color), 90, 270, 90);
}
export function analogousArray(color: any):any[]{
  return harmonizeRound(hexToHSL(color), 90, 270, 90);
}
export function hexToHSL(hex) {
  let r, g, b;

  // Remove '#' if present
  hex = hex.replace(/^#/, '');

  // Parse hex to RGB
  if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else if (hex.length === 6) {
    r = parseInt(hex.slice(0, 2), 16);
    g = parseInt(hex.slice(2, 4), 16);
    b = parseInt(hex.slice(4, 6), 16);
  } else {
    throw new Error('Invalid hex color');
  }

  // Convert RGB to HSL
  r /= 255, g /= 255, b /= 255;
  const max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  // Convert HSL to string
  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return `hsl(${h}, ${s}%, ${l}%)`;
}

// Example usage:

/*export function analogous(color: any) {
 // return  w3color(color)
 //  let hslColor = colorTypeConverter(color).toHslString();
}*/
export function hslToHex(h, s, l) {
  // Convert HSL to RGB first
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  // Convert RGB to hexadecimal
  const toHex = function toHex(c) {
    const hex = Math.round(c * 255).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  const hexR = toHex(r);
  const hexG = toHex(g);
  const hexB = toHex(b);

  return '#' + hexR + hexG + hexB;
}
function hslStringToHex(hslString) {
  // Parse HSL values from the string
  const regex = /hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/;
  const match = hslString.match(regex);

  if (!match) {
    throw new Error('Invalid HSL color string');
  }

  const h = parseInt(match[1]) / 360;
  const s = parseInt(match[2]) / 100;
  const l = parseInt(match[3]) / 100;

  // Convert HSL to RGB
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  // Convert RGB to hexadecimal
  const toHex = function toHex(c) {
    const hex = Math.round(c * 255).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  const hexR = toHex(r);
  const hexG = toHex(g);
  const hexB = toHex(b);

  return '#' + hexR + hexG + hexB;
}



export function chromaAll(color: string,setColorsCount:number = 15) {
  return {
    saturate: saturateGradient(color,setColorsCount),
    desaturate: desaturateGradient(color,setColorsCount),
    brighten: brightenGradient(color,setColorsCount),
    darken: darkenGradient(color,setColorsCount),
    opacity: opacityGradient(color,setColorsCount),
    luminance: luminanceGradient(color,setColorsCount),
    oposite: opositeGradient(color,setColorsCount),
    analogousOne: analogousOneGradient(color,setColorsCount),
    analogousTwo: analogousTwoGradient(color,setColorsCount),
    analogousThree: analogousThreeGradient(color,setColorsCount),
    complementaryOne: complementaryOneGradient(color,setColorsCount),
    splitOne: splitOneGradient(color,setColorsCount),
    splitTwo: splitTwoGradient(color,setColorsCount),
    triadicOne: triadicOneGradient(color,setColorsCount),
    triadicTwo: triadicTwoGradient(color,setColorsCount),
    tetradicOne: tetradicOneGradient(color,setColorsCount),
    tetradicTwo: tetradicTwoGradient(color,setColorsCount),
    tetradicThree: tetradicThreeGradient(color,setColorsCount),

  };
}
export function harmonyAll(color: string,setColorsCount:number = 15) {

  return {
    // analogous1: analogous1(color,setColorsCount),
  };
}
export function saturateGradient(colorStart: any = '#fa9a03', setColorsCount: any = 15) {
  let colorEnd = chroma(colorStart).saturate(setColorsCount).hex();
  return gradient(colorStart, colorEnd, setColorsCount);
}
export function analogousOneGradient(colorStart: any = '#d9a758', setColorsCount: any = 15) {
  let colorEnd =     harmonizeRound( hexToHSL(colorStart) , 30, 90, 30);
  return gradient(colorStart,colorEnd[0], setColorsCount);
}
export function analogousTwoGradient(colorStart: any = '#d9a758', setColorsCount: any = 15) {
  let colorEnd =     harmonizeRound( hexToHSL(colorStart) , 30, 90, 30);
  return gradient(colorStart,colorEnd[1], setColorsCount);
}
export function analogousThreeGradient(colorStart: any = '#d9a758', setColorsCount: any = 15) {
  let colorEnd =     harmonizeRound( hexToHSL(colorStart) , 30, 90, 30);
  return gradient(colorStart,colorEnd[2], setColorsCount);
}

export function desaturateGradient(colorStart: any, n: any = 15) {
  let colorEnd = chroma(colorStart).desaturate(n).hex();
  return gradient(colorStart, colorEnd, n);
}

export function brightenGradient(colorStart: any, n: any = 15) {
  let colorEnd = chroma(colorStart).brighten(n).hex();
  return gradient(colorStart, colorEnd,n);
}

export function darkenGradient(colorStart: any, n: any = 15) {
  let colorEnd = chroma(colorStart).darken(n).hex();
  return gradient(colorStart, colorEnd, n);
}

export function opacityGradient(colorStart: any, n: any = 15) {
  let colorEnd = chroma(colorStart).alpha(n).hex();
  return gradient(colorStart, colorEnd, n);

}

export function luminanceGradient(colorStart: any, n: any = 5) {
  let colorEnd = chroma(colorStart).luminance(n).hex();
  return gradient(colorStart, colorEnd);
}

export function opositeGradient(colorStart: any, n: any = 5) {
  let colorEnd = invertColor(colorStart);
  return gradient(colorStart, colorEnd);
}

export function next(color: any, n: any) {
  return chroma.scale(color).colors(n);

}

export function gradient(colorStart: any = '#00b89f', colorEnd: any = '#00b89f', n: any = 15) {
  let scale = chroma.scale([colorStart, colorEnd]);
  let colorsArray = scale
    .mode('lch')
    .colors(n);
  let colorsHexMap = colorsArray.map((hex: any) => {
    return hex;
  });
  return colorsHexMap;
}

export function invertColor(col: any) {
  const colors = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
  let inverseColor: any = '#';
  col.replace('#', '')
    .split('')
    .forEach((i: any) => {
      const index = colors.indexOf(i);
      inverseColor += colors.reverse()[index];
    });
  return inverseColor;
}

export function pointBitToHex(c: any) {
  let hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

export function rgbToHex(r: any, g: any, b: any) {
  return '#' + pointBitToHex(r) + pointBitToHex(g) + pointBitToHex(b);
}

export function hexToRgb(p: any)
{
  return 'rgb(' + p[0] + ',' + p[1] + ',' + p[2] + ')';
}
export function hexToRgbArray(hex:any) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}
export function hexToRGBA(hex:any,opacityBrush:any=10,multiplyValue:any = 0.1) {
  let colorArray =   hexToRgbArray(hex);
  // @ts-ignore
  return 'rgba(' + colorArray.r + ', ' + colorArray.g + ', ' + colorArray.b + ', '+ multiplyValue * (opacityBrush) + ')';

}
export function hexToRGBARandom(hex:any,opacityBrush:any=10) {
  let colorArray =   hexToRgbArray(hex);
  // @ts-ignore
  return 'rgba(' + Math.floor(Math.random() * colorArray.r) + ', ' + Math.floor(Math.random() * colorArray.g) + ', ' + Math.floor(Math.random() * colorArray.b) + ', ' + 0.1 * opacityBrush + ' )';

}
export  function generateRandomSmallColors() {
  // @ts-ignore

  // Array.from({ length: this.numSmallCircles }, () => this.getRandomColor());
}
export function  colorsListStandard() {
 return [
    {hex: '#ffffff'},
    {hex: '#c0c0c0'},
    {hex: '#808080'},
    {hex: '#000000'},
    {hex: '#ff0000'},
    {hex: '#800000'},
    {hex: '#ffff00'},
    {hex: '#808000'},
    {hex: '#00ff00'},
    {hex: '#008000'},
    {hex: '#00ffff'},
    {hex: '#008080'},
    {hex: '#0000ff'},
    {hex: '#000080'},
    {hex: '#ff00ff'},
    {hex: '#800080'},
  ];
}
export function  colorsListMonoChrome() {
  return [
    {value:'#e3e8ea'},
    {value: '#bccad0'},
    {value:'#9ba8ae'},
    {value: '#707a7e'},
    {value: '#495054'},
  ];
}
/*protected eventEyeDropper(canvas: any, ctx: any) {
  // if (this.isEnabledEyeDropper()){
  const onMouseClick = (event: any) => {
    // event.preventDefault();
    // let clickPosition = getPosition(event);
    // this.clickStageEyeDropper(ctx, clickPosition);

  };
  canvas.addEventListener('click', onMouseClick);
  canvas.onpointerdown = onMouseClick;
}*/
