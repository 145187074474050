import {cloneModel} from "./model-tools";
declare const _;
export function getIndex(m: any, ms: any[]): number {
  if (ms) {
    return ms.indexOf(m);
  }
}
export function getIndexLast(ms:any[])
{
  return ms.length-1;
}
export function deleteItem(m: any, ms: any[]): void {
  ms.splice(this.i(m, ms), 1);
}
export function deleteFromArray(m: any, ms: any[]) {
 return  ms.splice(getIndex(m, ms), 1);
}

export function addCloneModel(model: any, models: any) {
  let modelClone = cloneModel(model, true);
  addToStart(modelClone,models)

}
export function addToStart(m: any, ms:any[] = [], unic: boolean = true) {
  return unic ? !ms.includes(m) ? ms.unshift(m) : false : ms.unshift(m);
}

export function addToEnd(m: any, ms: any[] = [], unic: any = true) {
  return unic ? !ms.includes(m) ? ms.push(m) : false : ms.push(m);
}

export  function clear(ms: any[]):any[] {
 return  ms = [];
}
export  function  chunk(ms: any[],size:number = 5):any[] {
 return  _.chunk(ms, size);
}
export function filterHidden(ms: any[] = []): any[] {
  return ms.filter((item: any): boolean => {
    return item.sw == false;
  });
}
export function filterShowed(ms: any[] = []): any[] {
  return ms.filter((item: any): boolean => {
    return item.sw == true;
  });
}
export function countHidden(ms: any[] = []):number{
  return countAll(filterHidden(ms));
}
export function countShowed(ms: any[] = []):number{
  return countAll(filterShowed(ms));
}

export function countAll(ms: any[]): number {
  return ms.length;
}
export  function getIndexArray(m: any, ms: any) {
  if (ms) {
    return ms.indexOf(m);
  }
}
