<app-sidebar [show]="showReferenceSidebar"
             (onSave)="showReferenceSidebar =false; selectPhoto = null"
             [showClose]="true"
             [height]="100"
             [width]="100"
             [ext]="'%'"
             [zIndex]="100000"
             [position]="'top'"

>
  @if(selectPhoto){

    <app-image-reference
      *ngIf="(showReferenceSidebar && selectPhoto && photos)"
      [show]="selectPhoto"
      [srcImage]="selectPhoto.srcPreview"
      [images]="photos"
    ></app-image-reference>
    <span *ngIf="selectPhoto.name && selectPhoto.text" >
              {{selectPhoto.name}}
          </span>
    <app-text-slice *ngIf="selectPhoto.text"
                    [text]="selectPhoto.text"
    ></app-text-slice>
  }

</app-sidebar>
<!-- *ngIf="showGrid"-->
<div>
  <app-grid-photos
    [photos]="photos"
    (onClick)="onClickGrid($event)"
  ></app-grid-photos>
</div>
<!--[hidden]="showGrid"-->
<!--<div >-->
<!--  <button class=" absolute z-20 top-0 left-0 btn-controll" (click)=" this.onShowGrid(true);
" >GRID</button>-->
 <!-- <app-swiper-photos

    [photos]="photos"
    (onSlide)="onSlideSwiper($event)"
    (onClick)="onSelect.emit($event)"
  ></app-swiper-photos>-->
<!--</div>-->


<!--
    <div [hidden]="view !== 'rotator'" class="">

    </div>-->

<!-- <ng-container *ngIf="selectImage && view !=='grid'">
  <div class="relative z-20 ">
&lt;!&ndash;<app-image-viewer-tools
[selectImage]="selectImage"
(onClickShowCollection)="showClientCollection = true"
(onClickShowEduter)="showEtuder= true; showSketcher= false;showClientCollection=false"
(onClickShowSketcher)="showSketcher= true;showEtuder= false;showClientCollection=false"
(onClickShowGrid) = "showGridBtn(selectImage)"
(onFlip) = "onFlipItem()"
></app-image-viewer-tools>&ndash;&gt;
  </div>
</ng-container>-->


<!--<ng-container *ngIf="!photos">
  <p>loading....</p>
</ng-container>-->
<!--<app-tool-sidebar [show]="showClientCollection"
                  (onSave)="showClientCollection =false"
                  [showClose]="true"
                  [height]="100"
                  [ext]="'%'"
                  [zIndex]="100000"
                  [position]="'bottom'"
>
<app-client-collections></app-client-collections>
</app-tool-sidebar>-->
<!--<app-tool-sidebar [show]="showSketcher"
                  (onSave)="showSketcher =false"
                  [showClose]="true"
                  [height]="100"
                  [ext]="'%'"
                  [zIndex]="100000"
                  [position]="'bottom'"
>
  <app-draw-sketches-page *ngIf="showSketcher"></app-draw-sketches-page>
</app-tool-sidebar>-->
<!--<app-tool-sidebar [show]="showEtuder"
                  (onSave)="showEtuder =false"
                  [showClose]="true"
                  [height]="100"
                  [ext]="'%'"
                  [zIndex]="100000"
                  [position]="'bottom'"
>
<app-draw-etude-page *ngIf="showEtuder"></app-draw-etude-page>
</app-tool-sidebar>-->
