import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
import {
  brightenGradient,
  darkenGradient,
  desaturateGradient,
  opositeGradient,
  saturateGradient,
  tetradicThreeGradient
} from "../../services/common/color-tools";
import {chunk} from "../../traits/array-tools";
import {srcSketch} from "../../traits/image-tools";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";


@Component({
  selector: 'app-colors-rectangale',
  templateUrl: './colors-rectangale.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    JsonPipe,
    BtnDownloadComponent
  ]
})
export class ColorsRectangaleComponent {
  @ViewChild('canvas', {static: true}) canvas: ElementRef<HTMLCanvasElement>;
  @Input() parentColor: string = '#b60101';
  @Input() orientation: string = 'hor';
  @Input() widthCanvas: any =300;
  @Input() heightCanvas: any  = 150;
  @Input() showDownload: boolean = true;

  @Input() colors: string[] = [
    'red',
    'orange',
    'yellow',
    'green',
    'blue',
    'indigo',
    'violet',
    'purple',
    'cyan',
    'magenta',
    'pink',
    'brown'
  ];
  @Input() scaleCanvas: string = '75%';
  @Input() colorsEyeDropper: string[] = [
    'red',
    'orange',
    'yellow'
  ];
  @Input() customColors: string[] = [
    'red',
    'orange',
    'yellow'
  ];
  @Input() imageSrc: string = 'assets/avatars animals/10005358.png';
  @Input() imageSize: number = 225;
  @Input() cellSize: number = 50;
  @Input() smallCircleRadius: number = 10;
  @Input() numSmallCircles: number = 50;
  @Input() numCustomCircles: number = 50;
  @Input() radiusSaturate: number = 200;
  @Input() customCircleRadius: number = 180;
  @Input() textLogo: string = 'art-club.info';
  @Input() textSize: number = 12;

  @Output() onChange = new EventEmitter<string>();
  @Output() onGetPallete = new EventEmitter<string>();
  @Output() colorClicked = new EventEmitter<string>();
  @Output() imageSaved = new EventEmitter<void>();
  public srcResult: string;
  ngAfterViewInit() {
    this.draw();
  }

  drawLogo(ctx: CanvasRenderingContext2D, centerX: number, centerY: number) {
    ctx.font = `${this.textSize}px Arial`;
    ctx.fillStyle = 'white'//this.colors[0];
    // ctx.globalCompositeOperation = 'source-over'
    ctx.fillText(this.textLogo, centerX, centerY)


  }
  draw() {
    let canvas: any = this.canvas.nativeElement;
    const ctx: any = canvas.getContext('2d');
    let colorGroup = chunk(this.colors,15)

    if (this.orientation === 'hor') {
      colorGroup[0]?this.drawColorGroup(ctx, colorGroup[0], 0, 0, this.orientation):null;
      colorGroup[1]?this.drawColorGroup(ctx, colorGroup[1], 0, 20, this.orientation):null;
      colorGroup[2]?this.drawColorGroup(ctx,colorGroup[2] , 0, 40, this.orientation):null;
      colorGroup[3]?this.drawColorGroup(ctx, colorGroup[3], 0, 60, this.orientation):null;
      colorGroup[4]?this.drawColorGroup(ctx, colorGroup[4], 0, 80, this.orientation):null;
      colorGroup[5]?this.drawColorGroup(ctx, colorGroup[5], 0, 100, this.orientation):null;
    } else {
      colorGroup[0]?this.drawColorGroup(ctx, colorGroup[0], 0, 0, this.orientation):null;
        colorGroup[1]?this.drawColorGroup(ctx, colorGroup[1], 20, 0, this.orientation):null;
          colorGroup[2]?this.drawColorGroup(ctx,colorGroup[2], 40, 0, this.orientation):null;
            colorGroup[3]?this.drawColorGroup(ctx, colorGroup[3], 60, 0, this.orientation):null;
              colorGroup[4]?this.drawColorGroup(ctx, colorGroup[4], 80, 0, this.orientation):null;
                colorGroup[5]?this.drawColorGroup(ctx, colorGroup[5], 100, 0, this.orientation):null;
    }
    this.drawLogo(ctx, this.textSize, canvas.height - 5);
    const image: string = canvas.toDataURL('image/png');
    this.srcResult = image;
    this.onGetPallete.emit(this.srcResult);
  }

  drawColorGroup(ctx: CanvasRenderingContext2D, colors: string[], x: number, y: number, direction: any = 'hor', cellWidth: number = 15, padding: number = 5,): void {
    colors.forEach((color, index) => {
      let xPos = x;
      let yPos = y;
      if (direction === 'hor') {
        xPos += index * (cellWidth + padding); // Учитываем отступ между ячейками
      } else {
        yPos += index * (cellWidth + padding); // Учитываем отступ между ячейками
      }
      this.drawColorCell(ctx, color, xPos, yPos);
    });
  }
  drawColorCell(ctx: CanvasRenderingContext2D, color: string, x: number, y: number, wCell: number = 15): void {
    ctx.fillStyle = color;
    ctx.fillRect(x, y, wCell, wCell);
  }
  public getImage() {
    const canvas = this.canvas.nativeElement;
    const image: string = canvas.toDataURL('image/png');
    return image;
  }

  protected readonly imageSketch = srcSketch;
}
