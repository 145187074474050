import {Injectable} from '@angular/core';
import {LocalStorage} from "./local-storage";
import {
  jsonDecrypt,
  jsonEncrypt
} from "../../traits/crypt-tools";

@Injectable({
  providedIn: 'root',
})
export class ActivityServices {
  public data: any = {
    lastViewId: null,
  }
  private STORAGE_KEY: string = 'activity';

  constructor() {
    this.init();
    this.load();
  }

  public setStorageData(clientData: any = {}) {
    return LocalStorage.setItem(this.STORAGE_KEY, jsonEncrypt(clientData));// LocalStorage.setStorageData(this.STORAGE_KEY, );
  }

  public save() {
    this.setStorageData(this.data)
    console.log(this.data, 'saveActivity')
  }

  public init() {
    if (!this.getStorageData()) {
      this.save();
      this.load()
      console.log(this.data, 'saveActivity')
    } else {
      this.load()
    }
  }

  public load() {
    return this.data = this.getStorageData();
  }

  public getStorageData() {

    let exist: any = LocalStorage.getItem(this.STORAGE_KEY)
    return exist ? jsonDecrypt(exist) : null;
    // return exist?jsonDecrypt(exist)
  }

}


