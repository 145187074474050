import {Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root',
})


export class ClientAlertServices {

  public successMessege() {
    // this.alert.custom('Thanks for supporting us! ❤❤❤');
    return {
      show: true,
      type: 'success',
      text: `Welcome to the Art-club!`,
    };
  };
  public emailNotExistMessege() {
    return {
      show: false,
      type: 'error',
      text: 'This email is not on patreon.\n' +
        'Enter your Patreon E-mail and sign in!',
    }
  };



}
