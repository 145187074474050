import {Injectable, Input} from '@angular/core';
@Injectable({
  providedIn: 'root',
})


export class CropRigionServices {
  public crops: any = [];
  public rowName: any = 'srcPreview';
  public cropImage: any;

  public selectCrop: any;
  public close: any = false;
  public showCrop: any = false;
  public showCropResult: any = false;
  public cropResult: any;

  constructor(
  ) {
  }
  public countList() {
    return this.crops.length;
  }
  public cropsExist() {
    return   this.countList()>1;
  }
  public isSelect() {
    return   this.showCrop === true && this.selectCrop && this.selectCrop.srcPreview;
  }
  public cropsNotExist() {
    return  this.countList()<1;
  }
  public modelCrop(base64: any) {
   /* let model = {
      id: StringTools.randomId(),
      createAt: dateNow(),
      srcPreview: base64
    };
    return model;*/
  }

  public addCrop(base64: any) {

 /*   this.array.addToStart(this.modelCrop(base64), this.crops, false);
    console.log(this.crops);*/
  }

  public reInitCrop(base64: any) {
 /*   this.cropResult = base64;
    this.showCropResult = false;
    reInit(() => {
      this.showCropResult = true;

    });*/
  }

  public enableCropItem(model: any) {
    this.showCrop = true;
    this.selectCrop = model;
  }

  public disableCropItem() {
    this.showCrop = false;
    this.selectCrop = null;
  }

  public onClose() {
    this.close = false;
    // this.crops = [];
    this.cropResult = null;
  }
  public  cropReInit(base64:any){
    this.reInitCrop(base64);
    // this.scroll.reInitScrollerId()
  }

  public cropClose(){
    this.showCrop =false;
    this.disableCropItem();
    // !this.crops ? null : this.konvaTools.goCropTab();
  }

}
