import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-btn-premium-product',
  templateUrl: './btn-premium-product.component.html',
  standalone:true,
  imports:[NgIf]


})
export class BtnPremiumProductComponent  {
  @Input() gp: any;
  @Input() srcPreview: any;
  @Input() srcIcon: any;
  @Input() srcActive: any;
  @Input() activeText: any;
  @Input() showSrcIcon: any = false;
  @Input() label: any;
  @Input() count: any;
  @Input() active: any= false;
  @Input() animateClass: any;
  @Input() colorClass: any;
  @Input() absoluteClass: any;
  @Input() height: any = 40;
  @Input() width: any = 40;
  @Output() onClick:EventEmitter<any> = new EventEmitter();

  public clickBtn(){
    this.active = true;
    setTimeout(() => {
      this.onClick.emit()
      this.active = false;
    }, 2000);
}
}
