<div
  [class]="'bgColor'"
  [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
  class="mt-[75px]  bg-[length:50px_50px] bg-opacity-10  bg-repeat flex justify-center items-center flex-col bg-gradient-to-br from-gray-800 to-gray-900 relative animate-gradient-x">
<app-color-panel
  (onColor)="setColor($event)"
></app-color-panel>
  <div [style.width.px]="windowWidth()"
       class="flex overflow-x-auto py-4 space-x-4">
      <ng-container *ngFor=" let itemBtnHarmony of btns">
        <img
          (click)=" reInitHarmony(itemBtnHarmony.name)"
          class="rounded-md w-[75px] h-[75px] shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse "
             [src]="itemBtnHarmony.srcPreview"
             [alt]="itemBtnHarmony.name"
        >
      </ng-container>
  </div>
  <div class="m-3 p-3">
    <div class="w-full  p-3 rounded-lg shadow-lg">
      <app-circle-with-harmony
        #roundPallete
        *ngIf="showCircleComponent"
        [parentColor]="selectColor"
        [nameHarmony]="nameHarmony"
        (onGetPallete)="imagePallete = $event;
                          "
      ></app-circle-with-harmony>
    </div>
  </div>
  <div class="mt-1 mb-1">
    <app-btn-download
                      [srcImage]="imagePallete"
                      [disabled]="!imagePallete"
                      [showCoin]="true"
                      [textBtn]="'Download'"
    ></app-btn-download>
  </div>
</div>

