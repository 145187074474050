import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
import {deleteFromArray} from "../../traits/array-tools";
import {
  deleteIcon,
  moveIcon,
  saveIcon,
  zoomIcon
} from "../../traits/icons-tools";
import {ZipComponent} from "../zip/zip.component";
import {SortablejsModule} from "@maksim_m/ngx-sortablejs";
import {SortableOptions} from "sortablejs";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";

@Component({
  selector: 'app-pack-palettes',
  templateUrl: './pack-palettes.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    JsonPipe,
    ZipComponent,
    SortablejsModule,
    OverlayPanelModule,
    BtnDownloadComponent,
  ]
})
export class PackPalettesComponent {
  @Input() images: any[] = [];
  @Output() onClose = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onFinishGenerate = new EventEmitter();
  public sortableOptions:SortableOptions = {
    animation: 150,
    group: 'cards',
    handle: '.handle',
    onUpdate: (event: any) => {
      console.log(event);
    }
  }

  constructor() { }

  protected readonly deleteFromArray = deleteFromArray;
  protected readonly moveIcon = moveIcon;
  protected readonly deleteIcon = deleteIcon;
  protected readonly zoomIcon = zoomIcon;
  protected readonly saveIcon = saveIcon;
}
