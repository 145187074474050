<app-sidebar
  [show]="this.showCardOrder"
                  (onSave)="hide();"
                  [height]="100"
                  [width]="100"
                  [ext]="'%'"
                  [extHeight]="'%'"
                  [showClose]="true"
                  [position]="'right'"
                  [zIndex]="100000"
>


  <section *ngIf="(this.showCardOrder && selectModel)" class="antialiased  bg-[#3e122f] text-[#f0a9a7] min-h-screen p-4">
 <div class="h-full">
      <div>
        <div class="relative px-4 sm:px-6 lg:px-8 max-w-lg mx-auto">
          <img *ngIf="selectModel.srcPreview" class="rounded-t shadow-lg"
               [src]="selectModel.srcPreview"
               width="460"
               height="180"
               alt="Order Product Images"
          />
        </div>
        <div class="relative px-4 sm:px-6 lg:px-8 pb-8 max-w-lg mx-auto">
          <div class="bg-[#4d2052] px-8 pb-6 rounded-b shadow-lg">
            <div class="text-center mb-6">
              <div class="mb-2">
                <img *ngIf="selectModel.srcPreview"
                     class="-mt-8 inline-flex rounded-full"
                     [src]="selectModel.srcPreview"
                     width="64" height="64" alt="User"/>
              </div>
              <div *ngIf="selectModel.info" class="font-bold text-xl mb-2">
                <p  class="text-amber-500 text-base">{{selectModel.info}}</p>
              </div>
              <h1 *ngIf="selectModel.name"
                  class="text-xl leading-snug text-[#f0a9a7] font-semibold mb-2">
                Вибраний : {{selectModel.name}} </h1>
              <div *ngIf="selectModel.desc" class="text-sm">
                {{selectModel.desc}}
              </div>
              <div class="relative">
            <!--    <span *ngIf="(selectModel.cost)"
                      class=" desc-block old-cost line-through "
                >{{selectModel.cost}}</span>-->
                <span *ngIf="(selectModel.cost)"
                      class="new-cost absolute p-1 m-1 italic font-bold text-[#f7c570]  -top-3 -right-3 bg-[#812828]/90 drop-shadow-lg  rounded-tl-lg rounded-br-lg">
        {{ getDiscount(selectModel.cost) + ' грн.' }}
      </span>
              </div>


              <!-- Card form -->
              <div>
                <div class="">
                  <div class="w-full">
                    <label class="block text-sm font-medium mb-1" for="card-expiry">
                      Ім'я <span class="text-red-500">*</span></label>
                    <input pInputText
                           id="card-expiry"
                           class="p-1 m-1"
                           type="text"
                           [minLength]="5"
                           [(ngModel)]="form.nameClient"
                           placeholder="Введіть ім'я"
                    >

                  </div>
                  <div class="w-full">
                    <label class="block text-sm font-medium mb-1"
                           for="card-cvc">Телефон
                      <span class="text-red-500">*</span>
                    </label>
                    <input id="card-cvc"
                           pInputText
                           class="p-1 m-1"
                           type="text"
                           [minLength]="5"
                           [(ngModel)]="form.phone"
                           placeholder="Введіть телефон"
                    >

                  </div>
                </div>
                <div class="space-y-4">
                  <!-- Card Number -->
                  <div>

                    <label class="block text-sm font-medium mb-1"
                           for="card-nr">
                       Коментар
                      <span class="text-red-500"></span>
                    </label>

                    <textarea id="card-nr" pInputTextarea
                              class="p-1 m-1 w-full"
                              [(ngModel)]="form.comment"
                              [minLength]="5"
                              [placeholder]="' Введіть коментар'"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="flex justify-center mb-6">
                <button [disabled]="!form.validation"
                        (click)="submit(selectModel)"
                        class="btn-custom desc-block"
                >
                  <i class="fa-regular fa-paper-plane"></i>
                  Відправити
                </button>
               <!-- <button (click)=""
                        class="btn-custom desc-block ml-2">
                  <i class="fa-regular fa-rectangle-xmark"></i>
                  Повернуться
                </button>-->
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</app-sidebar>
