import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BoxServices} from 'src/app/services/box/box.services';
import {SidebarComponent} from '../../common/sidebar/sidebar.component';
import {
  JsonPipe,
  NgIf
} from '@angular/common';
import {ImagesViewerGridSlidderComponent} from '../../pages/images-viewer-grid-slidder/images-viewer-grid-slidder.component';
import {PreloadComponent} from "../../pages/preload/preload.component";

interface Box {

}


@Component({
  selector: 'app-box-viewer-photos',
  templateUrl: './box-viewer-photos.component.html',
  standalone: true,
  imports: [
    SidebarComponent,
    JsonPipe,
    ImagesViewerGridSlidderComponent,
    NgIf,
    PreloadComponent
  ]

})
export class BoxViewerPhotosComponent implements OnInit, OnDestroy {
  @Input() rowName: string = 'photos';
  @Input() boxId: any;
  public box: object | undefined | null;
  @Input() show: any = false;
  @Input() selectItem: any;
  @Output() onDestroy: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onGeneratePallete = new EventEmitter<any>();
  constructor(public boxServices: BoxServices) {

  }

  ngOnInit(): void {
    this.init(this.boxId);

  }
 public init(boxId:string){
    // boxId = boxId.replace(/\s/g, "")
   console.log(boxId)
      this.boxServices.findCardBoxById(boxId,
        (responseBox: any): void => {
          this.box = responseBox;
        });
  }
ngAfterViewInit(){
}
  ngOnDestroy():void {
    this.close();
  }

  public close() {
    this.show = false;
    this.box  = null;
    // this.boxId  = null;
    this.onDestroy.emit();
  }

}
