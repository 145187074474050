import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtClubServices} from '../static/static-art-club.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {Site} from '../../interfaces/site';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageMidjourneyServices{
  public SERVER_NAME: string = 'midjourney';
  public DOMAIN: string = 'art-club.artas.org.ua/midjourney';
  public route: string = 'midjourney';

  public fromServer: boolean = false;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'Midjourney',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'Midjourney',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Midjourney: Your Partner in the World of AI Image Generation',
    description: 'Discover Midjourney - the revolutionary app for creating visual content with AI. Instantly transform text descriptions into unique images. Expand the horizons of your creativity now!',
    text: 'Introduction: \n' +
      'Artificial Intelligence (AI) tirelessly works to expand the horizons of creativity and design. Midjourney represents a cutting-edge AI image generating application that provides users the ability to instantly transform text descriptions into visual content. Whether for artists, designers, or those seeking visual inspiration, Midjourney serves as a powerful tool, unlocking an unlimited world of creative possibilities.\n' +
      '\n' +
      'Main Content:\n' +
      '\n' +
      'Turn Words into Images with Midjourney\n' +
      'Our Midjourney app leverages advanced AI technology to interpret your textual request and create an image brimming with detail and creative intent. Enter any phrase - from "a forest glade in the mist" to "futuristic cityscape" - and Midjourney will instantly bring your words to life.\n' +
      '\n' +
      'Who is Midjourney For?\n' +
      'Midjourney is perfect for those in search of a continuous source of creative inspiration. Artists, designers, marketers, content creators, and educational institutions will discover in our app an unprecedented tool for visualizing and generating visual content.\n' +
      '\n' +
      'Key Benefits of Midjourney:\n' +
      '- Speed and accuracy in transforming text requests into unique images.\n' +
      '- Limitless space for creativity and experiments.\n' +
      '- An intuitive user interface, accessible to people of any skill level.\n' +
      '- Time and cost savings in high-quality visual content creation.\n' +
      '\n' +
      'How to Get Started with Midjourney?\n' +
      'To start creating dream images in Midjourney, simply install the app, register, and begin submitting your ideas as textual requests. Your imagination becomes reality in moments!\n' +
      '\n' +
      'Conclusion:\n' +
      'Join the creative revolution with Midjourney and let AI bring your most ambitious visual concepts to life. Take control of an endless creative tool and embark on a journey into a world where text descriptions are transformed into stunning images with the press of a button. Midjourney is not just an application; it\'s your partner in a journey through the world of art and design, limited only by your imagination.\n' +
      '\n' +
      'SEO Keywords: Midjourney, AI image generation, text to image AI, creative AI app, AI ideas visualization, AI deep learning, digital art, innovative AI creativity.Dive into the realms of boundless imagination with Midjourney, your premier AI companion in the quest for visual innovation. With this pioneering application, witness the transformation of mere words into vivid, detailed imagery that captures the essence of your creative vision. Midjourney stands at the forefront of AI technology, offering a seamless conduit between the abstract and the tangible, where every textual prompt is a precursor to visual marvels. \n' +
      '\n' +
      'Designed to cater to the expansive needs of artists, designers, and visionaries, Midjourney is more than just an app; it\'s a beacon for those navigating the vast sea of creativity. Its advanced algorithms work tirelessly, ensuring that each creation is not only unique but also resonates with the intent behind your words. \n' +
      '\n' +
      'Embrace the future of art and design where Midjourney acts as the key to unlocking a universe where creativity knows no bounds. The very fabric of how visual content is created is being reshaped, offering speed, precision, and limitless possibilities at your fingertips. Whether you\'re crafting futuristic landscapes or seeking fresh inspiration, Midjourney stands ready. \n' +
      '\n' +
      'Join the vanguard of the creative revolution. Let Midjourney be the canvas for your imagination, transforming every text into a masterpiece. Welcome to your new journey in art and design, where every idea is a gateway to visual discovery.',
    h1: 'Midjourney - A New Era of Image Generation with AI',
    h2: 'Midjourney turning Your Words into Visual Masterpieces',
    h3: 'Midjourney - A Revolutionary AI Image Generation App',
    h4: 'Key Benefits of Using Midjourney',
    h5: 'Who is Midjourney Perfect For',
    textEndPage: '',
    keywords: [
      'Midjourney',
      'AI image generation',
      'text to image conversion',
      'AI creativity app',
      'AI image creation',
      'ideas visualization through AI',
      'art innovations',
      'digital creativity',
      'deep learning technologies'
    ],
  };
  public site: any

  constructor(public server: ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  getSite() {
    /*  if (this.fromServer) {
        return this.getSiteFromServer();
      } else {
        return this.getSiteStatic();
      }*/

  }

  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }

  public saveSite(site: object) {
    this.site = site
    this.downloadSiteBtn(this.site);
    // let server: any = inject(ServerSiteServices)
    this.server.saveSite(this.DOMAIN, this.site);
  }

  getSiteStatic() {
    return this.site = inject(StaticArtClubServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer() {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: Site): void => {
      this.site = responseSite;
    });
    return this.site;
  }

}
