import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
import {
  brushIcon,
  closeSquareIcon,
  deleteIcon,
  downloadIcon,
  editIcon,
  eyeOpenIcon,
  favoriteIcon,
  imagesIcon,
  zoomPlusIcon
} from "../../traits/icons-tools";
import {deleteFromArray} from "../../traits/array-tools";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";
import {dateNow} from "../../traits/datetime-tools";
import { saveAs } from 'file-saver';
import {BadgeModule} from "primeng/badge";
import {SidebarComponent} from "../common/sidebar/sidebar.component";
import {ZoomImageComponent} from "../zoom-image/zoom-image.component";
import {CropRigionComponent} from "../crop-rigion/crop-rigion.component";
import {GrayscaleComponent} from "../grayscale/grayscale.component";
import {windowWidth} from "../../traits/window-tools";
import {SketchConverterComponent} from "../sketch-converter/sketch-converter.component";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {SliderModule} from "primeng/slider";
import {ImageEditorComponent} from "../image-editor/image-editor.component";
import {ImageTracingComponent} from "../image-postorizer/image-tracing.component";
import {CroppieComponent} from "../croppie/croppie.component";
import {TextSliceComponent} from "../text-slice/text-slice.component";
@Component({
  selector: 'app-image-reference',
  templateUrl: './image-reference.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    BadgeModule,
    SidebarComponent,
    ZoomImageComponent,
    CropRigionComponent,
    GrayscaleComponent,
    OverlayPanelModule,
    SliderModule,
    ImageEditorComponent,
    TextSliceComponent,
  ]
})
export class ImageReferenceComponent {
  @Input() public srcImage: string;
  @Input() images: any[];
  @Input() showEdit:false
  public imageCrop: string;
  public showCropRigionBtn: boolean = true;
  public showListHorisont: boolean =true;
  public show: boolean = false;
  public showCropRigion: boolean = false;
  public showGrayscale: boolean =false;
  public showSketch: boolean = false;
  public showImagePostoraze: boolean = false;
  public showOriginalSketch: boolean = false;
  brightness:number = 100;
  contrast:number = 100;
  saturation:number = 100;
  public showZoom: boolean =false;
  @Output() onClose = new EventEmitter();
  @Output() onCrop = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  constructor() { }
ngOnInit(){

}
  ngAfterViewInit(): void {
    this.imageCrop = this.srcImage;
    this.showZoom =true;
  }
  protected readonly eyeOpenIcon = eyeOpenIcon;
  protected readonly closeSquareIcon = closeSquareIcon;
  protected readonly editIcon = editIcon;


  protected readonly zoomPlusIcon = zoomPlusIcon;
  protected readonly favoriteIcon = favoriteIcon;
  protected readonly windowWidth = windowWidth;

  public reInitSketch(nameHarmony:string='tetradic') {
    // this.showSketch = false;
    this.showZoom = false;
    setTimeout(() => {
      // this.showSketch = true;
      this.showZoom = true;

    }, 50)
  }
  public reInitImagePostoraze(nameHarmony:string='tetradic') {
    this.showImagePostoraze = false;
    setTimeout(() => {
      // this.showSketch = true;
      this.showImagePostoraze = true;

    }, 50)
  }
  enableImagePostoraze() {
    this.showImagePostoraze =!this.showImagePostoraze;
    if(!this.showImagePostoraze){
      this.imageCrop = this.srcImage;
    }
    this.reInitSketch()
  }

  showSettings: boolean =false;

  enableSketch() {
    this.showSketch =!this.showSketch;
    if(!this.showSketch){
      this.imageCrop = this.srcImage;
    }
    this.reInitSketch()
  }

  protected readonly imagesIcon = imagesIcon;
  protected readonly brushIcon = brushIcon;
}
