import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtClubServices} from '../static/static-art-club.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {Site} from '../../interfaces/site';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageImageGetColorServices {
  public SERVER_NAME: string = 'image-get-color';
  public DOMAIN: string = 'art-club.artas.org.ua/image-get-color';
  public route: string = 'image-get-color';

  public fromServer: boolean = false;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'Image to grayscale',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'Image to grayscale',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Here\'s a text of approximately 3,000 characters on the topic of a color palette generator app:\n' +
      '\n' +
      '---\n' +
      '\n' +
      'Unlock the Power of Color with Our Easy-to-Use Color Palette Generator\n' +
      '\n' +
      'In the world of design, color plays a critical role. Whether you\'re working on a website, a piece of digital art, or a branding project, the colors you choose can make or break the entire visual experience. But finding the perfect color scheme can be challenging, especially when you\'re starting with a blank canvas. That’s where our color palette generator comes in. \n' +
      '\n' +
      'Our tool is designed to simplify the process of creating custom color palettes, making it easy for anyone—whether a seasoned designer or a beginner—to find the perfect colors for their project. With just a click, you can extract colors from any image and generate a beautiful palette that captures the essence of the photo. \n' +
      '\n' +
      '### How It Works: Instant Color Extraction\n' +
      '\n' +
      'The process of creating a color palette with our tool is incredibly simple. You start by uploading an image. It could be a photograph, a digital artwork, or even a screenshot. Once uploaded, our tool automatically analyzes the image and extracts the most prominent colors. In just seconds, you’ll have a palette of colors that reflects the image\'s mood and tone.\n' +
      '\n' +
      'Our advanced algorithm identifies the key colors in the image, ensuring that the palette is both balanced and representative of the original photo. You don’t have to worry about the technical details—just upload your image and let our tool do the rest.\n' +
      '\n' +
      '### Download and Customize Your Palette\n' +
      '\n' +
      'Once your colors are extracted, you can download your palette in various formats. Whether you need a HEX code for web design, an RGB value for digital art, or a simple color swatch for inspiration, our tool provides it all. This flexibility makes it perfect for a wide range of projects, from web design and branding to digital painting and interior design.\n' +
      '\n' +
      'But the customization doesn’t stop there. Our tool also allows you to tweak the palette to better suit your needs. You can adjust the brightness, contrast, and saturation of each color, ensuring that your palette is perfectly tailored to your project. This level of control allows for endless creativity and experimentation.\n' +
      '\n' +
      '### Why Use a Color Palette Generator?\n' +
      '\n' +
      'Creating a color palette from scratch can be time-consuming and requires a good eye for color theory. Even experienced designers can find it challenging to create a palette that is both harmonious and impactful. A color palette generator takes the guesswork out of the equation, providing you with a reliable starting point for your design.\n' +
      '\n' +
      'Moreover, using colors extracted from images ensures that your palette is rooted in the real world. Natural images, such as landscapes or photos of everyday objects, often contain subtle color combinations that are difficult to recreate manually. Our tool captures these nuances, allowing you to bring a piece of the real world into your design.\n' +
      '\n' +
      '### Who Can Benefit from Our Color Palette Generator?\n' +
      '\n' +
      'Our color palette generator is a versatile tool that can benefit a wide range of users:\n' +
      '\n' +
      '1. Web Designers: Quickly create color schemes that match your website\'s aesthetic.\n' +
      '2. Graphic Designers: Extract color palettes that align with your brand\'s identity.\n' +
      '3. Artists: Use the tool to find new color inspiration for your digital artwork.\n' +
      '4. Interior Designers: Generate palettes based on real-world images to use in your projects.\n' +
      '5. Social Media Managers: Create visually cohesive social media posts by matching colors from your images.\n' +
      '\n' +
      'Whether you’re working on a professional project or just exploring color combinations for fun, our tool can help you find the perfect palette.\n' +
      '\n' +
      '### The Importance of Color in Design Color is more than just a visual element—it’s a powerful tool for communication. The right color palette can evoke emotions, convey messages, and even influence behavior. For example, warm colors like red and orange can create a sense of urgency or excitement, while cool colors like blue and green can have a calming effect.\n' +
      '\n' +
      'In branding, colors are used to create a strong identity that resonates with the target audience. Think about iconic brands like Coca-Cola with its bold red or Starbucks with its calming green. These color choices are not accidental—they’re carefully selected to represent the brand\'s values and connect with consumers on a deeper level.\n' +
      '\n' +
      'Our color palette generator helps you tap into this power by giving you the tools to create color schemes that not only look great but also communicate the right message.\n' +
      '\n' +
      '### Start Creating Your Perfect Palette Today\n' +
      '\n' +
      'Ready to take your design projects to the next level? Our color palette generator is here to help. With just a click, you can extract colors from any image and start building a palette that’s uniquely yours. Whether you’re looking for inspiration or need a specific color scheme for a project, our tool makes it easy to find and create the perfect palette.\n' +
      '\n' +
      'Don’t let the complexities of color theory hold you back. Try our color palette generator today and discover how easy and fun it can be to create beautiful, cohesive color schemes that elevate your designs.\n' +
      '\n' +
      '---\n' +
      '\n' +
      '',
    description: 'Discover the easiest way to extract colors from any image and create a custom color palette with our advanced color picker. With our tool, you can instantly get colors from any photo or image and generate a unique palette tailored to your needs. Perfect for designers, artists, and anyone seeking inspiration, our color picker simplifies the process. Just click, extract, and download your palette in a few simple steps. Whether you\'re working on a design project or exploring color combinations, our color picker tool makes it easy to create a personalized color palette effortlessly.',
    text: 'Extract Colors from Images with a Click - Create and Download Custom Color Palettes',
    h1: 'Extract Colors from Any Image with Just a Click',
    h2: 'Create Custom Color Palettes from Images in Seconds',
    h3: 'Download Your Personalized Color Palette Instantly',
    h4: 'Why Choose Our Color Palette Generator?',
    h5: 'Get Started with Your Color Extraction Today',
    textEndPage:'Easily get colors from any image with our color picker! Create and download custom color palettes for your projects in just seconds. Perfect for designers and artists.',
    keywords: [
      'monochrome image to grayscale', 'monochrome photo converter', 'monochrome black and white app', 'monochrome grayscale photo editor', 'monochrome image converter', 'monochrome picture to grayscale', 'monochrome grayscale photo maker', 'monochrome picture converter', 'monochrome black-and-white photography app', 'monochrome gray scale converter',
    ],
  };
  public site: any

  constructor(public server:ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  getSite() {
  /*  if (this.fromServer) {
      return this.getSiteFromServer();
    } else {
      return this.getSiteStatic();
    }*/

  }
  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }
 public saveSite(site:object){
   if (this.fromServer) {

     this.site = site
    this.downloadSiteBtn(this.site);
    // let server: any = inject(ServerSiteServices)
    this.server.saveSite(this.DOMAIN, this.site);
  }
  }
  getSiteStatic() {
    return this.site = inject(StaticArtClubServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer() {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: Site): void => {
      this.site = responseSite;
    });
    return this.site;
  }

}
