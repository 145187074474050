import {Injectable } from '@angular/core';
import {Card} from '../../interfaces/Card';
@Injectable({
  providedIn: 'root',
})


export class BtnGoServices {

  constructor(
  ) {}

  public clickBtn() {
   /* this.active = true;
    setTimeout(() => {
      this.onClick.emit();
      this.active = false;
    }, 2000);*/
  }

  public showPremium(model:Card, showPremium:boolean):boolean {
    // @ts-ignore todo client
    return true;// !!(showPremium || model.free);
  }
  public showOrderForm(model:Card):boolean {
    return(
      !model.srcFRAME &&
      !model.srcUrl &&
      !model.routerLink &&
      !model.donateEnable &&
      !model.enableGoGallery
    );
  }

  public showRouterLink(model:Card):boolean {
     return !!model.routerLink;
  }
  public showGoGallery(model:Card):boolean {
    return model.enableGoGallery;
  }

  public showByeMyCoffe(model:Card):boolean  {
    return  model.donateEnable;
  }

  public showUrl(model:Card):boolean  {
    if(!model.srcFRAME && model.routerLink){
      return true;
    }
    /*return  (
      model.srcUrl &&
      !model.routerLink &&
      !model.donateEnable &&
      !model.enableGoGallery);*/
  }
  public showFrame(model:any):boolean  {
      if(model.srcFRAME && !model.routerLink){
       return  true
    }
   /* return  (
      model.srcFRAME  &&
      !model.routerLink &&
      !model.donateEnable &&
      !model.srcUrl &&
      !model.enableGoGallery
    );*/
  }
}
