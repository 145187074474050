

<!--<div style="display: flex;" class="eye-dropper-row-colors "  >
<ng-container *ngFor="let  color  of colors;">

    <div (click)="cd.selectColor = color.hex"
         [style.background-color]="color.hex"
         [style.width.px]="15"
         [style.height.px]="15"
         class="item-style"
    >
    </div>
</ng-container>
</div>-->
<!--<img
  *ngIf="sizeView ==  'full'"
  [class]="id"
  [src]="this.saveUrl(base64)"
  [alt]="id"
  (click)="initEyeDropperClick()"
/>-->
<!--  *ngIf="sizeView ==  'size'"-->

<div class="relative eye-dropper-container">

<!--  <app-eye-dropper-line-images-2
    [srcPreview]="src"
    [height]="heightImage"
    [width]="widthImage"
    (onColor)="onColor.emit($event)"
  >
  </app-eye-dropper-line-images-2>-->
  <!--
-->
  <img
    draggable="false"
    class="w-[500px] h-[500px]"
  [class]="id"
  [style.width.px]="widthImage"
  [style.height.px]="heightImage"
  [src]="base64"
  [alt]="id"
  (click)="initEyeDropperClick()"
  />
<!--  <div *ngIf="showColors && colors"
       class="  block flex justify-center bg-gray-950 z-20"

  >
    <ng-container *ngFor="let  color  of colors;">
      <app-color-item
        [model]="color"
        [width]="widthImage/10"
        [height]="heightImage/10"
        (onClick)="onColor.emit(color)"
        (onDblclick)="onDblclick.emit(color)"
        (onPress)="onPressColor.emit(color)"
      ></app-color-item>
    </ng-container>
  </div>-->
</div>
<!--</div>-->
<!--object-cover rounded-tl-lg rounded-br-lg m-1 p-1 shadow-sm-->
<!--[ngStyle]="cd.selectStyle(model,selectedImage,widthImage,heightImage)"-->
