import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import {
  isPlatformBrowser,
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
import {RouterOutlet} from '@angular/router';
import {NavbarTopComponent} from './components/pages/navbar-top/navbar-top.component';
import {SortablejsModule} from '@maksim_m/ngx-sortablejs';
import {CdServices} from "./services/common/cd.services";
import {SidebarComponent} from "./components/common/sidebar/sidebar.component";
import {StepperComponent} from "./components/stepper/stepper.component";
import {SecurityServices} from "./services/common/security.services";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    RouterOutlet,
    NavbarTopComponent,
    SortablejsModule,
    SidebarComponent,
    JsonPipe,
    StepperComponent
  ],
  templateUrl: './app.component.html',

})

export class AppComponent implements OnInit {


  constructor(@Inject(PLATFORM_ID) private platformId: object, public cd: CdServices, private securityServices: SecurityServices) {
  }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      const navMain = document.getElementById('navbarCollapse');
      if (navMain) {
        navMain.onclick = function onClick() {
          if (navMain) {
            navMain.classList.remove("show");
          }
        }
      }
    }
    // this.securityServices.disabledContextmenu();
    // this.securityServices.disabledCopy();
    // this.securityServices.disabledSelect();
    // this.securityServices.disabledHotKeys(['ctrl+shift+i','ctrl+a']);
  }

  showLoginFormIfRouteExistParams() {
    /* this.router.events.pipe(
     filter(event => event instanceof NavigationEnd)
   ).subscribe(() => {
     const fragment = this.activatedRoute.snapshot.fragment;
     if (fragment === 'login') {
       console.log('Навигация до формы входа');
       if(!this.cd.logined) {this.cd.loginForm = true}
     }
   });*/
  }

}
