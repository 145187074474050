import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {JsonPipe, NgFor, NgIf} from '@angular/common';
import {SliderModule} from "primeng/slider";
import {
  InputTextModule
} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";
@Component({
  selector: 'app-grayscale',
  templateUrl: './grayscale.component.html',
  standalone:true,
  imports: [
    NgIf,
    NgFor,
    SliderModule,
    InputTextModule,
    FormsModule,
    BtnDownloadComponent
  ]


})
export class GrayscaleComponent {
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  @Input() imageSrc: string;
  @Input() imgResult: string;
  @Input() scaleCanvas: string = '75%';
  @Input() isBlackAndWhite: boolean;
  @Input() showDownload: boolean =false;
  @Input()  showResult: boolean =true;
  @Output() imageConverted: EventEmitter<string> = new EventEmitter<string>();
  @Output() toggleBlackAndWhite: EventEmitter<boolean> = new EventEmitter<boolean>();

  originalImage: HTMLImageElement;

  ngAfterViewInit() {
    this.loadOriginalImage();
  }

  ngOnChanges() {
    if (this.originalImage && this.isBlackAndWhite !== undefined) {
      if (this.isBlackAndWhite) {
        this.convertToBlackAndWhite();
      } else {
        this.restoreOriginalImage();
      }
    }
  }
ngOnDestroy(){
  this.restoreOriginalImage();
}
  loadOriginalImage() {
    this.originalImage = new Image();
    this.originalImage.crossOrigin = 'Anonymous';
    this.originalImage.onload = () => {
      this.canvas.nativeElement.width = this.originalImage.width;
      this.canvas.nativeElement.height = this.originalImage.height;
      const ctx = this.canvas.nativeElement.getContext('2d');

      ctx.drawImage(this.originalImage, 0, 0);
      if (this.isBlackAndWhite) {
        this.convertToBlackAndWhite();
      } else {
        this.imgResult =this.canvas.nativeElement.toDataURL()
        this.imageConverted.emit(this.imgResult);
      }
    };
    this.originalImage.src = this.imageSrc;
  }

  convertToBlackAndWhite() {
    const ctx = this.canvas.nativeElement.getContext('2d');
    const imageData = ctx.getImageData(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
      data[i] = avg;
      data[i + 1] = avg;
      data[i + 2] = avg;
    }
    ctx.putImageData(imageData, 0, 0);
    this.imgResult = this.canvas.nativeElement.toDataURL()
    this.imageConverted.emit(this.imgResult);
  }

  restoreOriginalImage() {
    const ctx = this.canvas.nativeElement.getContext('2d');
    ctx.drawImage(this.originalImage, 0, 0);
    this.imageConverted.emit(this.canvas.nativeElement.toDataURL());
  }

  toggleBW() {
    this.isBlackAndWhite = !this.isBlackAndWhite;
    this.toggleBlackAndWhite.emit(this.isBlackAndWhite);
    if (this.isBlackAndWhite) {
      this.convertToBlackAndWhite();
    } else {
      this.restoreOriginalImage();
    }
  }
}
