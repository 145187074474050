import {Component, inject} from '@angular/core';
import {CommonHomeComponent} from "../../common-home/common-home.component";
import {HeaderComponent} from "../../../components/pages/header/header.component";
import {NavbarTopComponent} from "../../../components/pages/navbar-top/navbar-top.component";
import {PageAnatomyServices} from "../../../services/pages/page-anatomy.services";
import {PageArtistNotesServices} from "../../../services/pages/page-artist-notes.services";

@Component({
  selector: 'app-page-artist-notes',
  standalone:true,
  providers:[],
  imports: [
    CommonHomeComponent,
    HeaderComponent,
    NavbarTopComponent
  ],
  templateUrl: './page-artist-notes.component.html',
})
export class PageArtistNotesComponent {
   public site:any;
constructor(public dataPage: PageArtistNotesServices) {
  this.dataPage.getSite((site:any)=>{
    console.log(site)
    this.site = site;
  });
}
}
