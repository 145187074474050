
<div class="relative w-[50px] h-[50px] rounded-xl overflow-hidden shadow-lg">
  <!-- Спиннер загрузки *ngIf="loading" -->
  <div  class="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50">
    <i class="fas fa-spinner fa-spin text-gray-500 text-4xl"></i>
<!--    video...-->
  </div>
  <!-- Видео и кнопки [hidden]="!loading" -->
  <div class="relative">

    <video [hidden]="true" #video class="absolute inset-0 w-full h-full object-cover rounded-xl" autoplay></video>
   <!-- <button *ngIf="!loading" (click)="toggleCamera()" class="absolute top-4 left-4 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out">
      <i class="fa-solid fa-camera-rotate"></i> toggleCamera
    </button>-->
<!--    <button *ngIf="!loading" (click)="takeSnapshot()" class="absolute top-4 left-4 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out">
      <i class="fa-solid fa-camera"></i>
    </button>-->
<!--    <button *ngIf="!loading" (click)="toggleStreamToBase64()" class="absolute top-4 right-4 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out">
      <i *ngIf="!streamingToBase64" [class]="'fa-solid fa-video'"></i>
      <i *ngIf="streamingToBase64" [class]="'fa-regular fa-rectangle-xmark'"></i>
    </button>-->
  </div>
</div>
