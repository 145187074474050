import {Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';

declare const $: any;
import iro from '@jaames/iro';
import {NgIf} from "@angular/common";
@Component({
  selector: 'app-color-iro',
  standalone:true,
  imports:[NgIf],
  templateUrl: './color-iro.component.html',
})
export class ColorIroComponent implements OnInit, OnChanges {
  // @ts-ignore
  @ViewChild('accessId') accessId: ElementRef;

  @Input() model: any;
  @Input() id: any = 'colorPicker';
  @Input() change: any;
  @Input() color: any = '#258761';
  @Input() showRound: any = true;
  @Input() showSlider: any = false;
  @Input() layoutDirection: any = 'horizontal';
  @Input() colors: any = [
    'rgb(255, 0, 0)',
    'rgb(0, 255, 0)',
    'rgb(0, 0, 255)',
  ];
  @Input() colorList: any;
  @Input() activeColor: any;
  @Output() onClick = new EventEmitter();
  @Output() onAdd = new EventEmitter();
  public colorPicker: any;
  public iro: any;
  @Input() width: any = 100;
  @Input() height: any = 15;
  public view: any = 'all';
  public initColor: any = '#258761';
  public options: any;
  public layoutSelect: any = [];
  public layoutAll: any = [
    {
      component: iro.ui.Wheel,
      options: {
        id: 'hue-slider',
        sliderType: 'hue',
        sliderShape: 'circle',
        sliderSize: this.height,

      }
    },
    {
      component: iro.ui.Slider,
      options: {
        // id: 'hue-slider',
        sliderType: 'hue',
        // sliderShape: 'circle'
        sliderSize: this.height,

      }
    },
    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'value',
        sliderSize: this.height,

      }
    },
    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'saturation',
        sliderSize: this.height,

      }
    },
    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'kelvin',
        sliderSize: this.height,

      }
    },


    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'red',
        sliderSize: this.height,

      }
    },
    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'green',
        sliderSize: this.height,

      }
    },
    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'blue',
        sliderSize: this.height,

      }
    },
    /*   {
         component: iro.ui.Slider,
         options: {
           sliderType: 'alpha',
           sliderSize: this.height,

         }
       },*/
  ];
  public layoutSliders: any = [

    {
      component: iro.ui.Slider,
      options: {
        // id: 'hue-slider',
        sliderType: 'hue',
        // sliderShape: 'circle'
        sliderSize: this.height,

      }
    },
    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'value',
        sliderSize: this.height,

      }
    },
    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'saturation',
        sliderSize: this.height,

      }
    },
    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'kelvin',
        sliderSize: this.height,

      }
    },
    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'red',
        sliderSize: this.height,

      }
    },
    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'green',
        sliderSize: this.height,

      }
    },
    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'blue',
        sliderSize: this.height,

      }
    },
    /*   {
         component: iro.ui.Slider,
         options: {
           sliderType: 'alpha',
           sliderSize: this.height,

         }
       },*/
  ];
  public layoutRound: any = [
    {
      component: iro.ui.Wheel,
    },
  ];
  public layoutGrayscale: any = [
    {
      component: iro.ui.Wheel,
      options: {
        sliderType: 'value',
        sliderSize: this.height,

      }
    },
    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'value',
        sliderSize: this.height,

      }
    },
  ];
  constructor() {

  }

  public ngOnInit() {

  }

  public ngAfterViewInit() {
    this.colorList = document.getElementById('colorList');
    this.activeColor = document.getElementById('activeColor');
    this.initColorIro();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['change'] && changes['change']?.previousValue != changes['change']?.currentValue) {


    }
  }
  public setOptions() {
    switch (this.view) {
      case 'all':
        this.layoutSelect = this.layoutAll;
        break;

      case 'round':
        this.layoutSelect = this.layoutRound;
        break;
      case 'sliders':
        this.layoutSelect = this.layoutSliders;
        break;
      case 'grayscale':
        this.layoutSelect = this.layoutGrayscale;
        break;

    }
    this.options = {
      width: this.width,
      borderWidth: 2,
      borderColor: '#000000',
      handleRadius: 9,
      color: this.initColor,
      layoutDirection: 'horizontal',
      layout: this.layoutSelect
    };
  }
  public initColorIro() {
    // colors:this.colors,
    this.setOptions();
    // @ts-ignore
    let iroPicker = new iro.ColorPicker(this.accessId.nativeElement,this.options);
    iroPicker.on('color:change', (color:any) => {
      this.onClick.emit(color.hexString);
    });
    // let iro = new IroTools(this.accessId.nativeElement);
    // console.log(iro)
    /*iroPicker.initColor = this.color;
    // console.log(iro.initColor, 'iro.initColor');
    iroPicker.view=this.view;
    iroPicker.setOptions();
    iroPicker.init();
    iroPicker.change((color: any) => {
      this.onClick.emit(color.hexString);
      iroPicker.initColor = color.hexString;
      // this.color =  color.hexString;
    });*/

  }

}
