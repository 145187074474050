<li (click)="coins.coinService.minusCoin();(!cd.lock || cd.logined)?setSelectLesson(lessonItem):null" class="border-zinc-400  mb-1">

  <div
    [ngClass]="{'bg-amber-800':selectLesson == lessonItem}"
    class="  flex-row select-none cursor-pointer bg-zinc-600 text-amber-300 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
      <span [hidden]="coins.coinService.showCoinsFlag()" class="absolute z-30 -top-4 left-3">
       <app-coins #coins [showCoinMinus]="true"></app-coins>
          </span>

    <div class="flex flex-col rounded-md w-10 h-10 bg-zinc-800 justify-center items-center mr-4">
    <!--  <img *ngIf="lessonItem.srcPreview"
           [src]="lessonItem.srcPreview"
           [alt]="lessonItem.name">-->
      <button

        class=" mr-1 w-[75px] text-xl  bg-amber-400 p-2 rounded-xl shadow-lg"
      >
        <span class="flex content-center">
        <span class="text-md ">
          <span class="text-amber-50">PLAY</span>
          <i
            [class]="videoIcon"
            pBadge
            value=" {{lessonItem.duration}}"
          ></i>
        </span>
          </span>
      </button>
    </div>
    <div
      class="flex-1 pl-1 mr-16">
      <div class="font-medium"></div>
      <!--<app-edit-btn *ngIf="cd.showEditAll === true"
                    [modelCurrent]="lessonItem"
                    [modelsCurrent]="lessons"
                    [modelParent]="modelParent"
      ></app-edit-btn>-->
      <div *ngIf="lessonItem.name" class="text-amber-200 text-sm">
        {{ lessonItem.name}}
      </div>
      <div *ngIf="lessonItem.desc" class="text-amber-400 truncate h-[30px] text-[10px]">
        {{ lessonItem.desc}}
      </div>
    </div>
    <div  class="text-amber-300 text-xs">

    </div>
  </div>
</li>




<!--  [style.pointer-events]="gp.selectLesson.id == lessonItem.id ?'none':'auto'"-->

   <!--<i
          class="fa-solid fa-hashtag"
          pBadge
          value="{{(1)}}"
        ></i>-->
  <div [ngClass]="{'bg-amber-800':selectLesson == lessonItem}" class="w-[350px]  rounded-full">
    <div *ngIf="lessonItem.steps" class="mt-[2px] overflow-x-auto">
      <div class="flex space-x-1 text-center items-center">
        <ng-container *ngFor="let  stepItem  of  lessonItem.steps.slice(0,8);let i = index;">
          <div
            class="ml-1 w-[75px] text-[12px] inline-flex items-center text-center leading-sm uppercase px-[1px] py-[1px] bg-amber-700 rounded-full"
          >

            <span class="mr-1 text-amber-200 text-center truncate text-[8px] ">
<!--               <span>#{{(i+1)}} </span>-->
              {{stepItem.name}}
            </span>

          </div>
        </ng-container>
      </div>
    </div>
  </div>
