import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtClubServices} from '../static/static-art-club.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {Site} from '../../interfaces/site';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageStableDiffusion3Services {
  public SERVER_NAME: string = 'stable-diffusion-3';
  public DOMAIN: string = 'art-club.artas.org.ua/stable-diffusion-3';
  public route: string = 'stable-diffusion-3';
  public fromServer: boolean = false;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'Stable Diffusion 3: Unleashing Creativity with Next-Gen AI Image Synthesis',
    desc: 'Experience the power of Stable Diffusion 3, a groundbreaking AI application for generating bespoke visual content. Instantly transform your textual prompts into stunning visual art and expand the potential of your artistic endeavors today!',
    additions: [''],
  };
  public free: object = {
    name: 'Курсы',
    desc: 'Безлимитный доступ ко всем Курсам',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Stable Diffusion 3: Unleashing Creativity with Next-Gen AI Image Synthesis',
    description: 'Experience the power of Stable Diffusion 3, a groundbreaking AI application for generating bespoke visual content. Instantly transform your textual prompts into stunning visual art and expand the potential of your artistic endeavors today!',
    text: 'Welcome to Stable Diffusion 3, where the synergy of your creative vision and the prowess of artificial intelligence conjoins to forge extraordinary visuals. We\'re ushering in a transformative era of artistry where AI extends beyond being merely a tool—it\'s your collaborative ally, adept at converting even the most nuanced of prompts into tangible visual narratives.\n' +
      '\n' +
      'Crafting Vivid Visuals from Text with Unsurpassed Finesse\n' +
      '\n' +
      'Stable Diffusion 3 harnesses the profound potential of words, transforming them into captivating images that tell stories and evoke emotions. Our platform uses cutting-edge AI algorithms to interpret your text prompts, rendering everything from the surreal to hyper-realistic scenarios into visual wonders of high-caliber elegance.\n' +
      '\n' +
      'Discover Who Can Benefit from Stable Diffusion 3\n' +
      '\n' +
      'Stable Diffusion 3 is tailored for everyone with a vision they wish to translate into imagery. Whether you\'re an artist searching for inspiration, a designer crafting concepts, a marketer in need of visuals, or simply a dreamer aiming to visualize narratives, Stable Diffusion 3 is your go-to source for image synthesis.\n' +
      '\n' +
      'The Reasons Why Stable Diffusion 3 Stands Out in AI Image Synthesis\n' +
      '\n' +
      'Stable Diffusion 3 is characterized by its distinctive benefits, positioning it as a leader in the AI image creation arena:\n' +
      '\n' +
      '- Expansive Artistic Freedom: The platform\'s user-friendly interface paired with advanced algorithms, empowers you with boundless artistic expression.\n' +
      '- Agile and Precise: Expect to witness your ideas take shape swiftly—Stable Diffusion 3 processes inputs rapidly, delivering top-tier images without delay.\n' +
      '- Universal Accessibility: Skill level is a non-factor; Stable Diffusion 3 is crafted for easy adoption, ensuring anyone can achieve mastery and materialize their ideas into art.\n' +
      '- Constant Innovation: With relentless updates and the incorporation of the latest AI advancements, Stable Diffusion 3 stays at the forefront of image synthesis technology.\n' +
      '\n' +
      'Step into a domain where your creativity encounters no barriers. Stable Diffusion 3 is not just a utility but your companion, guiding you to unparalleled imaginative prowess and avant-garde innovation.Diving deeper into the essence of Stable Diffusion 3, it is not merely its technological prowess that sets it apart. The core of its distinctiveness lies in its ability to democratize the process of creativity. This platform does not just translate text into images; it bridges the gap between imagination and realization. In a world where ideas abound but the means to manifest them can be limited, Stable Diffusion 3 offers a canvas as vast as one’s imagination.\n' +
      '\n' +
      'Interactivity & Collaboration: An engaging aspect of Stable Diffusion 3 is its interactive nature. The application encourages iterative creativity, allowing users to refine their prompts and visualize the evolution of their ideas. This dynamic process fosters a deeper connection between the artist and the artwork. Furthermore, it opens doors for collaboration among creators, enabling the blending of styles and ideas in unprecedented ways. The platform effectively becomes a melting pot of creativity where diversity in thought and expression is not only welcomed but celebrated.\n' +
      '\n' +
      'The Impact on Creative Industries: The implications of Stable Diffusion 3 for creative industries are profound. For graphic designers, it’s a tool that accelerates the brainstorming process, offering instant visual prototypes. For marketers, it’s a treasure trove of compelling imagery that can be tailored to specific campaigns with ease. For educators and students, it serves as an interactive learning aid that brings concepts to life. And for artists, it’s both a muse and a medium, challenging traditional boundaries and inviting exploration into new artistic territories.\n' +
      '\n' +
      'Beyond the Horizon: Looking ahead, the evolution of Stable Diffusion 3 will be influenced by the community of users it cultivates. As the platform grows, so too will the possibilities it encompasses. Feedback loops between developers and users will drive enhancements, making the tool more intuitive, versatile, and powerful. The future of Stable Diffusion 3 is not just in the hands of its creators but in the collective imagination of its community.\n' +
      '\n' +
      'In a world where the line between the digital and the real continues to blur, Stable Diffusion 3 stands as a testament to human creativity amplified by artificial intelligence. It represents a leap into a future where art and technology converge, creating a landscape ripe with potential for discovery, innovation, and expression. Join us on this journey and unleash the full spectrum of your creativity with Stable Diffusion 3 – where every prompt is a promise of a new visual adventure.',
    h1: 'Embrace the Artistic Revolution with Stable Diffusion 3\'s AI Image Synthesis',
    h2: 'Crafting Vivid Visuals from Text with Unsurpassed Finesse',
    h3: 'Discover Who Can Benefit from Stable Diffusion 3',
    h4: 'The Reasons Why Stable Diffusion 3 Stands Out in AI Image Synthesis',
    h5: 'Embrace the Artistic Revolution with Stable Diffusion 3\'s AI Image Synthesis',
    textEndPage:'Embrace the Artistic Revolution with Stable Diffusion 3\'s AI Image Synthesis',
    keywords: [
      'Stable Diffusion 3',
      'AI-driven image synthesis',
      'AI art generation',
      'next-generation AI creativity tool',
      'visual content creation with AI',
      'innovative AI technology',
      'deep learning in digital art',
    ],
  };
  public site: any

  constructor(public server:ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  getSite() {
  /*  if (this.fromServer) {
      return this.getSiteFromServer();
    } else {
      return this.getSiteStatic();
    }*/

  }
  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }
 public saveSite(site:object){
   if (this.fromServer) {

     this.site = site
    this.downloadSiteBtn(this.site);
    // let server: any = inject(ServerSiteServices)
    this.server.saveSite(this.DOMAIN, this.site);
  }
  }
  getSiteStatic() {
    return this.site = inject(StaticArtClubServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer() {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: Site): void => {
      this.site = responseSite;
    });
    return this.site;
  }

}
