import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
import {SliderModule} from "primeng/slider";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {
  closeSquareIcon,
  colorHistoryIcon,
  dotesVerticalIcon,
  gridIcon,
  imagePanoramaIcon,
  listIcon,
  plusIcon,
  tagIcon
} from "../../traits/icons-tools";
import {ToggleButtonModule} from "primeng/togglebutton";
import {getRequestImagesGradioUsers,} from "../../traits/ai-tools";
import {
  windowHeight,
  windowWidth
} from "../../traits/window-tools";
import {NavbarTopComponent} from "../pages/navbar-top/navbar-top.component";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {SkeletonModule} from "primeng/skeleton";
import {SliderBtnsComponent} from "../slider-btns/slider-btns.component";
import {PageInfiniteBoardServices} from "../../services/pages/page-infinite-board.services";
import {CdServices} from "../../services/common/cd.services";
import {StageDbServices} from "../../services/stage-db.services";
import {
  srcSketch,
  urlToBlob
} from "../../traits/image-tools";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {SidebarComponent} from "../common/sidebar/sidebar.component";
import {AlbumComponent} from "../album/album.component";
import {addToStart} from "../../traits/array-tools";
import {OnlineStatusComponent} from "../pages/online-status/online-status.component";
import {SkeletonGridComponent} from "../skeleton-grid/skeleton-grid.component";
import {CoinsComponent} from "../coins/coins.component";

@Component({
  selector: 'app-text-to-image',
  templateUrl: './text-to-image.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    SliderModule,
    InputTextModule,
    FormsModule,
    ToggleButtonModule,
    NavbarTopComponent,
    ScrollPanelModule,
    SkeletonModule,
    SliderBtnsComponent,
    BtnDownloadComponent,
    OverlayPanelModule,
    SidebarComponent,
    JsonPipe,
    AlbumComponent,
    OnlineStatusComponent,
    SkeletonGridComponent,
    CoinsComponent,
  ]

})
export class TextToImageComponent implements AfterViewInit {
  @ViewChild('canvas', {static: true}) canvas: ElementRef<HTMLCanvasElement>;
  @Input() imageSrc: string;
  @Input() showCanvasPreview: boolean = true;
  @Output() imageEdited = new EventEmitter<string>();
  @Input() api: string = 'dalle-3';

  imagesConcat: any[] = [];
  countImages: number = 0;
  seconds: number = 60;
  translateEnable: boolean = false;
  secondsMax: number = 60;
  generatingFlag: boolean = false;
  dayGenerationMax: number = 30;
  maxGeneration: number = 6;
  public sizesGenerations: any[] = [
    {
      name: '512X512',
      height: 512,
      width: 512,
      srcPreview: ''
    },
    {
      name: '768X512',
      height: 768,
      width: 512,
      srcPreview: ''
    },
    {
      name: '768X1024',
      height: 768,
      width: 1024,
      srcPreview: ''
    },
    {
      name: '1024X768',
      height: 1024,
      width: 768,
      srcPreview: ''
    },
    {
      name: '1536X2048',
      height: 1536,
      width: 2048,
      srcPreview: ''
    },
    {
      name: '2048X1536',
      height: 2048,
      width: 1536,
      srcPreview: ''
    },
    /*{
      name: '1024X512',
      height: 1024,
      width: 512,
      srcPreview: ''
    },*/
    /*{
      name: '1536X1024',
      height: 1536,
      width: 1024,
      srcPreview: ''
    },*/
    /*{
      name: '2560X1440',
      height: 2560,
      width: 1440,
      srcPreview: ''
    },*/
  ]
  public quantityGenerations: any[] = [
    {
      name: '2-images',
      value: 2,
      srcPreview: ''
    },
    {
      name: '4-images',
      value: 4,
      srcPreview: ''
    },
    {
      name: '6-images',
      value: 6,
      srcPreview: ''
    },
    {
      name: '8-images',
      value: 8,
      srcPreview: ''
    },
    {
      name: '10-images',
      value: 10,
      srcPreview: ''
    },
    /*{
      name: '12-images',
      value: 12,
      srcPreview: ''
    },*/
  ]


  public stylesGenerations: any[] = [
    {
      name: 'Realistic',
      value: 'realistic',
      srcPreview: 'assets/ai-styles/realistic.png',
      text: 'Commercial photography, white lighting, studio light, 8k octane rendering, high resolution photography, insanely detailed, fine details, on white isolated plain, 8k, commercial photography, stock photo, professional color grading, --v 4 --ar 9:16 '

    },

    {
      name: 'Fantasy',
      value: 'fantasy',
      srcPreview: 'assets/ai-styles/fantasy.png',
      text: 'Create an enchanting fantasy artwork with mythical creatures, magical landscapes, and intricate details.'
    },
    {
      name: 'Neon',
      value: 'neon',
      srcPreview: 'assets/ai-styles/cyberpunk.png',
      text: 'Imagine a cyberpunk image with futuristic elements, neon lights, and a dark, dystopian atmosphere.'
    },

    {
      name: 'Studio',
      value: 'studio',
      srcPreview: 'assets/ai-styles/studio.png',
      text: 'Step into the world of studio photography with a sophisticated and professionally executed image, showcasing meticulous lighting, refined compositions, and a polished look that epitomizes elegance and style.'

    },
    {
      name: 'Cartoon',
      value: 'cartoon',
      srcPreview: 'assets/ai-styles/cartoon.png',
      text: 'Illustration in a detailed hand-drawn style with emphasis on fine lines and shading and vibrant colors'
    },
    {
      name: 'Sketch',
      value: 'sketch',
      srcPreview: 'assets/ai-styles/sketch.png',
      text: 'Illustration in a detailed hand-drawn style with  emphasis on fine lines and shading grayscale'
    },
    {
      name: 'Water',
      value: 'water',
      srcPreview: 'assets/ai-styles/water.png',
      text: 'High-quality watercolor painting with soft color gradients and detailed textures.'
    },
    {
      name: 'Pop',
      value: 'pop',
      srcPreview: 'assets/ai-styles/pop.png',
      text: 'Bright and bold pop art image with high contrast and detailed comic textures.'
    },
    {
      name: 'Anime',
      value: 'anime',
      srcPreview: 'assets/ai-styles/anime.png',
      text: 'High-quality, detailed anime-style character with vibrant colors and dynamic expression.'
    },
    {
      name: '3D',
      value: '3D',
      srcPreview: 'assets/ai-styles/3D.png',
      text: '"High-quality and detailed 3D render with realistic textures, lighting, and depth perception for a lifelike appearance.'
    },
    {
      name: 'Comics',
      value: 'comics',
      srcPreview: 'assets/ai-styles/comics.png',
      text: 'Vibrant comic book style with meticulous line work and rich, saturated colors'
    },
    {
      name: 'Minimal',
      value: 'minimal',
      srcPreview: 'assets/ai-styles/minimal.png',
      text: 'Produce a minimalist image with clean lines, simple shapes, and a focus on negative space.'
    },
    {
      name: 'Vintage',
      value: 'vintage',
      srcPreview: 'assets/ai-styles/vintage.png',
      text: 'Design a vintage-inspired image with a retro color palette, aged textures, and nostalgic vibes.'
    },
    {
      name: 'Abstract',
      value: 'abstract',
      srcPreview: 'assets/ai-styles/abstract.png',
      text: 'Craft an abstract artwork with bold shapes, vibrant colors, and expressive brushstrokes.'
    },
    {
      name: 'Surreal',
      value: 'surrealism',
      srcPreview: 'assets/ai-styles/surrealism.png',
      text: 'Create a surreal and dreamlike image with unexpected combinations, distortions, and unrealistic elements'
    },
    {
      name: 'Street',
      value: 'street',
      srcPreview: 'assets/ai-styles/street.png',
      text: 'Design an urban street art piece with graffiti-inspired elements, vivid colors, and a gritty aesthetic.'
    },
    {
      name: 'Steampunk',
      value: 'steampunk',
      srcPreview: 'assets/ai-styles/steampunk.png',
      text: 'An illustration in the steampunk style with a focus on mechanical and steam-powered elements, creating an atmosphere of retro-futurism and technical ingenuity. Craft a steampunk-inspired artwork with Victorian-era machinery, gears, cogs, and a touch of industrial revolution aesthetics'
    },
    {
      name: 'Deco',
      value: 'deco',
      srcPreview: 'assets/ai-styles/deco.png',
      text: 'Develop an elegant Art Deco piece with geometric patterns, luxurious textures, and a vintage glamor.'
    },
    {
      name: 'Modern',
      value: 'modern',
      srcPreview: 'assets/ai-styles/modern.png',
      text: 'Create a contemporary piece in a modern art style, incorporating abstract forms and experimental techniques.'
    },
    {
      name: 'Impress',
      value: 'impress',
      srcPreview: 'assets/ai-styles/impress.png',
      text: 'Capture a scene with an impressionistic touch, focusing on light, color, and capturing the essence of the moment.'
    },
    {
      name: 'Graffiti',
      value: 'graffiti',
      srcPreview: 'assets/ai-styles/graffiti.png',
      text: 'Produce a graffiti-inspired piece with bold lettering, vibrant colors, and a street art vibe.'
    },
    {
      name: 'Mosaic',
      value: 'mosaic',
      srcPreview: 'assets/ai-styles/mosaic.png',
      text: 'Craft an artwork using a mosaic style, showcasing intricate patterns and a diverse color palette.'
    },
    {
      name: 'Nature',
      value: 'nature',
      srcPreview: 'assets/ai-styles/nature.png',
      text: 'Design an artwork inspired by nature, featuring organic shapes, botanical elements, and peaceful hues.'
    },
    {
      name: 'Geometric',
      value: 'geometric',
      srcPreview: 'assets/ai-styles/geometric.png',
      text: 'Create a geometric abstraction piece with precise shapes, bold compositions, and a sense of order and balance.'
    },
    {
      name: 'Whimsical',
      value: 'whimsical',
      srcPreview: 'assets/ai-styles/whimsical.png',
      text: 'Illustrate a whimsical scene with playful characters, imaginative elements, and a touch of magic.'
    },
    {
      name: 'Futuristic',
      value: 'futuristic',
      srcPreview: 'assets/ai-styles/futuristic.png',
      text: 'Design a futuristic image with sleek lines, metallic textures, and a sense of advanced technology,Create a dynamic and futuristic image with bold shapes, sharp angles, and a sense of motion and energy.'
    },
    {
      name: 'RetroF',
      value: 'retroF',
      srcPreview: 'assets/ai-styles/retroF.png',
      text: 'Design an image that blends retro elements with futuristic concepts, incorporating vintage aesthetics with a modern twist.'
    },
    {
      name: 'Cyber',
      value: 'cyber',
      srcPreview: 'assets/ai-styles/cyber.png',
      text: 'Produce a digital artwork that explores the intersection of technology, virtual reality, and artistic expression.'
    },
    {
      name: 'Symbolism',
      value: 'symbolism',
      srcPreview: 'assets/ai-styles/symbolism.png',
      text: 'Create a symbolic image with hidden meanings and metaphors, allowing viewers to interpret the artwork in various ways.'
    },
    {
      name: 'Eco',
      value: 'eco',
      srcPreview: 'assets/ai-styles/eco.png',
      text: 'Design an environmentally conscious artwork that promotes sustainability, green living, and nature conservation.'
    },
    {
      name: 'AvantGarde',
      value: 'avantgarde',
      srcPreview: 'assets/ai-styles/avantgarde.png',
      text: 'Produce an avant-garde piece that challenges traditional norms and pushes the boundaries of art and creativity.'
    },
    {
      name: 'Biomorphic',
      value: 'biomorphic',
      srcPreview: 'assets/ai-styles/biomorphic.png',
      text: 'Produce an avant-garde piece that challenges traditional norms and pushes the boundaries of art and creativity.'
    },
    {
      name: 'Vector',
      value: 'vector',
      srcPreview: 'assets/ai-styles/vector.png',
      text: 'Vector illustration  vector graphic design with flat colors   in the style of vector art, using simple shapes and graphics with simple details, professionally designed as a tshirt logo ready for print on a white background. --ar 89:82 --v 6.0 --style raw'
    },
  ]
  public selectQuantityGeneration: any = {
    name: '2-images',
    value: 2,
    srcPreview: ''
  }
  public selectSizeGeneration: any = {
    name: '512X512',
    height: 512,
    width: 512,
    srcPreview: ''
  }
  public selectStyleGeneration: any = {
    name: 'Fantasy',
    value: 'fantasy',
    srcPreview: 'assets/ai-styles/fantasy.png',
    text: 'Create an enchanting fantasy artwork with mythical creatures, magical landscapes, and intricate details.'
  }
  public storageKey: string = 'textToImageStorage';

  public params: any = {
    prompt: "",
    negative_prompt: "",
    use_negative_prompt: true,
    seed: 0,
    width: 512,
    height: 512,
    guidance_scale: 7,
    randomize_seed: true,
    num_inference_steps: 30,
    NUM_IMAGES_PER_PROMPT: 8,
  };
  showHistoryImages: boolean = false;
  showHistoryTexts: boolean = false;
  public tagList: any[] = [
    "Tag1",
    "Tag2",
    "Tag3"
  ];
  showTextTools: any = false;
  tagsAll: any[];
  textsAll: any[] = [];
  showError: boolean = false
  errorText: any;
  protected readonly imagePanoramaIcon = imagePanoramaIcon;
  protected readonly gridIcon = gridIcon;
  protected readonly closeSquareIcon = closeSquareIcon;
  protected readonly windowHeight = windowHeight;
  protected readonly dotesVerticalIcon = dotesVerticalIcon;
  protected readonly windowWidth = windowWidth;
  protected readonly imageSketch = srcSketch;
  protected readonly tagIcon = tagIcon;
  protected readonly colorHistoryIcon = colorHistoryIcon;
  protected readonly listIcon = listIcon;
  protected readonly Array = Array;
  public imagesCount: any =0;

  constructor(public dataPage: PageInfiniteBoardServices, public cd: CdServices, public stageDB: StageDbServices) {
  }

  async ngOnInit() {
    this.getTagAll()
  }

  ngAfterViewInit(): void {
    this.textAreaScript();
  }

  public startCountdown(seconds, callbackCountEnd: any = null) {
    let timeLeft = seconds;
    const timer: any = setInterval(() => {
      timeLeft--;
      this.seconds = timeLeft
      if (timeLeft <= 0) {
        callbackCountEnd && callbackCountEnd()
        clearInterval(timer)
      }
    }, 1000);
  }
  public watermark(
     image:string,
     text:string = 'art-club.info',
     position:string='bottom-right',
     fontSize:number = 20,
     fontColor:string ='white',
     callback:any = null
   ) {
     const canvas = document.createElement('canvas');
     const ctx = canvas.getContext('2d');
     const img = new Image();
     img.src = image;
     img.onload = () => {
       canvas.width = img.width;
       canvas.height = img.height;
       ctx.drawImage(img, 0, 0);

       ctx.font = `${fontSize}px Arial`;
       ctx.fillStyle = fontColor;

       switch (position) {
         case 'top-left':
           ctx.fillText(text, 10, 30);
           break;
         case 'top-right':
           ctx.fillText(text, canvas.width - ctx.measureText(text).width - 10, 30);
           break;
         case 'bottom-left':
           ctx.fillText(text, 10, canvas.height - 10);
           break;
         case 'bottom-right':
           ctx.fillText(text, canvas.width - ctx.measureText(text).width - 10, canvas.height - 10);
           break;
         case 'center':
           ctx.fillText(text, (canvas.width - ctx.measureText(text).width) / 2, canvas.height / 2);
           break;
         default:
           ctx.fillText(text, 10, 30); // По умолчанию в верхнем левом углу
       }

       const watermarkedImage:string = canvas.toDataURL('image/jpeg');
      callback  && callback(watermarkedImage)
       console.log(watermarkedImage,'watermarkedImage');
     };
 }
  public getPrompt(): string {
    // let promptAi =   await getDradioApiPrompt(this.params.prompt)
    let prompt: string = this.params.prompt +' '+ this.selectStyleGeneration.text;
    return prompt;
  }




  getTagAll() {
    this.stageDB.getStages(this.storageKey, (selectStage: any) => {
      let wordsAll: any[] = [];
      let texts:any  = []
      if (selectStage) {
        let images = selectStage.images;
        this.imagesCount= images.length;

        images.forEach((imageItem: any) => {
          let  prompt = imageItem.params.prompt.trim();
          prompt =  prompt.replace(/ ,s*$/,"");
          let imageWords = prompt.split(",")
          imageWords =  imageWords.map(word => word.trim())
          imageWords = imageWords.filter(word => word.trim() !== "")
          Array.prototype.unshift.apply(wordsAll, imageWords);
          texts.push(prompt);




        })
      }
      wordsAll = [...new Set(wordsAll)]

      this.tagsAll = wordsAll.map((item) => {
        return {'name': item}
      });
      let uniqTexts:any[]  = [...new Set(texts)]
      this.textsAll = uniqTexts;
      console.log(this.tagsAll,'tagsAll')
      console.log(this.textsAll,'textsAll')
    })
  }

  public error(error: any) {
    console.error('Произошла ошибка в процессе генерации изображений: ', error)
    this.stopGenerate();
    this.errorText = 'Generation error:(' + error.message ? error.message : error + ')';
    this.showError = true;
    let timeShowError = 10;
    const timer: any = setInterval(() => {
      timeShowError -= 1;
      if (timeShowError <= 0) {

        this.showError = false;
        clearInterval(timer)
      }
    }, 1000);
  }

  public getParams(): object {
    let negativePrompt = ' (deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation';
    let params: any = {
      prompt: this.getPrompt(),
      negative_prompt: this.params.negative_prompt + negativePrompt,
      use_negative_prompt: true,
      seed: 0,
      width: this.selectSizeGeneration.width,
      height: this.selectSizeGeneration.height,
      guidance_scale: 7,
      randomize_seed: true,
      num_inference_steps: 30,
      NUM_IMAGES_PER_PROMPT: this.selectQuantityGeneration.value,
    };
    return params;
  }

  public saveParams(): object {
    let params: any = {
      prompt: this.params.prompt,
      negative_prompt: this.params.negative_prompt,
      width: this.selectSizeGeneration.width,
      height: this.selectSizeGeneration.height,
      style: this.selectStyleGeneration.value,
    };
    return params;
  }

  async generateImages() {
    this.startCountdown(this.secondsMax)
    let prompt: any = this.getPrompt();
    let params: any = this.getParams();
    let saveParams: any = this.saveParams();
    /* await getRequestImagesGradioMy(prompt, (resultImage: string) => {
       addToStart({srcPreview: resultImage},this.imagesConcat);
       this.stageDB.addImageToBoard(resultImage);
  });*/
    /*    await getRequestImages(prompt, (resultImage: object) => {
          this.imagesConcat.push({srcPreview: resultImage})
        });*/
    await getRequestImagesGradioUsers(prompt, (data: any) => {
      if (Array.isArray(data)) {
        console.log(data, 'many')
        data.forEach((itemImage) => {
          itemImage.params = saveParams;
          console.log(itemImage.srcPreview)
          urlToBlob(itemImage.srcPreview,(blobResult:string)=>{
            this.watermark(
              blobResult,
              'art-club.info',
              'bottom-right',
              25,
              'white',
              (watermarkBase64:string)=>{
                itemImage.srcPreview = watermarkBase64
                console.log(watermarkBase64,'watermarkBase64')
                addToStart(itemImage, this.imagesConcat);
                this.stageDB.addImageToBoard(itemImage.srcPreview, saveParams);
                this.getTagAll()
              }
            );
          })


        })
        // Array.prototype.unshift.apply(this.imagesConcat, data);
      } else {
        console.log(data, 'one')
        // this.stageDB.addImageToBoard(data.srcPreview,params)
        // addToStart(data, this.imagesConcat)
      }
    }, params);
  }

  async startGenerate() {
    this.generatingFlag = true;
    this.generateImages()
      .then(() => {
        this.generatingFlag = false;
        console.log('Генерация изображений завершена.')
      })
      .catch((error) => {

        this.error(error);
      })
  }

  async stopGenerate() {
    this.generatingFlag = false;
    this.countImages = 0;
    this.seconds = 30;
    this.imagesConcat = [];
  }


  addTagToTextArea(tagItem: string = 'tag-1') {
    const textArea: any = document.getElementById("prompt-text-area");
    let textToAdd = textArea.value ? `, ${tagItem}` : `${tagItem}`;
    textArea.value += textToAdd;
    this.params.prompt = textArea.value;
  }
  public textHistory = [];
  public currentPos = -1;
  getLastWordIndex(str) {
    let words = str.split(" ");
    return words.length > 0 ? words[words.length - 1].length : 0;
  }
  textAreaScript(){
    let currentText = "";

    const textArea:any = document.getElementById("prompt-text-area");
    textArea.addEventListener("input", () => {
      let updatedText = textArea.value
      if (updatedText.endsWith(" ")) {
        let lastWord = updatedText.split(" ").pop();
        console.log(lastWord)
        this.textHistory.push(lastWord);
        this.currentPos = this.textHistory.length - 1;
        currentText = "";
      } else {
        currentText = updatedText;
      }
      console.log(this.textHistory)
    });

  }

   undo() {

     const textArea:any = document.getElementById("prompt-text-area");
     let currentContent = textArea.value;
     let lastWordIndex = this.getLastWordIndex(currentContent);
    if (this.currentPos > 0) {
      this.currentPos--;
      let previousContent = this.textHistory[this.currentPos];
      let previousLastWordIndex = this.getLastWordIndex(previousContent);
      textArea.value = previousContent.slice(0, -previousLastWordIndex);
    }
  }

   redo() {
     const textArea:any = document.getElementById("prompt-text-area");
    if (this.currentPos < this.textHistory.length - 1) {
      this.currentPos++;
      textArea.value = this.textHistory[this.currentPos];
    }
  }

  protected readonly plusIcon = plusIcon;
}

/*
     // let route = 'stabilityai/stable-diffusion-3-medium' --
     // let route = 'stabilityai/stable-diffusion-3-medium-diffusers' --
     // let route = 'playgroundai/playground-v2.5-1024px-aesthetic' --
     // let route = 'h94/IP-Adapter-FaceID' --
     // let route = 'Corcelio/mobius' --
     // let route = 'nerijs/pixel-art-medium-128-v0.1' -*/
/* if (this.params.prompt) {
   /!*let params = {
     prompt: this.translateEnable ? await getAiITranslate(this.params.prompt) : this.params.prompt,
     negative_prompt: this.translateEnable ? await getAiITranslate(this.params.negative_prompt) : this.params.negative_prompt,
     use_negative_prompt: true,
     style: this.selectStyleGeneration.value,
     seed: 0,
     width: this.selectSizeGeneration.width,
     height: this.selectSizeGeneration.height,
     guidance_scale: 0.1,
     randomize_seed: true,
   }*!/
   let iterationItem:number = 2;
   for (const item of this.apiRequests) {
     if(item.sw === true){
         await getImageIaByRequestApi(this.params.prompt, item.route);
     }
   }*/
/*  this.apiRequests.forEach( (item:any)=>{
    await this.getImageIaByRequestApi(item.value)
 });*/

/*      for (let i = 0; i < this.selectQuantityGeneration.value; i++) {
        // let imagesApi: any[];
        if (this.api === 'dalle-3') {
          // imagesApi = await getAiImagesDALLE_4K(params);
        } else if(this.api === 'midjourney') {


         // let imagesApi:any = await getAiImagesMidjourney(params);
         // this.stageDB.addImageToBoard(imagesApi.srcPreview)
         //  console.log(imagesApi,'imagesApi')
         //  this.imagesConcat.push(imagesApi)
        }
        else if(this.api === 'stable-diffusion') {
          // imagesApi = await  getAiImagesStableDiffusion_2()
          // imagesApi = await  getAiImagesStableDiffusion_3()
          // Array.prototype.push.apply(this.imagesConcat, imagesApi);

        }
        this.countImages = this.imagesConcat.length;
      }*/

/*
  addTag() {
    document.addEventListener('DOMContentLoaded', () => {
      const tags = [
        "Tag1",
        "Tag2",
        "Tag3"
      ];
      const container = document.getElementById("tagsContainer");

      tags.forEach(tag => {
        let button = document.createElement("button");
        button.textContent = tag;
        container.appendChild(button);
      });

      container.addEventListener("click", (event: any) => {
        if (event.target.tagName === "BUTTON") {
          const textArea: any = document.getElementById("textInput");
          let textToAdd = textArea.value ? `, ${event.target.textContent}` : event.target.textContent;
          textArea.value += textToAdd;
        }
      });
    });
  }*/
