import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtClubServices} from '../static/static-art-club.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {Site} from '../../interfaces/site';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageColorHarmonyServices {
  public SERVER_NAME: string = 'color-harmony';
  public DOMAIN: string = 'art-club.artas.org.ua/color-harmony';
  public route: string = 'color-harmony';

  public fromServer: boolean = false;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'Color harmony',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'Color harmony',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Color Harmony: Create Harmonious Color Combinations in Seconds',
    description: 'The Color Harmony app is your reliable assistant in the world of colors, offering instant selection of harmonious color combinations for artists, designers, and all creatives. Craft stunning designs using perfectly matched colors with our unique tool.',
    text: 'Introducing "Color Harmony" – your universal tool for creating perfect color solutions. This application is your magical key to the world of harmony in design. With "Color Harmony", you can easily find ideal color combinations for your projects. Choose a base color, and the app automatically selects complementary shades from the color wheel, providing you not only with harmonious combinations but also with new ideas for creativity.\n' +
      '\n' +
      'Color Harmony is specially created for artists, designers, and anyone looking for inspiration in color. It\'s your digital assistant that helps you experiment with color, explore contrasts and nuances, seeking the perfect combinations for your drawings, textiles, interiors, and any visual compositions.\n' +
      '\n' +
      'The convenient and intuitive "Color Harmony" app makes the process of selecting colors not only efficient but also a creative pleasure. Start by selecting a color on the wheel or upload an image to copy colors directly from it. Once the base shade is chosen, the app offers a whole spectrum of harmonious combinations - from monochromatic to complementary and analogous.\n' +
      '\n' +
      'Color Harmony will become your indispensable tool in the world of color. Create visually appealing works, fill your projects with vivid colors, and let your creative potential fully unfold. Don\'t limit yourself - discover the endless possibilities of color harmony with the "Color Harmony" app.Meet "Color Harmony" – your comprehensive tool for crafting flawless color solutions. This application is a magical key to unlocking a world of design harmony. "Color Harmony" empowers you to effortlessly discover perfect color pairings for any project. Select a foundational hue, and watch as the app instantly curates a palette of complementary colors, providing not only visually pleasing blends but also sparking new avenues of creative thought.\n' +
      '\n' +
      'Designed with artists, designers, and color enthusiasts in mind, "Color Harmony" acts as your digital cohort in the color exploration journey—aiding in the experimentation of color, delving into contrasts, and discovering unparalleled combinations for illustrations, textiles, living spaces, and visual arrangements.\n' +
      '\n' +
      'The user-friendly and intuitive interface of the "Color Harmony" app streamlines the color selection process into an activity both practical and joyfully creative. Your color exploration begins by either choosing a tint on the wheel or uploading an image for direct color extraction. Once a primary color is pinpointed, a full array of harmonious matches is at your disposal, ranging from monochrome to complementary, and analogous schemes.\n' +
      '\n' +
      'Color Harmony is set to become an essential aspect of your design toolkit. It enables you to generate eye-catching compositions, inject your endeavors with lively chromatic schemes, and embrace the full spectrum of your artistic flair. Break free from constraints and unleash the full spectrum of color harmony possibilities with the "Color Harmony" app.',
    h1: 'Color Harmony: Unleash the World of Perfect Color Combinations',
    h2: 'Color Harmony- Transform Inspiration into Reality with Color Harmony App',
    h3: 'Color Harmony - effortlessly and Effectively Experiment with Color',
    h4: 'Color Harmony -The Ideal Color Solutions for Your Creative Projects',
    h5: 'Color Harmony: Create Harmonious Color Combinations in Seconds',
    textEndPage: 'Color Harmony: Create Harmonious Color Combinations in Seconds',
    keywords: [
      'color Harmony',
      'color combinations',
      'color harmony',
      'color wheel',
      'color matching',
      'color design application',
      'design creation',
      'artists',
      'designers',
      'color inspiration',
      'chromatic harmony',
      'digital color assistant'
    ],
  };
  public site: any

  constructor(public server: ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  getSite() {
    /*  if (this.fromServer) {
        return this.getSiteFromServer();
      } else {
        return this.getSiteStatic();
      }*/

  }

  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }

  public saveSite(site: object) {
    //  this.site = site
    // this.downloadSiteBtn(this.site);
    // // let server: any = inject(ServerSiteServices)
    // this.server.saveSite(this.DOMAIN, this.site);
  }

  getSiteStatic() {
    return this.site = inject(StaticArtClubServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer() {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: Site): void => {
      this.site = responseSite;
    });
    return this.site;
  }

}
