import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
import {
  brushIcon,
  closeSquareIcon,
  deleteIcon,
  downloadIcon,
  editIcon,
  eyeOpenIcon,
  favoriteIcon,
  imagesIcon,
  zoomPlusIcon
} from "../../traits/icons-tools";
import {deleteFromArray} from "../../traits/array-tools";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";
import {dateNow} from "../../traits/datetime-tools";
import { saveAs } from 'file-saver';
import {BadgeModule} from "primeng/badge";
import {SidebarComponent} from "../common/sidebar/sidebar.component";
import {ZoomImageComponent} from "../zoom-image/zoom-image.component";
import {CropRigionComponent} from "../crop-rigion/crop-rigion.component";
import {GrayscaleComponent} from "../grayscale/grayscale.component";
import {windowWidth} from "../../traits/window-tools";
import {SketchConverterComponent} from "../sketch-converter/sketch-converter.component";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {SliderModule} from "primeng/slider";
import {ImageEditorComponent} from "../image-editor/image-editor.component";
import {ImageTracingComponent} from "../image-postorizer/image-tracing.component";
import {CroppieComponent} from "../croppie/croppie.component";
import {CircleWithImageComponent} from "../circle-with-image/circle-with-image.component";
import {BrushesPhotoshopComponent} from "../brushes-photoshop/brushes-photoshop.component";
import {TabViewModule} from "primeng/tabview";
import {ScrollPanelModule} from "primeng/scrollpanel";
@Component({
  selector: 'app-course-resourses',
  templateUrl: './course-resourses.component.html',
  standalone: true,
    imports: [
        NgIf,
        NgFor,
        BadgeModule,
        SidebarComponent,
        ZoomImageComponent,
        CropRigionComponent,
        GrayscaleComponent,
        SketchConverterComponent,
        OverlayPanelModule,
        SliderModule,
        ImageEditorComponent,
        BtnDownloadComponent,
        ImageTracingComponent,
        CroppieComponent,
        CircleWithImageComponent,
        BrushesPhotoshopComponent,
        TabViewModule,
        ScrollPanelModule,
    ]
})
export class CourseResoursesComponent {
  @Input() public srcImage: string;
  @Input() images: any[];
  @Input() showEdit:false
  public showOriginalSketch: boolean = false;
  @Output() onClose = new EventEmitter();
  @Output() onCrop = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  imagePallete: string;
  constructor() { }
ngOnInit(){

}
  ngAfterViewInit(): void {
  }

}
