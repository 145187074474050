<div class="flex justify-center">
  <div class="grid grid-cols-1  gap-4">
    <ng-container *ngFor="let  downloadItem  of models">
      <div class="relative  inline-block transition-transform transform hover:scale-110">
        <img [src]="downloadItem.srcPreview" alt="upload_image" class="w-64 h-642 object-cover rounded cursor-pointer"
        >
        <button
          class="text-sm absolute top-0 -right-2 bg-red-500 text-white p-1 rounded-full transition-transform transform hover:scale-125"
          (click)="deleteItem(downloadItem)">
          <i [class]="deleteIcon"></i>
        </button>
        <button #downloadBtnElement
                class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-blue-500 border border-transparent rounded-l-md hover:bg-gradient-to-r hover:from-blue-600 hover:to-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                (click)="downloadImage(downloadItem.srcPreview)"
        >
          <i [class]="downloadIcon"></i>
          Downloads
        </button>
      </div>
    </ng-container>
  </div>
</div>
