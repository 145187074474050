import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {TabViewModule} from 'primeng/tabview';
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {BadgeModule} from "primeng/badge";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {ColorsImageComponent} from "../../colors-image/colors-image.component";
import {UploaderPhotoComponent} from "../admin/uploader-photo/uploader-photo.component";
import {SidebarComponent} from "../common/sidebar/sidebar.component";
import {CircleWithImageComponent} from "../circle-with-image/circle-with-image.component";
import {ShareImageComponent} from "../share-image/share-image.component";
import {CropRigionComponent} from "../crop-rigion/crop-rigion.component";
import {ImagesViewerGridSlidderComponent} from "../pages/images-viewer-grid-slidder/images-viewer-grid-slidder.component";
import {EyeDropperComponent} from "../eye-dropper/eye-dropper.component";
import {ImageMosaicComponent} from "../image-mosaic/image-mosaic.component";
import {GrayscaleComponent} from "../grayscale/grayscale.component";
import {CameraComponent} from "../pages/camera/camera.component";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";
import {AlbumComponent} from "../album/album.component";
import {ZipComponent} from "../zip/zip.component";
import {BrowserCloseAlertComponent} from "../browser-close-alert/browser-close-alert.component";
import {saveIcon} from 'src/app/traits/icons-tools';
import {dateNow} from 'src/app/traits/datetime-tools';
import {CircleWithColorComponent} from "../circle-with-color/circle-with-color.component";
import {ColorIroComponent} from "../color-iro/color-iro.component";
import {RectangleWithColorComponent} from "../rectangle-with-color/rectangle-with-color.component";
import {ColorHistoryComponent} from "../color-history/color-history.component";
import {ColorPanelComponent} from "../color-panel/color-panel.component";
import {CircleWithHarmonyComponent} from "../circle-with-harmony/circle-with-harmony.component";
import {windowWidth} from "../../traits/window-tools";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {CdServices} from "../../services/common/cd.services";
import {CoinsComponent} from "../coins/coins.component";
import {SliderModule} from "primeng/slider";
import {ToggleButtonModule} from "primeng/togglebutton";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {AccordionModule} from "primeng/accordion";
import {
  arrayStartEnd,
  arrayStartEndStep
} from "../../traits/params-tools";
import {ZoomImageComponent} from "../zoom-image/zoom-image.component";
import {
  downloadImage,
  downloadJson
} from "../../traits/importExport-tools";
import {
  applyFilters,
  colorizeImage,
  compress,
  initImageTracer,
  removeBackgroundWhite,
  srcSketch
} from 'src/app/traits/image-tools';
import {paramsList} from "swiper/angular/angular/src/utils/params-list";


@Component({
  selector: 'app-image-tracing',
  standalone: true,
  imports: [
    TabViewModule,
    SidebarComponent,
    CircleWithImageComponent,
    ColorsImageComponent,
    UploaderPhotoComponent,
    NgIf,
    FormsModule,
    BadgeModule,
    ShareImageComponent,
    CropRigionComponent,
    ImagesViewerGridSlidderComponent,
    NgForOf,
    EyeDropperComponent,
    ImageMosaicComponent,
    GrayscaleComponent,
    ButtonModule,
    CameraComponent,
    BtnDownloadComponent,
    AlbumComponent,
    ZipComponent,
    BrowserCloseAlertComponent,
    CircleWithColorComponent,
    ColorIroComponent,
    RectangleWithColorComponent,
    ColorHistoryComponent,
    ColorPanelComponent,
    CircleWithHarmonyComponent,
    ScrollPanelModule,
    CoinsComponent,
    SliderModule,
    ToggleButtonModule,
    OverlayPanelModule,
    AccordionModule,
    ZoomImageComponent,
  ],
  templateUrl: './image-tracing.component.html',
})

export class ImageTracingComponent implements AfterViewInit {

  @ViewChild('mediumZoomElement') mediumZoomElement: ElementRef<HTMLCanvasElement>;
  @Input() imageSrc: string = srcSketch
  @Input() imageSrcTemp?: string
  @Input() showPreview: boolean = true;
  @Input() showResult: boolean = true;
  @Input() blurAmount: number = 5;
  @Input() grayscaleAmount: number = 1;
  @Input() selectColor: string;
  @Input() brightness:number = 100;
  @Input() contrast:number = 100;
  @Input() saturation:number = 100;
  @Input() blurFilter: number = 0;
  @Input() opacityFilter: number = 100;
  @Input() invertAmount: number = 1;
  @Input() mode: object ={name:'normal',value:'color'};
  @Input() showBackground: boolean = true;
  @Input() isBlackAndWhite: boolean = true;
  @Input() showColorOverlay: boolean = true;

  public imgResult: string;
  public showListSidebar: boolean = false;
  public showParamsSidebar: boolean = false;
  public showHorizontalBar: boolean = true;
  @Input() colorHex: string = '#000000';
  @Input()  indexParamsTrace: number  = 0;


  widthImage: number = 512;
  heightImage: number = 512;
  @Output() onTracePreview = new EventEmitter<string>();
  @Output() onTraceOriginal = new EventEmitter<string>();

  @Input()  paramsBlack:any =  {
      colorsampling: 0,
      numberofcolors: 2,
      blurradius: 0,
      blurdelta: 20
    }

  defaultParams: any = {
    corsenabled: false,
    ltres: 1,
    qtres: 1,
    pathomit: 8,
    rightangleenhance: true,

    // Color quantization
    colorsampling: 2,
    numberofcolors: 16,
    mincolorratio: 0,
    colorquantcycles: 3,

    // Layering method
    layering: 0,

    // SVG rendering
    strokewidth: 1,
    linefilter: false,
    scale: 1,
    roundcoords: 1,
    viewbox: false,
    desc: false,
    lcpr: 0,
    qcpr: 0, // Blur
    blurradius: 0,
    blurdelta: 20
  };
  public paramsList: any[] = [
    {
      name: 'posterized1',
      srcPreview: 'assets/tracing/posterized1.jpg',
      value: {
        colorsampling: 0,
        numberofcolors: 2
      }
    },
    {
      name: 'posterized2',
      srcPreview: 'assets/tracing/posterized2.jpg',
      value: {
        numberofcolors: 4,
        blurradius: 5
      }
    },
    {
      name: 'curvy',
      srcPreview: 'assets/tracing/curvy.jpg',
      value: {
        ltres: 0.01,
        linefilter: true,
        rightangleenhance: false
      }
    },
    {
      name: 'sharp',
      srcPreview: 'assets/tracing/sharp.jpg',
      value: {
        qtres: 0.01,
        linefilter: false
      }
    },
    {
      name: 'detailed',
      srcPreview: 'assets/tracing/detailed.jpg',
      value: {
        pathomit: 0,
        roundcoords: 2,
        ltres: 0.5,
        qtres: 0.5,
        numberofcolors: 64
      }
    },
    {
      name: 'smoothed',
      srcPreview: 'assets/tracing/smoothed.jpg',
      value: {
        blurradius: 5,
        blurdelta: 64
      }
    },
    {
      name: 'grayscale',
      srcPreview: 'assets/tracing/grayscale.jpg',
      value: {
        colorsampling: 0,
        colorquantcycles: 1,
        numberofcolors: 7
      }
    },
    {
      name: 'fixedpalette',
      srcPreview: 'assets/tracing/fixedpalette.jpg',
      value: {
        colorsampling: 0,
        colorquantcycles: 1,
        numberofcolors: 27
      }
    },
    {
      name: 'randomsampling1',
      srcPreview: 'assets/tracing/randomsampling1.jpg',
      value: {
        colorsampling: 1,
        numberofcolors: 8
      }
    },
    {
      name: 'randomsampling2',
      srcPreview: 'assets/tracing/randomsampling2.jpg',
      value: {
        colorsampling: 1,
        numberofcolors: 64
      }
    },
    {
      name: 'artistic1',
      srcPreview: 'assets/tracing/artistic1.jpg',
      value: {
        colorsampling: 0,
        colorquantcycles: 1,
        pathomit: 0,
        blurradius: 5,
        blurdelta: 64,
        ltres: 0.01,
        linefilter: true,
        numberofcolors: 16,
        strokewidth: 2
      }
    },
    {
      name: 'artistic2',
      srcPreview: 'assets/tracing/artistic2.jpg',
      value: {
        qtres: 0.01,
        colorsampling: 0,
        colorquantcycles: 1,
        numberofcolors: 4,
        strokewidth: 0
      }
    },
    {
      name: 'artistic3',
      srcPreview: 'assets/tracing/artistic3.jpg',
      value: {
        qtres: 10,
        ltres: 10,
        numberofcolors: 8
      }
    },
    {
      name: 'artistic4',
      srcPreview: 'assets/tracing/artistic4.jpg',
      value: {
        qtres: 10,
        ltres: 10,
        numberofcolors: 64,
        blurradius: 5,
        blurdelta: 256,
        strokewidth: 2
      }
    },
  ];
  protected readonly windowWidth = windowWidth;
  protected readonly arrayStartEnd = arrayStartEnd;
  protected readonly arrayStartEndStep = arrayStartEndStep;
  protected readonly saveIcon = saveIcon;

  constructor(public cd: CdServices) {
  }

  ngOnInit(){
    this.setTraceOriginal()

  }
  ngAfterViewInit(): void {

  }


 public generatePreviewTrace(){
    compress(this.imageSrc, {quality: 0.2, maxHeight: 150}, (baseCompress64: any) => {
      setTimeout(() => {
        this.paramsList.map((itemParam:any)=>{
          initImageTracer(baseCompress64, itemParam.value, (base64: string) => {
            itemParam.srcPreview = base64;
          });

        })
      }, 500)
    });
    this.setTracePreview();

  }


 public setParamsFromList(modeItem){
    this.defaultParams = modeItem.value;
    this.setTracePreview();
    this.showListSidebar = false;
  }
 public setTraceOriginal(){

   let paramsColorize:any = {
     mode: this.mode,
     colorHex: this.colorHex,
   };
   let paramsFilters:any = {
     brightness: this.brightness,
     saturation: this.saturation,
     contrast: this.contrast,
     blur: this.blurFilter,
     opacity: this.opacityFilter,
   };

    initImageTracer(this.imageSrc, this.paramsBlack, (base64Trace: string) => {
if(this.showColorOverlay){
  colorizeImage(base64Trace,paramsColorize , (base64Colorize: string) => {
    applyFilters(base64Colorize, paramsFilters, (base64Filter: string) => {
      this.onTraceOriginal.emit(base64Filter);
      this.imgResult =base64Filter;
    })
  });
}else{
  applyFilters(base64Trace, paramsFilters, (base64Filter: string) => {
    this.onTraceOriginal.emit(base64Filter);
    this.imgResult =base64Filter;
  })
}

    });
  }
 public setTracePreview() {
   this.defaultParams.scale = 1;
   compress(this.imageSrc, {quality: 0.5, maxHeight: 300}, (baseCompress64: any) => {
     initImageTracer(baseCompress64, this.defaultParams, (base64: string) => {
       this.onTracePreview.emit(base64);
     });
   })
  }


  resetCanvas() {

  }

  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }


  saveParamsData() {
    let fileName = 'trace-params' + '_' + dateNow();
    downloadJson(this.defaultParams, fileName + '.txt')
    downloadImage(this.imgResult, fileName + '.jpg')
  }
}
