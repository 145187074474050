import {
  Component,
  ElementRef,
  inject,
  ViewChild
} from '@angular/core';
import {TabViewModule} from 'primeng/tabview';
import {SidebarComponent} from '../../../components/common/sidebar/sidebar.component';
import {CommonHomeComponent} from '../../common-home/common-home.component';
import {CircleWithImageComponent} from "../../../components/circle-with-image/circle-with-image.component";
import {ColorsImageComponent} from "../../../colors-image/colors-image.component";
import {UploaderPhotoComponent} from "../../../components/admin/uploader-photo/uploader-photo.component";
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {
  addToEnd,
  addToStart,
  deleteFromArray
} from "../../../traits/array-tools";
import {ThemeServices} from "../../../services/common/theme.services";
import {theme} from 'src/app/interfaces/theme';
import {BadgeModule} from "primeng/badge";
import {ShareImageComponent} from "../../../components/share-image/share-image.component";
import {CropRigionComponent} from "../../../components/crop-rigion/crop-rigion.component";
import {ImagesViewerGridSlidderComponent} from "../../../components/pages/images-viewer-grid-slidder/images-viewer-grid-slidder.component";
import {dateNow} from "../../../traits/datetime-tools";
import {EyeDropperComponent} from "../../../components/eye-dropper/eye-dropper.component";
import {ImageMosaicComponent} from "../../../components/image-mosaic/image-mosaic.component";
import {GrayscaleComponent} from "../../../components/grayscale/grayscale.component";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {HeaderComponent} from "../../../components/pages/header/header.component";
import {NavbarTopComponent} from "../../../components/pages/navbar-top/navbar-top.component";
import {PageImagePalleteServices} from "../../../services/pages/page-image-palette.services";
import {CameraComponent} from "../../../components/pages/camera/camera.component";
import {PreloadComponent} from "../../../components/pages/preload/preload.component";
import {ListDownloadsComponent} from "../../../components/list-downloads/list-downloads.component";
import {
  cameraIcon,
  closeSquareIcon,
  cropIcon,
  deleteIcon,
  downloadIcon,
  eyeDropperIcon,
  favoriteIcon,
  gridIcon,
  listIcon,
  moveIcon,
  pinIcon,
  shareIcon
} from "../../../traits/icons-tools";
import {modelNew} from "../../../traits/model-tools";
import {BtnDownloadComponent} from "../../../components/btn-download/btn-download.component";
import {AlbumComponent} from "../../../components/album/album.component";
import {ZipComponent} from "../../../components/zip/zip.component";
import {BrowserCloseAlertComponent} from "../../../components/browser-close-alert/browser-close-alert.component";
import {randomId} from "../../../traits/string-tools";
import {PackPalettesComponent} from "../../../components/pack-palettes/pack-palettes.component";
import {PalleteGeneratorImageComponent} from "../../../components/pallete-generator-image/pallete-generator-image.component";
import {PalleteGeneratorColorComponent} from "../../../components/pallete-generator-color/pallete-generator-color.component";
import {PageColorPalletePickerServices} from "../../../services/pages/page-color-palette-picker.services";

@Component({
  selector: 'app-page-color-palette-picker',
  standalone: true,
  imports: [
    TabViewModule,
    SidebarComponent,
    CommonHomeComponent,
    CircleWithImageComponent,
    ColorsImageComponent,
    UploaderPhotoComponent,
    NgIf,
    FormsModule,
    BadgeModule,
    ShareImageComponent,
    CropRigionComponent,
    ImagesViewerGridSlidderComponent,
    NgForOf,
    EyeDropperComponent,
    ImageMosaicComponent,
    GrayscaleComponent,
    ButtonModule,
    HeaderComponent,
    NavbarTopComponent,
    CameraComponent,
    PreloadComponent,
    ListDownloadsComponent,
    BtnDownloadComponent,
    AlbumComponent,
    ZipComponent,
    BrowserCloseAlertComponent,
    PackPalettesComponent,
    PalleteGeneratorImageComponent,
    PalleteGeneratorColorComponent,
  ],
  templateUrl: './page-color-palette-picker.component.html',
})

export class PageColorPalettePickerComponent {
  @ViewChild('roundPallete') roundPallete: ElementRef<HTMLCanvasElement>;

  public showUpload: boolean = true;
  public showDownload: boolean = false;
  public showPallete: boolean = true;
  public showListDownloads: boolean = false;
  public imageUploaded: string = 'assets/avatars animals/10078357.png';
  public resetUploaded: string = 'assets/avatars animals/10078357.png';
  public tempImage: string = 'assets/avatars animals/10078357.png';
  public bgImage: string = 'assets/background_logo_small.png';
  public bgColor: string = 'bg-zinc-700';

  public imageCrop: string;
  public imageGrayscale: string;
  public imagePixel: string;
  public imagePallete: string;
  public listUploads: any[] = [
    {
      srcPreview: 'assets/avatars animals/10078357.png',
      check: true
    },
    {
      srcPreview: 'assets/avatars animals/10005358.png',
      check: false
    },
    {
      srcPreview: 'assets/avatars animals/10005362.png',
      check: false
    },
  ];
  public listDownloads: any[] = [];
  public listPalletes: any[] = [];
  public listDownloadsCount: any = 0;
  public listUploadsCount: number = 0;
  public theme: theme = inject(ThemeServices);
  public showCropRigion: boolean = false;
  public showUploadsList: boolean = false;
  public showDownloadsList: boolean = false;
  public showPixelizer: boolean = false;
  public showPackPaletes: boolean = false;
  public showEyeDropper: boolean = false;
  public showPreload: boolean = true;
  public showGrayscale: boolean = false;
  public showCropRigionBtn: boolean = true;
  public showGrayscaleBtn: boolean = true;
  public showPixelizerBtn: boolean = true;

  public palleteSize: string = '75%';
  public showPreview: boolean = false;
  showCameraComponent: boolean = false;
  showChildComponent: boolean = true;
  isBlackAndWhite: boolean = true;
  showDownloadBtn: boolean = true;
  public colorsEyeDropper: any[] = []
  protected readonly deleteFromArray = deleteFromArray;
  protected readonly dateNow = dateNow;
  protected readonly deleteIcon = deleteIcon;
  protected readonly favoriteIcon = favoriteIcon;
  protected readonly shareIcon = shareIcon;
  protected readonly downloadIcon = downloadIcon;
  protected readonly listIcon = listIcon;
  protected readonly closeSquareIcon = closeSquareIcon;
  protected readonly gridIcon = gridIcon;
  protected readonly pinIcon = pinIcon;
  protected readonly eyeDropperIcon = eyeDropperIcon;
  protected readonly cropIcon = cropIcon;
  protected readonly cameraIcon = cameraIcon;

  constructor(public dataPage: PageColorPalletePickerServices) {
  }
  ngOnInit(){
    this.init();
  }
  init(){
    if(this.listUploads[0]){

    }
    let firstUpload = this.listUploads[0].srcPreview;
    this.imageCrop = firstUpload
    this.imageUploaded = firstUpload;
    this.tempImage = firstUpload;
    this.resetChildComponent()
    // this.getPalleteImage();
  }

  public setImageToRoundPallete(base64: string) {
    // @ts-ignore
    this.roundPallete.imageSrc = base64;
    // @ts-ignore
    this.roundPallete.reset();
    // @ts-ignore

    this.roundPallete.init();

    // this.resetChildComponent()
  }

  /* public downloadFromPallete(target: any) {
     // this.hideButtonTemporarily(target)
     // @ts-ignore
     this.roundPallete.downloadImage()
     // this.getPalleteImage();
     let modelDelete: any = modelNew();
     modelDelete.srcPreview = this.srcImage;
     addToStart(modelDelete, this.listDownloads);
     this.listDownloadsCount = this.listDownloads.length;
   }*/
  setImage(base64: string) {
    this.imageUploaded = base64;
    this.imageCrop = base64;
    this.tempImage = base64;
    this.resetChildComponent()
  }

  public uploadImage(base64: string): void {
    console.log('camera', base64)
    this.setImage(base64);
    addToStart(this.imageUploaded, this.listUploads)
    this.listUploadsCount = this.listUploads.length;
    // this.setImageToRoundPallete(base64)

    /* this.imageUploaded = base64;
     this.imageCrop = base64;
     this.showDownload = true;

     this.getPalleteImage();
     // @ts-ignore
     this.roundPallete.imageSrc = base64;

     // this.setImageToRoundPallete(base64)
     this.resetChildComponent()*/
  }


  downloadFromList() {
    // @ts-ignore
    this.roundPallete.downloadImage()
  }


  getPalleteImage() {
    // @ts-ignore
    this.imagePallete = this.roundPallete.getImage();
    // @ts-ignore
    // this.imagePallete = this.roundPalletePreview.getImage();
  }

  public cropImage(base64: string) {
    this.imageUploaded = base64;
    this.resetChildComponent()
    // this.imageCrop = base64;
    // this.setImageToRoundPallete(base64)
    // sidebar crop
    // small preview palletes
  }

  public toggleEyeDropper() {
    // show sidebar
    // show eyeDropperPicker image
    // small preview palletes
  }

  public isShowPalletes() {
    return (this.imageUploaded
      // && this.listDownloadsCount>0
    )
  }

  selectUpload(base64: string) {
    // console.log(base64,'selectUpload')
    this.imageCrop = base64;
    this.imageUploaded = base64;
    this.tempImage = base64;
    this.resetChildComponent()
    this.getPalleteImage();
    // this.resetDownloadComponent()

    // this.setImageToRoundPallete(base64)

  }


  public showCropPanel() {
    this.showCropRigion = !this.showCropRigion;
    this.showDownloadBtn = !this.showDownloadBtn;

    this.showUpload = !this.showUpload;
    if (this.showCropRigion) {
      this.palleteSize = '50%'
      this.showPixelizerBtn = false;
      this.showGrayscaleBtn = false;
    } else {
      this.palleteSize = '75%'
      this.showPixelizerBtn = true;
      this.showGrayscaleBtn = true;
    }
  }


  public showEyeDropperImage() {
    this.showEyeDropper = !this.showEyeDropper;
    this.showUpload = !this.showUpload;
    this.showDownloadBtn = !this.showDownloadBtn;
    this.showUpload = !this.showUpload;

    if (this.showEyeDropper) {
      this.palleteSize = '50%'
    } else {
      this.palleteSize = '75%'
    }
  }

  public showPixelazerImage() {
    this.showPixelizer = !this.showPixelizer;
    this.showUpload = !this.showUpload;
    this.showDownloadBtn = !this.showDownloadBtn;
    this.showUpload = !this.showUpload;

    if (this.showPixelizer) {
      this.palleteSize = '50%'
      this.showGrayscaleBtn = false;
      this.showCropRigionBtn = false;
    } else {
      this.palleteSize = '75%'
      this.showGrayscaleBtn = true;
      this.showCropRigionBtn = true;
    }
  }

  public showGrayscaleImage() {
    this.showGrayscale = !this.showGrayscale;
    this.showUpload = !this.showUpload;
    // this.showDownloadBtn =!this.showDownloadBtn;

    if (this.showGrayscale) {
      this.showPixelizerBtn = false;
      this.showCropRigionBtn = false;
    } else {
      this.showPixelizerBtn = true;
      this.showCropRigionBtn = true;
    }
  }
  packTrioPallete:any[] = [];
  packTrioPallete2:any[] = [];
  packTrioImage:any[] = [];

  handleImageConverted(base64: string) {
    // this.tempImage = base64;
    this.imageUploaded = base64;
    this.resetChildComponent()
  }

  toggleBlackAndWhite() {
    this.isBlackAndWhite = !this.isBlackAndWhite;
  }


  resetChildComponent(callback: any = null) {
    this.showChildComponent = false;
    // this.showPixelizer =false;
    setTimeout(() => {
      this.showChildComponent = true;
      // this.showPixelizer =true;
      // this.showPixelizer =false;

    })
  }

  resetDownloadComponent(callback: any = null) {
    this.showDownloadBtn = false;
    // this.showPixelizer =false;
    setTimeout(() => {
      this.showDownloadBtn = true;
      // this.showPixelizer =true;
      // this.showPixelizer =false;

    })
  }

  addColorToPallete(hexColor: any) {
    console.log(hexColor, 'addColorToPallete')
    console.log(this.colorsEyeDropper, 'addColorToPallete')
    addToStart(hexColor.hex, this.colorsEyeDropper);
    this.resetChildComponent()

    // @ts-ignore

    // this.imagePallete = this.roundPallete.setColorToBigCircle(colorItem.hex);

  }


  setUploadList($event: any) {
    this.listUploads = $event;
  }

  public showCamera() {
    this.showCameraComponent = !this.showCameraComponent;
    // this.showUpload = !this.showUpload;
    this.showDownloadBtn = !this.showDownloadBtn;
    if (this.showCameraComponent) {
      this.palleteSize = '50%'
    } else {
      this.palleteSize = '75%'
    }
  }

  onBatchPaletter(event: any) {
    console.log(event)
    // this.setImage(event.srcPreview);
    this.imageUploaded = event.srcPreview;
    this.tempImage = event.srcPreview;
    this.showChildComponent = false;
    // this.showPixelizer =false;
    setTimeout(() => {
      this.showChildComponent = true;
      // this.showPixelizer =true;
      // this.showPixelizer =false;

    }, 1000)
  }
  createPack(){
    this.showPreload = true;
    this.showGrayscale = true;
    setTimeout(() => {
      this.showGrayscale = false;
      this.showPixelizer = true;

      setTimeout(() => {
        this.showPixelizer = false;
        let model:any = modelNew();
        model.srcPreview = this.imageCrop;
       addToStart(model, this.packTrioPallete)
        setTimeout(() => {
          this.showPackPaletes = true;
          this.showPreload = false;
        }, 1000);
      }, 500);
    }, 500);
    // this.showCropPanel();
    // this.showGrayscaleImage();
    // this.showPixelazerImage();
    // this.packTrioImage = [
    //   {id:randomId(),name:'imageCrop',srcPreview:this.imageCrop},
    //   {id:randomId(),name:'imageGrayscale',srcPreview:this.imageGrayscale},
    //   {id:randomId(),name:'imagePixel',srcPreview:this.imagePixel},
    // ]
  }
  addToPalleteList($event: string) {
    let model = modelNew();
    model.srcPreview = $event
    addToStart(model, this.listPalletes)
  }
  addToPackList($event: string) {
    let model = modelNew();
    model.srcPreview = $event;
// Добавляем новый элемент в конец массива и оставляем только три последних элемента
    this.packTrioPallete = this.packTrioPallete.concat(model).slice(-3);
  }

  cropSelect(srcPreview: any) {

  }

  protected readonly moveIcon = moveIcon;
}
