import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {FileItem, FileUploadModule, FileUploader} from 'ng2-file-upload';
import {NgFor, NgIf} from '@angular/common';
import { fileToBase64 } from 'refactoring-code/services/traits/image-tools';
import {compress} from '../../../traits/image-tools';
import {ThemeServices} from "../../../services/common/theme.services";
import { theme } from 'src/app/interfaces/theme';
import {BadgeModule} from "primeng/badge";
import {
  addToStart,
  deleteFromArray
} from "../../../traits/array-tools";
import {
  modelBoxNew,
  modelNew
} from "../../../traits/model-tools";
import {
  deleteIcon,
  favoriteIcon
} from "../../../traits/icons-tools";
import {AlbumComponent} from "../../album/album.component";
import {
  addModelDB,
  countTableDB,
  getTableDB,
  updateModelDB
} from "../../../traits/indexedDB";
import {randomId} from "../../../traits/string-tools";
import {dateNow} from "../../../traits/datetime-tools";
import {SidebarComponent} from "../../common/sidebar/sidebar.component";
import {windowWidth} from "../../../traits/window-tools";

@Component({
  selector: 'app-uploader-photo',
  standalone:true,
  imports: [
    NgIf,
    NgFor,
    FileUploadModule,
    BadgeModule,
    AlbumComponent,
    SidebarComponent
  ],
  templateUrl: './uploader-photo.component.html',
  styleUrls: ['./uploader-photo.component.scss'],
})
export class UploaderPhotoComponent implements OnInit {
  @Input() t: any = 'img';
  @Input() textBtn: any = 'фото';
  @Input() showLabel: any = true;
  @Input() compressionEnable: any = true;
  public theme: theme = inject(ThemeServices);
  public showUploadsList: boolean = false;
  @Input() multiple: any = true;
  @Input() type: any = 'photo';
  @Input() folder: any = '';
  @Input() model: any;
  @Input() showListHorisont: any =false;
  @Input() showListModal: any =false;
  @Input() showUpload: any =true;
  @Input() showCounter: any = false;
  public uploadSpinner: any = false;
  @Output() oPhoto = new EventEmitter();
  @Output() onClickItem = new EventEmitter();
  @Output() onClickUpload = new EventEmitter();
  @Output() onUploadedFinish = new EventEmitter();
  @Output() oData = new EventEmitter();
  @Output() eventEmit = new EventEmitter();
  @Output() oPhotoLib = new EventEmitter();
  @Output() result = new EventEmitter();
  @Output() crop = new EventEmitter();
  @Output() onBase64 = new EventEmitter();
  @Output() onList = new EventEmitter();
  @Output() onShowList = new EventEmitter();
  // @ts-ignore
  public uploader: FileUploader;
  public index: any = 0;
  public length: any = 0;
  public time: any = 500;
  public timeOut: any;
  public timeTotal: any = 0;
  public sizeTotal: any = 0;
  public files: any = [];
  public quality: any = {quality: 0.7, maxHeight: 800};
  public firstFile: any;
  hasBaseDropZoneOver: any;
  hasAnotherDropZoneOver: any;
  imagesOptimization: any = [
    {quality: 0.7, maxHeight: 150, icon: 'fas fa-battery-empty'},
    {quality: 0.7, maxHeight: 800, icon: 'fas fa-battery-quarter'},
    {quality: 1, maxHeight: 1800, icon: 'fas fa-battery-full'},
    // {quality: 1, maxHeight: 2400},
    // {quality: 0.5, maxHeight: 75},
    // {quality: 0.5, maxHeight: 300},
    // {quality: 0.7, maxHeight: 600},


    // {quality: 1, maxHeight: 1200},
    // {quality: 1, maxHeight: 2400},
    // {quality: 1, maxHeight: 3000},
  ];
  public showUploadDropZone: any;
  private listUploadsCount: number;
  public storageKey: string = 'uploadedStages';
  public tableKey: string = 'stages';
  public stagesList: any[];
  public selectStage:any;

 public clickItem(uploadItem:any){
    this.onClickItem.emit(uploadItem.srcPreview);
     uploadItem.check = !uploadItem.check;
  }
  constructor() {}

 /* public millisToMinutesAndSeconds(millis: any) {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    // @ts-ignore
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }*/


 private  sendToServer() {
      this.uploader = new FileUploader({
       url: 'https://server-2.artas.org.ua/image/save-gif',
       autoUpload: true,
       isHTML5: true,
     });
     this.uploader.onBeforeUploadItem = (fileItem: FileItem) => {
       let id = Date.now();
       let fileName = id + '.gif';
       fileItem.withCredentials = false;
       this.uploader.options.additionalParameter = {
         id: id,
         name: fileName,
       };
     };
     /* this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
        fileItem.append('nameSecond', 'alex');
        fileItem.append('id', 50);
      };*/
     this.uploader.onAfterAddingFile = (file) => {
       file.withCredentials = false;
     };
    // allowedFileType: ['video'],
   /*  {
       url: 'https://server-2.artas.org.ua/collections/create-file-video',
         autoUpload: false,
       isHTML5: true,}*/
  }

  ngOnInit() {
    this.getStages()
    // @ts-ignore
    this.uploader = new FileUploader();
    // this.sendToServer();
  }

  public getStages() {
    countTableDB(this.storageKey, this.tableKey,(count:number)=>{
      this.initStage();
      if(count == 0) {
        this.addStage()
        this.initStage();
      }
    })

    }

  addStage(selectStage: any = this.stageModel()) {
    addToStart(selectStage, this.stagesList)
    if(this.stagesList){
    this.selectStage = this.stagesList[0];
    }
    addModelDB(this.storageKey, this.tableKey, selectStage)
  }
  updateStage() {
    updateModelDB(this.storageKey, this.tableKey, this.selectStage)
  }
  initStage(){
    getTableDB(this.storageKey, this.tableKey, (stages: any[]) => {
      if(stages){
        this.selectStage = stages[0]
        this.stagesList = stages
      }});
  }
  stageModel(srcPreview: string = 'assets/sketch.jpg') {
    return {
      id: randomId(),
      createAt: dateNow(),
      sw: true,
      name: 'Name',
      desc: 'Desc',
      text: 'Text',
      srcPreview: srcPreview,
      cat: 'boardImage',
      check: false,
      images: [],
      tags: [],
    }
  }
  imageBoardModel(srcPreview: string = 'assets/sketch.jpg') {
    return {
      id: randomId(),
      sw: true,
      createAt: dateNow(),
      name: 'Name',
      desc: 'Desc',
      text: 'Text',
      srcPreview: srcPreview,
      cat: 'boardImage',
      check: false,
      tags: [],
      x: 100,
      y: 100,
    }
  }
  addImageToBoard(base64: string) {
    const sceneWidth = 4000;
    const sceneHeight = 4000;
    const imageWidth = 100;
    const imageHeight = 100;
    const imageX = (sceneWidth / 2) - (imageWidth / 2);
    const imageY = (sceneHeight / 2) - (imageHeight / 2);

    let imageModelBoard: any = this.imageBoardModel(base64)
    console.log(imageModelBoard)
    imageModelBoard.x = imageX;
    imageModelBoard.y = imageY;
    addToStart(imageModelBoard, this.selectStage.images)
    this.updateStage();
    this.initStage();
  }



  /*public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
    // console.log(e)
  }*/

  /* public fileOverAnother(e: any): void {
     this.hasAnotherDropZoneOver = e;
     // console.log(e)

   }*/

  /* public cancelDownload(timeOut: any = null) {
     this.uploader.queue = [];
     clearInterval(timeOut);
     this.uploadSpinner = false;
   }*/

  showList(){
    // this.onList.emit(this.files);
    // this.onShowList.emit(true)
    this.showUploadsList = true;
  }
  private saveCompressBase64(rawFile: any) {
    fileToBase64(rawFile, (base64Raw: any) => {
      if(this.compressionEnable){
        compress(base64Raw, this.quality, (baseCompress64: any) => {
          this.createModelFromUpload(baseCompress64)
        });
      }else{
        this.createModelFromUpload(base64Raw)
      }

    });

  }

  createModelFromUpload(base64:string){
    let modelImage = modelNew()
    modelImage.srcPreview = base64;
    addToStart(modelImage,this.files)
    this.addImageToBoard(base64);
    this.onBase64.emit(base64);
  }
  private getTotalSizeFiles() {
    this.uploader.queue.map((el: any) => {
      this.sizeTotal += el.file.size;
    });
    return this.sizeTotal;
  }

  /* public clearFileList() {
     this.uploader.queue = [];
   }
 */

  /* public getFirstFile() {
     this.firstFile = this.uploader.queue[0].file.rawFile;
     return this.firstFile;
   }*/

  public uploadPhoto() {
    this.getTotalSizeFiles();
    this.timeFilesCombiner();
  }
  private timeFilesCombiner() {
    this.uploadSpinner = true;
    let timeOut = setInterval(() => {
      this.index++;
      this.length = this.uploader.queue.length;
      let rawFile = this.uploader.queue[this.index - 1].file.rawFile;
      this.saveCompressBase64(rawFile);
      this.clearTimeOut(rawFile, timeOut);
    }, this.time);
  }

  private clearTimeOut(rawFile: any, timeOut: any) {
    // @ts-ignore
    if (rawFile.size < 300000) {
      this.time = 500;
    }
    this.timeTotal = this.time * this.length;

    if (this.index == this.length) {
      // console.log(this.index, 'this.index');
      // console.log(this.length, 'this.length');
      this.index = 0;
      this.length = 0;
      this.timeTotal = 0;
      this.sizeTotal = 0;
      this.uploader.queue = [];
      clearInterval(timeOut);
      this.uploadSpinner = false;
      setTimeout(() => {
        this.onUploadedFinish.emit(true);
      }, 1000);

    }
  }

  public validate() {
    return !this.uploader.queue[0];
  }

  /* public uploadPhotoDrop($event: any) {
     this.firstFile = this.uploader.queue[0].file.rawFile;

     this.uploader.queue.map((el: any) => {
       this.cd.img.fileToBase64(el.file.rawFile, (base64: any) => {
         this.cd.img.compress(base64, this.quality, (baseCompress64: any) => {
           // console.log(baseCompress64,'baseCompress64')
           this.onBase64.emit(baseCompress64);
         });
       });
     });
     this.uploader.queue = [];


   }*/


  protected readonly deleteIcon = deleteIcon;
  protected readonly deleteFromArray = deleteFromArray;
  protected readonly favoriteIcon = favoriteIcon;
  protected readonly windowWidth = windowWidth;
}
