<div class="relative flex flex-col items-center">
  <div class="flex space-x-2 mt-4">
    <button *ngFor="let value of buttonValues"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-110"
            (click)="applyBlockSize(value)">
      {{ value }}
    </button>
  </div>
  <canvas #canvas class="border border-gray-400 rounded-lg shadow-lg" width="300" height="300"></canvas>

<!--  <div class="absolute top-0 right-0 m-4">
    <button (click)="resetImage()" class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-110">
      Reset
    </button>
  </div>-->

<!--  <button *ngIf="showSaveBtn" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-110"
          (click)="saveImage()">Save Image</button>-->
</div>
