@if (client.isLogined()) {
  <a
    class=" animate-pulse animate-infinite animate-ease-in-out text-orange-500 text-[12px]  flex items-center whitespace-nowrap transition duration-150 ease-in-out motion-reduce:transition-none mr-4"
    (click)="client.showCabinet = true;"
  >
    <!--<img
      [src]="client.clientData.avatar"
      class="rounded-full"
      style="height: 25px; width: 25px"
      alt="User Avatar"
    />-->
    <strong class="ml-1 block">{{ client.clientData.email }}</strong>
<!--    <span class="w-2 pl-1"><i [class]="arrowOneDownIcon"></i></span>-->
  </a>
} @else {
  <a
    class=" animate-pulse animate-infinite animate-ease-in-out text-orange-500 text-[12px]  flex items-center whitespace-nowrap transition duration-150 ease-in-out motion-reduce:transition-none mr-4"
    (click)="  client.showLoginForm = true; cd.loginForm = true;
"
  >
    <!--<img
    [src]="'assets/avatars animals/10005362.png'"
    class="rounded-full"
    style="height: 25px; width: 25px"
    alt="User Avatar"

  />-->
    <strong class="ml-1 block">Login</strong>
  </a>
}

<app-patreon-email-enter></app-patreon-email-enter>
