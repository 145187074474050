import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import {NgIf} from '@angular/common';
import {ProductListRightComponent} from "../../product-list-right/product-list-right.component";
import {Router} from "@angular/router";
import {PageArtClubServices} from "../../../services/pages/page-art-club.services";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  imports: [
    NgIf,
    ProductListRightComponent
  ],
  standalone: true,
})
export class HeaderComponent implements OnInit{

  @Input() bgImage: string = 'assets/header-background.jpg';
  @Input() headerText: string  = 'Free Online Digital Art Courses';
  @Input() subHeaderText: string = 'Free Online Digital Art Courses';
  @Input() messengesName: string ='Courses';
  @Input() arraysName: string ='Lessons';
  @Input() actionText: string ='New lesson or course every week or month!';
  @Input() animationClass: string  ='animate-pulse animate-infinite';
  @Input() countMessenges: number  = 1;
  @Input() countArrays: number  = 1;
  @Input() showCounters: boolean  =false;
  @Input() autoHide: boolean  =true;
  @Input() hideContent: boolean  =false;
  @Input() showProductLeft: boolean  =true;
constructor( private router: Router, public dataPage: PageArtClubServices) {
}
  ngOnInit(): void {
    this.autoHideComponent();
  }
  goToCourse(card: any) {
    this.router.navigate(['/',card.routerLink]);
  }
  autoHideComponent(){
    if(this.autoHide){
      setTimeout(()=>{this.hideContent = true},3000)
    }
  }
}
