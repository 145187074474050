<div class=" mt-3 mb-3 pt-3 pb-3">
  <div class="w-full rounded-lg shadow-lg">
    <div class="tabview">
      <p-tabView>
        <p-tabPanel header="Sliders">
          <app-color-iro
            [width]="200"
            [height]="200"
            (onClick)="setColor($event); colorHistory.addToHistory($event)"
          ></app-color-iro>
        </p-tabPanel>

        <p-tabPanel header="Photo picker">
          <p-tabView>
            <p-tabPanel header="EyeDropper">
                  <app-eye-dropper *ngIf="showEyeDropper"
                                   [widthImage]="350"
                                   [heightImage]="350"
                                   [src]="srcImage"
                                   (onColor)="setColor($event)"
                  ></app-eye-dropper>

            </p-tabPanel>
            <p-tabPanel header="Extract">

                <app-eye-dropper *ngIf="!showPaletteComponent"
                                 [widthImage]="400"
                                 [heightImage]="400"
                                 [src]="imagePallete"
                                 (onColor)="setColor($event)"
                >
                </app-eye-dropper>
                <app-circle-with-image
                  *ngIf="showPaletteComponent"
                  [imageSrc]="srcImage"
                  [showBigRounds]="false"
                  [showAllColors]="true"
                  (onGetPallete)="imagePallete = $event;"

                ></app-circle-with-image>
            </p-tabPanel>
          </p-tabView>
          <div class="text-center justify-center">

          <label
            class=" z-20 m-2 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer">
            <i [class]="uploadesIcon"></i>
            Upload Photo
            <input type="file" id="images-base64"
                   accept="image/*"
                   class="hidden"
                   (change)="importImage($event); "
            />
          </label>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
  <div class="">
    <app-color-history #colorHistory
                       (onColor)="setColor($event)"
    ></app-color-history>
  </div>
</div>

