import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
import {
  addIcon,
  archiveIcon,
  deleteIcon,
  dotesVerticalIcon,
  downloadIcon,
  favoriteIcon,
  filterIcon,
  folderCloseIcon,
  folderIcon,
  gridIcon,
  imagePanoramaIcon,
  libsIcon,
  lockCloseIcon,
  lockOpenIcon,
  moveIcon,
  plusIcon,
  saveIcon,
  uploadesIcon
} from "../../traits/icons-tools";
import {
  addToEnd,
  addToStart,
  deleteFromArray
} from "../../traits/array-tools";
import {SidebarComponent} from "../common/sidebar/sidebar.component";
import {FormsModule} from "@angular/forms";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {
  addModelDB,
  deleteModelDB,
  getTableDB,
  updateListDB,
  updateModelDB
} from "../../traits/indexedDB";
import {randomId} from "../../traits/string-tools";
import {
  dateNow,
  reInit
} from "../../traits/datetime-tools";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {
  textDecrypt,
  textEncrypt
} from "../../traits/crypt-tools";
import {
  cloneModel,
  imageBoardModel,
  stageModel
} from "../../traits/model-tools";
import {SortablejsModule} from "@maksim_m/ngx-sortablejs";
import {SortableOptions} from "sortablejs";
import {ImageReferenceComponent} from "../image-reference/image-reference.component";
import {ColorsGridComponent} from "../admin/colors-grid/colors-grid.component";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";
import {NavbarTopComponent} from "../pages/navbar-top/navbar-top.component";
import {PreloadComponent} from "../pages/preload/preload.component";
import {windowHeight} from "../../traits/window-tools";

@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    JsonPipe,
    SidebarComponent,
    FormsModule,
    ScrollPanelModule,
    OverlayPanelModule,
    SortablejsModule,
    ImageReferenceComponent,
    ColorsGridComponent,
    BtnDownloadComponent,
    NavbarTopComponent,
    PreloadComponent,
  ]


})
export class AlbumComponent implements OnInit{
  @Input() storageKey: string = 'uploadedStages';
  @Input() showAdd: any =false;
  @Input() imageSrc: string = null;
  @Input() addParent: boolean = false;
  @Input() showSelect: boolean = false;
  @Input() models: any[] = [];
  @Input() showUploadsList: boolean = false;
  showReference: boolean =false;

  @Input() showCounter: boolean = false;
  public selectImage: any;
  @Output() onSelect = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() onBatch = new EventEmitter();
  protected readonly deleteIcon: string = deleteIcon;
  protected readonly favoriteIcon: string = favoriteIcon;
  protected readonly downloadIcon = downloadIcon;
  public sortableOptionsStageImages:SortableOptions = {
    animation: 150,
    handle: '.handle',
    group: {
      name: "list",
      // pull: 'clone',
      // pull: true,
      // put: false
    },
    // sort: false,
    // ghostClass: "droppable-area",
    onUpdate: (event: any) => {
      // this.saveStage();
      this.save()
    }
  }
  public sortableOptionsStageList:SortableOptions = {
    animation: 0,
    group: {
      name: "list",
      // pull: true,
      // put: true
    },
    sort: false,
    handle: '.handle',
    onUpdate: (event: any) => {
      // this.saveStage();
      this.save()
    }
  }
  public tableKey: string = 'stages';
  public selectStage: any;
  public showStagesList: boolean = true;
  public stageFirst: any;
  public stagesList: any[];
  public updateStages() {
    updateListDB(this.storageKey, this.tableKey, this.stagesList)
    this.updateStatusSave()
  }
  public updateStage() {
    updateModelDB(this.storageKey, this.tableKey, this.selectStage)
    this.updateStatusSave()
  }
  save(){
    this.updateStage();
    this.updateStages();
  }
  updateStatusSave(){
    this.saveStatus = true
    reInit(()=>{
      this.saveStatus =false
    },1500)
  }
  public saveStatus: any =false;

  addStage(selectStage: any = stageModel()) {
    addToEnd(selectStage, this.stagesList)
    this.selectStage = this.stagesList[0]
    addModelDB(this.storageKey, this.tableKey, selectStage)
  }
  setStage(stageItem: any) {
    this.selectStage = stageItem;
    // this.showStagesList = false;
  }



  public importBin(event: any, callback: any  = null) {
    const files = event.target.files;
    if (files.length === 0) return;
    const file = files[0];
    let reader = new FileReader();
    reader.onload = (e) => {
      let decryptedText: string = textDecrypt(e.target.result);
      console.log(decryptedText)
      // callback & callback(decryptedText)
      let stageModel: any = JSON.parse(decryptedText);
      stageModel = cloneModel(stageModel, true)
      this.addStage(stageModel)
    };
    reader.readAsText(file);
  }

  public importImage(event: any, ) {
    const files = event.target.files;
    const base64Strings = [];
    const intervalTime = 1000;

    Array.from(files).forEach((file:any, index) => {
      setTimeout(() => {
        const reader = new FileReader();
        reader.onloadend = ()=> {
          const base64String:any = reader.result;
          base64Strings.push(base64String);
          this.addImageToBoard(base64String,this.selectStage)
          if (base64Strings.length === files.length) {
            console.log('Все изображения в Base64:', base64Strings);
          }
        };

        reader.readAsDataURL(file);
      }, intervalTime * index);
    });
  }
  public deleteStage(selectStage: any) {
    deleteFromArray(selectStage, this.stagesList);
    deleteModelDB(this.storageKey, this.tableKey, selectStage)
  }
  public archiveStage(selectStage: any) {
    selectStage.sw = false;
    this.updateStage();
  }

  public archiveImage(imageItem: any) {
    imageItem.sw = false;
    this.updateStage();
  }
  exportStage(selectStage: any) {
    let siteText: string = this.objectToString(selectStage);
    let fileName: string = selectStage.name + '_stage_' + dateNow();
    this.exportTextToBin(siteText, fileName);

  }
  public exportTextToBin(textToEncrypt: string, fileName: string) {
    let encrypted: string = textEncrypt(textToEncrypt);
    let blob = new Blob([encrypted], {type: "application/octet-stream"});
    let a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = `${fileName}.bin`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }
  public getStages() {
    this.showStagesList = true;
    getTableDB(this.storageKey, this.tableKey, (stages: any[]) => {
      this.stageFirst = stages[0]
      this.selectStage =this.stageFirst;
      this.stagesList = stages
      this.addParentImage()

    })
    if (!this.selectStage) {
      this.selectStage = stageModel()
      this.stageFirst = this.selectStage
      if (this.selectStage.images){
        this.selectImage =  this.selectStage.images[0]
      }
      this.addParentImage()

    }
  }
   ngOnInit() {
    this.getStages()
     this.addParentImage()
  }

 public addParentImage(){
    if((this.addParent && this.imageSrc)){
      this.addImageToBoard(this.imageSrc,this.stageFirst)
    }
  }
  addImageToBoard(base64: string,stageFirst:any) {
    const sceneWidth = 4000;
    const sceneHeight = 4000;
    const imageWidth = 100;
    const imageHeight = 100;
    const imageX = (sceneWidth / 2) - (imageWidth / 2);
    const imageY = (sceneHeight / 2) - (imageHeight / 2);

    let imageModelBoard: any = imageBoardModel(base64)
    imageModelBoard.x = imageX;
    imageModelBoard.y = imageY;
    addToStart(imageModelBoard, stageFirst.images)
    this.updateStage();
    this.initStage();
  }
  initStage(){
    getTableDB(this.storageKey, this.tableKey, (stages: any[]) => {
      if(stages){
        this.selectStage = stages[0]

        this.stagesList = stages
        if(this.selectStage.images){
          this.selectImage = this.selectStage.images[0]
        }
      }});
  }

  selectUpload(base64: string) {
    this.addImageToBoard(base64,this.selectStage);
  }


  public count(): number {
    return this.models.length;
  }

  public check(item: any) {
    this.selectImage =item;
    item.check = !item.check;
    this.updateStage()
    this.updateStages()
  }

  public deleteImage(item:any) {
    deleteFromArray(item, this.selectStage.images)
    if (this.count()) {
      this.close();
    }
  }

  public close() {
    this.showUploadsList = false;
    this.onClose.emit(false)
  }


  select(item: any) {
    this.onSelect.emit(item)
    this.close();
  }

  selectAll() {
    this.models.forEach(image => image.check = true);
  }

  selectMarked() {
    this.models.forEach(image => image.check = image.check);
  }

  selectUnmarked() {
    this.models.forEach(image => image.check = !image.check);
  }

  processSelectedImages() {
    const checkImageUrls: string[] = [];
    this.models.forEach((image, index) => {
      if (image.check) {
        setTimeout(() => {
          this.onBatch.emit(image);
          // this.imageUploaded = image.srcPreview;
          // this.tempImage = image.srcPreview;
          // this.selectUpload(image.srcPreview);
          // this.downloadImage(image.srcPreview);
          // @ts-ignore
          // this.roundPallete.downloadImage()
          // checkImageUrls.push(image.srcPreview);
        }, index * 1000); // Задержка увеличивается на 2 секунды для каждой итерации
      }
    });
    // console.log(checkImageUrls)
  }


  protected readonly dotesVerticalIcon = dotesVerticalIcon;
  protected readonly plusIcon = plusIcon;
  protected readonly lockCloseIcon = lockCloseIcon;
  protected readonly lockOpenIcon = lockOpenIcon;
  protected readonly saveIcon = saveIcon;
  protected readonly gridIcon = gridIcon;
  protected readonly uploadesIcon = uploadesIcon;
  protected readonly addIcon = addIcon;

  exportAllStages() {

  }

  protected readonly imagePanoramaIcon = imagePanoramaIcon;
  protected readonly folderIcon = folderIcon;
  protected readonly filterIcon = filterIcon;
  protected readonly libsIcon = libsIcon;
  protected readonly archiveIcon = archiveIcon;
  protected readonly folderCloseIcon = folderCloseIcon;

  protected readonly windowHeight = windowHeight;
  protected readonly moveIcon = moveIcon;
}
