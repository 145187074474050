<span class="relative w-[50px] h-[50px] flex align-items-center gap-2 ">
       <p-avatar
                 [image]="coinService.coinAnimation" shape="circle"></p-avatar>



  <span  class="p-[3px]  absolute top-[0] shadow-amber-700 -left-[13px] w-[20px] h-[20px] text-[#f8f8f8] text-[13px] rounded-full" style="
    background-color:#a4529f;
" >
       <span *ngIf="(showCoins && coinService.showCoins && !cd.logined)" >
        {{ coinService.coins }}
     </span>
     <span *ngIf="(showCoinMinus && !cd.logined)">
     {{coinService.coin}}
    </span>
    <span *ngIf="cd.logined">
       <i class="fa-solid fa-infinity"></i>
    </span>
       </span>
   <span class="absolute bottom-0 left-[1px] text-[#f8f8f8] text-[13px]"
         *ngIf="(showCoins && coinService.showCoins && !cd.logined)" >
        {{ 'month' }}
     </span>
</span>
