import {Injectable} from "@angular/core";
import {checkEndOfMonth} from "../../traits/datetime-tools";
import {CdServices} from "./cd.services";
import {
  numberDecrypt,
  numberEncrypt
} from "../../traits/crypt-tools";

@Injectable({
  providedIn: 'root',
})
export class CoinsServices {
  public coins: any = 100;
  public coins100Decrypt: any = 'U2FsdGVkX19mMYkZ8IySSxzbzxN2lV0PGbu6aYKGGMk=';
  public coins5Decrypt: any = 'U2FsdGVkX1/zVfZ8mMsQaw0cnPJln64klM5D3wb4is8=';
  public coin: any = 5;
  private storageKey = 'coins';
  public isFinishCoins:boolean = false;
  public isMinusCoin:boolean = false;
  public showCoins:boolean = true;
  public showCoin:boolean = true;
  public coinAnimation: any = 'assets/gifs/coin_animation-flip.gif';

  constructor(private cd:CdServices) {
    // this.resetCoins()

    this.loadCoins()
    this.monthlyCoinsUpdate();
  }


 public loadCoins(): void {
   // this.saveCoins();

  let coinsEncrypt:string = this.loadFromLocalStorage();
   this.coins =  coinsEncrypt?numberDecrypt(coinsEncrypt):numberDecrypt(this.coins100Decrypt);
   this.coins ===0?this.cd.lock = true:this.cd.lock =false;
  }
  public showCoinsFlag() {
    return (this.cd.lock|| this.cd.logined);
  }
  public minusCoin(): void {
    if(!this.showCoinsFlag) return;
    if(!this.cd.logined){
    if (this.coins !== 0) {
      this.coins -= this.coin;
      this.isMinusCoin = false;
      setTimeout(() => {
        this.isMinusCoin = false;
      }, 50)
      this.saveCoins();
    } else {
        this.cd.lock = true;
        this.isFinishCoins =true;
        this.cd.loginForm = true;
    }
    }
  }

  public saveCoins(): void {
    let coinsEncrypt =  numberEncrypt(this.coins);
    this.saveToLocalStorage(coinsEncrypt);
  }


  resetCoins(){
    this.coins = 100;
    this.saveCoins();
  }
  public monthlyCoinsUpdate(): void {

    checkEndOfMonth(() => {
      this.resetCoins();
    })
  }
  public saveToLocalStorage(coins: number): void {
    localStorage.setItem(this.storageKey, JSON.stringify(coins));
  }








  public loadFromLocalStorage(): string {
    return JSON.parse(localStorage.getItem(this.storageKey));
  }
}
