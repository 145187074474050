import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NgFor, NgIf} from "@angular/common";
import {cloneModel} from "../../traits/model-tools";
import { addToStart } from 'src/app/traits/array-tools';
import {ColorItemComponent} from "../color-item/color-item.component";

@Component({
  selector: 'app-color-history',
  standalone:true,
  imports: [
    NgIf,
    NgFor,
    ColorItemComponent
  ],
  templateUrl: './color-history.component.html'
})
export class ColorHistoryComponent {
  @Input() widthList: number = 400;
  @Input() heightList: number = 30;
  @Input() widthItem: number = 100;
  @Input() heightItem: number = 50;
  @Input() showClear: boolean =  false;
  public colors: string[] = [];
  @Output() onClick = new EventEmitter();
  @Output() onColor = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onPress = new EventEmitter();
  @Output() onDblclick = new EventEmitter();

 public addToHistory(colorHex:string){
   addToStart(colorHex, this.colors);
   // let uniqColors = uniq(this.colors,'hex');
   // this.colors = uniqColors.slice(0,this.sliceColors);
   // this.cd.historyColorCounter = this.colors.length;
// console.log(this.colors,'this.colorsHISTORY')
   // this.cd.historyColors = this.colors;
 }
  ngOnDestroy(): void {
  }
  ngAfterViewInit() {this.scrollerHorizont();}
  public scrollerHorizont() {
    const slider = document.querySelector('.scroll-items') ?? null;
    if (slider) {
      let isDown = false;
      let startX: any;
      let scrollLeft: any;

      // @ts-ignore
      slider.addEventListener('mousedown', (e) => {
        isDown = true;
        // @ts-ignore
        slider.classList.add('active');
        // @ts-ignore
        slider.classList.add('active');
        // @ts-ignore
        startX = e.pageX - slider.offsetLeft;
        // @ts-ignore
        scrollLeft = slider.scrollLeft;
      });
      // @ts-ignore
      slider.addEventListener('mouseleave', () => {
        isDown = false;
        // @ts-ignore
        slider.classList.remove('active');
      });
      // @ts-ignore
      slider.addEventListener('mouseup', () => {
        isDown = false;
        // @ts-ignore

        slider.classList.remove('active');
      });
      // @ts-ignore
      slider.addEventListener('mousemove', (e) => {
        if (!isDown) {
          return;
        }
        e.preventDefault();
        // @ts-ignore
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast

        // @ts-ignore

        slider.scrollLeft = scrollLeft - walk;
      });
    }

  }
}
