<!--<app-tool-spinner  *ngIf="preloadUpload"></app-tool-spinner>-->
<app-sidebar [show]="showCropRigion"
             (onSave)="showCropRigion = false"
             [showClose]="true"
             [height]="100"
             [ext]="'%'"
             [zIndex]="10000"
             [position]="'bottom'"
>
  <app-crop-rigion *ngIf="showCropRigion"
                   [width]="350"
                   [height]=350
                   [cropZoneHeight]=150
                   [cropZoneWidth]=150
                   [cropZoneX]="100"
                   [cropZoneY]="100"
                   [showReset]="false"
                   [showPressetsCrop]="false"
                   [showAspectRatio]="true"
                   [showZoom]="false"
                   [showFlip]="false"
                   [showRotate]="false"
                   [showDownload]="false"
                   [showAdd]="true"
                   [src]="itemSelect.srcPreview"
                   (onAdd)="addCropImage($event,models);onSort.emit(true)"
  ></app-crop-rigion>
</app-sidebar>

<app-uploader-photo
  *ngIf="(showUpload )"
  [showLabel]="true"
  [showCounter]="showCounter"
  (onClickUpload)="onClickUpload.emit()"
  (onUploadedFinish)="onUploadedFinish.emit()"
  (onBase64)="addPhotos($event)"
></app-uploader-photo>

<div *ngIf="showBatch"
     class=" flex justify-around text-[15px] font-bold mb-1 text-gray-300">
  <!--       <span
                (click)="imgServices.baseToSrcBatch(models,'middle','jpg')"
         >
           <span class="text-[8px]">500|Batch</span>
        </span>-->
  <!-- <span
     (click)="imgServices.baseToSrcBatch(models.slice(0,2),'middle','png')"
   >
          <span class="text-[8px]">500|PNG BACH</span>
       </span>-->
  <!--  <span
                (click)="imgServices.baseToSrcBatch(models,'large','jpg')"
         >
           <span class="text-[8px]">800|Batch</span>
        </span>-->
  <!--  <span
                (click)="imgServices.deleteAllFromServer(models)"
         >
           <span class="text-[8px]">DeleteAll</span>
        </span>-->

</div>
<p-overlayPanel #op>
  <ng-container *ngIf="itemSelect">
     <span *ngIf="itemSelect" class="flex align-items-center gap-2 w-full">
                    <p-avatar [image]="itemSelect.srcPreview" shape="circle"></p-avatar>
                    <span class="font-bold white-space-nowrap truncate w-[50px]"
                          (click)="itemSelect.check =!itemSelect.check;onFavorite.emit(itemSelect);onSort.emit(true);"
                    >
                      <i [class]="favoriteIcon"></i>
                    </span>

            <span class="font-bold white-space-nowrap truncate w-[50px]"
                  *ngIf="isBase64(itemSelect)"
                  (click)="baseToSrc(itemSelect,'large');"
            >
      <i [class]="imagePanoramaIcon"></i>
                </span>

        <span class="font-bold white-space-nowrap truncate w-[50px]" >
        <i [class]="tagIcon"
           (click)="setPreviewBtn(itemSelect)"
        ></i>
      </span>
        <span (click)="onSort.emit(itemSelect)"
              class="font-bold white-space-nowrap truncate w-[50px]">
        <i [class]="saveIcon" class="text-gray-300 hover:text-white"></i>
      </span>
       <p-overlayPanel #opEditImageCard>
              <span class=" m-3 p-3 font-bold white-space-nowrap truncate w-[50px]"
                    (click)="showCropRigion = true"
              >
                      <i [class]="cropIcon"></i>
                    </span>
            <span *ngIf="cd.enabledDelete" (click)="itemDelete(itemSelect, models);"
                  class="m-3 p-3 font-bold white-space-nowrap truncate w-[50px]">
        <i [class]="deleteIcon" class="text-gray-300 hover:text-white"></i>
      </span>
        <!-- <p-toggleButton [(ngModel)]="itemSelect.sw"
                         (ngModelChange)="onSort.emit(itemSelect)"
                         onLabel="Enabled"
                         offLabel="Disabled"
         ></p-toggleButton>-->
          <app-edit-text-row
          [nameRow]="'name'"
          [typeRow]="'text'"
          [model]="itemSelect"
          [models]="models"
          (onSave)="onSort.emit(itemSelect)"
          ></app-edit-text-row>
         <app-edit-text-row
          [nameRow]="'text'"
          [typeRow]="'text'"
          [model]="itemSelect"
          [models]="models"
          (onSave)="onSort.emit(itemSelect)"
          ></app-edit-text-row>
        <!-- <app-edit-text-row
          [nameRow]="'cat'"
          [typeRow]="'text'"
          [model]="itemSelect"
          [models]="models"
          (onSave)="onSort.emit(itemSelect)"
          ></app-edit-text-row>-->
       </p-overlayPanel>
     <span  #targetElImageCard
              class="font-bold white-space-nowrap truncate w-[50px]"
              (click)="
              opEditImageCard.toggle($event, targetElImageCard)"
     >
              <i [class]="editIcon"></i>
            </span>

       <!--   <span class="font-bold white-space-nowrap truncate w-[50px]"
                (click)="showSort = !showSort"
          >
         <i [class]="zoomIcon"></i>

                   </span>-->
    </span>
  </ng-container>
</p-overlayPanel>
<ng-container *ngIf="models && showList">
  <p-scrollPanel [style]="{height:500}">
    <ng-container *ngIf="view =='grid'">
      <div class="grid grid-cols-2 gap-1"
      >
        <ng-container *ngFor="let  listItem  of models;">
          <app-edit-text-row
            [nameRow]="'srcPreview'"
            [typeRow]="'srcPreview'"
            [showBottomMenu]="true"
            [model]="listItem"
            [models]="models"
            (onSetPreview)="onAdd.emit($event);"
            (onFavorite)="onFavorite.emit($event);"
            (onSave)="onSort.emit(true)"
          ></app-edit-text-row>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="view =='grid-small'">
      <div class="grid grid-cols-4 gap-1"
           [sortablejs]="models"
           [sortablejsOptions]="sortableOptions"
      >
        <ng-container *ngFor="let  listItem  of models;">
          <!--[ngClass]="{'handle': showSort}"-->
          <div class="relative inline-block transition-transform transform hover:scale-110">
            <img [src]="listItem.srcPreview"
                 alt="upload_image"
                 class="handle m-1 p-1 w-16 h-16 object-cover rounded-xl  border-1 border-zinc-100 cursor-pointer"

            >
            <!--    <button
                        class="text-sm absolute top-0 left-2 bg-green-500 text-white p-1 rounded-full transition-transform transform hover:scale-125"
              (click)="this.tempImage = uploadItem.srcPreview;showCropPanel()"
                >

                  <i [class]="cropIcon"></i>
                </button>-->
            <button *ngIf="listItem.check"
                    class="text-[15px] absolute top-0 left-0 bg-green-500 text-white  rounded-full transition-transform transform hover:scale-125"
            >
              <i [class]="favoriteIcon"></i>
            </button>
            <button  #targetEl
              class="text-[15px] absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full transition-transform transform hover:scale-125"
              (click)="itemSelect =listItem;
              onClick.emit(listItem);
              op.toggle($event, targetEl)"
            >
              <i [class]="editIcon"></i>
              <i *ngIf="isBase64(listItem)" [class]="imagePanoramaIcon"></i>

            </button>

          </div>


        </ng-container>
      </div>
    </ng-container>
  </p-scrollPanel>
</ng-container>
<!--<app-tool-list-layers

  *ngIf="showList"
  [view]="view"
  [models]="models"
  [cols]="cols"
  [showFavorite]="showFavorite"
  [showAdd]="showAdd"
  [showName]="showName"
  [showEdit]="showEdit"
  [showCheck]="showCheck"
  [showTools]="showTools"
  [showSort]="showSort"
  [showShow]="showShow"
  [showImage]="showImage"
  [showLock]="showLock"
  [sizeView]="sizeView"
  [showMore]="showMore"
  [showDelete]="showDelete"
  [showDownload]="showDownload"
  [showEyeDropper]="showEyeDropper"
  [showDeleteServerImage]="showDeleteServerImage"
  [showToJPG]="showToJPG"
  [heightList]="heightList"
  [heightItem]="heightItem"
  [widthItem]="widthItem"
  (onClick)="onClick.emit($event)"
  (onAdd)="onAdd.emit($event)"
  (onEdit)="onEdit.emit($event)"
  (onColor)="onColor.emit($event)"
  (onSort)="onSort.emit($event);this.onAdd.emit(this.models)"
  (onDelete)="onDelete.emit($event); this.onAdd.emit(this.models)"
  (onCheck)="onCheck.emit($event); this.onAdd.emit(this.models)"
  (onFavorite)="onFavorite.emit($event);"
  (onJPG)="onJPG.emit($event);"
>
</app-tool-list-layers>-->
<!--</ng-container>-->

