import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtClubServices} from '../static/static-art-club.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {Site} from '../../interfaces/site';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageDalle3Services {
  public SERVER_NAME: string = 'dalle-3';
  public DOMAIN: string = 'art-club.artas.org.ua/dalle-3';
  public route: string = 'dalle-3';

  public fromServer: boolean = false;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'Dalle-3',
    desc: 'Безлимитный доступ ко всем Курсам',
    additions: [''],
  };
  public free: object = {
    name: 'Dalle-3',
    desc: 'Безлимитный доступ ко всем Курсам',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Dalle3: A New Dimension in AI-Driven Image Creation',
    description: 'Discover Dalle3 - an innovative application for creating visual content using AI. Transform textual descriptions into unique visual artworks instantly and push the boundaries of your creativity now!',
    text: 'Dalle3 - A New Era of AI-Powered Image Generation\n' +
      '\n' +
      'Welcome to the world of Dalle3, where your imagination meets artificial intelligence to create something uniquely transformative. We stand on the cusp of a new creative era where AI isn\'t just a tool but a partner capable of instantly translating your ideas into realistic visual creations.\n' +
      '\n' +
      'Turning Your Words into Visual Masterpieces\n' +
      '\n' +
      'At Dalle3, we understand the power of words and how they can be transformed into images brimming with life and emotion. Our application employs advanced AI algorithms to turn any textual descriptions - from magical landscapes to complex narrative compositions - into high-quality visual masterpieces.\n' +
      '\n' +
      'Who is Dalle3 Designed For?\n' +
      '\n' +
      'Dalle3 is intended for anyone who seeks to explore new horizons in creativity and design. Artists, advertisers, designers, hobbyists - everyone will find Dalle3 a powerful tool for bringing their visual ideas to life.\n' +
      '\n' +
      'Why Dalle3 is Your Choice in the World of AI Image Generation\n' +
      '\n' +
      'Dalle3 offers a range of advantages that make it stand out among other image generation tools:\n' +
      '\n' +
      '- Endless Creativity: With an intuitive interface and powerful algorithms, the possibilities for creativity are limitless.\n' +
      '- Speed and Quality: You won\'t have to wait long to see the results of your ideas - Dalle3 processes requests quickly, providing quality images.\n' +
      '- Accessibility for All: Regardless of your level of experience, you will easily master Dalle3 and start creating incredible works.\n' +
      '- Innovative Solutions: Dalle3 is continuously improving by integrating the latest developments in artificial intelligence and machine learning.\n' +
      '\n' +
      'We invite you to a world where your imagination knows no bounds. Dalle3 - your new partner on the path to creative mastery and innovation.' +
      'Expanding on the groundbreaking capabilities of Dalle3, this platform stands as an embodiment of the future of digital creativity—a beacon for those who dare to dream in vivid colors and strive for visual expression. Dalle3 represents more than merely a technological advancement; it embodies the spirit of innovation that fuels the passion of creators across the globe.\n' +
      '\n' +
      'Customization & Adaptability: Dalle3\'s robust customization options ensure that every image generated is as unique as the individual behind the screen. The system\'s adaptability allows it to cater to a wide array of artistic preferences, making it equally useful for generating abstract concepts as it is for detailed and lifelike portrayals.\n' +
      '\n' +
      'A Catalyst for Creative Exploration: For the inquisitive minds who venture beyond the conventional, Dalle3 acts as a catalytic agent for exploration. It provides a sandbox environment where ideas can be nurtured and evolved without the constraints of traditional medium limitations. This freedom fosters an environment where experimentation is not only possible but actively encouraged.\n' +
      '\n' +
      'Enhancing Creative Workflows: Dalle3 seamlessly integrates into existing creative workflows, enhancing productivity and efficiency. Whether it\'s aiding in the initial stages of conceptualization or providing the final touches to a visual project, Dalle3 supplements the creative process with its profound AI capabilities.\n' +
      '\n' +
      'Community & Ecosystem Growth: As Dalle3\'s community of users expands, the potential for collective growth and learning is exponential. The platform encourages a thriving ecosystem of shared knowledge, tips, tricks, and techniques. Users can look forward to a future where updates and new features are shaped by real-world application and community insight.\n' +
      '\n' +
      'An Invitation to Visual Discovery: Dalle3 invites you into an era where artistic creation is boundless. With an interface that\'s both intuitive and enabling, creators from all walks of life can embark on a journey of visual discovery. Artists, educators, professionals, and hobbyists alike can all harness the power of Dalle3 to bring their visions to life, transforming ephemeral ideas into lasting artworks.\n' +
      '\n' +
      'Endeavor into the lush landscape of creativity that Dalle3 offers, and let it be the gateway to manifesting the images that dance in your thoughts. Join the revolution of AI-assisted creation where imagination is your only limit, and every creation is a testament to the potential within us all.',
    h1: 'Dalle3 of AI-Powered Image Generation',
    h2: 'Dalle3 bringing Your Ideas to Life with Unmatched Precision',
    h3: 'Who is Dalle3 Designed For?',
    h4: 'Why Dalle3 is Your Choice in the World of AI Image Generation',
    h5: 'Dalle3 - A New Era of AI-Powered Image Generation',
    textEndPage: 'Dalle3 - A New Era of AI-Powered Image Generation',
    keywords: [
      'Dalle3',
      'AI image generation',
      'text to image',
      'AI creativity application',
      'AI image creation',
      'AI idea visualization',
      'digital art innovations',
      'deep learning technology'
    ],
  };
  public site: any

  constructor(public server: ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  getSite() {
    /*  if (this.fromServer) {
        return this.getSiteFromServer();
      } else {
        return this.getSiteStatic();
      }*/

  }

  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }

  public saveSite(site: object) {
    this.site = site
    this.downloadSiteBtn(this.site);
    // let server: any = inject(ServerSiteServices)
    this.server.saveSite(this.DOMAIN, this.site);
  }

  getSiteStatic() {
    return this.site = inject(StaticArtClubServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer() {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: Site): void => {
      this.site = responseSite;
    });
    return this.site;
  }

}
