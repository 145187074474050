
<ng-container *ngIf="boxServices.isBoxArray(box,rowName)">
  <app-sidebar [show]="showCropRigion"
               (onSave)="showCropRigion = false"
               [showClose]="true"
               [height]="100"
               [ext]="'%'"
               [zIndex]="10000"
               [position]="'bottom'"
  >
    <app-crop-rigion *ngIf="showCropRigion"
                     [width]="350"
                     [height]=350
                     [cropZoneHeight]=150
                     [cropZoneWidth]=150
                     [cropZoneX]="100"
                     [cropZoneY]="100"
                     [showReset]="false"
                     [showPressetsCrop]="false"
                     [showAspectRatio]="true"
                     [showZoom]="false"
                     [showFlip]="false"
                     [showRotate]="false"
                     [showDownload]="false"
                     [showAdd]="true"
                     [src]="itemSelect.srcPreview"
                     (onAdd)="this.addCropImage($event,box[rowName])"
    ></app-crop-rigion>
  </app-sidebar>
  <div class="absolute top-[70px] right-[15px] z-20">
  <div  class="flex justify-center">
    <!--<img class="btn-controll"
         [src]="model.srcPreview"
         alt="name"
         width="30"
         height="30">-->

    <!--<span *ngIf="itemSelect" class="flex align-items-center gap-2 w-full">
                    <p-avatar [image]="itemSelect.srcPreview" shape="circle"></p-avatar>
                    <span class="font-bold white-space-nowrap truncate w-[50px]"
                          (click)="itemCheck(itemSelect);"
                    >
                      <i [class]="favoriteIcon"></i>
                    </span>
            <span class="font-bold white-space-nowrap truncate w-[50px]"
                  (click)="showCropRigion = true"
                    >
                      <i [class]="cropIcon"></i>
                    </span>
      <span *ngIf="cd.enabledDelete"
              class="font-bold white-space-nowrap truncate w-[50px]"
            (click)="itemDelete(itemSelect)">
      <i [class]="deleteIcon"></i>
    </span>
            <span class="font-bold white-space-nowrap truncate w-[50px]"
                  *ngIf="isBase64(itemSelect)"
                  (click)="baseToSrc(itemSelect,'large'); boxSave()"
            >
      <i [class]="imagePanoramaIcon"></i>

                </span>
    &lt;!&ndash;   <span class="font-bold white-space-nowrap truncate w-[50px]"
             (click)="showSort = !showSort"
       >
      <i [class]="zoomIcon"></i>

                </span>&ndash;&gt;
    </span>
-->
   <!-- <button
      (click)="onShowSortOtherCard.emit({rowName})"
      class="m-1 p-1 bg-zinc-700 text-white rounded-sm border-4 border-zinc-200 ">
      <i [class]="sortIcon"></i>
    </button>-->
    <!--<button class="btn-controll"
            (click)="boxSave()">
      <i [class]="saveIcon"
         pBadge
         [value]="boxServices.boxArrayCount(box[this.rowName])"
      ></i>
    </button>-->
    <button *ngIf="view !== 'grid'"
      (click)="view='grid'"
      class="btn-controll">
      <i [class]="listIcon"></i>
    </button>
    <button *ngIf="view !== 'grid-small'"
      (click)="view='grid-small'"
      class="btn-controll">
      <i [class]="gridIcon"></i>
    </button>
  </div>
  </div>
  <app-box-photos
    [rowName]="rowName"
    [models]="box[rowName]"
    [view]="view"
    [showFavorite]="false"
    [showUpload]="showUpload"
    [showEyeDropper]="true"
    [showDelete]="true"
    [showSort]="showSort"
    [showDeleteServerImage]="true"
    [showToJPG]="true"
    [showTools]="true"
    [showList]="true"
    [showAdd]="true"
    (onFavorite)=" onFavorite.emit($event.srcPreview)"
    (onClick)="itemSelect = $event"
    (onUploadedFinish)="boxSave()"
    (onAdd)=" onSet.emit($event.srcPreview)"
    (onJPG)="changeJPG($event,box)"
    (onSort)="boxSave()"
  ></app-box-photos>
</ng-container>
