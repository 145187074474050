import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {JsonPipe, NgFor, NgIf} from '@angular/common';
import {SliderModule} from "primeng/slider";
import {
  InputTextModule
} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
@Component({
  selector: 'app-image-mosaic',
  templateUrl: './image-mosaic.component.html',
  standalone:true,
  imports: [
    NgIf, NgFor,SliderModule,InputTextModule,FormsModule
  ]


})
export class ImageMosaicComponent {
  @ViewChild('canvas') canvas: ElementRef;
  context: CanvasRenderingContext2D;

  @Input() imageSrc: string = 'assets/avatars animals/10005358.png';
  @Output() onCrop = new EventEmitter();
  @Output() onReset = new EventEmitter();
  @Input() blockSize: any = 10;
  @Input() height: any = 300;
  @Input() width: any = 300;
  @Input() showSaveBtn: boolean = false;

  buttonValues: number[] = [5, 10, 15, 20]; // Массив с значениями для кнопок

  constructor() {}

  ngOnInit() {
    this.init(this.blockSize); // Инициализация с текущим значением blockSize
  }

  init(blockSize: number) {
    const img = new Image();
    img.onload = () => {
      this.renderMosaic(img, blockSize); // Передача blockSize в renderMosaic
    };
    img.src = this.imageSrc;
  }

  renderMosaic(image: HTMLImageElement, blockSize: number) {
    const canvas: HTMLCanvasElement = this.canvas.nativeElement;
    const context = canvas.getContext('2d', { alpha: true });

    const width = this.width;
    const height = this.height;

    canvas.width = width;
    canvas.height = height;

    context.drawImage(image, 0, 0, width, height);

    for (let y = 0; y < height; y += blockSize) {
      for (let x = 0; x < width; x += blockSize) {
        const imageData = context.getImageData(x, y, blockSize, blockSize);
        const averageColor = this.getAverageColor(imageData);
        context.fillStyle = `rgba(${averageColor.r}, ${averageColor.g}, ${averageColor.b}, ${averageColor.a / 255})`;
        context.fillRect(x, y, blockSize, blockSize);
      }
    }

    this.onCrop.emit(canvas.toDataURL('image/png'))
  }

  getAverageColor(imageData: ImageData) {
    let totalR = 0, totalG = 0, totalB = 0, totalA = 0;
    for (let i = 0; i < imageData.data.length; i += 4) {
      totalR += imageData.data[i];
      totalG += imageData.data[i + 1];
      totalB += imageData.data[i + 2];
      totalA += imageData.data[i + 3];
    }
    const pixels = imageData.data.length / 4;
    const averageR = Math.round(totalR / pixels);
    const averageG = Math.round(totalG / pixels);
    const averageB = Math.round(totalB / pixels);
    const averageA = Math.round(totalA / pixels);
    return { r: averageR, g: averageG, b: averageB, a: averageA };
  }

/*  saveImage() {
    const canvas = this.canvas.nativeElement;
    const image = canvas.toDataURL('image/png');
    const a = document.createElement('a');
    a.href = image;
    a.download = 'circle_image.png';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }*/

  // Метод для обработки кликов по кнопкам и применения выбранных значений
  applyBlockSize(value: number) {
    this.blockSize = value;
    this.init(this.blockSize); // Переинициализация с новым значением blockSize
  }

  // Метод для сброса изображения до исходного состояния
  resetImage() {
    this.applyBlockSize(1);
    // this.init(1);
    this.onReset.emit(this.imageSrc)

  }
}
