<header
  class="z-20 bg-zinc-800 fixed top-0 w-full h-[50px]  transition-all  shadow-md pt-0">
  <nav class=" h-[4px] max-w-screen-xl  mx-auto grid grid-flow-col">
    <div class="  text-orange-500 col-start-1 col-end-2 flex items-center">
      <app-logo></app-logo>
      <div class="absolute bottom-0 left-[50px]">
      <app-online-status
        (onOnlineStatus)="cd.onlineStatus = $event">
      </app-online-status>
      </div>
    </div>
    <div class="col-start-10 col-end-12 font-medium flex justify-end items-center">
      <app-coins *ngIf="!cd.logined" [showCoins]="true"></app-coins>
      <app-navbar-client></app-navbar-client>
<!--      <app-navbar-favorites></app-navbar-favorites>-->
    </div>
  </nav>
<!--  <app-breadcrumb [showBreadChumbs]="true"></app-breadcrumb>-->
</header>
