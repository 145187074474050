import {Component, Input} from '@angular/core';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'app-logo', templateUrl: './logo.component.html', standalone: true, imports: [RouterLink]
})
export class LogoComponent {
  @Input() srcLogo: string = 'assets/logo.jpg';
  @Input() textLogo: string = 'ART-CLUB.INFO';
}
