    <ng-container *ngFor="let  brushItem  of  brushesPhotoshopStandard">
      <div class="flex flex-col justify-center items-center h-[100vh]">
        <div
          class="relative flex flex-col items-center rounded-[10px] border-[1px] border-zinc-700 w-[400px] mx-auto p-4 bg-zinc-700 bg-clip-border shadow-md shadow-[#F3F3F3]">
          <div class="relative flex h-32 w-full justify-center rounded-xl bg-cover">
            <img [src]="brushItem.srcPreview"
                 class="absolute flex h-32 w-full justify-center rounded-xl bg-cover">
            <div
              class="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-zinc-700 bg-pink-400">
              <img class="h-full w-full rounded-full" [src]="brushItem.srcIcon"
                   [alt]="brushItem.name"/>
            </div>
          </div>
          <div class="mt-16 flex flex-col items-center">
            <h4 class="text-xl font-bold text-amber-400">
              {{brushItem.name}}
            </h4>
            <p class="text-base font-normal text-amber-500">
              <button  [disabled]="brushItem.download"
                       (click)="downloadBtn(brushItem)" class=" p-3 m-3 animate-pulse animate-infinite animate-ease-in-out animate-duration-[6000ms] flex aspect-square min-h-[32px] w-16 flex-col items-center justify-center gap-1 rounded-md p-1 bg-zinc-700 text-amber-600"
              >
                <div  *ngIf="!brushItem.download">
                <!--<img
                  [src]="cd.theme.iconSrc.download"
                  alt="photos previews"
                  class="h-[40px] w-[40px]"
                >-->
                <small class="text-center text-xs font-bold">
                  <span >Download</span>
                </small>
                </div>
                <div class="animate-jump-in animate-once animate-duration-[2000ms] animate-ease-in-out"  *ngIf="brushItem.download">

               <!-- <img [src]="cd.theme.iconSrc.ready"
                  alt="photos previews"
                  class="h-[40px] w-[40px]"
                >-->
                <small class="text-center text-xs font-bold">
                     <span>Ready!</span>
                </small>
                </div>
              </button>
            </p>
          </div>
          <!--<div class="mt-6 mb-3 flex gap-14 md:!gap-14">
            <div class="flex flex-col items-center justify-center">
              <p class="text-2xl font-bold text-amber-400">{{brushItem.name}}</p>
              <p class="text-sm font-normal text-amber-500">{{brushItem.name}}</p>
            </div>
            <div class="flex flex-col items-center justify-center">
              <p class="text-2xl font-bold text-amber-400">
                {{brushItem.name}}
              </p>
              <p class="text-sm font-normal text-amber-500">{{brushItem.name}}</p>
            </div>
            <div class="flex flex-col items-center justify-center">
              <p class="text-2xl font-bold text-amber-400">
                {{brushItem.name}}
              </p>
              <p class="text-sm font-normal text-amber-500">{{brushItem.name}}</p>
            </div>
          </div>-->
        </div>
      <!--  <p class="font-normal text-amber-400 mt-20 mx-auto w-max">{{brushItem.name}}
          <a
          href="https://horizon-ui.com?ref=tailwindcomponents.com"
          target="_blank" class="text-brand-500 font-bold">{{brushItem.name}}</a></p>-->
      </div>
    </ng-container>
