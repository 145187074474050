<div *ngIf="dataSeo"  style="height:25px; overflow: hidden"
     class="absolute top-0 seo-container text-center z-0">
  <h1>{{dataSeo.h1}}</h1>
  <h2>{{dataSeo.h2}}</h2>
  <h3>{{dataSeo.h3}}</h3>
  <p>{{dataSeo.text}}</p>
</div>
<ng-container *ngIf="card">

<app-contacts-left-artas
  [showForm]="false"
>
</app-contacts-left-artas>
<app-navbar-top-artas
  [routeHome]="'/zakazat-sharj-po-foto'"
></app-navbar-top-artas>


<app-navbar-top-artas
  [routeHome]="this.subRoute"
  [logoText]="this.logoText"
></app-navbar-top-artas>
<app-product-list-right-artas
  [cards]="cards"
  [selectCard]="card"
  (onSelectCard)="setSelect($event)"
></app-product-list-right-artas>
<app-contacts-left-artas
  [showForm]="true"
  [selectCard]="card">
</app-contacts-left-artas>
<app-product-more-artas
  [selectCard]="card"

></app-product-more-artas>
  <app-view-grid-artas *ngIf="photos"
                       [cards]="photos"
  ></app-view-grid-artas>

 <!-- todo <div class=" z-30 fixed top-[100px] right-[0px]">
    <button
      (click)="showTemlatesSharges = !showTemlatesSharges"

         class="relative text-amber-500 bg-color-custom text-center p-2  shadow-lg  rounded-md"
    >
      Вибрати з шаблонів
    </button>

  </div>
  <app-sidebar [show]="showTemlatesSharges"
               (onSave)="showTemlatesSharges = false"
               [showClose]="false"
               [height]="100"
               [ext]="'%'"
               [zIndex]="10000"
               [position]="'bottom'"
  >
    <app-page-templates-sharge *ngIf="showTemlatesSharges"
      [card]="card"
      [photosCard]="photos"
    ></app-page-templates-sharge>
  </app-sidebar>-->

</ng-container>
