import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtClubServices} from '../static/static-art-club.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {Site} from '../../interfaces/site';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageInfiniteBoardServices {
  public SERVER_NAME: string = 'art-club';
  public DOMAIN: string = 'art-club.artas.org.ua/art-club';
  public route: string = 'art-club';

  public fromServer: boolean = true;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'Курсы',
    desc: 'Безлимитный доступ ко всем Курсам',
    additions: [''],
  };
  public free: object = {
    name: 'Курсы',
    desc: 'Безлимитный доступ ко всем Курсам',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Image Palette Generator - Create Vibrant Color Schemes',
    description: ' Image Palettes Library generate vibrant color palettes from your photos with our Image Palette Generator. Perfect for designers, artists',
    text: 'Image Palettes Library welcome to the online tool for generating image color palettes!\n' +
      '\n' +
      'Our Image Palette Generator offers a simple and convenient way to create vibrant color schemes from your photos. With our tool, you can easily and quickly generate a color palette that perfectly suits your project.\n' +
      '\n' +
      'Creating an image color palette with our generator is easy. Simply upload your photo, and our tool will automatically analyze it, selecting the most suitable colors. You can also customize the palette generation process by choosing specific parameters or algorithms.\n' +
      '\n' +
      'Our image palette generator is ideal for designers, artists, web developers, and anyone who values the beauty and harmony of color. Use our tool to create unique color schemes for your projects, blogs, social media, and more.\n' +
      '\n' +
      'Start creating stunning color palettes right now with our Image Palette Generator!',
    h1: 'Image Palette Generator',
    h2: 'Image Palette Create Vibrant Color Schemes',
    h3: 'Color palette generator from image',
    h4: 'Picture Color Scheme Creator',
    h5: 'Generating vibrant color',
    textEndPage:'Image Palette,welcome to the online tool for generating vibrant color schemes from your photos! Our Image Palette Generator offers a simple and convenient way to create stunning color palettes. Whether you\'re a designer, artist, or web developer, our tool is perfect for adding vibrant colors to your projects.',
    keywords: [
      'image palette generator',
      'color palette',
      'color scheme',
      'vibrant colors',
      'design tool',
      'artist tool'
    ],
  };
  public site: any

  constructor(public server:ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  getSite() {
  /*  if (this.fromServer) {
      return this.getSiteFromServer();
    } else {
      return this.getSiteStatic();
    }*/

  }
  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }
 public saveSite(site:object){
   if (this.fromServer) {

     this.site = site
    this.downloadSiteBtn(this.site);
    // let server: any = inject(ServerSiteServices)
    this.server.saveSite(this.DOMAIN, this.site);
  }
  }
  getSiteStatic() {
    return this.site = inject(StaticArtClubServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer() {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: Site): void => {
      this.site = responseSite;
    });
    return this.site;
  }

}
