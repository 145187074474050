
@if (card) {

  <span class="flex align-items-center gap-2 w-full">
     <span *ngIf="cardIndex !== 0"
       (click)="prevCard()"
       class="font-bold white-space-nowrap truncate w-[100px]">
    Preview <i [class]="arrowLeftIcon"></i>
  </span>
                    <p-avatar [image]="card.srcPreview" shape="circle"></p-avatar>
                    <span class="font-bold white-space-nowrap truncate w-[50px]">
                      {{ card.name }}
                    </span>


   : {{getIndexLast(cards)+1}}
  <span *ngIf="cardIndex  !== getIndexLast(cards);"
    class="font-bold white-space-nowrap truncate w-[50px]"
        (click)="nextCard()"
  >
    Next <i [class]="arrowRightIcon"></i>
  </span>
 </span>
  <div [style.width.px]="windowWidth()" class="flex justify-center">

  <p-tabView [(activeIndex)]="tabIndexSelect"
             (activeIndexChange)="saveSite()">
    <p-tabPanel header="CA">
      <div *ngIf="tabIndexCard === tabIndexSelect" class="grid grid-cols-2 gap-1">
        <ng-container *ngFor="let  textBtn  of  textEditors">
          <app-edit-text-row
            [nameRow]="textBtn.name"
            [imageForColors]="card.srcPreview"
            [typeRow]="textBtn.type"
            [model]="card"
            [models]="cards"
            (onSave)="saveSite()"
          ></app-edit-text-row>
        </ng-container>
      </div>
    </p-tabPanel>
    <p-tabPanel header="CA">
      <app-edit-cards-sort
        *ngIf="tabIndexCards === tabIndexSelect"
        [cards]="cards"
        (onSelect)="card =$event; cardIndex =getIndex($event,cards);reInitBoxPhotos()"
        (onSave)="saveSite()"

      ></app-edit-cards-sort>
    </p-tabPanel>
    <p-tabPanel header="PH">
      <p-tabView *ngIf="tabIndexBoxPhotos === tabIndexSelect">
        <ng-container *ngFor="let  uploadEdit  of  uploadesEditors">
          <p-tabPanel [header]="uploadEdit.rowName">
            <app-box-upload
              [rowName]="uploadEdit.rowName"
              [model]="card"
              [view]="'grid-small'"
              (onSet)="card.srcPreview = $event"
              (onFavorite)="addToFavorite($event)"
              (onShowSortOtherCard)="showSortRightBlock = true;"
              (onSave)="saveSite()"
            ></app-box-upload>
          </p-tabPanel>
        </ng-container>
      </p-tabView>
    </p-tabPanel>
<!--    <p-tabPanel header="BoxSort">-->
<!--      <app-box-sort-->
<!--        *ngIf="tabIndexBoxSort=== tabIndexSelect"-->
<!--        [card]="card"-->
<!--        [cards]="cards"-->
<!--        (onSave)="saveSite()"-->
<!--      ></app-box-sort>-->
<!--    </p-tabPanel>-->
    <p-tabPanel header="SW">
      <div class="grid grid-cols-2 gap-2">
        <ng-container *ngFor="let  toggleBtn  of  toggleEditors">
          <div
            class="bg-white p-1 rounded-lg shadow-lgm m-1 w-[170px]">
            <p class="text-2xl font-bold mb-2 text-gray-800">{{ toggleBtn.name }}</p>
            <p-toggleButton [(ngModel)]="card[toggleBtn.name]"
                            (ngModelChange)="saveSite()"
                            onLabel="Enabled"
                            offLabel="Disabled"
            ></p-toggleButton>
          </div>
        </ng-container>
      </div>
    </p-tabPanel>
    <p-tabPanel  header="LI">
      <app-edit-array
                      [view]="'list'"
                      [model]="card"
                      [models]="card['list']"
                      [row]="'list'"
                      (onSave)="saveSite()"
      ></app-edit-array>
    </p-tabPanel>
    <p-tabPanel *ngIf="card.favorites" header="FS">
      <app-edit-array *ngIf="tabIndexFavorites === tabIndexSelect"
                      [view]="'list'"
                      [model]="card"
                      [models]="card['favorites']"
                      [row]="'favorites'"

                      (onSave)="saveSite()"
      ></app-edit-array>
    </p-tabPanel>
    <p-tabPanel *ngIf="card.array" header="AR">
      <app-edit-array
                      [view]="'list'"
                      [model]="card"
                      [models]="card['array']"
                      [row]="'array'"
                      [rows]="['name','desc','text','duration','nameBox','pathBox','srcPreview']"
                      (onSave)="saveSite()"
      ></app-edit-array>
    </p-tabPanel>
   <!-- <p-tabPanel header="Tools">
      <button class="btn-controll" (click)="addCloneModel(card,cards);saveSite()">
        Clone <i [class]="theme.icon.add"></i>
      </button>
      <button *ngIf="card.name" class="btn-controll">
        {{ card.name }}
      </button>
      <button *ngIf="cd.enabledDelete" (click)="delete(card, cards);"
              class="handle focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
        <i [class]="theme.icon.delete" class="text-gray-300 hover:text-white"></i>
      </button>
    </p-tabPanel>-->
    <!--<p-tabPanel header="Arrays">
      <p-tabView>
        <ng-container *ngFor="let  arrayEdit  of  arrayEditors">
          <ng-container>
            <p-tabPanel *ngIf="card[arrayEdit.rowName]"
                        header="{{arrayEdit?.rowName}}">
              <app-edit-array
                [row]="arrayEdit.rowName"
                [view]="arrayEdit.view"
                [rows]="arrayEdit.editRows"
                [models]="card[arrayEdit.rowName]"
                [model]="card"
                [modelsParent]="cards"
              ></app-edit-array>
            </p-tabPanel>
          </ng-container>
        </ng-container>
      </p-tabView>
    </p-tabPanel>-->
  </p-tabView>
  </div>

  <span class="flex align-items-center gap-2 w-full">
     <span *ngIf="cardIndex !== 0"
           (click)="prevCard()"
           class="font-bold white-space-nowrap truncate w-[100px]">
    Preview <i [class]="arrowLeftIcon"></i>
  </span>
                    <p-avatar [image]="card.srcPreview" shape="circle"></p-avatar>
                    <span class="font-bold white-space-nowrap truncate w-[50px]">
                      {{ card.name }}
                    </span>


   : {{getIndexLast(cards)+1}}
    <span *ngIf="cardIndex  !== getIndexLast(cards);"
          class="font-bold white-space-nowrap truncate w-[50px]"
          (click)="nextCard()"
    >
    Next <i [class]="arrowRightIcon"></i>
  </span>
 </span>

  }
