import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {NgFor, NgIf} from '@angular/common';
import {EditCardSelectComponent} from '../edit-card-select/edit-card-select.component';
import {Card} from '../../../interfaces/Card';
import {DataSites} from '../../../services/pages/data-sites';
import {PaginatorModule} from "primeng/paginator";
import {editIcon} from "../../../traits/icons-tools";

@Component({
  selector: 'app-edit-list-sites',
  templateUrl: './edit-list-sites.component.html',
standalone:true,
  imports: [
    NgFor,
    NgIf,
    PaginatorModule
  ]

})
export class EditListSitesComponent {

  @Input() dataSites: Array<any>;
  @Input() showFilter: boolean = false;
  @Output() onSelect:EventEmitter<any> = new EventEmitter();

  constructor() {
    this.getSites();
  }
  getSites(){
    this.dataSites  = inject(DataSites).list();
    // console.log(this.dataSites);
  }

  protected readonly editIcon = editIcon;
}
