import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtasServices} from '../static/static-artas.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageArtasServices {
  public SERVER_NAME: string = 'artas';
  public DOMAIN: string = 'art-club.artas.org.ua/artas';
  public route: string = 'artas';

  public fromServer: boolean = false;
  public premium: object = {
    name: 'artas',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'artas',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Шарж по фото онлайн/олівцем/пастелью',
    description: 'Замовити шарж по фото чи карикатуру. Дізнатись вартість шаржа та карикатури в Україні. Шарж недорого та швидко – від 300 грн. Замовити портретний/сюжетний/персонажний шарж за фото.',
    text: 'Замовити шарж сюжетний шарж- в якій герой поміщений у сцену, наповнену смисловими нюансами та деталями. Сюжет шаржа, створений за Вашим бажанням, дозволяє яскраво продемонструвати індивідуальність людини, відобразити її захоплення, відтінки натури та розвеселити її цим.\n' +
      '\n' +
      ' Крім підкресленої індивідуальності, шарж може бути наповнений і певною тематикою з нагоди якоїсь події: весілля, Дня народження, закінчення або початку навчання, переїзду в іншу країну і т.д.\n' +
      '\n' +
      ' В одному сюжеті шаржа можуть фігурувати кілька героїв.\n' +
      '\n' +
      ' Формати малюнка також можуть бути різними: А3 (30 * 40 см) А2 (40 * 60 см) А1 (60 * 85 см)\n' +
      '\n' +
      ' Для створення шаржа необхідні фотографії героя (героїв), опис сюжету та деталей, а також ваші побажання.' +
      '' +
      'У нас Ви можете замовити шарж (карикатуру) зі фотографії у Києві, Львові, Вінниці, Полтаві, Чернігові, Черкасах, Житомирі, Хмельницькому, Чернівцях, Рівному. Доставка в будь-яке місто України та світу',
    h1: 'Замовити шарж чи карикатуру з фото',
    h2: 'Замовлення шаржа чи карикатури',
    h3: 'Шарж по фото онлайн чи олівцем пастелью',
    h4: '',
    h5: '',
    textEndPage: '',
    keywords: [
      'Шаржист цена',
      'Шаржист на свадьбу',
      'Художник Шаржист Вашем на Празднике',
      'Заказать Шарж по фото',
      'Шаржи по фотографии\n дружеский шарж стоимость',
      'Шарж по фото',
    ],
  };

  public site: any

  constructor(public server: ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  getSite() {
      if (this.fromServer) {
        return this.getSiteFromServer();
      } else {
        return this.getSiteStatic();
      }

  }

  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }

  public saveSite(site: object) {
    this.site = site
    // this.downloadSiteBtn(this.site);
    // let server: any = inject(ServerSiteServices)
    this.server.saveSite(this.DOMAIN, this.site);
  }

  getSiteStatic() {
    return this.site = inject(StaticArtasServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)
  }

  public getSiteFromServer() {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite:any): void => {
      this.site = responseSite;
    });
    return this.site;
  }
  getCards(){
   return  this.site.organizer.messenges;
  }
  findCard(slug:any){
    console.log(slug)
      let cards = this.getCards();
     let findCard =   cards.filter((itemProduct:any)=>{
        if(itemProduct.id === slug){
          return itemProduct;
        }
      });
    return findCard[0];
  }
}
