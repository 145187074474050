import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
// import ColorThief from "../../../assets/params/js/color-thief.umd.js";
import {
  getPixel,
  getPosition
} from 'src/app/services/common/draw-methods';
import {
  addToStart,
  chunk
} from "../../traits/array-tools";
import {dateNow} from "../../traits/datetime-tools";
import {srcToBase64} from "../../traits/image-tools";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";

@Component({
  selector: 'app-circle-with-image',
  templateUrl: './circle-with-image.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    JsonPipe,
    BtnDownloadComponent
  ]
})
export class CircleWithImageComponent {
  @ViewChild('canvas', {static: true}) canvas: ElementRef<HTMLCanvasElement>;
  @Input() colors: string[] //= ['red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'violet', 'purple', 'cyan', 'magenta', 'pink', 'brown'];
  @Input() scaleCanvas: string = '75%';
  @Input() one: string[] = [];
  @Input() two: string[] = [];
  @Input() three: string[] = [];
  @Input() forth: string[] = [];
  @Input() colorsEyeDropper: string[] = [
    'red',
    'orange',
    'yellow'
  ];
  @Input() customColors: string[] = [
    'red',
    'orange',
    'yellow'
  ];
  @Input() imageSrc: string = 'assets/avatars animals/10005358.png';
  @Input() srcResult: string;
  @Input() imageSize: number = 225;
  @Input() cellSize: number = 50;
  @Input() spaceWithColors: number = 10;
  @Input() groupWithColors: number = 20;
  @Input() smallCircleRadius: number = 10;
  @Input() numSmallCircles: number = 50;
  @Input() numCustomCircles: number = 50;
  @Input() largeCircleRadius: number = 200;
  @Input() roundsSmallColors: number = 100;
  @Input() customCircleRadius: number = 180;
  @Input() textLogo: string = 'art-club.info';
  @Input() textSize: number = 15;
  @Input() showBigRounds: boolean = true;
  @Input() showAllColors: boolean = false;
  @Input() showDownload: boolean = false;

  @Output() onChange = new EventEmitter<string>();
  @Output() onGetPallete = new EventEmitter<string>();
  @Output() colorClicked = new EventEmitter<string>();
  @Output() imageSaved = new EventEmitter<void>();
  public showEyeDropper: boolean = true

  getImageColors(src: string, callback: any = null) {
    // @ts-ignore
    let colorThief = new ColorThief();
    let res = {
      all: [],
    };
    // const img = new Image();
    let img = new Image();// document.createElement('img');
    img.crossOrigin = 'Anonymous';
    img.src = src;
    img.onload = () => {
      const toColor = (color: any) => {
        // @ts-ignore
        let wcolor = w3color(color);
        return [
          // {name:'toName',value: wcolor.toName()},
          {
            name: 'toHexString',
            value: wcolor.toHexString()
          },
          // {name: 'toCmykString', value: wcolor.toCmykString()},
          // {name: 'toNcolString', value: wcolor.toNcolString()},
          // {name: 'toRgbString', value: wcolor.toRgbString()},
          // {name: 'toHslString', value: wcolor.toHslString()},
          // {name: 'toHwbString', value: wcolor.toHwbString()},
        ];
      };
      res.all = colorThief.getPalette(img).map((rgb: any) => {
        const rgbToHex = (r: any, g: any, b: any) => '#' + [
          r,
          g,
          b
        ].map(x => {
          const hex = x.toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        }).join('');
        let colorHex = rgbToHex(rgb[0], rgb[1], rgb[2]);
        return colorHex;// {hex: colorHex};

      });
      callback && callback(res.all);
    };
  };
  ngAfterViewInit() {
    this.init();
  }

  imageToPalette(imageSrc:string,callback:any){

  }
  init() {
    srcToBase64(this.imageSrc,(imageBase64)=>{
      this.imageSrc = imageBase64;
      this.getImageColors(this.imageSrc, (colorsBig: any) => {
        /*if(this.showAllColors){this.roundsSmallColors = 200}else {
          this.roundsSmallColors = 100
        }*/
        this.colors = this.colorsEyeDropper.concat(colorsBig).slice(0,10);
        // @ts-ignore
        colorjs.prominent(this.imageSrc, {
          amount: this.roundsSmallColors,
          format: 'hex',
          sample: this.spaceWithColors,
          group:this.groupWithColors,
        }).then((colorsSmall:any) => {
          if(this.roundsSmallColors>100){
            let [one, two,three,forth] =   chunk(colorsSmall,50);
            this.one = one;
            this.two = two;
            this.three = three;
            this.forth = forth;
          }else{
            let middleIndex = Math.floor(colorsSmall.length / 2);
            let [one, two] = [colorsSmall.slice(0, middleIndex), colorsSmall.slice(middleIndex)];
            this.one = one;
            this.two = two;
          }
          this.drawCircle();
        })

      })
    })
    // this.eventEyeDropper(this.canvas.nativeElement, this.canvas.nativeElement.getContext('2d'))

  }
public  drawCircle() {
  let canvas = Object.assign(document.createElement('canvas'), {
    width: 500,
    height: 500
  });
    // let canvas: any = this.canvas.nativeElement;
    const ctx: any = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const numLargeCircles = this.colors.length;
    const largeAngleIncrement = (2 * Math.PI) / numLargeCircles;
    const smallAngleIncrement = (2 * Math.PI) / this.numSmallCircles;
    this.drawLogo(ctx, this.textSize, canvas.height - this.textSize);
    if (this.showBigRounds) {
      this.drawLargeCircles(ctx, centerX, centerY, largeAngleIncrement);
    }
    this.drawImage(ctx, centerX, centerY,()=>{

      if(this.roundsSmallColors>100){
        this.drawCircleSmall(ctx, centerX, centerY, this.one,180,this.one?.length,10);
     this.drawCircleSmall(ctx, centerX, centerY, this.two,200,this.two?.length,10);
     this.drawCircleSmall(ctx, centerX, centerY, this.three,220,this.three?.length,10);
     this.drawCircleSmall(ctx, centerX, centerY, this.forth,240,this.forth?.length,10);

   }else{
     this.drawCircleSmall(ctx, centerX, centerY, this.one,180,this.one?.length,10);
     this.drawCircleSmall(ctx, centerX, centerY, this.two,200,this.two?.length,10);
   }
      const image: string = canvas.toDataURL('image/png');
      this.srcResult = image
      this.onGetPallete.emit(image);
    });

  }
 public drawCircleSmall(ctx: CanvasRenderingContext2D,
                 centerX: number,
                 centerY: number,
                 array:string[],
                 radiusSaturate: number = 200,
                 numSmallCircles:number =15,
                 radius:number =10

  ) {
    if(array?.length>0){
    for (let i = 0; i < numSmallCircles; i++) {
      const angle = i *   (2 * Math.PI) / numSmallCircles;
      const x = centerX + radiusSaturate * Math.cos(angle);
      const y = centerY + radiusSaturate * Math.sin(angle);
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI);
      ctx.fillStyle = array[i % numSmallCircles];
      ctx.fill();
    }
    }
  }

  public  drawLogo(ctx: CanvasRenderingContext2D, centerX: number, centerY: number) {
    ctx.font = `${this.textSize}px Arial`;
    ctx.fillStyle = 'white'//this.colors[0];
    // ctx.globalCompositeOperation = 'source-over'
    ctx.fillText(this.textLogo, centerX, centerY)


  }
  public drawLargeCircles(ctx: CanvasRenderingContext2D, centerX: number, centerY: number, angleIncrement: number) {
    for (let i = 0; i < this.colors.length; i++) {
      const angle = i * angleIncrement;
      const x = centerX + this.largeCircleRadius * Math.cos(angle);
      const y = centerY + this.largeCircleRadius * Math.sin(angle);
      ctx.beginPath();
      ctx.arc(x, y, this.cellSize, 0, 2 * Math.PI);
      ctx.fillStyle = this.colors[i];
      ctx.fill();
    }
  }
  public drawImage(ctx: CanvasRenderingContext2D, centerX: number, centerY: number,callback:any = null) {
    const img = new Image();
    img.onload = () => {
      const imageX = centerX - this.imageSize / 2;
      const imageY = centerY - this.imageSize / 2;
      ctx.drawImage(img, imageX, imageY, this.imageSize, this.imageSize);
      callback &&callback()
    };
    img.src = this.imageSrc;
  }
  public getImage() {
    const canvas = this.canvas.nativeElement;
    const image: string = canvas.toDataURL('image/png');
    return image;
  }

 public downloadImage() {
    const a = document.createElement('a');
    a.href = this.getImage();
    a.download = `art-club.info (pallete-${dateNow()}).png`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.imageSaved.emit();
  }

 /* protected clickStageEyeDropper(ctx: any, clickPosition: any) {
    if (this.showEyeDropper) {
      let colorHex: any = getPixel(ctx, clickPosition);
      console.log(colorHex)
      // this.colors = this.colorsPicks.concat(this.colors).slice(0, 10)
      // addToStart(colorHex,this.colors);
      // this.init();
      // this.showEyeDropper =false
    }
  }*/

  /*protected eventEyeDropper(canvas: any, ctx: any) {
    // if (this.isEnabledEyeDropper()){
    const onMouseClick = (event: any) => {
      // event.preventDefault();
      // let clickPosition = getPosition(event);
      // this.clickStageEyeDropper(ctx, clickPosition);

    };
    canvas.addEventListener('click', onMouseClick);
    canvas.onpointerdown = onMouseClick;
  }*/

  /*

    public clickEyeDropper(ctx: CanvasRenderingContext2D, positionX: number, positionY: number) {
      if (this.showEyeDropper) {
        let getPointerPosition: any = {
          y: positionY,
          x: positionX
        };
        let contextBrush: any = ctx;

        function rgb2hex(cursorData: any) {
          return ('0' + cursorData[0].toString(16)).slice(-2) + ('0' + cursorData[1].toString(16)).slice(-2) + ('0' + cursorData[2].toString(16)).slice(-2);
        }

        let cursorData: any = contextBrush.getImageData(getPointerPosition.x, getPointerPosition.y, 1, 1).data;

        let hex: any = '#' + rgb2hex(cursorData);
        if (hex !== '#000000') {
          // this.showEyeDropper = false;
          addToStart(hex, this.colorsPicks);
          this.init();
        }
      }
    }
  */
}
