import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtClubServices} from '../static/static-art-club.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {Site} from '../../interfaces/site';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageArtistToolsServices {
  public SERVER_NAME: string = 'art-sites';
  public DOMAIN: string = 'art-club.artas.org.ua/art-sites';
  public route: string = 'artist-tools';

  public fromServer: boolean = true;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'Artist-tools',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'Artist-tools',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Artist-Tools: Tools for the Creativity of the Digital Artist',
    description: 'Artist-Tools offers a complete set of tools for digital artists, including light management, creation of 3D images, and transforming photographs into unique works of art.',
    text: 'Artist-Tools is a toolkit specifically designed to breathe life into every detail of your digital creations. It offers you a unique opportunity to transform ordinary images into voluminous 3D scenes, making your works captivating and multifaceted. Thanks to advanced algorithms, "Artist-Tools" allows you to create realistic images from the most abstract silhouettes, giving every artist the ability to detail their ideas down to the finest nuances.\n' +
      '\n' +
      'The feature to generate based on uploaded photos opens limitless spaces for exploring new styles and techniques. Each photo you upload becomes the basis for creating unique works of art. "Artist-Tools" is not just a toolkit; it\'s your personal creative guide through the world of digital art, allowing you to experiment without borders.\n' +
      '\n' +
      'Your imagination is just the beginning; "Artist-Tools" offers technologies that turn your boldest ideas into visual masterpieces. With "Artist-Tools", each of your works becomes a bridge between dream and reality, opening up a world where creative ideas know no limits.' +
      'Discover the ultimate toolkit for the digital artist that will transform your creative process into a truly magical journey. With this offer, you can masterfully control the direction of light, adding depth and realism to your work. Transform ordinary pictures into captivating 3D images, giving your creations new life. Our unique tool allows you to transform silhouettes into fully realized real images, opening up incredible opportunities for creativity.\n' +
      '\n' +
      'Breathe life into your ideas using the image generation feature based on uploaded photos. This is not just a transformation; it\'s about creating something completely new and unique that is fully subject to your creative intent. In addition to these features, our package offers a host of other tools, specially designed to meet the most sophisticated demands of digital artists.\n' +
      '\n' +
      'Whether you are creating visual effects for cinema, developing concept art for video games, or simply exploring the possibilities of digital painting, our toolkit will provide you with everything you need to bring your ideas to life in the most vibrant and original forms. Discover new horizons in digital art and let your creativity reach unprecedented heights with our advanced technologies. Creating the world of your dreams is no longer a fantasy – it\'s a reality, available to every digital artist right now.',
    textEndPage: 'Artist-Tools offers a complete set of tools for digital artists, including light management, creation of 3D images, and transforming photographs into unique works of art.',
    h1: 'Artist-Tools: Revolution in Digital Art',
    h2: 'Transform Your Ideas into Visual Masterpieces',
    h3: 'The Mastery of 3D Immersiveness with Artist-Tools\n',
    h4: 'Advanced Image Generation: From Silhouette to Realism\n',
    h5: 'Artist-Tools: Tools for the Creativity of the Digital Artist\n',
    keywords: [
      'Artist-Tools',
      'digital art',
      '3D images',
      'image generation',
      'silhouette transformation',
      'light direction',
      'creation from photographs',
      'creative tools',
      'artistic imagination'
    ],
  };
  public site: any

  constructor(public server: ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  getSite() {
    if (this.fromServer) {
      return this.getSiteFromServer();
    } else {
      return this.getSiteStatic();
    }

  }

  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }

  public saveSite(site: object) {
    this.site = site
    // this.downloadSiteBtn(this.site);
    // let server: any = inject(ServerSiteServices)
    this.server.saveSite(this.DOMAIN, this.site);
  }

  getSiteStatic() {
    return this.site = inject(StaticArtClubServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer() {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: Site): void => {
      this.site = responseSite;
      console.log(this.site, 'page-art-sites')
    });
    return this.site;
  }

}
