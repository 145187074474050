<div style="height:25px; overflow: hidden"
     class="absolute top-0 seo-container text-center z-0">
  <h1>{{ dataPage.seoTexts.h1 }}</h1>
  <h2>{{ dataPage.seoTexts.h2 }}</h2>
  <h3>{{ dataPage.seoTexts.h3 }}</h3>
  <p>{{ dataPage.seoTexts.text }}</p>
  <p>{{ dataPage.seoTexts.textEndPage }}</p>
</div>

<app-navbar-top></app-navbar-top>
<app-header
  [bgImage]="dataPage.imageHeader"
  [headerText]="dataPage.seoTexts.h1"
  [subHeaderText]="dataPage.seoTexts.h2"
></app-header>

  <app-sidebar [show]="showCourseViewer"
               (onSave)="closeCourse()
                 "
               [showClose]="true"
               [height]="100"
  >
    <app-course-video-viewer
      *ngIf="this.selectCourse"
      [selectCourse]="selectCourse"
    >
    </app-course-video-viewer>
  </app-sidebar>
<app-common-home
 *ngIf="!showCourseViewer"
  [site]="site"
  [dataPage]="dataPage"
  (onClickRoute)="setSelectRoute($event)"
  (onSelectCourse)="setSelectCourse($event)"
></app-common-home>

<!--<app-list-courses
   *ngIf="(cd.showEditAll === false && gp.messengesClone)"
   [site]="this.site"
   [messengers]="gp.messengesClone"
   [gp]="gp"
 ></app-list-courses>-->


<!--<app-list-courses
  *ngIf="(cd.showEditAll === false && gp.messengesClone)"
  [site]="this.site"
  [messengers]="gp.messengesClone"
  [gp]="gp"
></app-list-courses>-->

<!--<app-list-courses
  *ngIf="(cd.showEditAll === true && site.organizer.messenges)"
  [site]="this.site"
  [messengers]="site.organizer.messenges"
  [gp]="gp"
></app-list-courses>-->
<!--  <app-card-edit [site]="site"></app-card-edit>-->
