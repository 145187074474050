import {Injectable} from "@angular/core";
import {
  addModelDB,
  countTableDB,
  deleteModelDB,
  getTableDB,
  updateListDB,
  updateModelDB
} from "../traits/indexedDB";
import {
  cloneModel,
  imageBoardModel,
  stageModel
} from "../traits/model-tools";
import {reInit} from "../traits/datetime-tools";
import {compress} from "../traits/image-tools";
import {
  addToEnd,
  addToStart,
  deleteFromArray
} from "../traits/array-tools";
import { exportStage } from "../traits/importExport-tools";
import {textDecrypt} from "../traits/crypt-tools";

@Injectable({
  providedIn: 'root',
})
export class StageDbServices {
  public filterByColor: string  ='';
  public selectStage: any = null;
  public showStagesList: boolean = false;
  public saveStatus: boolean = false;
  public tableKey: string = 'stages';
  public storageKey: string = 'infinityBoard'
  public stagesList: any[] = [];

  constructor() {
  }

  public getStages(storageKey:string = 'infinityBoard',callback:any = null) {
    this.storageKey =  storageKey;
    this.showStagesList = true;
    getTableDB(this.storageKey, this.tableKey, (stages: any[]) => {
      this.selectStage = stages[0]
      this.stagesList = stages
      if (!this.selectStage) {
        this.selectStage = stageModel();
        addModelDB(this.storageKey, this.tableKey, this.selectStage)
        this.saveDB();
      }
      callback && callback(this.selectStage)
    })


  }
  public createStages() {
    countTableDB(this.storageKey, this.tableKey,(count:number)=>{
      this.initStage();
      if(count == 0) {
        this.addStage()
        this.initStage();
      }
    })
  }
  public initStage(){
    getTableDB(this.storageKey, this.tableKey, (stages: any[]) => {
      if(stages){
        this.selectStage = stages[0]
        this.stagesList = stages
      }});
  }
  public destroy() {
    this.filterByColor =''
    this.selectStage = null;
    this. saveStatus = false;
    this.showStagesList = false;
    this.stagesList = [];
  }

 public updateStatusSave() {
    this.saveStatus = true
    reInit(() => {
      this.saveStatus = false
    }, 1500)
  }
  public updateStages() {
    updateListDB(this.storageKey, this.tableKey, this.stagesList)
    this.updateStatusSave()
  }
  saveDB() {
    this.updateStage();
    this.updateStages();
  }
  public updateStage() {
    updateModelDB(this.storageKey, this.tableKey, this.selectStage)
    this.updateStatusSave()
  }
 public stagePositionReset(){
    this.selectStage.x = -1000
    this.selectStage.y = -1000
  }
  public addImageToBoard(base64: string,params:object = null) {
    this.filterByColor =''
    const imageX = (2000 / 2) - (150 / 2);
    const imageY = (2000 / 2) - (150 / 2);
    compress(base64, {
      quality: 1,
      maxHeight: 1500
    }, (baseCompress64: any) => {
      let imageModelBoard: any = imageBoardModel(baseCompress64);
      console.log(imageModelBoard,'addImageToBoard')
      params?imageModelBoard.params = params: {};
      imageModelBoard.x = imageX;
      imageModelBoard.y = imageY;
      addToStart(imageModelBoard, this.selectStage.images)
      this.saveDB();
    });
    // this.stagePositionReset();
  }
  public addModelToBoard(base64: string) {
    this.filterByColor =''
    const imageX = (2000 / 2) - (150 / 2);
    const imageY = (2000 / 2) - (150 / 2);
    compress(base64, {
      quality: 1,
      maxHeight: 1500
    }, (baseCompress64: any) => {
      let imageModelBoard: any = imageBoardModel(baseCompress64);
      console.log(imageModelBoard,'addImageToBoard')

      imageModelBoard.x = imageX;
      imageModelBoard.y = imageY;
      addToEnd(imageModelBoard, this.selectStage.images)
      this.saveDB();
    });
    // this.stagePositionReset();
  }
  public addTextToBoard(elementMenu:any) {
// Вычисляем координаты для центрирования изображения
    const imageX = (2000 / 2) - (150 / 2);
    const imageY = (2000 / 2) - (150 / 2);

    let imageModelBoard: any = elementMenu.model
    imageModelBoard.x = imageX;
    imageModelBoard.y = imageY;
    addToEnd(imageModelBoard, this.selectStage.images)
    this.saveDB();
    this.stagePositionReset();

  }
 public addStage(selectStage: any = stageModel()) {
    addToStart(selectStage, this.stagesList)
    this.selectStage = this.stagesList[0]
    addModelDB(this.storageKey, this.tableKey, selectStage)
  }
  exportStage(selectStage: any) {
    exportStage(selectStage)
  }
  public deleteStage(selectStage: any) {
    deleteFromArray(selectStage, this.stagesList);
    deleteModelDB(this.storageKey, this.tableKey, selectStage)
  }

  public importStage(event: any, callback: any = null) {
    const files = event.target.files;
    if (files.length === 0) return;
    const file = files[0];
    let reader = new FileReader();
    reader.onload = (e) => {
      let decryptedText: string = textDecrypt(e.target.result);
      // callback & callback(decryptedText)
      let stageModel: any = JSON.parse(decryptedText);
      stageModel = cloneModel(stageModel, true)
      this.addStage(stageModel)
    };
    reader.readAsText(file);
  }
}
