  <p-scrollPanel [style]="{height:600}">
    <div class="mt-[75px] container mx-auto">
    <div class="grid grid-cols-2 gap-4 mt-3">
        @for(  photoItem  of  photos; track $index){
<!--          @if (photoItem.sw){-->
          <div class="relative"
          >
          <!-- <app-image-viewer-tools
              [selectImage]="photoItem"
              (onClickShowCollection)="showClientCollection = true"
              (onClickShowEduter)="showEtuder= true; showSketcher= false;showClientCollection=false"
              (onClickShowSketcher)="showSketcher= true;showEtuder= false;showClientCollection=false"
              (onClickShowRotator) = "showRotatorBtn(selectImage)"
            ></app-image-viewer-tools>-->
              <img (click)="coins.coinService.minusCoin();(!cd.lock || cd.logined)?click(photoItem):null"
                class="h-auto max-w-full rounded-lg"
                   [src]="photoItem.srcPreview"
                   alt="name">
            <span [hidden]="coins.coinService.showCoinsFlag()" class="text-sm absolute z-30 -top-4 left-3">
            <app-coins #coins [showCoinMinus]="true"></app-coins>
          </span>
            <span *ngIf="photoItem.name && photoItem.text" >
              {{photoItem.name}}
          </span>
            <app-text-slice *ngIf="photoItem.text"
                            [text]="photoItem.text"
            ></app-text-slice>
          </div>
        }
    </div>
    </div>
  </p-scrollPanel>
