// import moment from 'moment';

export function  interval(callback:any,time:any = 1000,) {
  return  setInterval(
    () => {
      // clearInterval(intervalCard);
      callback && callback(true);
    },time
  );
}

export function dateNow(){
 return Date.now();
}
export  function  reInit(callback:any,time:any = 1, disabled:any = true) {
  let intervalInit = interval(()=>{
    callback && callback(true);
    disabled ?clearInterval(intervalInit):null;

  },time)

}
export function timeOut(callback:any,time:any = 1){
   setTimeout(()=>{
     callback && callback(true);
   }, time);
}
export function onlineStatusInternetConnection() {
  return navigator.onLine;
}
export  function subscribeRestDaysMonths(months: any = 1) {
/*  const d = moment();
  const currentDay = d.get('date');
  const daysInMonth = d.daysInMonth();
  const remainingDays = daysInMonth - currentDay;
  return remainingDays;*/
}
export function subscribeLastDayInMonths(months: any = 1) {
/*  let thisMoment = moment();
  let endOfMonth = moment(thisMoment).endOf('month').format('DD-MM-YYYY');
  // let startOfMonth = moment(thisMoment).startOf('month');
  return endOfMonth;*/
}
