import {Component} from '@angular/core';
import {TabViewModule} from "primeng/tabview";
import {SidebarComponent} from "../../../components/common/sidebar/sidebar.component";
import {CommonHomeComponent} from "../../common-home/common-home.component";
import {HeaderComponent} from "../../../components/pages/header/header.component";
import {NavbarTopComponent} from "../../../components/pages/navbar-top/navbar-top.component";
import {CroppieComponent} from "../../../components/croppie/croppie.component";
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {PageArtistToolsServices} from "../../../services/pages/page-artist-tools.services";
@Component({
  selector: 'app-page-artist-tools',
  standalone:true,
  imports: [
    TabViewModule,
    SidebarComponent,
    CommonHomeComponent,
    HeaderComponent,
    NavbarTopComponent,
    CroppieComponent,
    NgForOf,
    NgIf,
    FormsModule
  ],
  templateUrl: './page-artist-tools.component.html',
})
export class PageArtistToolsComponent {
  constructor(public dataPage: PageArtistToolsServices) {}
}
