// @ts-ignore
import _ from 'lodash';

export  function sortableOptions(groupName: any = '1') {
  return {
    animation: 150,
    group: groupName,
    fallbackOnBody: true,
    handle: '.handle',
    swapThreshold: 1,
    swapClass: 'sortSwapClass'
  };
}

export  function findById(m: any = {}, ms: any = [], relationNameId: any = 'blockId', arrayNameId: any = 'blockId') {
  return ms.find((block: any) => {
    return block[arrayNameId] === m[relationNameId];
  });
}

export  function deletePhoto(m: any) {

  /*api.request('folders/delete-file', {src: m.id + '.png', srcPreview: m.id + '-preview.jpg'})
    .subscribe((src: any) => {
    });*/
}

export  function deleteCollection(collection: any) {
  /*  api.request('collections/delete', collection)
      .subscribe((r: any) => {
      });*/
}

export  function deleteFile(model: any, row: any = 'pdf', callback: any = null) {
  /* api.request('collections/delete-file-brushes', {id: model.id, ext: '.pdf', row: row})
     .subscribe((r: any) => {
       callback && callback(r);
     });*/
}

export  function remove(m: any, ms: any, showModal: any = true, callback: any = null,) {
  if (showModal) {
    callback && callback(m);
    ms.splice(getIndexArray(m, ms), 1);
    /*alert.confirmationService.confirm({
      message: `Remove? ${m.name}`,
      accept: () => {
        callback && callback(m);
        ms.splice(getIndexArray(m, ms), 1);
      }
    });*/
  } else {
    ms.splice(getIndexArray(m, ms), 1);
  }
}

/*export function delete(m: any, ms: any = [], showModal: any = true, callback: any = null) { // @ts-ignore
  return remove(m, ms, showModal, callback);
  // return ms.includes(m) ? remove(m, ms) : false;
}*/

export  function toArchive(m: any, callback: any = null) { // @ts-ignore
  callback && callback(false);
  m.sw = false;
 /* alert.confirmationService.confirm({
    message: `To archive? ${m.name}`,
    accept: () => {
      callback && callback(false);
      m.sw = false;
    },
  });*/
  callback && callback(true);
  m.sw = true;
}
export  function clear(m: any, nameArray:any='list', callback: any = null) { // @ts-ignore
  m[nameArray] = [];
  /*alert.confirmationService.confirm({
    message: `Delete All? ${m.name}`,
    accept: () => {
      callback && callback(false);
      m[nameArray] = [];
    },
  });*/
  callback && callback(true);
}

export  function lockPassword(m: any, showModal: any = false, callback: any = null) { // @ts-ignore
  if (showModal) {
    m.lock = true;

  /*  alert.confirmationService.confirm({
      message: `Lock with password?:${m.text}`,
      accept: () => {
        // callback && callback(m)
        m.lock = true;
      }
    });*/
  } else {
    // m.lock = false;
    // ms.splice(getIndexArray(m, ms), 1);
  }
}

export  function clearText(m: any, row: any = 'text', showModal: any = false, callback: any = null) { // @ts-ignore
  if (showModal) {
    m[row] = '';
    /*.confirmationService.confirm({
      message: `Clear?:${m[row]}`,
      accept: () => {
        alert    callback && callback(m);
        m[row] = '';
      }
    });*/
  } else {
    // ms.splice(getIndexArray(m, ms), 1);
  }
}

export  function copyText(m: any, row: any = 'text', showModal: any = false, callback: any = null) { // @ts-ignore
  if (showModal) {
  /*  alert.custom(`Copy! : ${m[row]}`);*/
  } else {
    // ms.splice(getIndexArray(m, ms), 1);
  }
}

export  function add(m: any, ms = [], unic: any = true) {
  // alert.custom('Добавлен в конец!');
  // console.log(m, 'add Model');
  // console.log(ms, 'in Models');

  if (unic) {
    // @ts-ignore
    return !ms.includes(m) ? ms.push(m) : false;

  } else {
    // @ts-ignore
    return ms.push(m);
  }
}


export  function count(ms = []) {
  return ms.length;
}

export  function first(m: any, ms: any = []) {
  return ms.indexOf(m) === 0 ;

}
export  function firstLeft(m: any, ms: any = []) {
  return ms.length !== 1;

}

export  function last(m: any, ms: any = []) {
  return ms.indexOf(m) !== ms.length+1;

}
export  function lastLeft(m: any, ms: any = []) {
  return ms.indexOf(m)+1 !== ms.length;

}

export  function moveToOtherArray(selectModel: any, selectArrayMoveFrom = [], selectArrayMoveTo: any[]) {
  console.log(selectModel, 'selectModel');
  let mIndex = getIndexArray(selectModel, selectArrayMoveFrom);
  console.log(selectArrayMoveFrom, 'selectArrayMoveFrom');

  selectArrayMoveFrom.splice(mIndex, 1);
  console.log(selectArrayMoveFrom, 'msDelete');
  selectArrayMoveTo.push(selectModel);
  console.log(selectArrayMoveTo, 'arrayResult');

}

export  function next(m: any, ms = []) {
  return ms[getIndexArray(m, ms) + 1];
}

export  function prev(m: any, ms = []) {
  // getIndexArray(m, ms) - 1
  /*let count = ms.length
  count = count-1
  count == 0?count  =ms.length:count = 0
  return ms[getIndexArray(m, ms) - 1];*/
  return ms[getIndexArray(m, ms) - 1];
}

export  function addToStart(m: any, ms:any = [], unic: any = true) {
  if (unic) {
    return !ms.includes(m) ? ms.unshift(m) : false;
  } else {
    return ms.unshift(m);
  }
}

export  function addToElementStart(addM: any, addElement: any, ms = [], unic: any = true) {
  // getIndexArray(addElement,ms)
  // @ts-ignore
  return ms.splice(getIndexArray(addElement, ms), 0, addM);
}

export  function addToElementEnd(addM: any, addElement: any, ms = [], unic: any = true) {
  // getIndexArray(addElement,ms)
  // @ts-ignore
  return ms.splice(getIndexArray(addElement, ms) + 1, 0, addM);
}

export  function exist(m: any, ms: any) {
  return getIndexArray(m, ms) != -1;
}

export  function getIndexArray(m: any, ms: any) {
  if (ms) {
    return ms.indexOf(m);
  }
}

export  function isArray(arr: any) {
  return arr instanceof Array;
}

// export  function selectCheck: any = false;

export  function selectAll(ms: any = [], $event: any) {
  ms.map(function(el: any) {
    return el.select = $event;
  });
}


export  function checkedInvert(ms: any = []) {
  ms.map(function(el: any) {
    return el.check = !el.check;
  });
}
export  function checkToArchive(ms: any = [] ) {
// @ts-ignore
  return   ms.map((el: any) => {
    if(el.check){
      return  el.sw = false;
    }
  });
 /* alert.confirmationService.confirm({
    message: `Checked to archive?`,
    accept: () => {
      // @ts-ignore
      ms.map((el: any) => {
        if(el.check){
          return  el.sw = false;
        }
      });
    },
  });*/
}

// deleteCurrent() {
//
//   alert.confirmationService.confirm({
//     message: 'Are you sure that you DELETE?',
//     accept: () => {
//       cd.deleteMs(cd.selectPhoto, cd.photoLib);
//       cd.selectPhoto = null;
//     }
//   });
// }
// export  function deleteFile(photo: any,photoLib:any) {
//   alert.confirmationService.confirm({
//     message: 'Are you sure that you DELETE?',
//     accept: () => {
//
//       cd.deleteMs(photo,photoLib );
//       // cd.api.setUrl('https://server.artas.org.ua/generator/');
//       // cd.api.custom(photo, 'delete-file')
//       //   .subscribe((r: any) => {
//       //   });
//     }
//   });
//
// }
//
// deleteAll() {
//
//   alert.confirmationService.confirm({
//     message: 'Are you sure that you DELETE?',
//     accept: () => {
//       cd.photoLib = [];
//     }
//   });
//
// }
//
export  function deleteSelect(ms: any = [],) {
  return ms.filter((el: any) => {
    return el.select == false;
  });

  // alert.confirmationService.confirm({
  //   message: 'Are you sure that you DELETE?',
  //   accept: () => {
  //     ms = ms.filter((el: any) => {
  //       return el.select == false;
  //     });
  //   }
  // });


}

export  function filterByRow(searchName: any = 'Text', models: any = [], searchRow = 'type',) {
  return models.filter((el: any) => {
    return el[searchRow] === searchName;
  });
}

export  function uniq(array: any, row: any = 'categoryName') {
  let uniqArray = _.uniqBy(array, row);
  return _.remove(uniqArray, (el: any) => {
    return el.name !== '';
  });
  /*return    array.reduce((acc:any, cur:any) => [
       ...acc.filter((obj:any) => obj.id !== cur.id), cur
     ], []);*/
  // return array.reduce((acc:any, current:any) => {
  //   current = current.trim();
  //   if (!acc.includes(current)) {
  //     acc.push(current);
  //   }
  //   return acc;
  // }, []);
}

export  function deleteUnChecked(ms: any = []) {
 return  ms = ms.filter((el: any) => {
    return el.select == true;
  });

  /*alert.confirmationService.confirm({
    message: 'Are you sure that you DELETE ALL?',
    accept: () => {
      ms = ms.filter((el: any) => {
        return el.select == true;
      });
    }
  });*/
}


export  function applyParamsToCurrent(params: any) {
  return params.blurRadius;

}

export  function  applyParamsToAll(ms: any = [], params: any) {
  ms.map(function(el: any) {
    return el.blurRadius = params.blurRadius;
  });
}

export  function applyParamsToChecked(ms: any = [], params: any) {
  ms.map(function(el: any) {
    if (el.select === true) {
      el.blurRadius = params.blurRadius;
    }
  });
}

export  function applyParamsToUnChecked(ms: any = [], params: any) {
  ms.map(function(el: any) {
    if (el.select === false) {
      el.blurRadius = params.blurRadius;
    }
  });
}

export  function  swap(array: any[], x: any, y: any) {
  var b = array[x];
  array[x] = array[y];
  array[y] = b;
}


export  function moveUp(m: any, ms: any) {
  let index = getIndexArray(m, ms);
  if (index >= 1) {
    swap(ms, index, index - 1);
  }
}

export  function moveDown(m: any, ms: any,) {
  let index = getIndexArray(m, ms);
  if (index < ms.length - 1) {
    swap(ms, index, index + 1);
  }
}

export  function moveEnd(m: any, ms: any) {
  let index = getIndexArray(m, ms);
  if (index < ms.length) {
    swap(ms, index, ms.length - 1);
  }
}

export  function moveStart(m: any, ms: any) {
  let index = getIndexArray(m, ms);
  if (index < ms.length) {
    swap(ms, index, 0);
  }
}

export  function reverse(array: any) {
  return _.reverse(array);

}

export  function filterCheckbox(selected: any = [], products: any = [],) {
  let filteredResult: any = [];
  selected.forEach((select: any) => {
    let filtered = products.filter((product: any) => {
      return product.name === select.name;
    });
    filteredResult.push(filtered);
  });
  return _.flatten(filteredResult);
}

export  function loadMore(selected: any = [], products: any = []) {

}

export  function sortNumberAsc(layers: any, row: any = 'cost') {
  return _.sortBy(layers, [row]);
}

export  function sortNumberDesc(layers: any, row: any = 'cost') {
  return reverse(_.sortBy(layers, [row]));
}
export  function sortNumberCustom(layers: any, row: any = 'cost', ask:any =true) {
  if(ask){
    return  sortNumberAsc(layers,row);
  }else{
    return  sortNumberDesc(layers,row);
  }

}


export  function deleteTest(object: any, row: any = 'tests') {
  delete object[row];
}
