export const SeoCaricatureOnline = {
  route: '/caricature-online/',
  title: 'Карикатура онлайн по фото',
  description: 'Карикатура онлайн по фото. Дізнатись вартість шаржа та карикатури в Україні. Шарж недорого та швидко – від 300 грн. Замовити портретний',
  h1: 'Карикатура онлайн по фото',
  h2: 'Карикатура онлайн по фото чи карикатури',
  h3: 'Карикатура онлайн по фото чи олівцем пастелью',
  h4: 'h4',
  h5: 'h5',
  textEndPage: 'textEndPage',
  keywords: [],
  text: 'Карикатура онлайн по фото- в якій герой поміщений у сцену, наповнену смисловими нюансами та деталями. Сюжет шаржа, створений за Вашим бажанням, дозволяє яскраво продемонструвати індивідуальність людини, відобразити її захоплення, відтінки натури та розвеселити її цим.\\n\' +\n' +
    '      \'\\n\' +\n' +
    '      \' Крім підкресленої індивідуальності, шарж може бути наповнений і певною тематикою з нагоди якоїсь події: весілля, Дня народження, закінчення або початку навчання, переїзду в іншу країну і т.д.\\n\' +\n' +
    '      \'\\n\' +\n' +
    '      \' В одному сюжеті шаржа можуть фігурувати кілька героїв.\\n\' +\n' +
    '      \'\\n\' +\n' +
    '      \' Формати малюнка також можуть бути різними: А3 (30 * 40 см) А2 (40 * 60 см) А1 (60 * 85 см)\\n\' +\n' +
    '      \'\\n\' +\n' +
    '      \' Для створення шаржа необхідні фотографії героя (героїв), опис сюжету та деталей, а також ваші побажання.\' +\n' +
    '      \'\' +\n' +
    '      \'У нас Ви можете замовити шарж (карикатуру) зі фотографії у Києві, Львові, Вінниці, Полтаві, Чернігові, Черкасах, Житомирі, Хмельницькому, Чернівцях, Рівному. Доставка в будь-яке місто України та світу\'',
}
