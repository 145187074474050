import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {JsonPipe, NgFor, NgIf} from '@angular/common';
import {SliderModule} from "primeng/slider";
import {
  InputTextModule
} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {CoinsServices} from "../../services/common/coins.services";
import {checkEndOfMonth} from "../../traits/datetime-tools";
import {AvatarModule} from "primeng/avatar";
import {CdServices} from "../../services/common/cd.services";
@Component({
  selector: 'app-coins',
  templateUrl: './coins.component.html',
  standalone:true,
  imports: [
    NgIf, NgFor,SliderModule,InputTextModule,FormsModule,AvatarModule
  ]
})
export class CoinsComponent {
  @Input() showCoins: boolean =false;
  @Input() showCoinMinus: boolean =false;
  constructor(
    public cd: CdServices,
    public coinService: CoinsServices
  ) {}
}
