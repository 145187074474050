import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtClubServices} from '../static/static-art-club.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {Site} from '../../interfaces/site';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageImageToSketchServices {
  public SERVER_NAME: string = 'image-to-sketch';
  public DOMAIN: string = 'art-club.artas.org.ua/image-to-sketch';
  public route: string = 'image-to-sketch';

  public fromServer: boolean = false;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'Image to sketch',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'Image to sketch',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Image to Sketch App - Transform Photos into Pencil Drawings Instantly"\n',
    description: 'Unleash creativity with the Image to Sketch App, an AI-driven tool that turns your photos into beautiful pencil sketches in seconds. Perfect for artists, designers, and anyone looking to transform images into art. Download now to explore a world where your memories become masterpieces.',
    text: 'Transform your photos into stunning pencil sketches with our revolutionary "Image to Sketch" application. Perfect for artists, designers, or anyone looking to add a creative touch to their images, our app leverages advanced AI technology to convert your photographs into exquisite pencil drawings in just a few clicks.\n' +
      '\n' +
      'Main Features:\n' +
      '\n' +
      '- AI-Driven Precision: Utilize the power of artificial intelligence to accurately interpret and transform your images into detailed sketches.\n' +
      '- User-Friendly Interface: Navigate through our app with ease, thanks to a simple and intuitive design that guarantees a seamless sketching experience.\n' +
      '- Versatile Applications: Whether it\'s for professional graphic design, artistic creations, or personal projects, our pencil sketch converter meets all your needs.\n' +
      '- High-Quality Output: Enjoy high-resolution sketches, ensuring that no detail is lost in the transformation process.\n' +
      '- Efficiency: Save time on manually drawing or editing photos to achieve a pencil sketch effect — our app does it instantly.\n' +
      '\n' +
      'SEO Keywords:\n' +
      '\n' +
      '- Pencil Sketch App\n' +
      '- Image to Sketch Converter\n' +
      '- AI Sketch Generator\n' +
      '- Photo to Drawing App\n' +
      '- AI Pencil Sketch\n' +
      '- Digital Sketching Tool\n' +
      '- Convert Photo to Pencil Drawing\n' +
      '- Artistic Photo Editing\n' +
      '\n' +
      'In today\'s digital age, where creativity meets technology, our "Image to Sketch" app stands out by offering a unique way to reimagine your photos. Whether you\'re looking to enhance your portfolio, gift a personalized sketch, or simply explore your artistic side, our app provides the perfect platform to transform your visions into reality.\n' +
      '\n' +
      'Embrace the blend of art and technology today. Download our "Image to Sketch" app and unleash the full potential of your creativity, one pencil stroke at a time.Elevate your digital artwork with the "Image to Sketch" application, where innovation meets artistry. In a world flooded with conventional photo editing tools, our app distinguishes itself by offering a specialized, AI-powered feature that turns any photo into a captivating pencil sketch. Ideal for art enthusiasts, social media aficionados, and everyone in between, this application promises to transform your ordinary images into works of art that resonate with sophistication and charm.\n' +
      '\n' +
      'Why Choose Image to Sketch?\n' +
      '\n' +
      '- Customization at Your Fingertips: Adjust the intensity and style of the sketch to match your artistic vision.\n' +
      '- Instant Artwork Creation: Forget the hassle of sketching or finding an artist. Create a masterpiece in moments.\n' +
      '- Share Your Art: Easily export and share your sketches on social media, making it perfect for profile pictures, gifts, or showcasing your creativity.\n' +
      '\n' +
      'As we strive to bridge the gap between technology and creativity, the "Image to Sketch" app serves as your personal artist, ready to convert your memories into timeless sketches. Whether you\'re looking to preserve a special moment in a unique way or simply explore the boundaries of digital art, our app is designed to provide an enriching user experience.\n' +
      '\n' +
      'Indulge in the art of transformation. With "Image to Sketch," your photographs will no longer just capture moments; they will embody artistic expressions waiting to be uncovered. Download now and start your journey from image to sketch, where every photo becomes a potential masterpiece.',
    h1: 'Transform Your Photos into Exquisite Pencil Sketches with Image to Sketch App',
    h2: 'Revolutionize Your Digital Art with AI-Powered Sketch Conversion',
    h3: 'Why Choose Image to Sketch',
    h4: 'Your Personal Artist: From Image to Sketch in Moments',
    h5: 'Explore the Boundaries of Digital Art without Limits',
    textEndPage:'Unleash creativity with the Image to Sketch App, an AI-driven tool that turns your photos into beautiful pencil sketches in seconds. Perfect for artists, designers, and anyone looking to transform images into art. Download now to explore a world where your memories become masterpieces.',
    keywords: [
      'Image to Sketch', 'Pencil Sketch App', 'Photo to Drawing', 'AI Sketch Generator', 'Convert Photo to Sketch', 'Digital Art Tool', 'Sketch Conversion App', 'Artistic Photo Editing', 'Instant Sketching', 'Creative Image Transformation'
    ],
  };
  public site: any

  constructor(public server:ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  getSite() {
  /*  if (this.fromServer) {
      return this.getSiteFromServer();
    } else {
      return this.getSiteStatic();
    }*/

  }
  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }
 public saveSite(site:object){
   if (this.fromServer) {

     this.site = site
    this.downloadSiteBtn(this.site);
    // let server: any = inject(ServerSiteServices)
    this.server.saveSite(this.DOMAIN, this.site);
  }
  }
  getSiteStatic() {
    return this.site = inject(StaticArtClubServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer() {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: Site): void => {
      this.site = responseSite;
    });
    return this.site;
  }

}
