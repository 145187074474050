import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {ArrayServices} from "../services/common/array.services";

@Pipe({
  name: 'filter',
  standalone:true
})
export class FilterPipe implements PipeTransform {
  // prop: string, value: string , method:Method
  constructor( public array:ArrayServices) {
  }
  transform(arr: any,value:any,searchRow:any ='name'): any {
    if (arr) {
      if (!value ) {
        // console.log('arr',arr);
        return arr
      } else {
        // console.log('keyword',value);
        // console.log('models',arr);
        // console.log('searchRow',searchRow);
        return this.array.filterByRow(value,arr,searchRow);

        // return arr.filter(obj => this.filter(obj[prop],value, method))
      }
    } else {
      return []
    }
  }

  /*filter(source :string, target :string, method:Method) : boolean {

    // switch(method) {
    //   case "includes" : return source.includes(target)
    //   case "equal"  : return source === target
    //   case "not-equal" : return source !== target
    // }
  }*/
}

// type Method ="includes" | "equal" | "not-equal"
