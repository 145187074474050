import {
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import {TabViewModule} from 'primeng/tabview';
import {
  JsonPipe,
  NgForOf,
  NgIf
} from "@angular/common";
import {BadgeModule} from "primeng/badge";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {ColorsImageComponent} from "../../colors-image/colors-image.component";
import {UploaderPhotoComponent} from "../admin/uploader-photo/uploader-photo.component";
import {SidebarComponent} from "../common/sidebar/sidebar.component";
import {CircleWithImageComponent} from "../circle-with-image/circle-with-image.component";
import {ShareImageComponent} from "../share-image/share-image.component";
import {CropRigionComponent} from "../crop-rigion/crop-rigion.component";
import {ImagesViewerGridSlidderComponent} from "../pages/images-viewer-grid-slidder/images-viewer-grid-slidder.component";
import {EyeDropperComponent} from "../eye-dropper/eye-dropper.component";
import {ImageMosaicComponent} from "../image-mosaic/image-mosaic.component";
import {GrayscaleComponent} from "../grayscale/grayscale.component";
import {CameraComponent} from "../pages/camera/camera.component";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";
import {AlbumComponent} from "../album/album.component";
import {ZipComponent} from "../zip/zip.component";
import {BrowserCloseAlertComponent} from "../browser-close-alert/browser-close-alert.component";
import {PackPalettesComponent} from "../pack-palettes/pack-palettes.component";
import {
  addToEnd,
  addToStart,
  deleteFromArray
} from 'src/app/traits/array-tools';
import {
  cameraIcon,
  closeSquareIcon,
  cropIcon,
  deleteIcon,
  editIcon,
  eyeDropperIcon,
  favoriteIcon,
  gridIcon,
  imagePanoramaIcon,
  moveIcon,
  pinIcon
} from 'src/app/traits/icons-tools';
import {dateNow} from 'src/app/traits/datetime-tools';
import {modelNew} from "../../traits/model-tools";
import {ColorIroComponent} from "../color-iro/color-iro.component";
import {ToggleButtonModule} from "primeng/togglebutton";
import {SliderModule} from "primeng/slider";
import {ImageEditorComponent} from "../image-editor/image-editor.component";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {CoinsServices} from "../../services/common/coins.services";
import {CoinsComponent} from "../coins/coins.component";
import {CdServices} from "../../services/common/cd.services";
@Component({
  selector: 'app-pallete-generator-image',
  standalone: true,
  imports: [
    TabViewModule,
    SliderModule,
    SidebarComponent,
    CircleWithImageComponent,
    ColorsImageComponent,
    UploaderPhotoComponent,
    NgIf,
    FormsModule,
    BadgeModule,
    ShareImageComponent,
    CropRigionComponent,
    ImagesViewerGridSlidderComponent,
    NgForOf,
    EyeDropperComponent,
    ImageMosaicComponent,
    GrayscaleComponent,
    ButtonModule,
    CameraComponent,
    BtnDownloadComponent,
    AlbumComponent,
    ZipComponent,
    BrowserCloseAlertComponent,
    PackPalettesComponent,
    JsonPipe,
    ColorIroComponent,
    ToggleButtonModule,
    ImageEditorComponent,
    OverlayPanelModule,
    CoinsComponent,
  ],
  templateUrl: './pallete-generator-image.component.html',
})

export class PalleteGeneratorImageComponent {
  @ViewChild('roundPallete') roundPallete: ElementRef<HTMLCanvasElement>;
  @Input() public imageParent: string = null

  public colorsEyeDropper: any[] = []
  public packTrioPallete: any[] = [];
  public listPalletes: any[] = [];
  public imageUploaded: string = 'assets/image-pallete_generator-flowers.jpg';
  public resetUploaded: string = 'assets/image-pallete_generator-flowers.jpg';
  public tempImage: string = 'assets/image-pallete_generator-flowers.jpg';
  public imageCrop: any;
  public imageGrayscale: any;
  public imagePixel: string;
  public imagePallete: string;
  public showUpload: boolean = true;
  public showCamera: boolean = true;
  public showDownload: boolean = false;
  public showCropRigion: boolean = false;
  public showPixelizer: boolean = false;
  public showPackPaletes: boolean = false;
  public showEyeDropper: boolean = false;
  public showPreload: boolean = false;
  public showGrayscale: boolean = false;
  public showCropRigionBtn: boolean = true;
  public showGrayscaleBtn: boolean = true;
  public showPixelizerBtn: boolean = true;
  public showBigRounds: boolean = true;
  public showAllColors: boolean = false;
  public showSettings: boolean = false;
  public showCameraComponent: boolean = false;
  public showChildComponent: boolean = true;
  public showColorEditor: boolean = true;
  public isBlackAndWhite: boolean = true;
  public showBundleBtn: boolean = false;
  public showDownloadBtn: boolean = true;
  public palleteSize: string = '75%';
  roundsSmallColors: number = 100;
  spaceWithColors: number = 10;
  spaceWithColorsTemp: number = 10;
  groupWithColors: number = 20;
  groupWithColorsTemp: number = 20;
  protected readonly deleteFromArray = deleteFromArray;
  protected readonly dateNow = dateNow;
  protected readonly deleteIcon = deleteIcon;
  protected readonly favoriteIcon = favoriteIcon;
  protected readonly moveIcon = moveIcon;
  protected readonly closeSquareIcon = closeSquareIcon;
  protected readonly gridIcon = gridIcon;
  protected readonly pinIcon = pinIcon;
  protected readonly eyeDropperIcon = eyeDropperIcon;
  protected readonly cropIcon = cropIcon;
  protected readonly cameraIcon = cameraIcon;
  protected readonly imagePanoramaIcon = imagePanoramaIcon;
  protected readonly editIcon = editIcon;
  private enablePack: boolean = false;
  constructor(public cd: CdServices) {
  }
  ngOnInit() {
    this.init();
  }

  init() {
    if (this.imageParent) {
      this.selectUpload(this.imageParent)
      this.showUpload = false;
      this.showCamera = false;
    } else {
      this.showUpload = true;
      this.showCamera = true;
      let firstUpload = 'assets/image-pallete_generator-flowers.jpg';
      // console.log(firstUpload, 'firstUpload')
      this.imageCrop = firstUpload
      this.imageUploaded = firstUpload;
      this.tempImage = firstUpload;

      this.resetChildComponent(() => {
        this.getPalleteImage();

      })
    }

  }

  async imageUrlToBase64(url: string) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  ngOnDestroy() {
    this.imageParent = null;
    this.imageCrop = null;
    this.imageUploaded = null;
    this.tempImage = null;
  }

  public setImageToRoundPallete(base64: string) {
    // @ts-ignore
    this.roundPallete.imageSrc = base64;
    // @ts-ignore
    this.roundPallete.reset();
    // @ts-ignore

    this.roundPallete.init();

    // this.resetChildComponent()
  }

  setImage(base64: string) {
    this.imageUploaded = base64;
    this.imageCrop = base64;
    this.tempImage = base64;
    this.resetChildComponent()
  }

  public uploadImage(base64: string): void {
    // console.log('camera', base64)
    this.setImage(base64);
    // addToStart(this.imageUploaded, this.listUploads)
    // this.listUploadsCount = this.listUploads.length;
    // this.setImageToRoundPallete(base64)

    /* this.imageUploaded = base64;
     this.imageCrop = base64;
     this.showDownload = true;

     this.getPalleteImage();
     // @ts-ignore
     this.roundPallete.imageSrc = base64;

     // this.setImageToRoundPallete(base64)
     this.resetChildComponent()*/
  }

  downloadFromList() {
    // @ts-ignore
    this.roundPallete.downloadImage()
  }

  getPalleteImage() {
    // @ts-ignore
    this.imagePallete = this.roundPallete.getImage();
    // @ts-ignore
    // this.imagePallete = this.roundPalletePreview.getImage();
  }

  public cropImage(base64: string) {
    this.imageUploaded = base64;
    this.resetChildComponent()
    // this.imageCrop = base64;
    // this.setImageToRoundPallete(base64)
    // sidebar crop
    // small preview palletes
  }

  public toggleEyeDropper() {
    // show sidebar
    // show eyeDropperPicker image
    // small preview palletes
  }

  public isShowPalletes() {
    return (this.imageUploaded
      // && this.listDownloadsCount>0
    )
  }

  selectUpload(base64: string) {
    this.imageCrop = base64;
    this.imageUploaded = base64;
    this.tempImage = base64;
    // this.showPackPaletes = false;
    this.optOpt();
    this.resetChildComponent(() => {
      this.getPalleteImage();
    });
    this.imageUrlToBase64(base64)
      .then((base64String: string) => {
        this.imageCrop = base64String;
        this.imageUploaded = base64String;
        this.tempImage = base64String;
        this.resetChildComponent(() => {
          this.getPalleteImage();
        });
      })

  }

  public showCropPanel() {
    this.showCropRigion = !this.showCropRigion;
    this.showDownloadBtn = !this.showDownloadBtn;
    this.showUpload = !this.showUpload;
    this.showCamera = !this.showCamera;
    if (this.showCropRigion) {
      this.palleteSize = '50%'
      this.showPixelizerBtn = false;
      this.showGrayscaleBtn = false;
      this.showBundleBtn = false;
    } else {
      this.palleteSize = '75%'
      this.showPixelizerBtn = true;
      this.showGrayscaleBtn = true;
      this.showBundleBtn = true;
    }
  }

  public showEyeDropperImage() {
    this.showEyeDropper = !this.showEyeDropper;
    this.showUpload = !this.showUpload;
    this.showDownloadBtn = !this.showDownloadBtn;
    this.showUpload = !this.showUpload;
    this.showBundleBtn = !this.showBundleBtn;

    if (this.showEyeDropper) {
      this.palleteSize = '50%'
    } else {
      this.palleteSize = '75%'
    }
  }

  public showPixelazerImage() {
    this.showPixelizer = !this.showPixelizer;
    this.showUpload = !this.showUpload;
    this.showDownloadBtn = !this.showDownloadBtn;
    if (this.showPixelizer) {
      this.palleteSize = '50%'
      this.showGrayscaleBtn = false;
      this.showCropRigionBtn = false;
      this.showBundleBtn = false;
    } else {
      this.palleteSize = '75%'
      this.showGrayscaleBtn = true;
      this.showCropRigionBtn = true;
      this.showBundleBtn = true;

    }
  }

  public showGrayscaleImage() {
    this.showGrayscale = !this.showGrayscale;
    this.showUpload = !this.showUpload;
    // this.showDownloadBtn =!this.showDownloadBtn;

    if (this.showGrayscale) {
      this.showPixelizerBtn = false;
      this.showCropRigionBtn = false;
      this.showBundleBtn = false;

    } else {
      this.showPixelizerBtn = true;
      this.showCropRigionBtn = true;
      this.showBundleBtn = true;
    }
  }

  handleImageConverted(base64: string) {
    // this.tempImage = base64;
    this.imageUploaded = base64;
    this.resetChildComponent()
  }

  toggleBlackAndWhite() {
    this.isBlackAndWhite = !this.isBlackAndWhite;
  }

  public generatePack() {
    const timeSwitch:number  = 2000;
    // this.spaceWithColors = 10;
    this.showUpload = false;
    this.showCamera = false;
    this.imageUploaded = this.imageCrop;
    this.packTrioPallete = [];
    this.enablePack = true;
    this.showPreload = true;
    this.resetChildComponent()
    setTimeout(() => {
      this.groupWithColors = 2;
      this.showGrayscale = true;
      setTimeout(() => {
        this.showGrayscale = false;
        this.groupWithColors = this.groupWithColorsTemp;

        this.showPixelizer = true;
        setTimeout(() => {
          this.showPixelizer = false;
          // this.showChildComponent = false;
          this.imageUploaded = this.imageCrop;
          this.maxOpt();
          this.resetChildComponent()
          setTimeout(() => {
           /* let model: any = modelNew();
            model.srcPreview = this.imageCrop;
            addToStart(model, this.packTrioPallete)*/
            this.showPackPaletes = true;
            this.showPreload = false;
            this.showUpload = true;
            this.showCamera = true;
            this.enablePack = false;

            this.selectUpload(this.imageCrop)
          }, timeSwitch);
        }, timeSwitch);
      }, timeSwitch);
    }, timeSwitch);

    // this.showCropPanel();
    // this.showGrayscaleImage();
    // this.showPixelazerImage();
    // this.packTrioImage = [
    //   {id:randomId(),name:'imageCrop',srcPreview:this.imageCrop},
    //   {id:randomId(),name:'imageGrayscale',srcPreview:this.imageGrayscale},
    //   {id:randomId(),name:'imagePixel',srcPreview:this.imagePixel},
    // ]
  }

  resetChildComponent(callback: any = null) {
    this.showChildComponent = false;
    // this.showPixelizer =false;
    setTimeout(() => {
      this.showChildComponent = true;
      callback && callback(true)
      // this.showPixelizer =true;
      // this.showPixelizer =false;

    })
  }

  resetColorEditorComponent(callback: any = null) {
    this.showColorEditor = false;
    setTimeout(() => {
      this.showColorEditor = true;
      callback && callback(true)

    })
  }

  resetDownloadComponent(callback: any = null) {
    this.showDownloadBtn = false;
    // this.showPixelizer =false;
    setTimeout(() => {
      this.showDownloadBtn = true;
      // this.showPixelizer =true;
      // this.showPixelizer =false;

    })
  }

  addColorToPallete(hexColor: any) {
    // console.log(hexColor, 'addColorToPallete')
    console.log(this.colorsEyeDropper, 'addColorToPallete')
    addToStart(hexColor.hex, this.colorsEyeDropper);
    this.resetChildComponent()

    // @ts-ignore

    // this.imagePallete = this.roundPallete.setColorToBigCircle(colorItem.hex);

  }

  public showCameraBtn() {
    this.showCameraComponent = !this.showCameraComponent;
    // this.showUpload = !this.showUpload;
    this.showDownloadBtn = !this.showDownloadBtn;
    this.showDownloadBtn = !this.showDownloadBtn;
    if (this.showCameraComponent) {
      // this.palleteSize = '50%'
    } else {
      this.palleteSize = '75%'
    }
  }

  onBatchPaletter(event: any) {
    // console.log(event)
    // this.setImage(event.srcPreview);
    this.imageUploaded = event.srcPreview;
    this.tempImage = event.srcPreview;
    this.showChildComponent = false;
    // this.showPixelizer =false;
    setTimeout(() => {
      this.showChildComponent = true;
      // this.showPixelizer =true;
      // this.showPixelizer =false;

    }, 1000)
  }

  addToPalleteList($event: string) {
    let model = modelNew();
    model.srcPreview = $event
    addToStart(model, this.listPalletes)
  }

  addToPackList($event: string) {
    // console.log($event,'addToPackList')
    if (this.enablePack) {
      let model = modelNew();
      console.log(model)
      model.srcPreview = $event;
      addToEnd(model, this.packTrioPallete);
    }
  }

  cropSelect(srcPreview: any) {

  }

  maxOpt() {
    this.roundsSmallColors = 200;
    this.showBigRounds = false;
    this.spaceWithColors = 10;
    this.groupWithColors = 20;
  }

  optOpt() {
    this.roundsSmallColors = 100;
    this.showBigRounds = true
    this.spaceWithColors = 10;
    this.groupWithColors = 20;
  }

  public switchMaxOpt() {
    if (this.showAllColors) {
      this.maxOpt()
    } else {
      this.optOpt()
    }
    this.resetChildComponent()

  }
}
