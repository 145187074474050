import {Component, Input} from '@angular/core';
import {RouterLink} from '@angular/router';
import {BreadcrumbComponent} from '../breadcrumb/breadcrumb.component';
import {LogoComponent} from '../logo/logo.component';
import {NavbarClientComponent} from '../navbar-client/navbar-client.component';
import {NavbarFavoritesComponent} from '../navbar-favorites/navbar-favorites.component';
import {CoinsComponent} from "../../coins/coins.component";
import {CdServices} from "../../../services/common/cd.services";
import {NgIf} from "@angular/common";
import {OnlineStatusComponent} from "../online-status/online-status.component";

@Component({
  selector: 'app-navbar-top',
  templateUrl: './navbar-top.component.html',
  standalone:true,
  imports: [
    NgIf,
    BreadcrumbComponent,
    LogoComponent,
    NavbarClientComponent,
    NavbarFavoritesComponent,
    CoinsComponent,
    OnlineStatusComponent
  ]
})
export class NavbarTopComponent {
constructor(public cd:CdServices) {

}
}
