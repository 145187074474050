import {
  inject,
  Injectable
} from '@angular/core';
import {ServerSiteServices} from '../servers/server-site.services';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";
import {StaticRotateHeadServices} from "../static/static-rotate-head.services";
import {StaticRotatorLibServices} from "../static/static-rotator-lib.services";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageCharacterMixerServices {
  public SERVER_NAME: string = 'character-mixer';
  public DOMAIN: string = 'art-club.artas.org.ua/character-mixer';
  public route: string = 'character-mixer';
  public fromServer: boolean = true;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'rotator-lib',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'rotator-lib',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'rotator-lib',
    description: 'rotator-lib',
    text: 'rotator-lib',
    h1: 'rotator-lib',
    h2: 'rotator-lib',
    h3: 'rotator-lib',
    h4: 'rotator-lib',
    h5: 'rotator-lib',
    textEndPage: 'rotator-lib',
    keywords: [
      'rotator-lib'
    ],
  };
  public site: any

  constructor(public server: ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  public getSite(callback: any = null) {
    if (this.fromServer) {
      return this.getSiteFromServer(callback);
    } else {
      return this.getSiteStatic();
    }

  }

  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }

  public saveSite(site: object) {
    if (this.fromServer) {

      this.site = site
      // this.downloadSiteBtn(this.site);
      // let server: any = inject(ServerSiteServices)
      this.server.saveSite(this.DOMAIN, this.site);
    }
  }

  getSiteStatic() {
    return this.site = inject(StaticRotatorLibServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer(callback: any = null) {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: any): void => {
      this.site = responseSite;
      callback && callback(responseSite)
    });
    return this.site;
  }

}
