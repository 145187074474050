import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {
  NgFor,
  NgIf
} from "@angular/common";

@Component({
  selector: 'app-color-item',
  standalone:true,
  imports:[NgIf,NgFor],
  templateUrl: './color-item.component.html'
})
export class ColorItemComponent  {
  // @ts-ignore
  @ViewChild('accessId') accessId: ElementRef;
  @Input() model: any;
  @Input() selectModel: any;
  @Input() colorHex: any;
  @Input() width: any =15;
  @Input() height: any =15;
  @Input() change: any;
  @Output() onClick = new EventEmitter();
  @Output() onDblclick = new EventEmitter();
  @Output() onPress = new EventEmitter();
  @Output() onTop = new EventEmitter();
  @Output() onBottom = new EventEmitter();


  initHammerJs(element: any, callback: any = null) {

  /*  // @ts-ignore
    let mc = new Hammer(element);

    mc.on('panleft panright click dblclick tap press doubletap', (ev: any) => {
      // @ts-ignore
      let atrsData = $(ev.target).data('val');

      // element.textContent = ev.type +" gesture detected.";
      callback && callback(atrsData, ev.type);
    });
*/
  }

  ngAfterViewInit() {

 /*    this.initHammerJs(this.accessId.nativeElement, (atr: any, type: any) => {
      // panleft panright
       if (type == 'press') {
         console.log(type)
         this.onPress.emit(this.model)

         // this.child =this.cd.array.next(this.child,this.set.childs)

       }
       if (type == 'doubletap') {
         console.log(type)
         this.onDblclick.emit(this.model)

         // this.child =this.cd.array.next(this.child,this.set.childs)

       }
      if (type == 'panleft') {
        console.log(type)
        // this.child =this.cd.array.next(this.child,this.set.childs)

      } if (type == 'panright') {
        console.log(type)
        // this.child =this.cd.array.prev(this.child,this.set.childs)

      }
      // console.log(type,'type')
      // console.log(atr,'atr')
      // console.log(this.page,'this.Set')
    });
*/
  }


  ngOnDestroy(): void {
  }

}
