
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  JsonPipe,
  NgClass,
  NgFor,
  NgIf,
  NgStyle
} from '@angular/common';
import {
  resizeWindow,
  windowHeight,
  windowWidth
} from "../../traits/window-tools";
import {
  addIcon,
  archiveIcon,
  closeIcon,
  cropIcon,
  deleteIcon,
  dotesVerticalIcon,
  editIcon,
  favoriteIcon,
  filterIcon,
  imagePanoramaIcon,
  lockCloseIcon,
  lockOpenIcon,
  minusIcon,
  moveIcon,
  plusIcon,
  saveIcon,
  zoomMinusIcon,
  zoomPlusIcon
} from "../../traits/icons-tools";
import {
  FormsModule,
} from "@angular/forms";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";
import {ColorsGridComponent} from "../admin/colors-grid/colors-grid.component";
import {
  addCloneModel,
  deleteFromArray
} from "../../traits/array-tools";
import {InteractTools} from "../../services/common/interact-tools";
import {toArchive} from "../../../../refactoring-code/services/traits/array-tools";
import {CdServices} from "../../services/common/cd.services";
import {ImageReferenceComponent} from "../image-reference/image-reference.component";
import {SidebarComponent} from "../common/sidebar/sidebar.component";
import {ZoomImageComponent} from "../zoom-image/zoom-image.component";
import {reInit} from "../../traits/datetime-tools";
import {StageCropperComponent} from "../stage-cropper/stage-cropper.component";
import {CropRigionComponent} from "../crop-rigion/crop-rigion.component";
import {StageImageComponent} from "../stage-image/stage-image.component";
import {CheckboxModule} from "primeng/checkbox";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {listModel} from "../../traits/model-tools";
import {ToggleButtonModule} from "primeng/togglebutton";
import {FilterPipe} from "../../pipe/filter.pipe";
import {StageTextComponent} from "../stage-text/stage-text.component";
import {StageListComponent} from "../stage-list/stage-list.component";
import {exportStage} from "../../traits/importExport-tools";
import {ModalProductComponent} from "../../modal-product/modal-product.component";

declare const interact;
@Component({
  selector: 'app-infinite-board',
  templateUrl: './infinite-board.component.html',
  styleUrls: ['./infinite-board.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    JsonPipe,
    NgStyle,
    NgClass,
    OverlayPanelModule,
    BtnDownloadComponent,
    ColorsGridComponent,
    ImageReferenceComponent,
    SidebarComponent,
    ZoomImageComponent,
    StageCropperComponent,
    CropRigionComponent,
    StageImageComponent,
    CheckboxModule,
    ScrollPanelModule,
    ToggleButtonModule,
    FilterPipe,
    StageTextComponent,
    StageListComponent,
    ModalProductComponent,
  ]


})


export class InfiniteBoardComponent {
 @Input() stage:any;
  @ViewChild('gestureArea') gestureArea: ElementRef;
  // @ts-ignore
  @ViewChild('scaleElement') scaleElement: ElementRef;
  @ViewChild('draggableElement') draggableElement: ElementRef;
  @Output() onChangeImages = new EventEmitter<void>();
  @Output() onChangeContainer = new EventEmitter<void>();
  @Output() onAddImage = new EventEmitter<string>();
  elementSelect: any;
  showReferenceSidebar: boolean =false;
  showListSidebar: boolean =false;
  showTextSidebar: boolean =false;
  showArchived: boolean =false;
  public saveStatus: boolean =false;

  @Input()  lockAll: boolean =false;
  @Input()  showGrid: boolean =true;
  @Input()  height: number  = windowHeight();
  @Input()  width: number = windowWidth();
  enableDrag:boolean = true;
  expand:boolean = false;
 filterByColor:string = '';
  constructor() {}
  showCropBoard: boolean  =false;

  public reInitStageCropper(nameHarmony:string='tetradic') {
    // this.showSketch = false;
    this.showCropBoard = false;
    setTimeout(() => {
      // this.showSketch = true;
      this.showCropBoard = true;

    }, 50)
  }

  //
  ngOnInit(): void {

  }
  public interactRaw() {

    let inputParams:any = {
      scale:this.stage.scale,
      x: this.stage.x,
      y:this.stage.y,
      angle:this.stage.angle,
    }
    let interactTools:any = new InteractTools(inputParams);
    interactTools.enableDrag = true;
    interactTools.scalable(
      this.gestureArea.nativeElement,
      this.scaleElement.nativeElement,
      (paramsOutput:any)=>{
      },
      (paramsOutput:any)=>{
        this.stage.scale = paramsOutput.scale;
        this.stage.x = paramsOutput.x;
        this.stage.y = paramsOutput.y;
        this.stage.angle = paramsOutput.angle;
        this.elementSelect = null;
        this.save();
      });
    interactTools.draggable(
      (paramsOutput:any)=>{
        if(this.elementSelect){
          this.elementSelect.x = paramsOutput.x;
          this.elementSelect.y = paramsOutput.y;
        }
this.save();
        // this.stage.scale = paramsOutput.scale;
        // this.stage.x = paramsOutput.x;
        // this.stage.y = paramsOutput.y;
        // this.stage.angle = paramsOutput.angle;
        // this.onChangeContainer.emit(this.stage)
        // this.onChangeImages.emit(this.stage)
      });

  }


  stageToCenterElement(elementItem:any){
    /*const {x,y} = elementItem;
    this.stage.x = x-1000;
    this.stage.y = y-1000;*/
   // const {x,y} = this.centerObjectToAnother(this.stage,elementItem)
   //  this.stage.x = x
   //  this.stage.y = y
    // this.interactRaw();

  }


  renderContainer(elemRect:any){
    // const container = document.getElementById('zoom-container');
    // const elemRect = event.target.getBoundingClientRect();
    // const containerRect = container.getBoundingClientRect();
    // console.log(containerRect,'elemRect'  )

    // Вычисляем необходимое смещение
    const centerX = (window.innerWidth / 2) - (elemRect.width / 2);
    const centerY = (window.innerHeight / 2) - (elemRect.height / 2);
    // const shiftX = centerX - elemRect.x;
    // const shiftY = centerY - elemRect.y;
  let container =this.scaleElement.nativeElement
    let x = (parseFloat(container.getAttribute('data-x')) || 0)// + event.dx;
    let y = (parseFloat(container.getAttribute('data-y')) || 0) //+ event.dy;
    // const  containerX =  this.stage.x //containerRect.left;
    // const containerY = this.stage.y  //containerRect.top;
    // const  resultX =  shiftX +x;
    // const resultY = shiftY +y;
;

    // Вычисляем необходимое смещение
    const shiftX = centerX - elemRect.x + x;
    const shiftY = centerY - elemRect.y + y;

    // Обновляем позицию container с использованием transform translate
    const translation = `translate(${shiftX}px, ${shiftY}px)`;
    container.style.transform = translation;
    // this.stage.x =shiftX;
    // this.stage.y =shiftY;
    // Обновляем позицию container
    // this.stage.y =resultX;
    // this.stage.x =resultY;
    // console.log(this.stage.x,'X')
    // console.log(this.stage.y,'Y')
    // container.style.transform = 'translate(' + resultX + 'px, ' + resultY + 'px)';

    // container.style.left = `${resultX}px`;
    // /* +*/
    /*containerRect.top +*/
    // container.style.top = `${resultY}px`;
    //   document.querySelectorAll('.draggable').forEach((element:any) => {
     /*   element.addEventListener('click', (event:any) => {

        });*/
      // });

  }


 /*  centerObjectToAnother(objToMove, objToAlignWith) {
    // Вычисляем центр второго объекта
    const centerX = objToAlignWith.x + objToAlignWith.width / 2;
    const centerY = objToAlignWith.y + objToAlignWith.height / 2;

    // Вычисляем новые координаты для первого объекта
    const newX = centerX - objToMove.width / 2;
    const newY = centerY - objToMove.height / 2;

    // Возвращаем обновлённые координаты для первого объекта
    return { x: newX, y: newY };
  }*/
   updateTransform(stage, flagZoom,) {
     let scaleElement = this.scaleElement.nativeElement
    const scaleValue = Math.max(1, Math.min(stage.scale, 5)); // Пример ограничения масштаба между 1 и 5
    let translateX = stage.x;
    let translateY = stage.y;

    // Если флаг zoom активен, то используем заранее заданные значения


    if (flagZoom) {
      translateX = -1000;
      translateY = -1000;
      this.expand = true;
      scaleElement.style.transform = `translate(${translateX}px, ${translateY}px) scale(0.2)`;
    } else {
      this.expand =  false;
      scaleElement.style.transform = `translate(${translateX}px, ${translateY}px) scale(${scaleValue})`;
    }

    // Обновляем масштаб в атрибутах элемента
    scaleElement.setAttribute('data-scale', scaleValue);
    // Сохраняем текущие значения трансформации
    scaleElement.setAttribute('data-x', translateX);
    scaleElement.setAttribute('data-y', translateY);
     this.interactRaw();
  }
  checkExistArchive(){
     return this.stage.images.some(item => item.sw === false);
  }

  filterColorsWithCount() {
    const colorCounts = this.stage.images.reduce((acc, item) => {
      // Если цвет уже есть в аккумуляторе, увеличиваем его счетчик
      if (acc[item.color]) {
        acc[item.color].count += 1;
      } else {
        // Если цвет встречается впервые, инициализируем счетчик этого цвета
        acc[item.color] = {color: item.color, count: 1};
      }
      return acc;
    }, {});

    // Преобразуем объект colorCounts обратно в массив для возможного удобства последующей работы
    const uniqueColorsWithCounts = Object.values(colorCounts);
    return uniqueColorsWithCounts;
  }
  filterColors(){
    const uniqueColors:any = this.stage.images
      .map(item => item.color)
      .filter((value, index, self) => self.indexOf(value) === index);
    return uniqueColors;
  }
  countArchived(){
  return   this.stage.images.reduce((accumulator, current) => {
      return current.sw === false ? accumulator + 1 : accumulator;
    }, 0);
  }
  ngAfterViewInit(): void {
     // this.renderContainer();
    this.interactRaw();
  /*  resizeWindow((width:number,height:number)=>{
      this.width  = width ; this.height  = height}
    )
    this.initializeInteract();*/
  }

  resizeWindow(){
    window.addEventListener('resize', function(event) {
    });
  }
  initializeInteract(): void {
      interact('.container').gesturable({
      onmove: (event) => {
        const scale = 1 * event.ds;
        const el = event.target;
        const x = (parseFloat(el.getAttribute('data-x')) || 0) + event.dx;
        const y = (parseFloat(el.getAttribute('data-y')) || 0) + event.dy;
        // scale(' + scale/10 + ')'
        el.style.webkitTransform =
          el.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
        el.setAttribute('data-x', x);
        el.setAttribute('data-y', y);
        if(this.stage){
          this.stage.x = x;
          this.stage.y = y;
        }
        this.onChangeContainer.emit(this.stage)

      }
    });

    interact('.draggable').draggable({
      modifiers: [
        this.showGrid?interact.modifiers.snap({
          targets: [
            interact.createSnapGrid({ x: 25, y: 25 }) // Здесь 25 - это расстояние между линиями сетки
          ],
          range: Infinity,
          relativePoints: [{ x: 25, y: 25 }]
        }):null,
        // interact.modifiers.restrict({
        //   restriction: 'parent',
        //   elementRect: { top: 0, left: 0, bottom: 0, right: 0 },
        //   endOnly: true
        // })
      ],
      inertia: true,
      onmove: (event) => {
        if(true) {
          const target = event.target;
          let x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
          let y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
          x = x + event.dx / 2;
          y = y + event.dy / 2;
          target.style.webkitTransform =
            target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
          if(this.elementSelect){
            this.elementSelect.x = x;
            this.elementSelect.y = y;
          }
          this.onChangeImages.emit(this.stage)
        }
      },
      ignoreFrom: '.container'
    });
  }

  protected readonly windowHeight = windowHeight;
  protected readonly windowWidth = windowWidth;
  protected readonly dotesVerticalIcon = dotesVerticalIcon;
  protected readonly deleteIcon = deleteIcon;
  protected readonly lockCloseIcon = lockCloseIcon;
  protected readonly lockOpenIcon = lockOpenIcon;
   public showColorsLib: boolean =false;
  protected readonly deleteFromArray = deleteFromArray;
  updateStatusSave(){
    this.saveStatus = true
    reInit(()=>{
      this.saveStatus =false
    },1500)
  }
 public save() {
   this.reInitStageCropper()
    this.onChangeContainer.emit(this.stage);
    this.onChangeImages.emit(this.stage);
    this.updateStatusSave()
  }
  click(elementItem: any) {
    this.elementSelect = elementItem;
    this.save();
  }
  protected readonly toArchive = toArchive;
  protected readonly archiveIcon = archiveIcon;
  protected readonly plusIcon = plusIcon;
  protected readonly minusIcon = minusIcon;
  protected readonly imagePanoramaIcon = imagePanoramaIcon;
  protected readonly saveIcon = saveIcon;
  protected readonly cropIcon = cropIcon;
  stageCropImage: string;
  protected readonly addIcon = addIcon;
  protected readonly addCloneModel = addCloneModel;
  protected readonly listModel = listModel;
  protected readonly moveIcon = moveIcon;
  protected readonly closeIcon = closeIcon;
  protected readonly editIcon = editIcon;
  protected readonly zoomPlusIcon = zoomPlusIcon;
  protected readonly zoomMinusIcon = zoomMinusIcon;
  protected readonly filterIcon = filterIcon;
  protected readonly favoriteIcon = favoriteIcon;
  protected readonly exportStage = exportStage;
  showSelectTool: boolean =false;

  public setColorFilter(color: string) {
    console.log('fdfsfds','color')
    console.log(color,'color')
    this.filterByColor = color; this.save();
  }
}
