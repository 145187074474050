

  <div *ngIf="!showArrayJson" class=" relative mt-3">
    <span class="absolute -top-[10px] left-0 bg-amber-700 text-white px-1  rounded-md text-[10px] ">
      {{ label }}
    </span>
    <div
      [style.width.px]="windowWidth()" class="flex overflow-x-auto space-x-4">
      <ng-container *ngFor=" let value of array">
        <p (click)="click(value)"
                [style.font-size.px]="textSize"
                [ngClass]="select === value?'bg-amber-600':'bg-zinc-600'"
                class="text-white block leading-4 rounded-sm min-w-[40px]  w-[30px] h-[30px] p-1  ">
          {{ value }} </p>
      </ng-container>
    </div>
  </div>

  <div *ngIf="showArrayJson"  class=" relative mt-3">
    <span class="absolute -top-[10px] left-0 bg-amber-700 text-white px-1  rounded-md text-[10px] ">
      {{ label }}
    </span>
    <div
      [style.width.px]="windowWidth()" class="flex overflow-x-auto space-x-4">
      <p-overlayPanel #opStylePreview>
        <img *ngIf="select" [src]="select.srcPreview"
             class="max-w-[200px] max-h-[400px]" >
      </p-overlayPanel>
      <ng-container *ngFor=" let item of array">

        <div #target (click)="click(item);"  class=" relative w-[75px]"   >
          <button  *ngIf="showPopover"
                   (click)=" this.select = item; showPopover?opStylePreview.toggle($event,target):null"
                   class="z-20  text-sm absolute top-0 left-0 bg-zinc-500/20 text-white p-1 rounded-full  h-auto  transition-transform transform hover:scale-125"
          >
           <i [class]="eyeOpenIcon"></i>
          </button>
        <img class="w-[75px] h-[75px]" *ngIf="item.srcPreview && showImages"
             [src]="item.srcPreview" alt="">

        <p [style.font-size.px]="textSize"
                [ngClass]="select === item?'bg-amber-600':'bg-zinc-600'"
                class=" text-white truncate  block leading-4 rounded-sm h-auto p-2 w-[75px]  ">
          {{ item.name }}
        </p>
        </div>
      </ng-container>
    </div>
  </div>




