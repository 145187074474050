import {
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import {TabViewModule} from 'primeng/tabview';
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {BadgeModule} from "primeng/badge";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {SliderModule} from "primeng/slider";
import { srcSketch } from 'src/app/traits/image-tools';
import { deleteFromArray } from 'src/app/traits/array-tools';
import { dateNow } from 'src/app/traits/datetime-tools';
import {addIcon, closeSquareIcon, deleteIcon, dotesHorizontalIcon, dotesVerticalIcon, editIcon,
  eyeOpenIcon,
  gridIcon, zoomPlusIcon } from 'src/app/traits/icons-tools';
import {PageImageGetColorServices} from "../../services/pages/page-image-get-color.services";
import { windowWidth } from 'src/app/traits/window-tools';
import { arrayStartEndStep } from 'src/app/traits/params-tools';
import {ZoomImageComponent} from "../../components/zoom-image/zoom-image.component";
import {UploaderPhotoComponent} from "../../components/admin/uploader-photo/uploader-photo.component";
import {BtnDownloadComponent} from "../../components/btn-download/btn-download.component";
import {EyeDropperComponent} from "../../components/eye-dropper/eye-dropper.component";
import {PreloadComponent} from "../../components/pages/preload/preload.component";
import {NavbarTopComponent} from "../../components/pages/navbar-top/navbar-top.component";
import {HeaderComponent} from "../../components/pages/header/header.component";
import {ColorHistoryComponent} from "../../components/color-history/color-history.component";
import {ColorsRectangaleComponent} from "../../components/colors-rectangale/colors-rectangale.component";
import {RectangleWithColorComponent} from "../../components/rectangle-with-color/rectangle-with-color.component";
import {CircleWithColorComponent} from "../../components/circle-with-color/circle-with-color.component";

@Component({
  selector: 'app-page-image-get-color',
  standalone: true,
  imports: [
    TabViewModule,
    NgIf,
    FormsModule,
    BadgeModule,
    NgForOf,
    ButtonModule,
    SliderModule,
    ZoomImageComponent,
    UploaderPhotoComponent,
    BtnDownloadComponent,
    EyeDropperComponent,
    PreloadComponent,
    NavbarTopComponent,
    HeaderComponent,
    ColorHistoryComponent,
    ColorsRectangaleComponent,
    RectangleWithColorComponent,
    CircleWithColorComponent,
  ],
  templateUrl: './page-image-get-color.component.html',
})

export class PageImageGetColorComponent {
  @ViewChild('roundPallete') roundPallete: ElementRef<HTMLCanvasElement>;
  @ViewChild('horizontalPallete') horizontalPallete: ElementRef<HTMLCanvasElement>;
  @ViewChild('verticalPallete') verticalPallete: ElementRef<HTMLCanvasElement>;
  @Input() public imageParent: string = null
  public nameHarmony: string = 'sketch';
  public showDownload: boolean = false;
  public showPreload: boolean = true;
  showBeforeAfterComponent: boolean = true;
  showSketchConverterComponent: boolean = true;
  showColorChangeComponent: boolean = true;
  public showCircleComponent: boolean = true;
  public showVerticalComponent: boolean = true;
  public showHorizontalComponent: boolean = true;
  public viewPallete: string = 'horizontal';

  showBeforeAfter: boolean = true;
  showSettings: boolean =false;
  public imagePallete: string =srcSketch;
  public imageColorChange: string;
  public imageUploaded: string = srcSketch;
  public tempImage: string = srcSketch;
  public imageCrop: string =srcSketch;
  public imageSketch: string =srcSketch;
  showBackground: boolean = true;
  showColorSidebar: boolean = true;
  invertAmount: number = 1;
  blurAmount: number = 1;
  grayscaleAmount: number = 1;
  selectColor: string;
  brightness:number = 100;
  contrast:number = 100;
  saturation:number = 100;
  opacityFilter: number = 100;
  blurFilter: number = 0;

  mode: object ={name:'normal',value:'color'};
  modeIndex: any =1;

  public modes:object[] = [
    {name:'normal',value:'color'},
    {name:'soft', value:'screen'},
    {name:'hard',value:'overlay'},
  ];
  public showCropRigionBtn: boolean = true;
  public showCropRigion: boolean = false;

  protected readonly deleteFromArray = deleteFromArray;
  protected readonly dateNow = dateNow;
  protected readonly deleteIcon = deleteIcon;
  protected readonly dotesVerticalIcon = dotesVerticalIcon;
  protected readonly dotesHorizontalIcon = dotesHorizontalIcon;
  protected readonly editIcon = editIcon;
  protected readonly closeSquareIcon = closeSquareIcon;
  protected readonly zoomPlusIcon = zoomPlusIcon;
  protected readonly addIcon = addIcon;
  protected readonly windowWidth = windowWidth;
  protected readonly gridIcon = gridIcon;
  protected readonly eyeOpenIcon = eyeOpenIcon;
  protected readonly arrayStartEndStep = arrayStartEndStep;
  constructor(public dataPage: PageImageGetColorServices) {
  }

  public reset() {
    this.blurAmount = 5;
    this.invertAmount = 1;
    this.grayscaleAmount = 1;
    this.showBackground = false;
    this.reInitColorChange()
  }
  resetCanvas(){
    this.brightness = 100;
    this.contrast = 100;
    this.saturation = 100;
    this.mode =   {name:'normal',value:'color'}
    this.reInitSketchConverter();
  }
  public showCropPanel() {
    this.showCropRigion = !this.showCropRigion;
  }
  public cropImage(base64: string) {
    this.imageUploaded = base64;
    this.reInitSketchConverter()
  }
  ngOnInit() {
  }
  ngAfterViewInit(): void {
    this.initBeforeAfter();

  }

        initBeforeAfter(){
      /*todo after 10.06*/
      }


  public setColor(colorHex: string) {
    this.selectColor = colorHex;

    console.log(this.selectColor)
  }
  public reInitBeforeAfter() {
    this.showBeforeAfter = false;
    setTimeout(() => {
      this.showBeforeAfter = true;
      // this.initBeforeAfter();
    }, 50)
  }
  public reInitColorChange() {
    this.showColorChangeComponent = false;
    setTimeout(() => {
      this.showColorChangeComponent = true;
    }, 50)
  }

  public reInitSketchConverter() {
    this.showSketchConverterComponent = false;
    setTimeout(() => {
      this.showSketchConverterComponent = true;
    }, 50)
  }

  selectUpload(base64: string) {
    this.imageUploaded = base64;
    this.imageCrop = base64;
    this.tempImage = base64;
    this.reInitSketchConverter()
  }
  public switchHorizontal() {
    this.viewSwitcher('horizontal')
  }
  public switchCircle() {
    this.viewSwitcher('circle')
  }
  public switchVertical() {
    this.viewSwitcher('vertical')
  }

  protected viewSwitcher(viewName: string = 'circle') {
    this.viewPallete = viewName;
    this.reInitAllPalletes()
  }
  protected reInitAllPalletes() {
    this.reInitCircle();
    this.reInitVertical();
    this.reInitHorizontal();
  }

  protected reInitCircle() {
    this.showCircleComponent = false;
    setTimeout(() => {
      this.showCircleComponent = true;
      // @ts-ignore
      // this.imagePallete = this.roundPallete.getImage();
    }, 50)
  }

  protected reInitHorizontal(component: boolean = false) {
    this.showHorizontalComponent = false;
    setTimeout(() => {
      this.showHorizontalComponent = true;
      // @ts-ignore
      // this.imagePallete = this.horizontalPallete.getImage();
    }, 50)
  }

  protected reInitVertical(component: boolean = false) {
    this.showVerticalComponent = false;
    setTimeout(() => {
      this.showVerticalComponent = true;
      // @ts-ignore
      // this.imagePallete = this.verticalPallete.getImage();
    }, 50)
  }


}
