
<div class="bg-zinc-500 p-[2px] rounded-lg shadow-lgm m-[2px] w-[170px]"
>
  <div
       class=" font-bold mb-1 border-amber-300 bg-zinc-500 text-zinc-300 ">
    <p-scrollPanel [style]="{height:'150px'}">
      <div class="grid grid-cols-5 gap-1 mt-2">

      <ng-container *ngFor="let  colorHex  of  colors">
        <div
          class=" flex  justify-between w-[25px] h-[25px] text-[10px] font-bold mb-1 p-[5px] text-zinc-300 border-amber-300 bg-zinc-800 truncate"
        >
         <span
               class="block w-[25px] h-[25px] "
               (click)="select(colorHex)"
               [style.background-color]="colorHex"
         >
         </span>
        </div>
      </ng-container>
  </div>

    </p-scrollPanel>

  </div>


</div>
