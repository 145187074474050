import {
  inject,
  Injectable
} from '@angular/core';
import {ServerSiteServices} from '../servers/server-site.services';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";
import {StaticRotateHeadServices} from "../static/static-rotate-head.services";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PagePosesNudeServices {
  public SERVER_NAME: string = 'poses-nude';
  public DOMAIN: string = 'art-club.artas.org.ua/poses-nude';
  public route: string = 'poses-nude';
  public fromServer: boolean = true;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'Poses nude',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'Poses nude',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Pose Library for Artists: Enhance Your Artistic Skills',
    description: 'Discover the ultimate pose library for artists designed to elevate your creative process. Access thousands of poses that cater to various artistic styles, helping you refine anatomy, posture, and composition in your artwork.',
    text: '### Pose Library for Artists: Elevate Your Artistic Practice\n' +
      '\n' +
      'In the world of art, especially in figure drawing and character design, having a reliable source of reference material is invaluable. A pose library for artists provides just that—a comprehensive collection of poses that can significantly enhance your artistic process, from sketching to the final touches of your work. Whether you\'re a beginner learning the basics of human anatomy or a seasoned artist refining your style, a pose library is a tool you should consider adding to your creative arsenal.\n' +
      '\n' +
      '#### The Importance of Pose Libraries in Artistic Development\n' +
      '\n' +
      'For centuries, artists have relied on live models to study the human form, capture its nuances, and translate those observations into their art. While working with live models remains a highly effective method of practice, it is not always feasible. This is where a pose library comes in. These digital collections offer a broad spectrum of poses, including various body types, angles, and expressions, that can be accessed anytime and anywhere. \n' +
      '\n' +
      'A pose library not only serves as a practical resource but also as a teaching tool. By studying different poses, artists can gain a deeper understanding of anatomy, perspective, and the subtleties of human movement. This, in turn, helps in creating more dynamic and realistic figures. \n' +
      '\n' +
      '#### Exploring the Diverse Range of Poses\n' +
      '\n' +
      'One of the most significant advantages of using a pose library is the variety it offers. These libraries often contain thousands of poses, categorized by factors such as gender, age, activity, and emotion. This extensive range allows artists to find the perfect reference for any scenario they wish to depict in their artwork. \n' +
      '\n' +
      'For example, an artist working on a fantasy illustration might need a reference for a character in an action pose, such as leaping or brandishing a weapon. A pose library can provide multiple options, allowing the artist to experiment with different angles and positions until they find the one that best fits their vision. Similarly, artists focused on more subtle, emotional portraits can find references that capture the complexity of human expression, from joy to sorrow.\n' +
      '\n' +
      'In addition to static poses, many libraries also offer sequences that depict movement, helping artists to understand the flow of motion and how to translate that into their work. This is particularly useful for animators and those working on comic book illustrations, where conveying movement and energy is crucial.\n' +
      '\n' +
      '#### Enhancing Your Artwork with Anatomy and Proportions\n' +
      '\n' +
      'One of the most challenging aspects of figure drawing is getting the anatomy and proportions right. Even a slight mistake in proportion can throw off the entire composition of a piece. Pose libraries can be a lifesaver in this regard. They provide a clear and accurate reference that artists can use to check their work against, ensuring that the anatomy is correct.\n' +
      '\n' +
      'By regularly using a pose library, artists can also train their eye to recognize proper proportions more intuitively. Over time, this practice helps in developing muscle memory, allowing artists to draw more accurately from imagination.\n' +
      '\n' +
      '#### Digital Art and Pose Libraries: A Perfect Match\n' +
      '\n' +
      'With the rise of digital art, the use of pose libraries has become even more widespread. Digital artists can easily integrate pose references into their workflow by importing images directly into their preferred drawing software. This allows them to trace or use the pose as a base, which can then be manipulated and customized to suit their needs.\n' +
      '\n' +
      'Moreover, many pose libraries offer 3D models that can be rotated and viewed from different angles, giving artists complete control over the perspective. This feature is particularly beneficial when working on complex compositions where multiple figures interact with each other.\n' +
      '\n' +
      '#### Tips for Maximizing the Use of a Pose Library\n' +
      '\n' +
      'While pose libraries are incredibly useful, it\'s important to use them effectively to get the most out of them. Here are some tips to consider:1. Study the Poses, Don’t Just Copy: Use the poses as a reference but try to understand the underlying anatomy and structure. This will help you improve your skills and enable you to create more original work.\n' +
      '   \n' +
      '2. Experiment with Different Poses: Don’t limit yourself to poses that are easy or comfortable. Challenge yourself with difficult angles and dynamic positions to broaden your artistic abilities.\n' +
      '\n' +
      '3. Combine Poses: Mix and match elements from different poses to create something unique. For example, you might take the arm position from one pose and combine it with the torso from another to create a new, dynamic composition.\n' +
      '\n' +
      '4. Use 3D Models When Available: If the library offers 3D models, take advantage of this feature. It allows you to explore the pose from various angles, giving you a better understanding of the form.\n' +
      '\n' +
      '5. Practice Regularly: The more you use a pose library, the more familiar you will become with human anatomy and movement. Regular practice will lead to noticeable improvements in your drawing skills.\n' +
      '\n' +
      '#### Conclusion: A Valuable Resource for Every Artist\n' +
      '\n' +
      'Incorporating a pose library into your artistic routine can be a game-changer. It provides a wealth of resources that can inspire creativity, enhance technical skills, and ultimately lead to better artwork. Whether you\'re sketching for fun, working on a professional project, or trying to improve your understanding of the human form, a pose library is an indispensable tool that every artist should have in their toolkit.',
    h1: 'Pose Library for Artists: Your Ultimate Reference Tool',
    h2: 'Why Every Artist Needs a Pose Library',
    h3: 'Diverse Range of Poses for Every Art Style',
    h4: 'How to Use a Pose Library to Improve Your Art',
    h5: 'Tips for Maximizing Your Pose Library Experience',
    textEndPage: 'Looking to improve your drawing skills? Our pose library offers a vast collection of reference poses for artists of all levels. Perfect your anatomy and composition with thousands of poses tailored for various artistic styles.',
    keywords: [
      'pose library for artists',
      'artist reference poses',
      'drawing poses',
      'anatomy for artists',
      'digital art poses',
      'figure drawing',
      'art reference library',
    ],
  };
  public site: any

  constructor(public server: ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  public getSite(callback: any = null) {
    if (this.fromServer) {
      return this.getSiteFromServer(callback);
    } else {
      return this.getSiteStatic();
    }

  }

  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }

  public saveSite(site: object) {
    if (this.fromServer) {

      this.site = site
      // this.downloadSiteBtn(this.site);
      // let server: any = inject(ServerSiteServices)
      this.server.saveSite(this.DOMAIN, this.site);
    }
  }

  getSiteStatic() {
    return this.site = inject(StaticRotateHeadServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer(callback: any = null) {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: any): void => {
      this.site = responseSite;
      callback && callback(responseSite)
    });
    return this.site;
  }

}
