import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {EditTextRowService} from '../../../services/common/edit-text-row.service';
import {
  NgFor,
  NgIf
} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {clearTextRow} from '../../../traits/string-tools';
import {ThemeServices} from '../../../services/common/theme.services';
import {ClipboardModule} from 'ngx-clipboard';
import {SimilarsCardsComponent} from '../similars-cards/similars-cards.component';
import {ColorsGridComponent} from '../colors-grid/colors-grid.component';
import {
  ConfirmationService,
  MessageService
} from 'primeng/api';
import {ConfirmComponent} from '../confirm/confirm.component';
import {
  addToStart,
  deleteFromArray
} from "../../../traits/array-tools";
import {CropRigionComponent} from "../../crop-rigion/crop-rigion.component";
import {SidebarComponent} from "../../common/sidebar/sidebar.component";
import {cropIcon} from "../../../traits/icons-tools";
import {
  cloneModel,
  modelNew
} from "../../../traits/model-tools";
import {CdServices} from "../../../services/common/cd.services";

@Component({
  selector: 'app-edit-text-row',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    ClipboardModule,
    SimilarsCardsComponent,
    ColorsGridComponent,
    ConfirmComponent,
    CropRigionComponent,
    SidebarComponent
  ],
  providers: [
    ConfirmationService,
    MessageService
  ],
  templateUrl: './edit-text-row.component.html',

})
export class EditTextRowComponent implements OnInit {
  @Input() nameRow: any = 'name';
  @Input() typeRow: any = 'text';
  @Input() model: any;
  @Input() models: any = [];
  @Input() minHeight: any = 170;
  @Input() minWidth: any = 170;
  @Input() lineHeight: any = 30;
  @Input() similar: any = [];
  @Input() collapseSimilar: any = false;
  @Input() showBottomMenu: any = false;
  @Input() showColorsLib: any = false;
  @Input() showText: any = true;
  @Input() showImage: any = false;
  @Output() onSetPreview = new EventEmitter();
  @Output() onFavorite = new EventEmitter();
  @Output() onSave = new EventEmitter();
  public theme: any = inject(ThemeServices);
  public saveChangeSite: boolean = false;
  public showCropRigion: boolean = false;
  protected readonly cropIcon = cropIcon;
  @Input()  public imageForColors: string;

  constructor(
    public cd:CdServices,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private textServices: EditTextRowService,) {

  }

  public ngOnInit() {
    // this.model.color = 'red'
  }

  public clearText() {
    clearTextRow(this.model, this.nameRow);
    this.saveSite();
  }
saveSite(){
    this.onSave.emit();
}
  public copyText(model: any, nameRow: any) {
    alert('copy text');
    this.saveSite();

  }

  public onSelectSimilar($event: any): void {
    this.model[this.nameRow] = $event;
    this.collapseSimilar = false;
    this.saveSite();
  }

  public onSelectColor(colorHex: string): void {
    this.model.color = colorHex; // hex;
    this.showColorsLib = false;
    this.saveSite();
  }

  public addCloneModel(model: any, models: any) {
    let modelClone = cloneModel(model, true);
    models.push(modelClone);
  }

  public addCropImage(base64: string, models: any[]) {
    let modelCrop: any = modelNew()
    modelCrop.srcPreview = base64;
    addToStart(modelCrop, models)
    this.saveSite();
  }

  public deleteModelFromServer(model: any, models: any) {
    deleteFromArray(model, models);
    this.textServices.deleteImageFromServer(model);
    /*   this.confirmationService.confirm({
         header: 'Confirmation',
         message: 'Please confirm to proceed moving forward.',
         acceptIcon: 'pi pi-check mr-2',
         acceptLabel: 'Save1',
         rejectLabel: 'Close2',
         rejectIcon: 'pi pi-times mr-2',
         rejectButtonStyleClass: 'p-button-sm',
         acceptButtonStyleClass: 'p-button-outlined p-button-sm',
         accept: () => {
           this.messageService.add({
             severity: 'info',
             summary: 'Confirmed',
             detail: 'You have accepted',
             life: 3000
           });
         },
         reject: () => {
           this.messageService.add({
             severity: 'error',
             summary: 'Rejected',
             detail: 'You have rejected',
             life: 3000
           });
         }
       });*/
  }

  public isBase64(model: any): boolean {
    return (this.typeRow == 'srcPreview' && this.textServices.isBase64(model));
  }

  public baseToSrc(model: any, size: string = 'large') {
    this.textServices.baseToSrc(model, size);
    this.saveSite();
  }

  public isText(model: any): boolean {
    return (!model.srcPreview && this.typeRow !== 'srcPreview');
  }

  public isNotText(model: any): boolean {
    return !this.isText(model);
  }

  public setFavoriteBtn(model: any) {
    this.onFavorite.emit(model);
    this.saveSite();
  }

  public setPreviewBtn(model: any) {
    this.onSetPreview.emit(model);
    this.saveSite()
  }

  isImage(model: any) {
    return  (
      this.typeRow == 'srcPreview' ||
      this.typeRow == 'srcGif' ||
      this.typeRow == 'srcIcon'
    )
  }
}
