<!--<app-browser-close-alert></app-browser-close-alert>-->

<app-preload *ngIf="showPreload"></app-preload>
<div style="height:25px; overflow: hidden"
     class="absolute top-0 seo-container text-center z-0">
  <h1>{{ dataPage.seoTexts.h1 }}</h1>
  <h2>{{ dataPage.seoTexts.h2 }}</h2>
  <h3>{{ dataPage.seoTexts.h3 }}</h3>
  <p>{{ dataPage.seoTexts.text }}</p>
  <p>{{ dataPage.seoTexts.textEndPage }}</p>
</div>

<app-navbar-top></app-navbar-top>
<app-header
  [showCounters]="false"
  [bgImage]="'assets/header-palettes-generator.png'"
  [headerText]="dataPage.seoTexts.h1"
  [subHeaderText]="dataPage.seoTexts.h1"
  [actionText]="dataPage.seoTexts.h3"
  [showProductLeft]="true"

></app-header>
<app-pallete-generator-image>

</app-pallete-generator-image>
