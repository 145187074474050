import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {JsonPipe, NgFor, NgIf} from '@angular/common';
import {SliderModule} from "primeng/slider";
import {
  InputTextModule
} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {
  closeSquareIcon,
  gridIcon,
  imagePanoramaIcon
} from "../../traits/icons-tools";
import {ToggleButtonModule} from "primeng/togglebutton";
@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  standalone:true,
  imports: [
    NgIf,
    NgFor,
    SliderModule,
    InputTextModule,
    FormsModule,
    ToggleButtonModule,
  ]

})
export class ImageEditorComponent implements AfterViewInit {
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  @Input() imageSrc: string;
  @Input()  showCanvasPreview:boolean = true;
  @Output() imageEdited = new EventEmitter<string>();

  brightness:number = 100;
  contrast:number = 100;
  saturation:number = 100;
  private ctx: CanvasRenderingContext2D;
  private image = new Image();

  ngAfterViewInit(): void {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.image.onload = () => {
      this.canvas.nativeElement.width = this.image.width;
      this.canvas.nativeElement.height = this.image.height;
      this.applyFilters();
    };
    this.image.src = this.imageSrc;
  }
  resetCanvas(){
    this.brightness = 100;
    this.contrast = 100;
    this.saturation = 100;
    this.applyFilters();
  }

  applyFilters(): void {
    this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.ctx.filter = `brightness(${this.brightness}%) contrast(${this.contrast}%) saturate(${this.saturation}%)`;
    this.ctx.drawImage(this.image, 0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.imageEdited.emit(this.canvas.nativeElement.toDataURL());
  }
  protected readonly imagePanoramaIcon = imagePanoramaIcon;
  protected readonly gridIcon = gridIcon;
  protected readonly closeSquareIcon = closeSquareIcon;
}
