<div id="list-feed"
     [class]="bgColor"
     [style.background-image]="'url(' + bgImage + ')'"
class="bg-[length:50px_50px] bg-opacity-10  bg-repeat min-h-screen flex justify-center items-center">
  <div class="md:px-1 md:grid md:grid-cols-2 lg:grid-cols-3 gap-2 space-y-4 md:space-y-0">
    <ng-container
      *ngFor="let  cardItem  of  cards; let last = last;">
      <ng-container *ngIf="cardItem.sw == true || last === true ">
          <app-edit-card-view
            [card]="cardItem"
            (onEdit)="onSelect.emit(cardItem)"
          ></app-edit-card-view>
      </ng-container>
    </ng-container>
  </div>
</div>
