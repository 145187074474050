import {Component} from '@angular/core';
import {CommonHomeComponent} from "../../common-home/common-home.component";
import {HeaderComponent} from "../../../components/pages/header/header.component";
import {NavbarTopComponent} from "../../../components/pages/navbar-top/navbar-top.component";
import {PageCoursesServices} from "../../../services/pages/page-courses.services";
import {CourseVideoViewerComponent} from "../../../components/courses/course-video-viewer/course-video-viewer.component";
import {SidebarComponent} from "../../../components/common/sidebar/sidebar.component";
import {
  NgFor,
  NgIf
} from "@angular/common";
import {
  ActivatedRoute,
  Router
} from "@angular/router";

@Component({
  selector: 'app-page-courses',
  standalone: true,
  providers: [],
  imports: [
    NgIf,
    NgFor,
    CommonHomeComponent,
    HeaderComponent,
    NavbarTopComponent,
    CourseVideoViewerComponent,
    SidebarComponent,
  ],
  templateUrl: './page-courses.component.html',
})
export class PageCoursesComponent {
  public site: any;
  public showCourseViewer: any  =false;
  public selectCourse: any;

  constructor(public dataPage: PageCoursesServices,
              private router: Router,
              private route: ActivatedRoute,
              ) {
  }

  public setSelectCourse(course: any) {
    console.log(course,'course')
    this.selectCourse = course;
    this.showCourseViewer = true;
  }

  public isViewCourse() {
    // return (this.showCourseViewer && );
  }
  setSelectRoute(card: any) {
    this.router.navigate(['digital-art-courses/',card.id]);

  }

  closeCourse() {
    this.showCourseViewer = false;
    this.selectCourse = null;
  }
}
