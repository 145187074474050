import {Injectable} from '@angular/core';

import {ApiServices} from '../api/api.services';
import {checkSrc, switchSizeParams} from '../../traits/image-tools';
@Injectable({
  providedIn: 'root',
})


export class ServerImageServices {
  constructor(
    public api: ApiServices,
  ) {
}


  public checkSrc(src: any) {
    return checkSrc(src);
  }
  public deleteSrc(model: any,
                   ext: any = 'jpg', callback: any = null,) {

    this.api.request(`image/delete`, {
      fileName: model.id + '_' + model.createAt + '_.' + ext,
    })
      .subscribe((image: any) => {
        callback && callback(model);
      });

  }

  public toImage(model: any, sizeMode: any = 'middle', mimeType: any = 'image/png', ext: any = '.jpg', callback: any = null,) {

    this.api.request(`image/save-jpg`, {
      model: model,
      sizeParams: switchSizeParams(sizeMode, mimeType),
      sizeMode: sizeMode
    })
      .subscribe((image: any) => {
        model.srcPreview = image.srcPreview;
      });
  }

  public toImageJpg(model: any,
                    sizeMode: any = 'middle', callback: any = null,) {

    console.log(model, 'model');

    this.api.request(`image/save-jpg`, {
      model: model,
      sizeParams: switchSizeParams(sizeMode, 'image/jpeg', '.jpg'),
      sizeMode: sizeMode
    })
      .subscribe((image: any) => {
        model.srcPreview = image.srcPreview;
        callback && callback(model);
      });

  }
  public toImagePng(model: any, sizeMode: any = 'middle', mimeType: any = 'image/png', ext: any = '.jpg', callback: any = null,) {

    this.api.request(`image/save-png`, {
      model: model,
      sizeParams: switchSizeParams(sizeMode, mimeType),
      sizeMode: sizeMode
    })
      .subscribe((image: any) => {
        model.srcPreview = image.srcPreview;
      });
  }

  public toImageWebP(model: any, sizeMode: any = 'middle', mimeType: any = 'image/png', ext: any = '.jpg', callback: any = null,) {
    this.api.request(`image/save-webp`,
      {
        model: model,
        sizeParams: switchSizeParams(sizeMode, mimeType),
        sizeMode: sizeMode
      })
      .subscribe((image: any) => {
        model.srcPreview = image.srcPreview;
      });
  }

}
