import {
  Component, ElementRef, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild
} from '@angular/core';
import {ConfirmationService} from 'primeng/api';

import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from 'primeng/toast';
@Component({
  selector: 'app-confirm',
  standalone:true,
  imports: [
    ConfirmDialogModule,
    ToastModule,
  ],
  templateUrl: './confirm.component.html',
})
export class ConfirmComponent implements OnInit {
  @Output() onClick = new EventEmitter();
  constructor() {

  }
  public ngOnInit() {
    console.log('init confirm');
  }


}
