<div
  class="z-30 relative bg-zinc-900 mt-[50px]  "
>

  <nav
    class=" animate-fade-right animate-once animate-ease-in-out z-30 flex shrink-0 grow-0 justify-around gap-1 border-t border-gray-200 bg-zinc-900/50 p-1 backdrop-blur-lg  fixed top-[50px] -translate-y-2 -right-[20px] min-h-[auto] min-w-[64px] flex-col rounded-lg shadow-lg  border"
  >

    <a
      (click)="showList =!showList "
      class="flex aspect-square min-h-[32px] w-[50px] flex-col items-center justify-center gap-1 rounded-md p-1  text-amber-600"
    >
      <img *ngIf="!showList"
        [src]="'assets/icons/9195928.png'"
        [alt]="'show cards list'"
        class="h-[50px] w-[50px] shadow-lg  border border-zinc-700   "
      >
      <img *ngIf="showList"
        [src]="'assets/icons/close-icon.png'"
        [alt]="'show cards list'"
        class="h-[50px] w-[50px] shadow-lg  border border-zinc-700   "
      >

    </a>
    <ng-container *ngIf="showList">
    <p-scrollPanel [style]="{width:100 +'px',height:windowHeight()-100+'px'}">
      <ng-container *ngFor="let  itemCard  of cards; let first = first">

        <a
          (click)="onSelectCard.emit(itemCard)"
          [ngClass]="itemCard === selectCard ?'bg-amber-400':'bg-zinc-900'"
           class="flex aspect-square min-h-[32px] w-[100px] flex-col items-center justify-center gap-1 rounded-md p-1  text-amber-600"
        >
          <img
            [src]="itemCard.srcPreview"
            [alt]="itemCard.name"
            class="h-[100px] w-[100px] shadow-lg  border border-zinc-700   "
          >
          <!-- <small class=" truncate text-center text-xs font-bold w-[40px]">
               <span>
                 {{ itemCard.name }}
               </span>
           </small>-->
        </a>
      </ng-container>
    </p-scrollPanel>
    </ng-container>
  </nav>
</div>
