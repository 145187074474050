import {Injectable} from '@angular/core';
import {Color} from '../interfaces/Color';
@Injectable({
  providedIn: 'root',
})


export class ColorsLibsServices {
  public colorsFavorites:string[] = [
   '#4b4a4a',
   '#000000',
   '#802f2f',
   '#808000',
   '#2e8e2e',
   '#2e9a9a',
   '#222282',
    '#872c87',
  ];
  public colorsMiddleTailWind: string[] = [
    '#64748b',
    '#6b7280',
    '#71717a',
    '#737373',
    '#78716c',
    '#ef4444',
    '#f97316',
    '#f59e0b',
    '#eab308',
    '#84cc16',
    '#22c55e',
    '#10b981',
    '#14b8a6',
    '#06b6d4',
    '#0ea5e9',
    '#3b82f6',
    '#6366f1',
    '#8b5cf6',
    '#a855f7',
    '#d946ef',
    '#ec4899',
    '#f43f5e',
  ];
  public colorsPastel: string[] = [
    '#F7F6CF',
    '#B6D8F2',
    '#F4CFDF',
    '#5784BA',
    '#9AC8EB',
    '#CCD4BF',
    '#E7CBA9',
    '#EEBAB2',
    '#F5F3E7',
    '#F5E2E4',
    '#F5BFD2',
    '#E5DB9C',
    '#D0BCAC',
    '#BEB4C5',
    '#E6A57E',
    '#218B82',
    '#9AD9DB',
    '#E5DBD9',
    '#98D4BB',
    '#EB96AA',
    '#C6C9D0',
    '#C54B6C',
    '#E5B3BB',
    '#C47482',
    '#D5E4C3',
    '#F9968B',
    '#F27348',
    '#26474E',
    '#76CDCD',
    '#2CCED2',
    '#B8E0F6',
    '#A4CCE3',
    '#37667E',
    '#DEC4D6',
    '#7B92AA',
    '#DDF2F4',
    '#84A6D6',
    '#4382BB',
    '#E4CEE0',
    '#A15D98',
    '#DC828F',
    '#F7CE76',
    '#E8D6CF',
    '#8C7386',
    '#9C9359',
    '#F4C815',
    '#F9CAD7',
    '#A57283',
    '#C1D5DE',
    '#DEEDE6',
    '#E9BBB5',
    '#E7CBA9',
    '#AAD9CD',
    '#E8D595',
    '#8DA47E',
    '#CAE7E3',
    '#B2B2B2',
    '#EEB8C5',
    '#DCDBD9',
    '#FEC7BC',
    '#FBECDB',
    '#F3CBBD',
    '#90CDC3',
    '#AF8C72',
    '#938F43',
    '#B8A390',
    '#E6D1D2',
    '#DAD5D6',
    '#B2B5B9',
    '#8FA2A6',
    '#8EA4C8',
    '#C3B8AA',
    '#DEDCE4',
    '#DB93A5',
    '#C7CDC5',
    '#698396',
    '#A9C8C0',
    '#DBBC8E',
    '#AE8A8C',
    '#7C98AB',
    '#C2D9E1',
    '#D29F8C',
    '#D9D3D2',
    '#81B1CC',
    '#FFD9CF',
    '#C6AC85',
    '#E2E5CB',
    '#D9C2BD',
    '#A2C4C6',
    '#82B2B8',
    '#874741',
    '#CA9C95',
    '#40393E',
    '#E5E4E5',
    '#897C87',
    '#46302B',
    '#76504E',
    '#D3CCCA',
    '#A37E7E',
    '#86736C',
  ];
  public colorsSketch: string[] = [
   '#000000',
   '#959595',
   '#7b4343',
   '#498d49',
   '#379a9a',
  ];
  public colorsAll(){
  return   this.colorsFavorites
      .concat(this.colorsMiddleTailWind)
      .concat(this.colorsPastel)
      .concat(this.colorsSketch)
  }
}
