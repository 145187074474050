<div style="height:25px; overflow: hidden"
     class="absolute top-0 seo-container text-center z-0">
  <h1>{{ dataPage.seoTexts.h1 }}</h1>
  <h2>{{ dataPage.seoTexts.h2 }}</h2>
  <h3>{{ dataPage.seoTexts.h3 }}</h3>
  <p>{{ dataPage.seoTexts.text }}</p>
  <p>{{ dataPage.seoTexts.textEndPage }}</p>
</div>
<!--<app-preload *ngIf="showPreload"></app-preload>-->
<app-navbar-top></app-navbar-top>

<app-header
  [autoHide]="true"
  [bgImage]="'assets/header-palettes-generator.png'"
  [headerText]="dataPage.seoTexts.h1"
  [subHeaderText]="dataPage.seoTexts.h1"
  [actionText]="dataPage.seoTexts.h3"
  [showProductLeft]="true"
></app-header>


<div class="mt-2">
  <div
    [class]="'bgColor'"
    [style.background-image]="'url(' + srcLogo + ')'"
    class=" bg-[length:50px_50px] bg-opacity-10  bg-repeat flex justify-center items-center flex-col bg-gradient-to-br from-gray-800 to-gray-900 relative animate-gradient-x">

    <div class="flex space-x-4">
      <div class=" w-[300px] ">
        <div   class="z-20 fixed bottom-4 right-4">

          <div class="  justify-center grid grid-cols-1 md:grid-cols-1 gap-4">
            <button (click)="showStagesList = true"
                    class=" z-20 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
              <i [class]="listIcon"></i>
            </button>
          <button #targetEl (click)="opPlusList.toggle($event, targetEl)"
            class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
            </svg>
            <span class=" rounded-lg absolute -top-2 -right-2 bg-zinc-700 w-[20px]  h-[20px]" *ngIf="selectStage">
              {{ selectStage.images.length }}
            </span>
          </button>
          </div>
        </div>
        <p-overlayPanel #opPlusList>
          <div class=" grid grid-cols-3 md:grid-cols-3 gap-4">
            <ng-container *ngFor="let elementType of elementsTypes">
              <button (click)="addTextToBoard(elementType)"
                      class=" z-20 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
                <span class="mx-1">{{ elementType.name }}</span>
                <i [class]="elementType.icon"></i>
              </button>
            </ng-container>
            <button (click)="showToolsList =true;"
                    class=" z-20 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
              <span class="mx-1">Tool</span>
              <i [class]="toolsIcon"></i>
            </button>
          </div>

          <div class="flex flex-col space-y-1 mt-1">
            <app-uploader-photo
              [showLabel]="true"
              [showListHorisont]="true"
              [showListModal]="false"
              [showUpload]="true"
              [showCounter]="true"
              (onClickItem)="addImageToBoard($event)"
            ></app-uploader-photo>
          </div>
        </p-overlayPanel>

      </div>
    </div>

    <div class="m-3 p-3">
      <div class="w-full  p-3 rounded-lg shadow-lg">
        <app-infinite-board
          *ngIf="showBoardComponent && selectStage"
          [stage]="selectStage"
          [lockAll]="lockAll"
          [showGrid]="showGrid"
          (onChangeContainer)=" this.selectStage = $event;this.save()"
          (onChangeImages)=" this.selectStage = $event;this.save()"
          (onAddImage)="addImageToBoard($event);this.save()"
          #infiniteBoard
        ></app-infinite-board>
       <!-- [filterByColor]="filterByColor"-->
      </div>
    </div>

  </div>
</div>
<app-sidebar [show]="showStagesList"
             (onSave)="showStagesList =false;"
             [showClose]="false"
             [modal]="false"
             [height]="100"
             [width]="100"
             [ext]="'%'"
             [zIndex]="10000"
             [position]="'left'"
>

  <p-overlayPanel #opPlusGreateImport>
    <div class=" justify-center grid grid-cols-1 md:grid-cols-1 gap-1 w-[120px]">

    <button
      class=" w-[120px] bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
      (click)="addStage()"
    >
      Create
      <i [class]="plusIcon"></i>
    </button>
    <label class=" w-[120px] bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer">
      Import
      <i class="fa-solid fa-file-import"></i>
      <!--<input type="file" id="fileInput" accept=".txt" class="hidden"
             (change)="importTextFile($event)" />
      -->
      <input type="file" id="fileInput" accept=".bin" class="hidden"
             (change)="importBin($event)" />
    </label>
    </div>
  </p-overlayPanel>
  <p-overlayPanel #opSettings>
    <div *ngIf="selectStage" class=" justify-center grid grid-cols-1 md:grid-cols-1 gap-4">

    <!--<button   (click)="deleteStage(selectStage); "
              class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
      <span class="mx-1">Delete</span>
      <i [class]="deleteIcon" class="text-gray-300 hover:text-white"></i>
    </button>-->
      <button (click)="selectStage.sw =!selectStage.sw ; save(); opSettings.hide()"
              class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
        <span *ngIf="selectStage.sw === true" class="mx-1">To Archive</span>
        <span *ngIf="selectStage.sw === false" class="mx-1">From Archive</span>
        <i [class]="archiveIcon"></i>
      </button>
    <button   (click)="exportStage(selectStage); "
              class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
      <span class="mx-1">Export</span>
      <i  class="fa-solid fa-file-export"></i>
    </button>
    </div>
  </p-overlayPanel>
  <div class="z-20 fixed bottom-4 right-4">
    <div class="  justify-center grid grid-cols-1 md:grid-cols-1 gap-4">
   <!-- <button (click)="showStagesList = true"
            class=" z-20 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
     <i [class]="listIcon"></i>
    </button>-->
    <button #targetEl (click)="opPlusGreateImport.toggle($event, targetEl)"
            class=" z-20 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
      </svg>
    </button>
    </div>
  </div>

<p-scrollPanel *ngIf="(showStagesList && stagesList)"
               [style]="{height:windowHeight()-100+'px'}">
  <app-navbar-top></app-navbar-top>

  <div class="flex justify-center">

    <div class="grid grid-cols-2 md:grid-cols-5 gap-4">
      <ng-container *ngFor="let  stageItem  of stagesList; let first = first">
        <ng-container *ngIf="stageItem?.sw === true">
        <div
          class="bg-zinc-700  relative  inline-block  ">

          <textarea
            style="padding: 2px!important;font-size: 16px !important; "
            pInputTextarea
            [placeholder]="'Name Stage'"
            [(ngModel)]="stageItem.name"
            (ngModelChange)="save()"
            [style.min-height.px]="25"
            [style.height.px]="25"
            [style.min-width.px]="75"
          ></textarea>
          <img

               [src]="stageItem.srcPreview" alt="upload_image"
               class="w-[200px] h-[200px] object-cover  cursor-pointer"
          >
          <button  #targetEl
            class="text-sm absolute top-0 -right-2 bg-red-500 text-white p-1 w-16 rounded-full transition-transform transform hover:scale-125"
            (click)="selectStage =stageItem; opSettings.toggle($event, targetEl)">
            <i [class]="dotesVerticalIcon"></i>
          </button>
          <button *ngIf="(first || cd.logined)"
            class="w-full bg-gradient-to-r rounded-sm from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-2  shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
            (click)="setStage(stageItem)"
          >
            <span class="mx-1">Go</span>
            <span class="bg-zinc-700 w-[15px] px-1 h-[15px]" *ngIf="stageItem.images">
              {{ stageItem.images.length }} - elements
            </span>
          </button>
            <button *ngIf="(!first && !cd.logined)"
                    class="w-full bg-gradient-to-r rounded-sm from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-2  shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
                    (click)="cd.loginForm = true;"
            >
              Login
            </button>
        </div>
      </ng-container>
      </ng-container>
    </div>
  </div>
</p-scrollPanel>
</app-sidebar>

<app-sidebar [show]="showToolsList"
             (onSave)="showToolsList =false;"
             [showClose]="true"
             [modal]="false"
             [height]="100"
             [width]="100"
             [ext]="'%'"
             [zIndex]="10000"
             [position]="'left'"
>
  <div class="mt-2" *ngIf="toolsList">
    <div
      [class]="'bgColor'"
      [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
      class=" bg-[length:50px_50px] bg-opacity-10  bg-repeat flex justify-center items-center flex-col bg-gradient-to-br from-gray-800 to-gray-900 relative animate-gradient-x">
      <div class="flex space-x-4">
      </div>
      <div class="m-3 p-3">
        <div class="w-full  p-3 rounded-lg shadow-lg">
          <p-scrollPanel [style]="{height:600}">
            <div class="flex justify-center">
              <div class="grid grid-cols-2 md:grid-cols-2 gap-4">
                <ng-container *ngFor="let  itemTool  of toolsList; let first = first">
                  <div class="text-center relative  inline-block transition-transform transform hover:scale-110">
                    <img [src]="itemTool.srcPreview" alt="upload_image"
                         class="p-3 m-1 w-32 h-32 object-cover rounded cursor-pointer"

                    >
                    <div class="flex flex-row gap-1 w-[150px] ">
                      <button
                              class="w-full bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
                              (click)="setTool(itemTool)"
                      >
                        Select tool
                      </button>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </p-scrollPanel>
        </div>
      </div>
      <div class="mt-1 mb-1">
      </div>
    </div>
  </div>


</app-sidebar>
