

  <div class="flex overflow-x-auto py-4 space-x-4">
    <ng-container *ngIf="showEdit">
    <button *ngIf="showCropRigionBtn" #targetEl
            class="bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
            (click)="showCropRigion =!showCropRigion; opCrop.toggle($event, targetEl)"
    >
      Crop
      <i *ngIf="!showCropRigion" [class]="editIcon"></i>
      <i *ngIf="showCropRigion" [class]="closeSquareIcon"></i>
    </button>
      <button #targetEl
              (click)="showSettings = !showSettings;;
                    opPalleteSettings.toggle($event, targetEl)"
              class="bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
      >
        <i class="fa-solid fa-sliders"></i>
      </button>

    </ng-container>
    <button
            class="bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
            (click)="showGrayscale =!showGrayscale;"
    >
      GS
      <i *ngIf="!showGrayscale" [class]="editIcon"></i>
      <i *ngIf="showGrayscale" [class]="closeSquareIcon"></i>
    </button>

    <!--<button
            class="bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
            (click)="enableSketch()"
    >
      <i [class]="brushIcon"></i>
    </button>-->
 <!--   <button
            class="bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
            (click)="enableImagePostoraze()"
    >
      <i [class]="brushIcon"></i>
    </button>-->

    <button #targetEl
            (click)=" opImages.toggle($event, targetEl)"
            class="bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
    >
      <i [class]="imagesIcon"></i>
    </button>

  </div>


  <p-overlayPanel #opCrop>
    <ng-container *ngIf="showEdit">

    <div *ngIf="srcImage"
         class=" w-[300px] ">
      <app-crop-rigion
        [width]="300"
        [height]=300
        [cropZoneHeight]=150
        [cropZoneWidth]=150
        [cropZoneX]="100"
        [cropZoneY]="100"
        [showReset]="true"
        [showPressetsCrop]="false"
        [showZoom]="true"
        [showFlip]="true"
        [showRotate]="false"
        [showAspectRatio]="true"
        [showDownload]="false"
        [showAdd]="true"
        [src]="srcImage"
        (onCrop)=" imageCrop = $event;   "
        (onAdd)=" onCrop.emit($event)   "
      ></app-crop-rigion>
    </div>
    </ng-container>
  </p-overlayPanel>
  <p-overlayPanel #opPalleteSettings>
    <ng-container *ngIf="showEdit">

    <app-image-editor
      [showCanvasPreview]="false"
      [imageSrc]="srcImage"
      (imageEdited)="imageCrop = $event;  reInitSketch()"
    >
    </app-image-editor>
    </ng-container>
  </p-overlayPanel>
<p-overlayPanel #opImages>
  <div *ngIf="(images && showListHorisont) "
       [style.width.px]="windowWidth()"
       class="flex overflow-x-auto py-4 space-x-4">
    <ng-container *ngFor="let  imageItem  of images;">
      <img
           [src]="imageItem.srcPreview"
           alt="upload_image"
           class="rounded-md w-[75px] h-[75px] shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse "
           (click)="this.srcImage = imageItem.srcPreview; this.imageCrop = imageItem.srcPreview "
      >
    </ng-container>
  </div>
  </p-overlayPanel>

<app-grayscale
  *ngIf="showGrayscale"
  [imageSrc]="imageCrop"
  [isBlackAndWhite]="showGrayscale"
  [showResult]="false"
  (imageConverted)="imageCrop = $event;"
>
</app-grayscale>

 <!-- <app-sketch-converter
    *ngIf="showSketch "

    [showBackground]="false"
    [imageSrc]="srcImage"
    [imageSrcTemp]="srcImage"
    [showResult]="false"
    [isBlackAndWhite]="true"
    (sketchGenerated)="imageCrop =$event">
  </app-sketch-converter>-->

<!--  <app-image-tracing
    *ngIf="showImagePostoraze "
    [imageSrc]="srcImage"
    [imageSrcTemp]="srcImage"
    [showResult]="false"
    [isBlackAndWhite]="true"
    (onTraceOriginal)="imageCrop =$event">
  </app-image-tracing>-->
<!--  <app-croppie *ngIf="showCropRigion"
               [imageSrc]="imageCrop"
  >
  </app-croppie>-->

  <app-zoom-image *ngIf="showZoom" >
      <img class="w-auto h-auto"
           [src]="imageCrop"
           alt="ref image"
      >
  </app-zoom-image>
<!--  <app-btn-download
        [srcImage]="imageCrop"
        [disabled]="false"
      ></app-btn-download>-->

<!--
</app-sidebar>
-->
