import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {ScrollPanelModule} from "primeng/scrollpanel";
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {OrderFormComponent} from "../order-form/order-form.component";
import {MyDataServices} from "../../../../services/my-data.services";
@Component({
  selector: 'app-action',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
  ],
  templateUrl: './action.component.html',
})
export class ActionComponent {
  @Input() textAction: string;
  @Input() card: object;
  @Input() date: string = '15.09';
  // @Input() date: string = 'до 05.08';
  @Input() showAction: boolean =true;
  @Input()  discount: number =30;


  constructor(public myData:MyDataServices,) {
  }
  ngOnInit() {
    this.textAction = this.getText();
  }
  ngAfterViewInit(){
  }
  getDiscount (cost,discount:any = 20) {
    cost = cost.replace(/\D/g,'')
    return   cost - (cost * discount / 100);
  }
getText(){
    return `Акція (знижка ${this.discount}%)`
}





}
