import {Injectable} from '@angular/core';
import {HotkeyServices} from './hotkey.services';
@Injectable({
  providedIn: 'root',
})


export class SecurityServices {

  constructor(public hotkeyServices:HotkeyServices) {
  }


 public disabledContextmenu(){
    window.addEventListener('contextmenu', e => e.preventDefault());
  }
  public disabledSelect(){
    window.addEventListener('selectstart', e => e.preventDefault());
  }
  public disabledCopy(){
    window.addEventListener('copy', e => e.preventDefault());
  }
  public disabledDrag(){
    window.addEventListener('drag', e => e.preventDefault());
  }
  public disabledDrop(){
    window.addEventListener('drop', e => e.preventDefault());
  }
  public disabledHotKeys(hotKeys:any){
    this.hotkeyServices.disableKeys(hotKeys);
  }
  public disableAll(){
    this.disabledContextmenu();
    this.disabledSelect();
    this.disabledCopy();
    this.disabledDrag();
    this.disabledDrop();
    this.disabledHotKeys([
      'ctrl+shift+i',
      'ctrl+u',
      'ctrl+a',
      'ctrl+c',
      'ctrl+v'
    ]);
  }


}
