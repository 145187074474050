import {Injectable} from '@angular/core';

import {ApiServices} from '../api/api.services';
import {timeOut} from "../../traits/datetime-tools";

@Injectable({
  providedIn: 'root',
})


export class ServerSiteServices {
  constructor(public api: ApiServices,) {

  }

  public initSite(findByName: any = 'lessons', callback: any = null) {
    this.api.request(`libs/one`, {domain: `art-club.artas.org.ua/${findByName}`})
      .subscribe((responseSite: any): void => {
        callback && callback(responseSite);
      });
  }
  public designSite(findByName: any = 'lessons',callback: any = null) {
    this.api.request(`collections/one`, {domain: `art-club.artas.org.ua/${findByName}`})
      .subscribe((siteResult: any) => {
        callback && callback(siteResult);
      });
  }
  public saveSite(
    domain: string = 'art-club.artas.org.ua/art-club',
                  site: object,
    callback: any = null) {
    timeOut(() => {
      let data: object = {
        domain: domain, ...site
      };
      this.api.request(`libs/save`, data)
        .subscribe((collectionFlag: any) => {
          callback && callback(collectionFlag);
        });
    }, 1000);

  }

}
