import {hexToRGBA, hexToRgbArray, rgbToHex} from './color-tools';

declare const $: any;

export  function resizeImage(src:any, height: any = 1000, width:any = 1000,callback:any =null) {
  const canvas: any = document.createElement('canvas');
  const context: any = canvas.getContext('2d');
  let imgCanvas: any = new Image();
  canvas.width =width;
  canvas.height = height;
  imgCanvas.width = canvas.width;
  imgCanvas.height = canvas.width;
  imgCanvas.crossOrigin = `Anonymous`;
  imgCanvas.onload = () => {
    context.drawImage(imgCanvas, 0, 0, canvas.width, canvas.height);
    let base64:any =  canvas.toDataURL( "image/png", 0.1)
    callback && callback(base64,imgCanvas);
  }
  imgCanvas.src = src;

}

export function makeCursor(
  canvas:any,
  position:any = {x:0,y:0},
  params:any ={
    colorHex: '#FF00FF',
    size:20,
  }
) {
  window.onload = () => {
    let cursor: any = document.querySelector('#cursor');
    // let cursor: any =  this.cursor;
    let ctx: any = cursor.getContext('2d');
    cursor.style.opacity = 1;
    cursor.style.left = position.x - (cursor.offsetWidth / 2) + 'px';
    cursor.style['z-index'] = '200000';
    cursor.style.top = position.y - (cursor.offsetHeight / 2) + 'px';
    cursor.width = params.size;
    cursor.height = params.size;
    let cross = params.size / 5, pad = params.size / 4;
    ctx.beginPath();
    ctx.arc(cross + pad, cross + pad, cross + pad, 0, 2 * Math.PI, false);
    ctx.fillStyle = params.colorHex;
    ctx.fill();
    ctx.beginPath();
    ctx.globalCompositeOperation = 'destination-out';
    ctx.arc(cross + pad, cross + pad, cross + pad / 3, 0, 2 * Math.PI, false);
    ctx.lineWidth = 1;
    ctx.strokeStyle = params.colorHex;
    ctx.stroke();
    ctx.fill();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.beginPath();
    ctx.lineWidth = 5;
    ctx.strokeStyle = 'rgba(255,255,255,0.7)';
    ctx.arc(cross + pad, cross + pad, cross / 3, 0, 2 * Math.PI, false);
    ctx.stroke();
    /* this.canvasBrush.onpointerdown = (e: any) => {
       this.cursor.style.left = e.pageX - (this.cursor.offsetWidth / 2) + 'px';
       this.cursor.style.top = e.pageY - (this.cursor.offsetHeight / 2) + 'px';
       this.cursor.classList.toggle('touched');
       console.log('onpointerdown')
       // downFlag = true;
     };*/
    canvas.style.cursor = 'url(' + cursor.toDataURL() + ') ' + (pad + cross) + ' ' + (pad + cross) + ' , crosshair';
  };
}




export function changeImageStrokeItem(src: any = '',
                                      params: any =
                                        {
                                          colorHex: 'red',
                                          height: 10,
                                          width: 10,
                                          blurFilter: 0,
                                          opacityFilter: 100,
                                          saturateFilter: 0,
                                          globalCompositeOperation: 'normal',
                                          globalAlpha: 1,

                                        },callback:any =null) {
  let heXtoRGB: any = hexToRgbArray(params.colorHex);
  let r: any = heXtoRGB.r;
  let g: any = heXtoRGB.g;
  let b: any = heXtoRGB.b;
  const canvas: any = document.createElement('canvas');
  const context: any = canvas.getContext('2d');
  let imgCanvas: any = new Image();
  canvas.width = params.width;
  canvas.height = params.height;
  imgCanvas.width = canvas.width;
  imgCanvas.height = canvas.width;
  imgCanvas.crossOrigin = `Anonymous`;

  context.globalAlpha = params.globalAlpha;
  // context.globalCompositeOperation = params.globalCompositeOperation;
  let blurFilter = params.blurFilter !== 0 ? `blur(${params.blurFilter}px)` : `blur(${0}px)`;
  let opacityFilter = params.opacityFilter !== 100 ? `opacity(${params.opacityFilter}%)` : `opacity(${100}%)`;
  let saturateFilter = params.saturateFilter !== 0 ? `saturate(${params.saturateFilter}%)` : `saturate(${0}%)`;
  let filterCombiner = `${blurFilter} ${opacityFilter} ${saturateFilter}`;
  filterCombiner ? context.filter = filterCombiner : 'none';

  imgCanvas.onload = () => {
    // let base64:any =  canvas.toDataURL( "image/png", 0.5)
    // console.log(base64)

    context.drawImage(imgCanvas, 0, 0,canvas.width, canvas.height);
    const imgData = context.getImageData(0, 0, canvas.width, canvas.height);
    for (let i = 0; i < imgData.data.length; i += 4) {
      imgData.data[i] = r;
      imgData.data[i + 1] = g;
      imgData.data[i + 2] = b;
      // imgData.data[i + 3] = params.globalAlpha;
    }
    context.putImageData(imgData, 0, 0);
    let base64:any =  canvas.toDataURL( "image/png", 0.1)
    callback && callback(base64,imgCanvas);

  };
  imgCanvas.src = src;
}

export function changeImageColor(src: any = '', hex: any = 'red') {
  let heXtoRGB: any = hexToRgbArray(hex);
  let r: any = heXtoRGB.r;
  let g: any = heXtoRGB.g;
  let b: any = heXtoRGB.b;


  const canvas: any = document.createElement('canvas');
  const context: any = canvas.getContext('2d');
  let imgCanvas: any = new Image();
  canvas.width = 100;
  canvas.height = 100;
  imgCanvas.width = 100;
  imgCanvas.height = 100;
  imgCanvas.src = src;
  imgCanvas.crossOrigin = `Anonymous`;

  imgCanvas.onload = () => {

  };
  context.drawImage(imgCanvas, 0, 0);
  const imgData = context.getImageData(0, 0, canvas.width, canvas.height);
  for (let i = 0; i < imgData.data.length; i += 4) {
    imgData.data[i] = r;
    imgData.data[i + 1] = g;
    imgData.data[i + 2] = b;
  }
  context.putImageData(imgData, 0, 0);
  return canvas.toDataURL();

}


export function createCanvas(w?: number, h?: number): HTMLCanvasElement {
  console.log('createCanvas')
  const result = document.createElement('canvas');
  if (w && h) {
    result.width = w;
    result.height = h;
  }
  return result;
}

export function getContext(canvas: any) {
  return canvas.getContext('2d');
}

export function imageDataToBase64(imageData: any, callback: any = null) {
  let convasClone = createCanvas(imageData.width, imageData.height);
  let contextClone = getContext(convasClone);
  // @ts-ignore
  contextClone.putImageData(imageData, 0, 0);
  let url = convasClone.toDataURL();
  callback && callback(url);
  // console.log(url);
  return url;
}

export function getImageData(canvas: any) {
  let context = getContext(canvas);
  return context.getImageData(0, 0, canvas.width, canvas.height);
}

export function imageDataToSrcStroke(canvas: any) {
  let imageData = getImageData(canvas);
  return imageDataToBase64(imageData);
}

export function putImageData(canvas: any, imageData: any) {
  return getContext(canvas).putImageData(imageData, 0, 0);
}

export function base64ToImageData(base64: any, callback: any = null) {
  let image = document.createElement('img'), w, h;
  image.crossOrigin = `Anonymous`;
  image.src = base64;
  $(image).one('load', function() {
    w = image.naturalWidth;
    h = image.naturalHeight;
    let canvas = createCanvas(w, h);
    $(canvas).attr('width', w);
    $(canvas).attr('height', h);

    let context = getContext(canvas);
    // @ts-ignore
    context.drawImage(image, 0, 0);
    // @ts-ignore
    let imageData = context.getImageData(0, 0, w, h);
    callback && callback(imageData);
  });
  return;

}

export function clearCanvas(canvas: any, callback: any = null) {
  let context = getContext(canvas);
  context.clearRect(0, 0, canvas.width, canvas.height);
  callback && callback(canvas);
  return canvas;
}

export function clearHalfCanvas(canvas: any, callback: any = null) {
  let context = getContext(canvas);
  // context.clearRect(0, 0, canvas.width, canvas.height);
  context.fillStyle = hexToRGBA('#FFFFFF', 0.5);
  context.fillRect(0, 0, canvas.width, canvas.height);
  callback && callback(canvas);
  return canvas;
}

export function getColorPixel(imageElement: any, width: any = 500, height: any = 500, callback: any = null) {
  let canvas = createCanvas(width, height);
  let ctx = getContext(canvas);
  ctx.drawImage(imageElement, 0, 0, width, height);
  // filtersMap(canvas, filterList());
  let src = canvas.toDataURL();
  // callback && callback('', src);

  let x: any;
  let y: any;
  if (imageElement) {


    imageElement.addEventListener('click', (e: any) => {
      // chrome
      if (e.offsetX) {
        x = e.offsetX;
        y = e.offsetY;
      }
      // firefox
      else if (e.layerX) {
        x = e.layerX;
        y = e.layerY;
      }

      let colorPoint = ctx.getImageData(x, y, 1, 1).data;
      let colorPixelHex = rgbToHex(colorPoint[0], colorPoint[1], colorPoint[2]);
      callback && callback(colorPixelHex, src);

    }, false);
  }

  function spaceBeetwin() {
   /*
    function distanceBetween(point1, point2) {
      return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
    }
    function angleBetween(point1, point2) {
      return Math.atan2( point2.x - point1.x, point2.y - point1.y );
    }

    var el = document.getElementById('c');
    var ctx = el.getContext('2d');

//ctx.fillStyle = "rgba('255, 0, 0, 0.1')";
    ctx.fillStyle = "red";
    ctx.strokeStyle = "red";
    ctx.globalAlpha = "0.01";
    ctx.lineWidth = 0;
    ctx.globalCompositeOperation = "source-over";

    var isDrawing, lastPoint;

    el.onmousedown = function(e) {
      isDrawing = true;
      lastPoint = { x: e.clientX, y: e.clientY };
    };

    el.onmousemove = function(e) {
      if (!isDrawing) return;

      var currentPoint = { x: e.clientX, y: e.clientY };
      var dist = distanceBetween(lastPoint, currentPoint);
      var angle = angleBetween(lastPoint, currentPoint);

      for (var i = 0; i < dist; i+=3) {
        x = lastPoint.x + (Math.sin(angle) * i) - 25;
        y = lastPoint.y + (Math.cos(angle) * i) - 25;
        ctx.beginPath();
        ctx.arc(x+10, y+10, 20, false, Math.PI * 2, false);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
      }

      lastPoint = currentPoint;
    };

    el.onmouseup = function() {
      isDrawing = false;
    };

    function clearit() {
      ctx.clearRect(0,0, 1000, 1000);
    }
*/
  }

  /*public smogleBrush(){
   // @ts-ignore
   const ctx:any = document.querySelector('#canvas').getContext('2d');
   // @ts-ignore
   const brushDisplayCtx:any = document.querySelector('#brush-display').getContext('2d');

   function reset() {
     const {width, height} = ctx.canvas;
     const wd2 = width / 2
     ctx.globalAlpha = 1;
     ctx.fillStyle = 'white';
     ctx.fillRect(wd2, 0, wd2, height);

     const gradient = ctx.createLinearGradient(0, 0, 0, height);
     gradient.addColorStop(0, 'red');
     gradient.addColorStop(0.5, 'yellow');
     gradient.addColorStop(1, 'blue');
     ctx.fillStyle = gradient;
     ctx.fillRect(0, 0, wd2, height);
   }
   reset();

   function getCanvasRelativePosition(e:any, canvas:any) {
     const rect:any = canvas.getBoundingClientRect();
     return {
       x: (e.clientX - rect.left) / rect.width  * canvas.width,
       y: (e.clientY - rect.top ) / rect.height * canvas.height,
     };
   }

   function lerp(a:any, b:any, t:any) {
     return a + (b - a) * t;
   }

   function setupLine(x:any, y:any, targetX:any, targetY:any) {
     const deltaX = targetX - x;
     const deltaY = targetY - y;
     const deltaRow = Math.abs(deltaX);
     const deltaCol = Math.abs(deltaY);
     const counter = Math.max(deltaCol, deltaRow);
     const axis = counter == deltaCol ? 1 : 0;

     // setup a line draw.
     return {
       position: [x, y],
       delta: [deltaX, deltaY],
       deltaPerp: [deltaRow, deltaCol],
       inc: [Math.sign(deltaX), Math.sign(deltaY)],
       accum: Math.floor(counter / 2),
       counter: counter,
       endPnt: counter,
       axis: axis,
       u: 0,
     };
   };

   function advanceLine(line:any) {
     --line.counter;
     line.u = 1 - line.counter / line.endPnt;
     if (line.counter <= 0) {
       return false;
     }
     const axis = line.axis;
     const perp = 1 - axis;
     line.accum += line.deltaPerp[perp];
     if (line.accum >= line.endPnt) {
       line.accum -= line.endPnt;
       line.position[perp] += line.inc[perp];
     }
     line.position[axis] += line.inc[axis];
     return true;
   }

   let lastX:any;
   let lastY:any;
   let lastForce:any;
   let drawing = false;
   let alpha = 0.5;

   const brushCtx:any = document.createElement('canvas').getContext('2d');
   let featherGradient:any;

   function createFeatherGradient(radius:any, hardness:any) {
     /!*const innerRadius = Math.min(radius * hardness, radius - 1);
     const gradient = brushCtx.createRadialGradient(
       0, 0, innerRadius,
       0, 0, radius);
     gradient.addColorStop(0, 'rgba(0, 0, 0, 0)');
     gradient.addColorStop(1, 'rgba(0, 0, 0, 1)');
     return gradient;*!/
   }

   // const radiusElem:any = document.querySelector('#radius');
   // const hardnessElem:any = document.querySelector('#hardness');
   // const alphaElem:any = document.querySelector('#alpha');
   // radiusElem.addEventListener('input', updateBrushSettings);
   // hardnessElem.addEventListener('input', updateBrushSettings);
   // alphaElem.addEventListener('input', updateBrushSettings);
   // @ts-ignore
   //  document.querySelector('#reset').addEventListener('click', reset);

   function updateBrushSettings() {
     const radius = 10 // radiusElem.value;
     const hardness = 10 // hardnessElem.value;
     alpha = 1// alphaElem.value;
     featherGradient = createFeatherGradient(radius, hardness);
     brushCtx.canvas.width = radius * 2;
     brushCtx.canvas.height = radius * 2;

     {
       const ctx:any = brushDisplayCtx;
       const {width, height} = ctx.canvas;
       ctx.clearRect(0, 0, width, height);
       ctx.fillStyle = `rgba(0, 0, 0, ${alpha})`;
       ctx.fillRect(width / 2 - radius, height / 2 - radius, radius * 2, radius * 2);
       feather(ctx);
     }
   }
   updateBrushSettings();

   function feather(ctx:any) {
     // feather the brush
     ctx.save();
     ctx.fillStyle = featherGradient;
     ctx.globalCompositeOperation = 'destination-out';
     const {width, height} = ctx.canvas;
     ctx.translate(width / 2, height / 2);
     ctx.fillRect(-width / 2, -height / 2, width, height);
     ctx.restore();
   }

   function updateBrush(x:any, y:any) {
     let width = brushCtx.canvas.width;
     let height = brushCtx.canvas.height;
     let srcX = x - width / 2;
     let srcY = y - height / 2;
     // draw it in the middle of the brush
     let dstX = (brushCtx.canvas.width - width) / 2;
     let dstY = (brushCtx.canvas.height - height) / 2;

     // clear the brush canvas
     brushCtx.clearRect(0, 0, brushCtx.canvas.width, brushCtx.canvas.height);

     // clip the rectangle to be
     // inside
     if (srcX < 0) {
       width += srcX;
       dstX -= srcX;
       srcX = 0;
     }
     const overX = srcX + width - ctx.canvas.width;
     if (overX > 0) {
       width -= overX;
     }

     if (srcY < 0) {
       dstY -= srcY;
       height += srcY;
       srcY = 0;
     }
     const overY = srcY + height - ctx.canvas.height;
     if (overY > 0) {
       height -= overY;
     }

     if (width <= 0 || height <= 0) {
       return;
     }
     /!**!/

     let imgResult: any = document.createElement('img');
     imgResult.crossOrigin = 'Anonymous';
     imgResult.width = width;
     imgResult.height = height;
     imgResult.onload = () => {
     };
     imgResult.src = 'https://server-2.artas.org.ua/Server/images/7ns8djl7d_1698400117707_.jpg';

     brushCtx.drawImage(imgResult
       ,
       srcX, srcY, width, height,
       dstX, dstY, width, height);

     feather(brushCtx);
   }

   function start(e:any) {
     const pos = getCanvasRelativePosition(e, ctx.canvas);
     lastX = pos.x;
     lastY = pos.y;
     lastForce = e.force || 1;
     drawing = true;
     updateBrush(pos.x, pos.y);
   }

   function draw(e:any) {
     if (!drawing) {
       return;
     }
     const pos = getCanvasRelativePosition(e, ctx.canvas);
     const force = e.force || 1;

     const line = setupLine(lastX, lastY, pos.x, pos.y);
     for (let more = true; more;) {
       more = advanceLine(line);
       ctx.globalAlpha = alpha * lerp(lastForce, force, line.u);
       ctx.drawImage(
         brushCtx.canvas,
         line.position[0] - brushCtx.canvas.width / 2,
         line.position[1] - brushCtx.canvas.height / 2);
       updateBrush(line.position[0], line.position[1]);
     }
     lastX = pos.x;
     lastY = pos.y;
     lastForce = force;
   }

   function stop() {
     drawing = false;
   }

   window.addEventListener('mousedown', start);
   window.addEventListener('mousemove', draw);
   window.addEventListener('mouseup', stop);
   /!*window.addEventListener('touchstart', e => {
     e.preventDefault();
     start(e.touches[0]);
   }, {passive: false});
   window.addEventListener('touchmove', e => {
     e.preventDefault();
     draw(e.touches[0]);
   }, {passive: false});*!/
 }*/


}
