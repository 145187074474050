<app-preload *ngIf="showPreload"></app-preload>
<div style="height:25px; overflow: hidden"
     class="absolute top-0 seo-container text-center z-0">
  <h1>{{ dataPage.seoTexts.h1 }}</h1>
  <h2>{{ dataPage.seoTexts.h2 }}</h2>
  <h3>{{ dataPage.seoTexts.h3 }}</h3>
  <p>{{ dataPage.seoTexts.text }}</p>
  <p>{{ dataPage.seoTexts.textEndPage }}</p>
</div>
<app-navbar-top></app-navbar-top>

<app-header
  [showCounters]="false"
  [bgImage]="'assets/header-palettes-generator.png'"
  [headerText]="dataPage.seoTexts.h1"
  [subHeaderText]="dataPage.seoTexts.h1"
  [actionText]="dataPage.seoTexts.h3"
  [showProductLeft]="true"
></app-header>


  <div
    [class]="'bgColor'"
    [style.background-image]="'url(' + srcLogo + ')'"
    class="mt-[75px] bg-[length:50px_50px] bg-opacity-10  bg-repeat flex justify-center items-center flex-col bg-gradient-to-br from-gray-800 to-gray-900 relative animate-gradient-x">

    <div class="flex overflow-x-auto py-4 space-x-4">
      <!--  <button *ngIf="showCropRigionBtn"
                class="bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
                (click)="showCropPanel()"
        >
          Crop
          <i *ngIf="!showCropRigion" [class]="editIcon"></i>
          <i *ngIf="showCropRigion" [class]="closeSquareIcon"></i>
        </button>-->

      <button #targetEl
        (click)="

         opColorPanel.toggle($event, targetEl);
"
        class=" bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
      >
        Color
        <i class="fa-solid fa-palette"></i>
      </button>
      <button #targetEl *ngIf="!showCropRigion"
              (click)="showSettings = !showSettings;;
                    opPalleteSettings.toggle($event, targetEl)"
              class="bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
      >
        <i class="fa-solid fa-sliders"></i>
      </button>
    </div>
<!--      <app-slider-btns
        [label]="'Sampling'" [start]="0" [end]="20"
        (onClick)="paramsBlack.colorsampling = $event; reInitImageTracing()"
      ></app-slider-btns>-->
      <app-slider-btns
        [label]="'Tones'" [start]="2" [end]="20"
        (onClick)="paramsBlack.numberofcolors = $event; paramsBlack.colorsampling = $event*2; reInitImageTracing()"
      ></app-slider-btns>
          <app-image-tracing *ngIf="showTracing "
                             #tracerComponent
                             [paramsBlack]="paramsBlack"
                             [imageSrc]="imageCrop"
                             [showResult]="true"
                             [blurAmount]="blurAmount"
                             [grayscaleAmount]="grayscaleAmount"
                             [brightness]="brightness"
                             [contrast]="contrast"
                             [saturation]="saturation"
                             [blurFilter]="blurFilter"
                             [opacityFilter]="opacityFilter"
                             [colorHex]="selectColor"
                             [mode]="mode.value"
                             (onTraceOriginal)="imageResult =$event"
          >
          </app-image-tracing>
        <app-uploader-photo
          [showLabel]="true"
          [showListHorisont]="true"
          [showListModal]="true"
          [showUpload]="true"
          [showCounter]="true"
          (onClickItem)="selectUpload($event)"
        ></app-uploader-photo>
        <app-btn-download
          [srcImage]="imageResult"
          [disabled]="false"
          [showCoin]="true"
          [textBtn]="'Download' "
        ></app-btn-download>
</div>

<p-overlayPanel #opColorPanel>
  <app-color-panel *ngIf="this.showColorSidebar"
                   (onColor)="setColor($event)"
  ></app-color-panel>
</p-overlayPanel>

<p-overlayPanel #opPalleteSettings>
  <div class="flex flex-col space-x-1 w-[300px] ">
    <button
      (click)="resetCanvas()"
      class="absolute -bottom-[20px] right-[20px] bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
    >
      Reset
      <i class="fa-solid fa-arrows-spin"></i>
    </button>

    <div class="text-white p-1">
      <span>Brightness:{{ brightness }}</span>
      <p-slider [min]="100" [max]="300"
                [(ngModel)]="brightness"
                (ngModelChange)="reInitImageTracing()">
      </p-slider>
    </div>

    <div class="text-white p-1">
      <span>Contrast:{{ contrast }}</span>
      <p-slider [min]="100" [max]="300"
                [(ngModel)]="contrast"
                (ngModelChange)="reInitImageTracing()">
      </p-slider>
    </div>

    <div class="text-white p-1">
      <span>Blur:{{ blurFilter }}</span>
      <p-slider [min]="0" [max]="10"
                [(ngModel)]="blurFilter"
                (ngModelChange)="reInitImageTracing()">
      </p-slider>
    </div>
    <div class="text-white p-1">
      <span>Opacity:{{ opacityFilter }}</span>
      <p-slider [min]="1" [max]="100"
                [(ngModel)]="opacityFilter"
                (ngModelChange)="reInitImageTracing()">
      </p-slider>
    </div>
    <div class="text-white p-1">
      <span>Saturation:{{ saturation }}</span>
      <p-slider [min]="1" [max]="300"
                [(ngModel)]="saturation"
                (ngModelChange)="reInitImageTracing()">
      </p-slider>
    </div>
    <div class="text-white p-1 w-[300px]">
      <div [style.width.px]="windowWidth()"
           class="flex overflow-x-auto py-4 space-x-4">
        <ng-container *ngFor=" let modeItem of modes">
          <div (click)="mode =modeItem;reInitImageTracing()">
            <div
              class="bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out">
              {{ modeItem.name }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</p-overlayPanel>
<!--

     <app-slider-btns
       [label]="'Blur'" [start]="0" [end]="20"
       (onClick)="paramsBlack.blurradius = $event; paramsBlack.blurdelta =$event; reInitImageTracing()"
     ></app-slider-btns>-->
