import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgFor, NgIf} from '@angular/common';
import {EditCardSelectComponent} from '../edit-card-select/edit-card-select.component';
import {Card} from '../../../interfaces/Card';
import {EditCardViewComponent} from '../edit-card-view/edit-card-view.component';

@Component({
  selector: 'app-edit-list-cards',
  templateUrl: './edit-list-cards.component.html',
standalone:true,
  imports: [NgFor, NgIf, EditCardViewComponent]

})
export class EditListCardsComponent {

  @Input() cards: Array<Card>;
  @Input() showFilter: boolean = false;
  public selectItem: object;
  public indexCard: number = 0;
  public maxView: number = 100;
  public selectModelCurrent: any;
  public bgColor: string ='bg-zinc-700';
  public bgImage: string ='assets/background_logo_small.png';
  @Output() onSelect:EventEmitter<any> = new EventEmitter();

}
