import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtClubServices} from '../static/static-art-club.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {Site} from '../../interfaces/site';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageImagePalettesLibraryServices {
  public SERVER_NAME: string = 'image-palettes-library';
  public DOMAIN: string = 'art-club.artas.org.ua/image-palettes-library';
  public route: string = 'image-palettes-library';
  public fromServer: boolean = true;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'Image Palettes Library',
    desc: 'Безлимитный доступ ко всем Курсам',
    additions: [''],
  };
  public free: object = {
    name: 'Курсы',
    desc: 'Безлимитный доступ ко всем Курсам',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Image Palettes Library: Explore Unique Color Palettes',
    description: 'Image Palettes Library, Explore our extensive collection of color palettes at the Image Palettes Library. Find inspiration from famous artworks and vibrant creations to infuse your designs with depth and creativity. Our curated selection offers a spectrum of colors and moods, allowing you to express your unique vision through bold contrasts or soft pastels. With our library, unleash your creativity and elevate your designs to new heights.',
    text: 'Image Palettes Library welcome to our Image Palettes Library, where you will discover unique collections of color schemes inspired by famous artworks and the vibrant creations of talented artists! Our library features a wide array of palettes influenced by the mastery and creativity found in bright light photographs. From bold and contrasting to soft and pastel shades, our curated selection captures the full spectrum of colors and moods. Whether you are working on a project or task, our image palettes will help you craft impressive designs enriched with deep, saturated colors, expressing the full force of art and inspiration. Explore our rich collection of color palettes to infuse your projects with a bright and unique artistic embodiment. Unleash your creativity, experiment with different color combinations, and uncover exciting new ideas. Our Image Palettes Library is your indispensable tool for bringing your boldest concepts to life, ensuring your work shines brightly and stands out among others. With our vast array of palettes, you will find endless possibilities to ignite your imagination and create captivating visual experiences. Elevate your designs and unleash the power of color with our Image Palettes Library today',
    h1: 'Image Palettes Library',
    h2: 'Image Palettes Library welcome to Our Color Palette Collection',
    h3: 'Image Palettes Library discover Unique Color Schemes Inspired by Famous Artworks',
    h4: 'Craft Impressive Designs with Deep and Saturated Colors',
    h5: 'Unleash Your Creativity with Our Vast Array of Palettes',
    textEndPage:'Image Palettes Library welcome to our Image Palettes Library, where you\'ll discover unique collections of color schemes inspired by famous artworks and the vibrant creations of talented artists! Whether you\'re working on a project or task, our image palettes will help you craft impressive designs enriched with deep, saturated colors, expressing the full force of art and inspiration',
    keywords: [
      'color palettes',
      'image palettes',
      'art-inspired color schemes',
      'design inspiration',
      'creative color combinations',
      'image palette generator',
      'color palette',
      'color scheme',
      'vibrant colors',
      'design tool',
      'artist tool'
    ],
  };
  public site: any

  constructor(public server:ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

 public getSite(callback:any = null) {
    if (this.fromServer) {
      return this.getSiteFromServer(callback);
    } else {
      return this.getSiteStatic();
    }

  }
  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }
 public saveSite(site:object){
   if (this.fromServer) {

     this.site = site
    // this.downloadSiteBtn(this.site);
    // let server: any = inject(ServerSiteServices)
    this.server.saveSite(this.DOMAIN, this.site);
  }
  }
  getSiteStatic() {
    return this.site = inject(StaticArtClubServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer(callback:any = null) {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: any): void => {
      this.site = responseSite;
      callback && callback(responseSite)
    });
    return this.site;
  }

}
