import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtClubServices} from '../static/static-art-club.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {Site} from '../../interfaces/site';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageTemplatesShargeServices {
  public SERVER_NAME: string = 'templates-sharge';
  public DOMAIN: string = 'art-club.artas.org.ua/templates-sharge';
  public route: string = 'templates-sharge';
  public fromServer: boolean = true;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'templates-sharge',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'templates-sharge',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'templates-sharge',
    description: 'templates-sharge',
    text: 'templates-sharge',
    h1: 'templates-sharge',
    h2: 'templates-sharge',
    h3: 'templates-sharge',
    h4: 'templates-sharge',
    h5: 'templates-sharge',
    textEndPage:'templates-sharge',
    keywords: [
      'templates-sharge',
    ],
  };
  public site: any

  constructor(public server:ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

 public getSite(callback:any = null) {
    if (this.fromServer) {
      return this.getSiteFromServer(callback);
    } else {
      return this.getSiteStatic();
    }

  }
  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }
 public saveSite(site:object){
   if (this.fromServer) {

     this.site = site
     // this.downloadSiteBtn(this.site);
     // let server: any = inject(ServerSiteServices)
     this.server.saveSite(this.DOMAIN, this.site);
   }
  }
  getSiteStatic() {
    return  {} //this.site = inject(Static3DReferencesServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer(callback:any = null) {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: any): void => {
      this.site = responseSite;
      callback && callback(responseSite)
    });
    return this.site;
  }
  getCards(){
    return  this.site.organizer.messenges;
  }
  findCard(slug:any){
    let cards = this.getCards();
    let findCard =   cards.filter((itemProduct:any)=>{
      if(itemProduct.id === slug){
        return itemProduct;
      }
    });
    return findCard[0];
  }
}
