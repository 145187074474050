<div   [style.width.px]="width"
       [style.height.px]="height"
      [class]="absoluteClass"
     class=" py-1 px-3 rounded-br-lg rounded-full">
  <a *ngIf="!active" (click)="clickBtn()"
     [class]="animateClass+' '+ colorClass"
     class="text-amber-200 focus:ring-4 shadow-lg transform active:scale-75 transition-transform flex aspect-square min-h-[32px] w-16 flex-col items-center justify-center gap-1 rounded-md p-1 "
  >
    <img *ngIf="showSrcIcon"
         [src]="srcIcon"
         [style.width.px]="width/2"
         [style.height.px]="height/2"
         alt="premium-icon"
         class=" absolute -top-[2px] -right-[2px] "
    >
    <img *ngIf="srcPreview"
      [src]="srcPreview"
      alt="photos previews"
         [style.width.px]="width"
         [style.height.px]="height"
    >
    <small class="text-center text-xs font-bold ">
            <span class="truncate" *ngIf="label">{{label}}</span>
            <span *ngIf="count">({{count}})</span>
    </small>
  </a>

  <div class="animate-jump-in animate-once animate-duration-[2000ms] animate-ease-in-out"
       *ngIf="active"
  >

    <img *ngIf="srcActive"
         [src]="srcActive"
         alt="photos previews"
         [style.width.px]="width"
         [style.height.px]="height"
         class="h-[40px] w-[40px]"
    >
    <small *ngIf="activeText" class="text-center text-xs font-bold">
      <span>{{activeText}}</span>
    </small>
  </div>
</div>
