import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgIf} from '@angular/common';
import {SwiperPhotosComponent} from '../swiper-photos/swiper-photos.component';
import {GridPhotosComponent} from '../grid-photos/grid-photos.component';
import {Photo} from '../../../interfaces/Photo';
import {getIndex} from '../../../traits/array-tools';
import {Box} from '../../../interfaces/Box';
import {HideDirective} from '../../../directives/hide.directive';
import {ImageReferenceComponent} from "../../image-reference/image-reference.component";
import {SidebarComponent} from "../../common/sidebar/sidebar.component";
import {TextSliceComponent} from "../../text-slice/text-slice.component";

@Component({
  selector: 'app-images-viewer-grid-slidder',
  templateUrl: './images-viewer-grid-slidder.component.html',
standalone:true,
  imports: [
    HideDirective,
    NgIf,
    SwiperPhotosComponent,
    GridPhotosComponent,
    ImageReferenceComponent,
    SidebarComponent,
    TextSliceComponent
  ]
})
export class ImagesViewerGridSlidderComponent  {
  @ViewChild(SwiperPhotosComponent) swiperPhotosComponent: SwiperPhotosComponent;
  @Input() rowName: any;
  @Input() photos: Array<Photo>  = [
    {srcPreview:'https://www.centeredrecoveryprograms.com/wp-content/uploads/2018/09/bigstock-Collage-Of-Many-Diverse-Multi-244504738-384x384.jpg'},
    {srcPreview:'https://server-2.artas.org.ua/Server/images/sgvfjpp8g_1700031317496_.jpg'},
    {srcPreview:'https://www.centeredrecoveryprograms.com/wp-content/uploads/2018/09/bigstock-Collage-Of-Many-Diverse-Multi-244504738-384x384.jpg'},
    {srcPreview:'https://www.centeredrecoveryprograms.com/wp-content/uploads/2018/09/bigstock-Collage-Of-Many-Diverse-Multi-244504738-384x384.jpg'},
    {srcPreview:'https://server-2.artas.org.ua/Server/images/0bmcj5qse_1699818799328_.jpg'},
    {srcPreview:'https://www.centeredrecoveryprograms.com/wp-content/uploads/2018/09/bigstock-Collage-Of-Many-Diverse-Multi-244504738-384x384.jpg'},
  ];
  @Input() showGrid: boolean = true;
  @Input() model: any;
  @Input() showClientCollection: any =false;
  @Input() showEtuder: any =false;
  @Input() showSketcher: any =false;
  public box: Box;
  @Input()  selectPhoto: any;
  public indexPhoto: any =  0;
  @Output() onClose = new EventEmitter();
  @Output() onSelect = new EventEmitter();
  public showReferenceSidebar: boolean =false;
  ngOnInit() {
  }

  addToFavorite(){
    /* this.basketFavoriteServices.selectImage = modelSlide;
   this.selectImage = modelSlide;
   this.cd.selectSlideImage = modelSlide;
   this.cd.selectSlideImage.scaleX =1;*/

  }
 private setPhotoItem(photoItem: Photo):void{
    this.selectPhoto = photoItem;
    // this.indexPhoto = getIndex(photoItem,this.photos);
  }
 public  onSlideSwiper(photoItem: Photo):void {

   this.setPhotoItem(photoItem);
  }
 public onShowGrid(show:boolean):void{
    this.showGrid=show;
  }
 public onClickGrid(photoItem: Photo):void {
    // this.setPhotoItem(photoItem);
   this.selectPhoto = photoItem;
   this.showGrid=false;
    // this.swiperPhotosComponent.slideToIndex(this.indexPhoto+1);
   this.onSelect.emit(photoItem);
   this.showReferenceSidebar=true;

 }


  /*public showRotatorBtn(photoItem:any) {
    this.view ='rotator';
    this.onSlide(photoItem);
    this.imageIndex(photoItem)
  }*/
 /* public showGridBtn(photoItem:any) {
    this.view ='grid';
    this.onSlide(photoItem);
  }*/
 /*   public onFlipItem(){
    if( this.scaleX ==1 ){
      this.scaleX = -1
    }
      else if(this.scaleX == -1){
      this.scaleX =1;
    }
   /!* if (this.cd.selectSlideImage.scaleX === -1 || ) {
      this.cd.selectSlideImage.scaleX = 1;
    }*!/
  }*/
 /* showClientCollectionBtn(){
    this.basketFavoriteServices.addToFavorites(this.selectImage)
    this.showClientCollection =true;
  }*/
}
