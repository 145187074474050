<!--<app-stepper *ngIf="!cd.logined"></app-stepper>-->
<router-outlet></router-outlet>
<!--<app-sidebar
  [show]="cd.log"
  (onSave)="cd.log =null"
>
  <ng-container *ngIf="cd.log">
    {{ cd.log|json }}
  </ng-container>

</app-sidebar>-->
