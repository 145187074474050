import {Injectable} from '@angular/core';

import {ApiServices} from '../api/api.services';
import {timeOut} from "../../traits/datetime-tools";

@Injectable({
  providedIn: 'root',
})


export class ServerOrderServices {
  constructor(public api: ApiServices) {

  }

  public initSite(form,selectModel,callback) {
    this.api.request(`telegram/form`,
      {...form, ...
          {nameOrder: selectModel.name
          }})
      .subscribe((responseSite: any): void => {
        callback && callback(responseSite);
      });
  }



}
