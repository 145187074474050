<div [hidden]="this.hideContent" id="top-id" class="bg-amber-600 relative overflow-hidden rounded-lg shadow-lg cursor-pointer h-[150px] ">
  <img *ngIf="true" class=" object-cover w-full h-[125px]"
       [src]="bgImage"
       [alt]="headerText"/>
  <div class="  w-full h-[150px]  backdrop-blur-sm  absolute top-0 left-0 px-6 py-4">
    <h4 *ngIf="headerText"
        class=" animate-fade-right animate-once pl-1 pr-1  bg-fuchsia-600/50  rounded-xl mb-3 text-xl font-semibold tracking-tight text-zinc">
      {{headerText}}
    </h4>
    <p *ngIf="subHeaderText"
       class=" animate-fade-left animate-once  pl-1 pr-1
        bg-fuchsia-600/50  rounded-xl leading-normal text-zinc-100">
      {{subHeaderText}}
    </p>
  </div>
  <div *ngIf="(countMessenges  || countArrays) && showCounters "
       class="absolute right-2 bottom-6">
    <small class="text-center  text-xs font-bold">
            <span class="pl-1 pr-1 rounded-xl  text-zinc-100">
              {{messengesName}}: {{countMessenges}}
            </span>
      |
      <span *ngIf="(actionText && countArrays)"
            class="pl-1 pr-1 rounded-xl text-zinc-100">
              {{arraysName}}: {{countArrays}}
            </span>
    </small>
  </div>
  <div *ngIf="(actionText && countArrays)"
       [class]="animationClass"
       class="absolute right-2 bottom-1">
    <small *ngIf="actionText"
           class=" pl-1 pr-1 rounded-xl   rounded-2 text-center text-zinc-100 text-xs font-bold">
      {{actionText}}
    </small>
  </div>
</div>
<app-product-list-right *ngIf="showProductLeft"
  [cards]="dataPage.getCards()"
  (onSelectCard)="goToCourse($event)"
></app-product-list-right>
