<app-confirm></app-confirm>

<app-sidebar [show]="showCropRigion"
             (onSave)="showCropRigion = false"
             [showClose]="true"
             [height]="100"
             [ext]="'%'"
             [zIndex]="10000"
             [position]="'bottom'"
>
  <app-crop-rigion *ngIf="showCropRigion"
                   [width]="350"
                   [height]=350
                   [cropZoneHeight]=150
                   [cropZoneWidth]=150
                   [cropZoneX]="100"
                   [cropZoneY]="100"
                   [showReset]="false"
                   [showPressetsCrop]="false"
                   [showAspectRatio]="true"
                   [showZoom]="false"
                   [showFlip]="false"
                   [showRotate]="false"
                   [showDownload]="false"
                   [showAdd]="true"
                   [src]="model.srcPreview"
                   (onAdd)="this.addCropImage($event,models)"
  ></app-crop-rigion>
</app-sidebar>
<div
  class="bg-zinc-700 p-[2px] rounded-lg shadow-lgm m-[2px] w-[170px]">

  <div class=" flex justify-around text-[15px] font-bold mb-1 text-gray-300">
    <!--    <app-translate-btn *ngIf="!showImage && model[nameRow]" [model]="model" [nameRow]="nameRow"></app-translate-btn>-->

    <span  *ngIf="cd.enabledDelete && !isImage(model)"
           (click)="clearText()">
    <i [class]="theme.icon.close"></i>
    </span>


    <span *ngIf=" !isImage(model)" ngxClipboard #copyContainer3
          (cbOnSuccess)=" copyText(model,nameRow)"
          [cbContent]="model[nameRow]"
          [container]="copyContainer3">
          <i class="far fa-copy"></i>
    </span>

    <span  *ngIf=" !isImage(model)"
           (click)="collapseSimilar  = !collapseSimilar;
">
      <i class="fas fa-compress-arrows-alt"></i>
  </span>

    <span (click)="showBottomMenu  = !showBottomMenu;">
     <i class="fa-solid fa-ellipsis"></i>
  </span>

  </div>
  <div class="relative">
    <div class=" p-[2px] absolute -left-[1px] -top-[40px] text-[10px] bg-zinc-600 text-white">
      {{ nameRow }}
    </div>

    <div *ngIf="typeRow === 'color'"
         class="absolute top-0 right-1">
       <span *ngIf="model.color"
             class="block w-[50px] h-[50px] "
             (click)="showColorsLib = !showColorsLib"
             [style.background-color]="model.color"

       >
       </span>
    </div>
    <div *ngIf="typeRow === 'srcGif'"
    >
      <img (click)="showText = !showText" class="w-[100px] h-[100px]"
           *ngIf="model.srcGif"
           [src]="model.srcGif"
           alt="srcIcon"
      >
    </div>

    <div *ngIf="typeRow === 'srcIcon'"
    >
      <img (click)="showText = !showText" class="w-[100px] h-[100px]"
           *ngIf="model.srcIcon"
           [src]="model.srcIcon"
           alt="srcIcon"
      >
    </div>

    <div *ngIf="typeRow === 'srcPreview'"
    >
      <img *ngIf="model.srcPreview"
           (click)="showText = !showText"
           [style.height.px]="minHeight"
           [style.width.px]="minWidth"

           [src]="model.srcPreview"
           alt="srcPreview"
      >

    </div>
    <!--*ngIf="isText(model)" [autoResize]="true"-->
    <textarea  *ngIf=" !isImage(model)"
              style="padding: 2px!important;font-size: 16px !important; "
              pInputTextarea
              [placeholder]="nameRow"
              [(ngModel)]="model[nameRow]"
              [style.min-height.px]="minHeight"
              [style.height.px]="minHeight"
              [style.min-width.px]="minWidth"
              [style.color]="model?.color"
    ></textarea>

  </div>


  <div *ngIf="showBottomMenu"
       class=" flex justify-around text-[15px] font-bold mb-1 text-gray-300">

    <!-- <span  *ngIf="isBase64(model)"
             (click)="baseToSrc(model,'middle')"
     >
       <span class="text-[8px]">500|</span>
    </span>-->

    <span *ngIf="isBase64(model)"
          (click)="baseToSrc(model,'large')"
    >
                 <span class="text-[8px]">800|</span>
        </span>
    <span *ngIf="isNotText(model)">
        <i [class]="theme.icon.tag"
           (click)="setPreviewBtn(model)"
        ></i>
      </span>

    <span *ngIf="isNotText(model)">
        <i [class]="cropIcon"
           (click)="showCropRigion = true"
        ></i>

      </span>
    <span *ngIf="isNotText(model)">
        <i [class]="theme.icon.favorite"
           (click)="setFavoriteBtn(model)"
        ></i>
      </span>
    <span *ngIf="cd.enabledDelete" (click)="deleteModelFromServer(model,models)">
       <i [class]="theme.icon.delete"
       ></i>
     </span>

    <!--<span>
            <i *ngIf="model.sw === true"
               [class]="theme.icon.eyeClose"
               (click)="model.sw =false;cd.saveChangeSite =false;"
            ></i>
            <i *ngIf="model.sw === false"
               [class]="theme.icon.eyeOpen"
               (click)="model.sw =true;cd.saveChangeSite =false;">

            </i>
        </span>-->

    <!--    <span class="handle">
                         <i [class]="'fas fa-arrows-alt'"></i>
                     </span>-->


    <!--  <span *ngIf="!showImage" >
        <i [class]="theme.icon.add"
           (click)="addCloneModel(model,models);"
        ></i>
      </span>-->
  </div>

  <app-similars-cards
    *ngIf="collapseSimilar"
    [cards]="models"
    (onClick)="onSelectSimilar($event)"
  ></app-similars-cards>

  <app-colors-grid
    *ngIf="showColorsLib"
    [imageForColors]="this.imageForColors"
    (onClick)="onSelectColor($event)"
  ></app-colors-grid>


</div>
