<a
  class=" animate-fade-down animate-once animate-ease-in-out ml-2 mr-1 mt-3 flex items-center text-[15px]  text-amber-500 font-bold  lg:mb-0 lg:mt-0"
  [routerLink]="'/'">
  <img class="mr-1"
       [src]="srcLogo"
       style="height: 25px"
       [alt]="textLogo"
  />
  {{textLogo}}

</a>
