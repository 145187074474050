import {
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import {TabViewModule} from 'primeng/tabview';
import {SidebarComponent} from '../../../components/common/sidebar/sidebar.component';
import {CommonHomeComponent} from '../../common-home/common-home.component';
import {CircleWithImageComponent} from "../../../components/circle-with-image/circle-with-image.component";
import {ColorsImageComponent} from "../../../colors-image/colors-image.component";
import {UploaderPhotoComponent} from "../../../components/admin/uploader-photo/uploader-photo.component";
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {deleteFromArray} from "../../../traits/array-tools";
import {BadgeModule} from "primeng/badge";
import {ShareImageComponent} from "../../../components/share-image/share-image.component";
import {CropRigionComponent} from "../../../components/crop-rigion/crop-rigion.component";
import {ImagesViewerGridSlidderComponent} from "../../../components/pages/images-viewer-grid-slidder/images-viewer-grid-slidder.component";
import {dateNow} from "../../../traits/datetime-tools";
import {EyeDropperComponent} from "../../../components/eye-dropper/eye-dropper.component";
import {ImageMosaicComponent} from "../../../components/image-mosaic/image-mosaic.component";
import {GrayscaleComponent} from "../../../components/grayscale/grayscale.component";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {HeaderComponent} from "../../../components/pages/header/header.component";
import {NavbarTopComponent} from "../../../components/pages/navbar-top/navbar-top.component";
import {CameraComponent} from "../../../components/pages/camera/camera.component";
import {PreloadComponent} from "../../../components/pages/preload/preload.component";
import {ListDownloadsComponent} from "../../../components/list-downloads/list-downloads.component";
import {
  addIcon,
  closeSquareIcon,
  deleteIcon,
  dotesHorizontalIcon,
  dotesVerticalIcon,
  editIcon,
  eyeOpenIcon,
  gridIcon,
  zoomPlusIcon
} from "../../../traits/icons-tools";
import {BtnDownloadComponent} from "../../../components/btn-download/btn-download.component";
import {AlbumComponent} from "../../../components/album/album.component";
import {ZipComponent} from "../../../components/zip/zip.component";
import {BrowserCloseAlertComponent} from "../../../components/browser-close-alert/browser-close-alert.component";
import {PackPalettesComponent} from "../../../components/pack-palettes/pack-palettes.component";
import {PalleteGeneratorImageComponent} from "../../../components/pallete-generator-image/pallete-generator-image.component";
import {PalleteGeneratorColorComponent} from "../../../components/pallete-generator-color/pallete-generator-color.component";
import {SketchConverterComponent} from "../../../components/sketch-converter/sketch-converter.component";
import {SliderModule} from "primeng/slider";
import {ToggleButtonModule} from "primeng/togglebutton";
import {BeforeAfterSliderComponent} from "../../../components/before-after-slider/before-after-slider.component";
import {ImageColorChangerComponent} from "../../../components/image-color-changer/image-color-changer.component";
import {ColorPanelComponent} from "../../../components/color-panel/color-panel.component";
import {ZoomImageComponent} from "../../../components/zoom-image/zoom-image.component";
import {HTMLImgComparisonSliderElement} from "img-comparison-slider";
import {ImageEditorComponent} from "../../../components/image-editor/image-editor.component";
import {windowWidth} from "../../../traits/window-tools";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {ImageTracingComponent} from "../../../components/image-postorizer/image-tracing.component";
import {srcSketch} from "../../../traits/image-tools";
import {arrayStartEndStep} from "../../../traits/params-tools";
import {SliderBtnsComponent} from "../../../components/slider-btns/slider-btns.component";
import {PageImageToGrayscaleServices} from "../../../services/pages/page-image-to-grayscale.services";
import {PageImageToMosaicServices} from "../../../services/pages/page-image-to-mosaic.services";


@Component({
  selector: 'app-page-image-to-mosaic',
  standalone: true,
  imports: [
    TabViewModule,
    SidebarComponent,
    CommonHomeComponent,
    CircleWithImageComponent,
    ColorsImageComponent,
    UploaderPhotoComponent,
    NgIf,
    FormsModule,
    BadgeModule,
    ShareImageComponent,
    CropRigionComponent,
    ImagesViewerGridSlidderComponent,
    NgForOf,
    EyeDropperComponent,
    ImageMosaicComponent,
    GrayscaleComponent,
    ButtonModule,
    HeaderComponent,
    NavbarTopComponent,
    CameraComponent,
    PreloadComponent,
    ListDownloadsComponent,
    BtnDownloadComponent,
    AlbumComponent,
    ZipComponent,
    BrowserCloseAlertComponent,
    PackPalettesComponent,
    PalleteGeneratorImageComponent,
    PalleteGeneratorColorComponent,
    SketchConverterComponent,
    SliderModule,
    ToggleButtonModule,
    BeforeAfterSliderComponent,
    ImageColorChangerComponent,
    ColorPanelComponent,
    ZoomImageComponent,
    ImageEditorComponent,
    OverlayPanelModule,
    ImageTracingComponent,
    SliderBtnsComponent,
  ],
  templateUrl: './page-image-to-mosaic.component.html',
})

export class PageImageToMosaicComponent {
  @ViewChild('roundPallete') roundPallete: ElementRef<HTMLCanvasElement>;
  @ViewChild('horizontalPallete') horizontalPallete: ElementRef<HTMLCanvasElement>;
  @ViewChild('verticalPallete') verticalPallete: ElementRef<HTMLCanvasElement>;
  @Input() public imageParent: string = null
  public nameHarmony: string = 'sketch';
  public showDownload: boolean = false;
  public showPreload: boolean = true;
  showBeforeAfterComponent: boolean = true;
  showSketchConverterComponent: boolean = true;
  showColorChangeComponent: boolean = true;
  showBeforeAfter: boolean = true;
  public imagePallete: string =srcSketch;
  public imageColorChange: string;
  public imageUploaded: string = srcSketch;
  public tempImage: string = srcSketch;
  public imageCrop: string =srcSketch;
  public imageSketch: string =srcSketch;
  showBackground: boolean = true;
  showColorSidebar: boolean = true;
  invertAmount: number = 1;
  blurAmount: number = 1;
  grayscaleAmount: number = 1;
  selectColor: string;
  brightness:number = 100;
  contrast:number = 100;
  saturation:number = 100;
  opacityFilter: number = 100;
  blurFilter: number = 0;

  mode: object ={name:'normal',value:'color'};
  modeIndex: any =1;

  public modes:object[] = [
    {name:'normal',value:'color'},
    {name:'soft', value:'screen'},
    {name:'hard',value:'overlay'},
  ];
  public showCropRigionBtn: boolean = true;
  public showCropRigion: boolean = false;

  protected readonly deleteFromArray = deleteFromArray;
  protected readonly dateNow = dateNow;
  protected readonly deleteIcon = deleteIcon;
  protected readonly dotesVerticalIcon = dotesVerticalIcon;
  protected readonly dotesHorizontalIcon = dotesHorizontalIcon;

  constructor(public dataPage: PageImageToMosaicServices) {
  }

  public reset() {
    this.blurAmount = 5;
    this.invertAmount = 1;
    this.grayscaleAmount = 1;
    this.showBackground = false;
    this.reInitColorChange()
  }
  resetCanvas(){
    this.brightness = 100;
    this.contrast = 100;
    this.saturation = 100;
    this.mode =   {name:'normal',value:'color'}
    this.reInitSketchConverter();
  }
  public showCropPanel() {
    this.showCropRigion = !this.showCropRigion;
  }
  public cropImage(base64: string) {
    this.imageUploaded = base64;
    this.reInitSketchConverter()
  }
  ngOnInit() {
  }
  ngAfterViewInit(): void {
    this.initBeforeAfter();

  }

        initBeforeAfter(){
      /*todo after 10.06*/
      }


  public setColor(colorHex: string) {
    this.selectColor = colorHex;
    this.reInitSketchConverter()
  }
  public reInitBeforeAfter() {
    this.showBeforeAfter = false;
    setTimeout(() => {
      this.showBeforeAfter = true;
      // this.initBeforeAfter();
    }, 50)
  }
  public reInitColorChange() {
    this.showColorChangeComponent = false;
    setTimeout(() => {
      this.showColorChangeComponent = true;
    }, 50)
  }

  public reInitSketchConverter() {
    this.showSketchConverterComponent = false;
    setTimeout(() => {
      this.showSketchConverterComponent = true;
    }, 50)
  }

  selectUpload(base64: string) {
    this.imageUploaded = base64;
    this.imageCrop = base64;
    this.tempImage = base64;
    this.reInitSketchConverter()
  }

    protected readonly editIcon = editIcon;
    protected readonly closeSquareIcon = closeSquareIcon;
  protected readonly zoomPlusIcon = zoomPlusIcon;




  protected readonly addIcon = addIcon;
  protected readonly windowWidth = windowWidth;
  protected readonly gridIcon = gridIcon;
  showSettings: boolean =false;
  protected readonly eyeOpenIcon = eyeOpenIcon;
  protected readonly arrayStartEndStep = arrayStartEndStep;
}
