import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {
  NgFor,
  NgIf
} from "@angular/common";
import { randomId } from 'src/app/traits/string-tools';
import { getColorPixel } from 'src/app/services/common/canvas-tools';
import {urlToBlob} from "../../traits/image-tools";

@Component({
  selector: 'app-eye-dropper',
  standalone:true,
  imports:[NgIf,NgFor],
  templateUrl: './eye-dropper.component.html',
  styleUrls: ['./eye-dropper.component.scss'],

})
export class EyeDropperComponent implements OnInit {
  @ViewChild('dv') dv: any;
  @Input() change: any;
  @Input() colors: any = [];
  @Input() colorsLibPicks: any = [];
  @Input() model: any;
  @Input() float: any = false;
  @Input() id: any = 'block';
  @Input() src: any;
  @Input() base64: any;
  @Input() showColors: any = true;
  @Input() row: any;
  public imgElement: any;
  public y: any;
  public x: any;
  public canvas: any;
  public ctx: any;
  @Output() eventEmit: any = new EventEmitter();
  @Output() selected = {hex: '#22142b', rgb: 'rgb(34,20,43)'};
  @Input() selectedImage: any;
  public moveColor = {hex: '#22142b', rgb: 'rgb(34,20,43)'};
  @Output() onColors = new EventEmitter();
  @Output() onClick = new EventEmitter();
  @Output() onColor = new EventEmitter();
  @Output() onMove = new EventEmitter();
  @Output() onColorsLibPicks = new EventEmitter();
  @Output() onPress = new EventEmitter();
  @Output() onPressColor = new EventEmitter();
  @Output() onDblclick = new EventEmitter();
  @Input() widthImage: any = 500;
  @Input() heightImage: any = 500;
  @Input() scaleX:any =  1;
  @Input() scale:any =  1;
  @Input() rotation:any =  0;
  @Input() translateX:any =  0;
  @Input() translateY:any =  0;
  @Input() sizeView: any = 'full';
  // @ts-ignore
  @ViewChild('accessId') accessId: ElementRef;

  constructor(
              public sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    this.id = '_' + Date.now() + '_eye_dropper_' + randomId();
    this.srcToBase64();
    this.initColors();
  }

  public initColors() {
/*    if (this.src && this.showColors) {
      this.cd.img.color(this.src, (colors: any) => {

     /!*   let filter:any = [
          {hex:'#040404'},
          {hex:'#0c0c09'},
          {hex:'#050605'},
          {hex:'#060806'},
          {hex:'#060505'},
          {hex:'#050506'},
          {hex:'#000000'},
        ]
        colors= this.cd.array.removeObjectsByValues(filter,colors,'hex')*!/
       /!* colors=  this.cd.array.removeByValues(
          filter
          ,colors)*!/
        this.colors = colors;
        // console.log(this.colors,'colors')

      });
    }*/
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['change'] && changes['change']?.previousValue != changes['change']?.currentValue) {
      // this.initCanvasImage();
    }
  }

  /* initCanvasImage() {
     this.imgElement = this.findEl(this.id);
     this.canvas = document.createElement('CANVAS');
     this.canvas.width = 400;
     this.canvas.height = 600;

     this.ctx = this.canvas.getContext('2d');
     this.ctx.drawImage(this.imgElement, 0, 0);
     // @ts-ignore
     let imgData = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
     JSManipulate.posterize.filter(imgData, {levels: 5});
     this.ctx.putImageData(imgData, 0, 0);
     this.base64 = this.canvas.toDataURL();
     /!*   this.imgElement = document.createElement('img');
        this.imgElement.setAttribute('class', this.id);
        this.imgElement.crossOrigin = 'Anonymous';
        this.imgElement.src = this.src;
        this.imgElement.onload = ()=>{


        }*!/
     // @ts-ignore
     // document.querySelector('.container-draw').appendChild(this.imgElement);
     this.clickEvent();
     this.moveEvent();
   }*/

 public  stage:any =  {

  };
  public transformRenderZoneParams() {
   // return  flipHorizontalDiv(this.scaleX);
  }

  public initEyeDropperClick() {
    this.imgElement = this.findEl('.' + this.id);
    // console.log(this.imgElement)

    // this.canvas.setAttribute('id', this.id);
    // if(this.cd.enabledEyeDropper === true){
    getColorPixel(
      this.imgElement,
      this.widthImage,
      this.heightImage,
      (colorPixelHex: any, src: any) => {
        this.selected.hex = colorPixelHex;
        // console.log(this.selected);
        this.onColor.emit(colorPixelHex);

        // this.base64 =src;
        // console.log(src, 'src');
      });
    // }
  }

  public initEyeDropperMove() {
    // console.log('e')

    /* this.imgElement = this.findEl('.'+this.id);
     // console.log(this.imgElement)
     this.canvas = document.createElement('CANVAS');
     // this.canvas.setAttribute('id', this.id);
     this.moveEvent();*/
  }


  ngAfterViewInit() {
    this.srcToBase64();
    // this.cdr.detectChanges();
    // this.initEyeDropperClick();
  }

  public saveUrl(url: any) {
    let urlSafe: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return urlSafe;
  }

  public srcToBase64() {
   urlToBlob(this.src, (base64: any) => {
      this.base64 = base64;
    });
  }

  public findEl(el: any) {
    return document.querySelector(el);
  };


  /* public moveEvent() {

     this.imgElement.addEventListener('mousemove', (e: any) => {

       // chrome
       if (e.offsetX) {
         this.x = e.offsetX;
         this.y = e.offsetY;
       }
       // firefox
       else if (e.layerX) {
         this.x = e.layerX;
         this.y = e.layerY;
       }

       this.useCanvas(this.canvas, this.imgElement, () => {
         // @ts-ignore
         let pixel = this.ctx.getImageData(this.x, this.y, 1, 1).data;
         let hexColor = this.rgbToHex(pixel[0], pixel[1], pixel[2]);
         let rgbColor = this.hexToRgb(pixel);
         let colorObj = {hex: hexColor, rgb: rgbColor};
         this.onMove.emit(colorObj);

         this.cd.previewColor.hex = hexColor;
       });

     }, false);

   }*/


  /*public useCanvas(el: any, image: any, callback: any) {

    this.canvas.width = this.widthImage;
    this.canvas.height = this.heightImage;
    this.ctx = getContext(this.canvas);

    this.ctx.drawImage(image, 0, 0, this.widthImage, this.heightImage);
    return callback();
  }*/

}
