import {Component, Input, OnInit} from '@angular/core';
import {scrollToId} from '../../../traits/window-tools';
import {Card} from '../../../interfaces/Card';
@Component({
  selector: 'app-scroll-cards-horizontal',
  templateUrl: './scroll-cards-horizontal.component.html',
  standalone: true,
  imports: []
})
export class ScrollCardsHorizontalComponent{
  @Input() cards: Array<Card>;
  @Input() width: any = 100;
  @Input() height: any = 100;
  @Input() showCountArray: any = 1000;
  public scrollToId(idFrom:any = 'myButton' ,idTo:any = 'insert2' ){
    scrollToId(idFrom,idTo)
  }
}
