// online-status.component.ts

import {
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import {OnlineStatusService} from '../../../services/common/online-status.service';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-online-status',
  templateUrl: './online-status.component.html',
  standalone:true,
  imports:[NgIf]
})
export class OnlineStatusComponent implements OnInit {
  online: boolean = navigator.onLine;
  @Output() onOnlineStatus = new EventEmitter<boolean>();

  constructor(private onlineStatusService: OnlineStatusService) { }

  ngOnInit(): void {
    this.onlineStatusService.getOnlineStatus().subscribe(isOnline => {
      this.online = isOnline;
      this.onOnlineStatus.emit(isOnline)
    });
  }
}
