@if (cards) {
  <div class=" sticky top-[50px] z-10 flex flex-col card-container">
    <div class="flex overflow-x-scroll p-1 hide-scroll-bar">
      <div class="flex flex-nowrap lg:ml-1 md:ml-1 ml-1">
        @for (cardItem of cards.slice(0, showCountArray); track cardItem.id) {
          @if (cardItem.sw) {
            <div class="inline-block px-3 text-center">

              <div (click)="scrollToId(cardItem.id,cardItem.id)"
                   [style.background-image]="'url(' + cardItem.srcPreview + ')'"
                   [style.width.px]="width"
                   [style.height.px]="height"
                   [style.color]="cardItem.color"
                   [style.border]="'2px solid ' + cardItem.color +' '"
                   class="   bg-no-repeat bg-cover  max-w-xs overflow-hidden rounded-lg shadow-md  hover:shadow-xl transition-shadow duration-300 ease-in-out"
              >
                <a class=" focus:ring-4 shadow-lg transform active:scale-75 transition-transform  pl-1 pr-1 rounded-xl bg-zinc-700  font-bold  text-[15px] -mt-3  shadow-lg ">
                <span class="truncate w-[70px]"
                      *ngIf="(cardItem.costDiscount)">
                    {{ cardItem.costDiscount }}
                  </span>
                  <span> <i class="fa-solid fa-circle-chevron-down"></i></span>
                </a>
              </div>
            </div>
          }
        }
      </div>
    </div>
  </div>
}
