import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  NgClass,
  NgFor,
  NgIf,
  NgStyle
} from "@angular/common";
import {videoIcon} from "../../../traits/icons-tools";
import {BadgeModule} from "primeng/badge";
import {CoinsComponent} from "../../coins/coins.component";
import {CdServices} from "../../../services/common/cd.services";
import {faCirclePlay} from "@fortawesome/free-solid-svg-icons/faCirclePlay";

@Component({
  selector: 'app-card-lesson',
  templateUrl: './card-lesson.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgStyle,
    NgClass,
    BadgeModule,
    CoinsComponent,
  ],

})
export class CardLessonComponent implements OnInit {
  @Input() site: any;
  @Input() lessonItem: any;
  @Input() modelParent: any;
  @Input() lessons: any;
  @Output() onSelectLesson = new EventEmitter();
  selectLesson: any;
  showLessonBtn: boolean;

  constructor(public cd:CdServices) {

  }

  ngOnInit() {

  }

setSelectLesson(lesson:any){
    this.selectLesson = lesson;
  this.onSelectLesson.emit(lesson);
}
  protected readonly videoIcon = videoIcon;
  protected readonly faCirclePlay = faCirclePlay;
}
