// online-status.service.ts

import { Injectable } from '@angular/core';
import { fromEvent, merge, Observable } from 'rxjs';
import { mapTo, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OnlineStatusService {

  constructor() { }

  // Функция для определения состояния подключения к интернету
  getOnlineStatus(): Observable<boolean> {
    return merge(
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    ).pipe(
      startWith(navigator.onLine) // Запускаем с начальным значением состояния подключения
    );
  }
}
