<p-overlayPanel #op>
  <img [src]="this.srcImage"
       class="w-16 h-16 rounded-full shadow-lg"
       alt="product" />
  <p>download...</p>
</p-overlayPanel>
<div  class=" mt-3 inline-flex rounded-md shadow-sm" role="group">
  <button *ngIf="listDownloadsCount>0 && showList"
          class=" text-lg inline-flex items-center px-4 py-2 font-medium text-white bg-gradient-to-r from-purple-500 to-blue-500 border border-transparent rounded-l-md hover:bg-gradient-to-r hover:from-blue-600 hover:to-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          (click)="showDownloadsList =true"
  >
    <i [class]="listIcon"
       pBadge
       [value]="listDownloadsCount"
    ></i>
  </button>

  <button   #targetEl [disabled]="disabled"
          class="relative items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-blue-500 border border-transparent rounded-l-md hover:bg-gradient-to-r hover:from-blue-600 hover:to-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          (click)="(!cd.lock|| cd.logined)?downloadImage(srcImage):null; op.show($event, targetEl); coins.coinService.minusCoin()"
  >
<!--    <img [src]="srcImage" class=" absolute top-0 right-0 w-16 h-16 rounded-full shadow-lg bg-zinc-800 p-1 border-1" alt="pallete_generate">-->

    <span [hidden]="!showCoin" class="absolute -top-4 -left-2">
       <app-coins #coins [showCoinMinus]="true"></app-coins>
          </span>
    <i [class]="downloadIcon"></i>
    {{ textBtn }}

  </button>

  <button #showSharedBtnElement *ngIf="showShare"
          class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-blue-500 border border-transparent rounded-r-md hover:bg-gradient-to-r hover:from-blue-600 hover:to-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          (click)="showShareImage(showSharedBtnElement);coins.coinService.minusCoin()">
    <i [class]="shareIcon"></i>
  </button>

<!--*ngIf="showSharedComponent"-->
  <app-share-image #shareComponent
                   [srcImage]="srcImage"
                   [showBtn]="false"
  ></app-share-image>
  <button *ngIf="showLib"
    (click)=" saveResultToDB(srcImage)"
    class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-blue-500 border border-transparent rounded-md hover:bg-gradient-to-r hover:from-blue-600 hover:to-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
  >
    <i  [class]="saveIcon"></i>
   <span *ngIf="addParent">save...</span>
  </button>
</div>
<!--<app-album
  *ngIf="(addParent && imageResult)"
  [imageSrc]="imageResult"
  [addParent]="addParent"
  [showUploadsList]="false"
></app-album>-->
<app-sidebar [show]="showDownloadsList"
             (onSave)="showDownloadsList = false; "
             [showClose]="true"
             [height]="100"
             [width]="100"
             [ext]="'%'"
             [zIndex]="100000"
             [position]="'top'"

>
  <app-list-downloads
    *ngIf="listDownloadsCount && showDownloadsList"
    [models]="listDownloads"
    (onClose)="showDownloadsList = false;"
  ></app-list-downloads>
</app-sidebar>
