<canvas [width]="300" [height]="300" [hidden]="!showCanvasPreview" #canvas></canvas>

<div class="flex flex-col space-x-1 w-[300px] ">
  <div class="text-white p-1">
    <button
      (click)="resetCanvas()"
      class=" absolute bottom-2 right-2 bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
    >
      Reset
      <i class="fa-solid fa-arrows-spin"></i>
    </button>
  </div>
  <div class="text-white p-1">
    <span>brightness:{{ brightness }}</span>
    <p-slider [min]="1" [max]="300"
              [(ngModel)]="brightness"
              (ngModelChange)="applyFilters()">
    </p-slider>
  </div>
  <div class="text-white p-1">
    <span>Contrast:{{ contrast }}</span>
    <p-slider [min]="1" [max]="300"
              [(ngModel)]="contrast"
              (ngModelChange)="applyFilters()">
    </p-slider>
  </div>
  <div class="text-white p-1">
    <span>Saturation:{{ saturation }}</span>
    <p-slider [min]="1" [max]="300"
              [(ngModel)]="saturation"
              (ngModelChange)="applyFilters()">
    </p-slider>
  </div>
</div>


