import {Injectable} from '@angular/core';
import {LocalStorage} from '../common/local-storage';
import {
  jsonDecrypt,
  jsonEncrypt
} from "../../traits/crypt-tools";

@Injectable({
  providedIn: 'root',
})
export class ClientLocalstorageServices {
  public STORAGE_KEY: string = 'customer';

  public setStorageData(clientData: any = {}) {
    return LocalStorage.setItem(this.STORAGE_KEY , jsonEncrypt(clientData));// LocalStorage.setStorageData(this.STORAGE_KEY, );
  }

  public getStorageData() {

 let exist:any = LocalStorage.getItem(this.STORAGE_KEY)
   return exist? jsonDecrypt(exist):null;
    // return exist?jsonDecrypt(exist)
  }

}

/*

  private initFromStorage() {
    let storageCustomer = this.getStorageData(this.STORAGE_KEY);
    if (storageCustomer) {
      return storageCustomer;
    }
  }

  public checkCustomerLogin() {
    // @ts-ignore

    let emailStorage = this.getStorageData('place').email;
    if (emailStorage) {
      this.existEmailInLocalStorage = emailStorage;
    } else {
      this.existEmailInLocalStorage = false;
    }
    // this.showLogin = !this.existEmailInLocalStorage;
    /!*    ? this.loginEnterStatus = true : this.loginEnterStatus = false;
        console.log(this.loginEnterStatus)
        !this.loginEnterStatus ? this.showLogin = true : this.showLogin = false;*!/
  }
*/

/* public setCustomerEmailInPlaceStorage(email: any) {
   this.getStorageData('place', (place: any) => {
     let placeEmailConcat = {...{email: email}, ...place};
     this.setStorageData('place', placeEmailConcat);
     // return place = this.getStorageData('place');
     // console.log(place, 'setCustomerEmailInPlaceStorage');

   });
 }*/
