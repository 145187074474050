<!--<app-browser-close-alert></app-browser-close-alert>-->

<div style="height:25px; overflow: hidden"
     class="absolute top-0 seo-container text-center z-0">
  <h1>{{ dataPage.seoTexts.h1 }}</h1>
  <h2>{{ dataPage.seoTexts.h2 }}</h2>
  <h3>{{ dataPage.seoTexts.h3 }}</h3>
  <p>{{ dataPage.seoTexts.text }}</p>
  <p>{{ dataPage.seoTexts.textEndPage }}</p>
</div>
<app-navbar-top></app-navbar-top>
<app-header
  [showCounters]="false"
  [bgImage]="'assets/header-palettes-generator.png'"
  [headerText]="dataPage.seoTexts.h1"
  [subHeaderText]="dataPage.seoTexts.h1"
  [actionText]="dataPage.seoTexts.h3"
></app-header>
<div class="mt-2">
  <div
    [class]="'bgColor'"
    [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
    class="mt-[75px]  bg-[length:50px_50px] bg-opacity-10  bg-repeat flex justify-center items-center flex-col bg-gradient-to-br from-gray-800 to-gray-900 relative animate-gradient-x">

<app-text-to-image
  [api]="'stable-diffusion'"
></app-text-to-image>

  </div>
</div>
