
<div *ngIf="showResult" class="chessboard-bg">
  <img [src]="imgResult"/>
</div>








<app-sidebar [show]="showParamsSidebar"
             (onSave)="showParamsSidebar =false;"
             [showClose]="true"
             [height]="100"
             [width]="100"
             [ext]="'%'"
             [zIndex]="100000"
             [position]="'top'"

>
  <div class="flex flex-col space-x-1 w-[300px] ">
      <img *ngIf="showParamsSidebar" class="w-auto h-auto"
           [src]="imgResult"
           alt="ref image"
      >
<!--    <p-scrollPanel  [style]="{ width: '100%', height: '300px' }">
&lt;!&ndash;      <button
        (click)="saveParamsData()"
        class=" bg-gradient-to-r from-purple-500 to-blue-500 hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
      >
        ....
      </button>&ndash;&gt;

            <div class="text-white p-1"><div class="text-white p-1">
              <span>Sampling ({{ defaultParams.colorsampling }})</span>
              <div [style.width.px]="250" class="flex overflow-x-auto py-1 space-x-1">
                <ng-container *ngFor=" let paramItem of arrayStartEndStep(1,100,1)">
                  <button (click)="defaultParams.colorsampling = paramItem; setBigTrace()"
                          class="block m-1 leading-4 rounded-sm w-[25px] h-[25px] bg-blue-600 p-1 text-white ">{{ paramItem }}</button>
                </ng-container>
              </div>
            </div>
              <div class="text-white p-1">
                <span>Colors ({{ defaultParams.numberofcolors }})</span>
                <div [style.width.px]="250" class="flex overflow-x-auto py-1 space-x-1">
                  <ng-container *ngFor=" let paramItem of arrayStartEndStep(2,500,1)">
                    <button (click)="defaultParams.numberofcolors = paramItem; setBigTrace()"
                            class="block m-1 leading-4 rounded-sm w-[25px] h-[25px] bg-blue-600 p-1 text-white ">{{ paramItem }}</button>
                  </ng-container>
                </div>

              </div>

              <div class="text-white p-1">
                <span>Blur radius ({{ defaultParams.blurradius }})</span>
                <div [style.width.px]="250" class="flex overflow-x-auto py-1 space-x-1">
                  <ng-container *ngFor=" let paramItem of arrayStartEndStep(0,500,1)">
                    <button (click)="defaultParams.blurradius = paramItem; setBigTrace()"
                            class="block m-1 leading-4 rounded-sm w-[25px] h-[25px] bg-blue-600 p-1 text-white ">{{ paramItem }}</button>
                  </ng-container>
                </div>

              </div>
              <div class="text-white p-1">
                <span>Blur Delta ({{ defaultParams.blurdelta }})</span>
                <div [style.width.px]="250" class="flex overflow-x-auto py-1 space-x-1">
                  <ng-container *ngFor=" let paramItem of arrayStartEndStep(0,500,1)">
                    <button (click)="defaultParams.blurdelta = paramItem; setBigTrace()"
                            class="block m-1 leading-4 rounded-sm w-[25px] h-[25px] bg-blue-600 p-1 text-white ">{{ paramItem }}</button>
                  </ng-container>
                </div>
              </div>
              <span>mincolorratio ({{ defaultParams.mincolorratio }})</span>
              <div [style.width.px]="250" class="flex overflow-x-auto py-1 space-x-1">
                <ng-container *ngFor=" let paramItem of arrayStartEndStep(0,500,1)">
                  <button (click)="defaultParams.mincolorratio = paramItem; setBigTrace()"
                          class="block m-1 leading-4 rounded-sm w-[25px] h-[25px] bg-blue-600 p-1 text-white ">{{ paramItem }}</button>
                </ng-container>
              </div>
            </div>
            <div class="text-white p-1">
              <span>colorquantcycles ({{ defaultParams.colorquantcycles }})</span>
              <div [style.width.px]="250" class="flex overflow-x-auto py-1 space-x-1">
                <ng-container *ngFor=" let paramItem of arrayStartEndStep(0,500,1)">
                  <button (click)="defaultParams.colorquantcycles = paramItem; setBigTrace()"
                          class="block m-1 leading-4 rounded-sm w-[25px] h-[25px] bg-blue-600 p-1 text-white ">{{ paramItem }}</button>
                </ng-container>
              </div>
            </div>
            <div class="text-white p-1">
              <span>layering ({{ defaultParams.layering }})</span>
              <div [style.width.px]="250" class="flex overflow-x-auto py-1 space-x-1">
                <ng-container *ngFor=" let paramItem of arrayStartEndStep(0,500,1)">
                  <button (click)="defaultParams.layering = paramItem; setBigTrace()"
                          class="block m-1 leading-4 rounded-sm w-[25px] h-[25px] bg-blue-600 p-1 text-white ">{{ paramItem }}</button>
                </ng-container>
              </div>
            </div>
            <div class="text-white p-1">
              <span>roundcoords ({{ defaultParams.roundcoords }})</span>
              <div [style.width.px]="250" class="flex overflow-x-auto py-1 space-x-1">
                <ng-container *ngFor=" let paramItem of arrayStartEndStep(0,500,1)">
                  <button (click)="defaultParams.roundcoords = paramItem; setBigTrace()"
                          class="block m-1 leading-4 rounded-sm w-[25px] h-[25px] bg-blue-600 p-1 text-white ">{{ paramItem }}</button>
                </ng-container>
              </div>
            </div>

            <div class="text-white p-1">
              <span>Stroke width ({{ defaultParams.strokewidth }})</span>
              <div [style.width.px]="250" class="flex overflow-x-auto py-1 space-x-1">
                <ng-container *ngFor=" let paramItem of arrayStartEndStep(1,10,1)">
                  <button (click)="defaultParams.strokewidth = paramItem; setBigTrace()"
                          class="block m-1 leading-4 rounded-sm w-[25px] h-[25px] bg-blue-600 p-1 text-white ">{{ paramItem }}</button>
                </ng-container>
              </div>
            </div>
            <div class="text-white p-1">
              <span>QT res ({{ defaultParams.qtres }})</span>
              <div [style.width.px]="250" class="flex overflow-x-auto py-1 space-x-1">
                <ng-container *ngFor=" let paramItem of arrayStartEndStep(1,100,1)">
                  <button (click)="defaultParams.qtres = paramItem; setBigTrace()"
                          class="block m-1 leading-4 rounded-sm w-[25px] h-[25px] bg-blue-600 p-1 text-white ">{{ paramItem }}</button>
                </ng-container>
              </div>
            </div>
            <div class="text-white p-1">
              <span>LT res ({{ defaultParams.ltres }})</span>
              <div [style.width.px]="250" class="flex overflow-x-auto py-1 space-x-1">
                <ng-container *ngFor=" let paramItem of arrayStartEndStep(1,100,1)">
                  <button (click)="defaultParams.ltres = paramItem; setBigTrace()"
                          class="block m-1 leading-4 rounded-sm w-[25px] h-[25px] bg-blue-600 p-1 text-white ">{{ paramItem }}</button>
                </ng-container>
              </div>
            </div>
            <div class="text-white p-1">
              <span>pathomit ({{ defaultParams.pathomit }})</span>
              <div [style.width.px]="250" class="flex overflow-x-auto py-1 space-x-1">
                <ng-container *ngFor=" let paramItem of arrayStartEndStep(1,100,1)">
                  <button (click)="defaultParams.pathomit = paramItem; setBigTrace()"
                          class="block m-1 leading-4 rounded-sm w-[25px] h-[25px] bg-blue-600 p-1 text-white ">{{ paramItem }}</button>
                </ng-container>
              </div>
            </div>
            <div class="text-white p-1">
              <span>scale ({{ defaultParams.scale*widthImage }}/{{ defaultParams.scale*heightImage }}px )</span>
              <div [style.width.px]="250" class="flex overflow-x-auto py-1 space-x-1">
                <ng-container *ngFor=" let paramItem of arrayStartEndStep(1,10,1)">
                  <button (click)="defaultParams.scale = paramItem; setBigTrace()"
                          class="block m-1 leading-4 rounded-sm w-[100px] h-[100px] bg-blue-600 p-1 text-white ">
                    {{ paramItem*heightImage }}/{{ paramItem*heightImage }}px
                  </button>
                </ng-container>
              </div>
            </div>
    &lt;!&ndash;
          <p-accordionTab header="Others">


            &lt;!&ndash;<div class="text-white p-1">
              <span>linefilter:{{ defaultParams.linefilter }}</span>
              <p-toggleButton [(ngModel)]="defaultParams.linefilter"
                              (ngModelChange)="setBigTrace()"
                              onLabel="Enabled"
                              offLabel="Disabled"
              ></p-toggleButton>
            </div>&ndash;&gt;
           &lt;!&ndash; <div class="text-white p-1">
              <span>corsenabled:{{ defaultParams.corsenabled }}</span>
              <p-toggleButton [(ngModel)]="defaultParams.corsenabled"
                              (ngModelChange)="setBigTrace()"
                              onLabel="Enabled"
                              offLabel="Disabled"
              ></p-toggleButton>
            </div>&ndash;&gt;
           &lt;!&ndash; <div class="text-white p-1">
              <span>rightangleenhance:{{ defaultParams.rightangleenhance }}</span>
              <p-toggleButton [(ngModel)]="defaultParams.rightangleenhance"
                              (ngModelChange)="setBigTrace()"
                              onLabel="Enabled"
                              offLabel="Disabled"
              ></p-toggleButton>
            </div>&ndash;&gt;

           &lt;!&ndash; <div class="text-white p-1">
              <span>viewbox:{{ defaultParams.viewbox }}</span>
              <p-toggleButton [(ngModel)]="defaultParams.viewbox"
                              (ngModelChange)="setBigTrace()"
                              onLabel="Enabled"
                              offLabel="Disabled"
              ></p-toggleButton>
            </div>
            <div class="text-white p-1">
              <span>desc:{{ defaultParams.desc }}</span>
              <p-toggleButton [(ngModel)]="defaultParams.desc"
                              (ngModelChange)="setBigTrace()"
                              onLabel="Enabled"
                              offLabel="Disabled"
              ></p-toggleButton>
            </div>&ndash;&gt;
          </p-accordionTab>&ndash;&gt;

    </p-scrollPanel>-->
  </div>
</app-sidebar>


<!--<div *ngIf="showHorizontalBar && paramsList" class="text-white p-1 w-[300px]">

  <div [style.width.px]="windowWidth()"
       class="flex overflow-x-auto py-1 space-x-1">
    <button
      (click)=" showParamsSidebar  =true"
      class="absolute top-[250px] left-[20px] z-20 bg-gradient-to-r from-purple-500 to-blue-500 hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
    >
      <i class="fa-solid fa-sliders"></i>
    </button>

    <ng-container *ngFor=" let modeItem of paramsList">
      <img (click)="setParamsFromList(modeItem);"
           [src]="modeItem.srcPreview" alt="upload_image"
           class="p-3 m-1 w-[100px] h-[100px] object-cover rounded cursor-pointer"
      >
    </ng-container>
  </div>
</div>-->

<!--<app-sidebar [show]="showListSidebar"
             (onSave)="showListSidebar =false;"
             [showClose]="true"
             [height]="100"
             [width]="100"
             [ext]="'%'"
             [zIndex]="100000"
             [position]="'top'"

>
  <div class="text-white p-1 ">
    @if (showListSidebar) {
      <div class="m-3 p-3">
        <div class="w-full  p-3 rounded-lg shadow-lg">
          <p-scrollPanel [style]="{height:'600px'}">
            <div class="grid grid-cols-1 md:grid-cols-1 gap-4">
              <ng-container *ngFor=" let modeItem of paramsList">
                <img (click)="setParamsFromList(modeItem)" [src]="modeItem.srcPreview" alt="upload_image"
                     class="p-3 m-1 w-[300px] h-[300px] object-cover rounded cursor-pointer"

                >
              </ng-container>
            </div>
          </p-scrollPanel>
        </div>
      </div>
    }
  </div>
</app-sidebar>-->


