import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtClubServices} from '../static/static-art-club.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {Site} from '../../interfaces/site';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageImageToTraceServices {
  public SERVER_NAME: string = 'image-to-trace';
  public DOMAIN: string = 'art-club.artas.org.ua/image-to-trace';
  public route: string = 'image-to-trace';

  public fromServer: boolean = false;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'Image to trace',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'Image to trace',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Image to Trace: Transform Your Images into Stylized Art with Precision',
    description: 'Empower your creativity with Image to Trace, the innovative app designed to elevate your images into stylized artworks by adjusting color counts, tracing, and posterization for a custom visual experience.',
    text: 'Image to Trace to the future of photo editing and digital artistry with Image to Trace, where the transformation of your everyday images into striking stylized graphics is just a few taps away. With our cutting-edge app, you hold the power to fully customize the look and feel of your images, ensuring each piece is infused with your unique artistic vision.\n' +
      '\n' +
      'A boundless palette awaits, as Image to Trace\'s dynamic color control allows for the precise adjustment of color count, enabling your photos to leap out with vibrancy or whisper with subtlety. Whether you\'re gearing up for graphic design, fashioning fine arts, or simply seeking to spice up your social media feed, our color manipulation tools are designed to cater to your every whim.\n' +
      '\n' +
      'Dive into the details with our tracing feature - a digital take on the classic art of defining edges. Refine your images for that hand-drawn look, or create bold standout pieces that capture every eye. The freedom is yours as you shape the lines and curves of your subjects.\n' +
      '\n' +
      'But that\'s not all. The posterization function within Image to Trace is nothing short of a game-changer, giving you the capability to manipulate images for that trendy, abstract feel or to distill visual complexity into impactful, simplified statements. Make your photos pop with style and personality by adjusting the posterization levels to your heart\'s content.\n' +
      '\n' +
      'Each control within the Image to Trace interface is designed with you in mind - intuitive, responsive, and always ready to realize your creative vision. Say goodbye to complicated processes and hello to straightforward, satisfying results.\n' +
      '\n' +
      'Join the community of digital artists and creative enthusiasts who have already discovered the transformative effects of Image to Trace. Download our app today to start your journey of image perfection. Whether for professional aspirations or personal pleasure, Image to Trace is your companion in the art of visual enhancement.\n' +
      '\n' +
      'Embark on your artistic quest with Image to Trace and reshape the way the world sees your images.Unleash your creativity and transform ordinary images into extraordinary art with the Image to Trace app. This innovative app provides a creative toolbox for artists, designers, and photo enthusiasts who wish to elevate their images through stylized effects, including customizable color adjustments, intricate tracing options, and unique posterization features.\n' +
      '\n' +
      'Unlock the full potential of your photos by fine-tuning their color schemes to match your artistic vision. With Image to Trace, adjusting the color count is simple and effective, allowing you to create visuals that stand out with their clarity and depth.\n' +
      '\n' +
      'The app\'s tracing functionality goes beyond ordinary boundaries, enabling you to outline and emphasize the key elements of your images precisely. Whether it\'s adding a delicate sketch effect or defining bold contours, Image to Trace offers unparalleled control over your artistic direction.\n' +
      '\n' +
      'Explore the artistic technique of posterization to simplify colors and transform your images into visually stunning pieces that capture attention and evoke emotion. This feature allows for the creative expression of moods and themes, making every image a statement piece.\n' +
      '\n' +
      'Suitable for both beginners and seasoned professionals, Image to Trace is designed to inspire creativity and push the limits of digital artistry. Download the app now and embark on a journey of transformation where your photos become the canvas for your imagination.\n' +
      '\n' +
      'Transform, stylize, and create with Image to Trace—where every image is a masterpiece waiting to happen.Dive deeper into the art of image transformation with Image to Trace, the versatile app that redefines the boundaries between photography and digital art. Its user-friendly interface and powerful features make it an essential tool for anyone looking to infuse their images with a unique artistic touch.\n' +
      '\n' +
      'With Image to Trace, you control the narrative of your visuals. From subtle aesthetic tweaks to dramatic stylistic changes, the app empowers you to reimagine what your images can become. Adjust the color count to achieve the perfect balance between realism and abstraction, enhancing the emotional impact of your work.\n' +
      '\n' +
      'The tracing feature offers a bridge between photography and drawing, allowing you to highlight the essence of your images through enhanced outlines and details. This process not only accentuates the subject matter but also introduces a personal touch that distinguishes your work in the digital landscape.\n' +
      '\n' +
      'Posterization in Image to Trace is not just a feature; it\'s a doorway to new creative realms. By adjusting the levels, you can strip down complexities or amplify contrasts, crafting visuals that resonate with viewers and leave a lasting impression.\n' +
      '\n' +
      'Whether you\'re a professional artist looking to expand your digital toolkit, a graphic designer in search of new inspiration, or a hobbyist eager to explore the art of image stylization, Image to Trace is designed for you. Its seamless integration of color adjustment, tracing, and posterization tools offers endless possibilities to transform ordinary images into extraordinary works of art.\n' +
      '\n' +
      'Embrace the potential of Image to Trace and let it guide you on a creative journey that transcends traditional boundaries. Explore, experiment, and express your artistic vision like never before. Download the app today and step into a world where every image holds the promise of discovery and transformation.',
    h1: 'Image to Trace: The Ultimate Image Transformation Experience',
    h2: 'Image to Trace: Fine-Tune Colors Like a Pro with Image to Trac',
    h3: 'Master Tracing and Posterization for Artistic Flair',
    h4: 'Creative Color Adjustment at Your Fingertips',
    h5: 'Precision Tracing for Enhanced Artistic Control',
    textEndPage: 'Image to Trace app, stylize images, adjust color count, image tracing, posterization, photo editing, digital art, creative app, transform images, artistic filter',
    keywords: [
      'Image to Trace app',
      'stylize images',
      'adjust color count',
      'image tracing',
      'posterization',
      'photo editing',
      'digital art',
      'creative app',
      'transform images',
      'artistic filter'
    ],
  };
  public site: any

  constructor(public server: ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  getSite() {
    /*  if (this.fromServer) {
        return this.getSiteFromServer();
      } else {
        return this.getSiteStatic();
      }*/

  }

  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }

  public saveSite(site: object) {
    this.site = site
    this.downloadSiteBtn(this.site);
    // let server: any = inject(ServerSiteServices)
    this.server.saveSite(this.DOMAIN, this.site);
  }

  getSiteStatic() {
    return this.site = inject(StaticArtClubServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer() {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: Site): void => {
      this.site = responseSite;
    });
    return this.site;
  }

}
