import {
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';
import {TabViewModule} from 'primeng/tabview';
import {SidebarComponent} from '../../../components/common/sidebar/sidebar.component';
import {CommonHomeComponent} from '../../common-home/common-home.component';
import {CircleWithImageComponent} from "../../../components/circle-with-image/circle-with-image.component";
import {ColorsImageComponent} from "../../../colors-image/colors-image.component";
import {UploaderPhotoComponent} from "../../../components/admin/uploader-photo/uploader-photo.component";
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {
  addToEnd,
  addToStart,
  deleteFromArray
} from "../../../traits/array-tools";
import {BadgeModule} from "primeng/badge";
import {ShareImageComponent} from "../../../components/share-image/share-image.component";
import {CropRigionComponent} from "../../../components/crop-rigion/crop-rigion.component";
import {ImagesViewerGridSlidderComponent} from "../../../components/pages/images-viewer-grid-slidder/images-viewer-grid-slidder.component";
import {
  dateNow,
  reInit
} from "../../../traits/datetime-tools";
import {EyeDropperComponent} from "../../../components/eye-dropper/eye-dropper.component";
import {ImageMosaicComponent} from "../../../components/image-mosaic/image-mosaic.component";
import {GrayscaleComponent} from "../../../components/grayscale/grayscale.component";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {HeaderComponent} from "../../../components/pages/header/header.component";
import {NavbarTopComponent} from "../../../components/pages/navbar-top/navbar-top.component";
import {CameraComponent} from "../../../components/pages/camera/camera.component";
import {PreloadComponent} from "../../../components/pages/preload/preload.component";
import {ListDownloadsComponent} from "../../../components/list-downloads/list-downloads.component";

import {BtnDownloadComponent} from "../../../components/btn-download/btn-download.component";
import {AlbumComponent} from "../../../components/album/album.component";
import {ZipComponent} from "../../../components/zip/zip.component";
import {BrowserCloseAlertComponent} from "../../../components/browser-close-alert/browser-close-alert.component";
import {PackPalettesComponent} from "../../../components/pack-palettes/pack-palettes.component";
import {PalleteGeneratorImageComponent} from "../../../components/pallete-generator-image/pallete-generator-image.component";
import {PalleteGeneratorColorComponent} from "../../../components/pallete-generator-color/pallete-generator-color.component";
import {SketchConverterComponent} from "../../../components/sketch-converter/sketch-converter.component";
import {SliderModule} from "primeng/slider";
import {ToggleButtonModule} from "primeng/togglebutton";
import {InfiniteBoardComponent} from "../../../components/infinite-board/infinite-board.component";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {
  addModelDB,
  countTableDB,
  deleteModelDB,
  getTableDB,
  updateListDB,
  updateModelDB
} from "../../../traits/indexedDB";
import {
  archiveIcon,
  deleteIcon,
  dotesHorizontalIcon,
  dotesVerticalIcon,
  gridIcon,
  libsIcon,
  listIcon,
  lockCloseIcon,
  lockOpenIcon,
  plusIcon,
  saveIcon,
  toolsIcon
} from "../../../traits/icons-tools";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {
  cloneModel,
  imageBoardModel,
  listBoardModel,
  stageModel,
  textBoardModel,
  toolBoardModel
} from "../../../traits/model-tools";
import {
  textDecrypt,
  textEncrypt
} from "../../../traits/crypt-tools";
import {
  compress,
  srcLogo
} from "../../../traits/image-tools";
import {PageInfiniteBoardServices} from "../../../services/pages/page-infinite-board.services";
import {windowHeight} from "../../../traits/window-tools";
import {CdServices} from "../../../services/common/cd.services";
import {
  exportStage,
  exportTextToBin
} from 'src/app/traits/importExport-tools';
import {StageDbServices} from "../../../services/stage-db.services";
import {PageArtClubServices} from "../../../services/pages/page-art-club.services";
import {filter} from "rxjs";

@Component({
  selector: 'app-page-ref-boards',
  standalone: true,
  imports: [
    TabViewModule,
    SidebarComponent,
    CommonHomeComponent,
    CircleWithImageComponent,
    ColorsImageComponent,
    UploaderPhotoComponent,
    NgIf,
    FormsModule,
    BadgeModule,
    ShareImageComponent,
    CropRigionComponent,
    ImagesViewerGridSlidderComponent,
    NgForOf,
    EyeDropperComponent,
    ImageMosaicComponent,
    GrayscaleComponent,
    ButtonModule,
    HeaderComponent,
    NavbarTopComponent,
    CameraComponent,
    PreloadComponent,
    ListDownloadsComponent,
    BtnDownloadComponent,
    AlbumComponent,
    ZipComponent,
    BrowserCloseAlertComponent,
    PackPalettesComponent,
    PalleteGeneratorImageComponent,
    PalleteGeneratorColorComponent,
    SketchConverterComponent,
    SliderModule,
    ToggleButtonModule,
    InfiniteBoardComponent,
    ScrollPanelModule,
    OverlayPanelModule,
  ],
  templateUrl: './page-ref-boards.component.html',
})

export class PageRefBoardsComponent {
  @ViewChild('infiniteBoard') infiniteBoard: ElementRef<HTMLCanvasElement>;
  public imagesBoard: any[] = []
  public storageKey: string = 'infinityBoard';
  public tableKey: string = 'stages';
  public showBoardComponent: any = true;
  public selectStage: any;
  public stagesList: any[];
  public showStagesList: boolean = true;
  public showToolsList: boolean = false;
  lockAll: boolean = false;
  showGrid: boolean = true;
  public saveStatus: any = false;
  public showPreload: boolean = true;
  public elementsTypes: any = [
    {
      name: 'List',
      icon: listIcon,
      type: 'list',
      model:listBoardModel()
    },
    {
      name: 'Text',
      icon: libsIcon,
      type: 'text',
      model:textBoardModel()
    },
  ]
  protected readonly plusIcon = plusIcon;
  protected readonly deleteIcon = deleteIcon;
  protected readonly dotesVerticalIcon = dotesVerticalIcon;
  protected readonly saveIcon = saveIcon;
  protected readonly lockCloseIcon = lockCloseIcon;
  protected readonly lockOpenIcon = lockOpenIcon;
  protected readonly gridIcon = gridIcon;
  protected readonly dotesHorizontalIcon = dotesHorizontalIcon;
  protected readonly listIcon = listIcon;
  protected readonly archiveIcon = archiveIcon;
  filterByColor: string;
  public  toolsList: any[];

  constructor(
    public dataPage: PageInfiniteBoardServices,
    public dataPageArtClub: PageArtClubServices,
              public cd:CdServices,
              public stageDB:StageDbServices
  ) {
  }

  async ngOnInit() {
    await this.initToolList()

    this.getStages()
  }
  async initToolList(){
      let products =   this.dataPageArtClub.site.organizer.messenges;
      if(products){
        let tools =  products.filter((itemProduct:any)=>{
          if(itemProduct.sw === true){
            return itemProduct
          }
        })
        this.toolsList = tools;
        console.log(tools,'tools')
      }


  }
  setTool(itemTool: any) {
  let elementType = {
      name: 'Tool',
        icon: toolsIcon,
      type: 'tool',
      model:toolBoardModel(itemTool)
    }
    this.addTextToBoard(elementType)
    this.showToolsList = false;
  }
  reInitBoardComponent() {
    this.showBoardComponent = false;
    // this.showPixelizer =false;
    setTimeout(() => {
      this.showBoardComponent = true;
    })
  }

  public getStages() {
    this.stageDB.getStages(this.storageKey)
    this.showStagesList = true;
    this.showBoardComponent = false;
    getTableDB(this.storageKey, this.tableKey, (stages: any[]) => {
      this.selectStage = stages[0]
      this.stagesList = stages
    })
    if (!this.selectStage) {
      this.selectStage = stageModel()
    }

  }

  updateStatusSave() {
    this.saveStatus = true
    reInit(() => {
      this.saveStatus = false
    }, 1500)
  }


  public updateStages() {
    updateListDB(this.storageKey, this.tableKey, this.stagesList)
    this.updateStatusSave()
  }

  public updateStage() {
    updateModelDB(this.storageKey, this.tableKey, this.selectStage)
    this.updateStatusSave()
  }

  save() {
    this.updateStage();
    this.updateStages();
  }
  stagePositionReset(){
    this.selectStage.x = -1000
    this.selectStage.y = -1000
    // this.interactRaw();

  }
  addTextToBoard(elementMenu:any) {
// Вычисляем координаты для центрирования изображения
    const imageX = (2000 / 2) - (150 / 2);
    const imageY = (2000 / 2) - (150 / 2);

    let imageModelBoard: any = elementMenu.model
    imageModelBoard.x = imageX;
    imageModelBoard.y = imageY;
    addToEnd(imageModelBoard, this.selectStage.images)
    this.save();
    this.stagePositionReset();

    this.reInitBoardComponent()


  }

  addImageToBoard(base64: string) {
    this.filterByColor =''
    // const sceneWidth = 4000;
    // const sceneHeight = 4000;
    // const imageWidth = 100;
    // const imageHeight = 100;

// Вычисляем координаты для центрирования изображения
    const imageX = (2000 / 2) - (150 / 2);
    const imageY = (2000 / 2) - (150 / 2);
    compress(base64, {
      quality: 1,
      maxHeight: 1500
    }, (baseCompress64: any) => {
      let imageModelBoard: any = imageBoardModel(baseCompress64);
      imageModelBoard.x = imageX;
      imageModelBoard.y = imageY;
      addToEnd(imageModelBoard, this.selectStage.images)
      this.save();
      this.reInitBoardComponent()
    });
    this.stagePositionReset();
  }

  setStage(stageItem: any) {
    this.selectStage = stageItem;
    this.showStagesList = false;
    this.reInitBoardComponent();
  }

  public deleteStage(selectStage: any) {
    deleteFromArray(selectStage, this.stagesList);
    deleteModelDB(this.storageKey, this.tableKey, selectStage)
  }

  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public exportTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  exportStage(selectStage: any) {
    exportStage(selectStage)
  /*  let siteText: string = this.objectToString(selectStage);
    let fileName: string = selectStage.name + '_stage_' + dateNow();
    exportTextToBin(siteText, fileName);
    // this.exportTextFile(siteText ,fileName );*/

  }

  importTextFile(event: any) {
    const files = event.target.files;
    if (files.length === 0) return;
    const file = files[0];
    if (file.type !== 'text/plain') {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e: any) => {
      let stageModel: any = JSON.parse(e.target.result);
      stageModel = cloneModel(stageModel, true)
      this.addStage(stageModel)
    };
    reader.readAsText(file);
  }
  public createStages() {
    countTableDB(this.storageKey, this.tableKey,(count:number)=>{
      this.initStage();
      if(count == 0) {
        this.addStage()
        this.initStage();
      }
    })
  }


 public initStage(){
    getTableDB(this.storageKey, this.tableKey, (stages: any[]) => {
      if(stages){
        this.selectStage = stages[0]
        this.stagesList = stages
      }});
  }
 public addStage(selectStage: any = stageModel()) {
    addToStart(selectStage, this.stagesList)
    this.selectStage = this.stagesList[0]
    addModelDB(this.storageKey, this.tableKey, selectStage)
    this.reInitBoardComponent()
  }

/*  public exportTextToBin(textToEncrypt: string, fileName: string) {
    let encrypted: string = textEncrypt(textToEncrypt);
    let blob = new Blob([encrypted], {type: "application/octet-stream"});
    let a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = `${fileName}.bin`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }*/

  public importBin(event: any, callback: any = null) {
    const files = event.target.files;
    if (files.length === 0) return;
    const file = files[0];
    let reader = new FileReader();
    reader.onload = (e) => {
      let decryptedText: string = textDecrypt(e.target.result);
      console.log(decryptedText)
      // callback & callback(decryptedText)
      let stageModel: any = JSON.parse(decryptedText);
      stageModel = cloneModel(stageModel, true)
      this.addStage(stageModel)
    };
    reader.readAsText(file);
  }
  protected readonly srcLogo = srcLogo;
  protected readonly windowHeight = windowHeight;
  protected readonly toolsIcon = toolsIcon;
}
