import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
import {
  deleteIcon,
  downloadIcon
} from "../../traits/icons-tools";
import {deleteFromArray} from "../../traits/array-tools";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";
import {dateNow} from "../../traits/datetime-tools";
import { saveAs } from 'file-saver';
import {BadgeModule} from "primeng/badge";
declare const JSZip;
@Component({
  selector: 'app-zip',
  templateUrl: './zip.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    JsonPipe,
    BadgeModule,
  ]
})
export class ZipComponent {
  @Input() images: any[] = [];
  @Input() label: string= 'Download zip';
  @Output() onClose = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  constructor() { }

  indexZero(index:number){
   return index.toString().padStart(2, '0');
  }

  async saveArchive(): Promise<void> {

   this.images = this.images.map((item:any)=>{
      return {name:`art-club.info (pallete-${item.id}).png`,srcPreview:item.srcPreview};
    });
    const zip = new JSZip();
    // Добавляем каждое изображение в архив
    this.images.forEach((image:any,index:number) => {
      // Извлекаем данные base64 без заголовка
      const base64Data = image.srcPreview.split(';base64,')[1];
      zip.file(`${this.indexZero(index+1)}_${image.name}`, base64Data, { base64: true });
    });

    // Генерируем архив
    zip.generateAsync({ type: 'blob' }) // Генерируем архив в виде blob
      .then(function (blob:any) {
        // Скачиваем архив с использованием библиотеки file-saver
        saveAs(blob, `art-club.info (pallete-${dateNow()}).zip`);
      })
      .catch(function (error:any) {
        console.error('Ошибка создания архива:', error);
      });
    this.label = 'save on device...';
     setTimeout(()=>{this.label = 'Download zip'},2000)
  }
}
