import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtClubServices} from '../static/static-art-club.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {Site} from '../../interfaces/site';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageColorPalletePickerServices {
  public SERVER_NAME: string = 'color-palette-picker';
  public DOMAIN: string = 'art-club.artas.org.ua/color-palette-picker';
  public route: string = 'color-palette-picker';

  public fromServer: boolean = false;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'Color palette picker',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'Color palette picker',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Color Palette Picker - Your Ultimate Color Scheme Companion',
    description: 'Revolutionize your creative projects with Color Palette Picker - the innovative app transforming any color into a tailored palette of shades & gradients. Perfect for artists, designers, and creatives seeking precision and harmony in their color choices. Download now and elevate your color game',
    text: 'Discover the ultimate tool for artists, designers, and creatives with the "Color Palette Picker" app, an innovative application that transforms your color choices into a comprehensive palette of shades and gradients. This app is a game-changer for anyone involved in creative projects, offering an intuitive and user-friendly interface that simplifies color selection and palette creation.\n' +
      '\n' +
      'Unlock Creative Potential with Intuitive Color Selection\n' +
      'With "Color Palette Picker," choosing the right color for your project has never been easier. Select any color, and the app instantly generates a spectrum of shades and gradients, allowing you to visualize and refine your color scheme effortlessly. Whether you\'re working on digital art, graphic design, interior design, or any creative task, this app ensures your color choices are precise and harmonious.\n' +
      '\n' +
      'Seamless Palette Customization and Download Options\n' +
      'What sets "Color Palette Picker" apart is its ability to offer complete customization of your color palette. Adjust the depth of shades, tweak gradients, and handpick the hues that best match your vision. Once satisfied, easily download your custom palette as an image, ready to be used in your projects or shared with collaborators and clients.\n' +
      '\n' +
      'A Must-Have Tool for Creatives and Professionals\n' +
      '"Color Palette Picker" is more than just an app; it\'s a companion for your creative journey. Whether you\'re a professional designer looking for precision in your projects, an artist exploring color theory, or a hobbyist passionate about crafting, this app is designed to support your creative endeavors. With its powerful features and simplicity of use, "Color Palette Picker" is the go-to tool for anyone looking to elevate their work with the perfect color scheme.\n' +
      '\n' +
      'Key Features:\n' +
      '- Instant generation of shades and gradients from any chosen color\n' +
      '- Fully customizable palettes to match your specific project needs\n' +
      '- Easy download of palettes as images for use in projects or sharing\n' +
      '- User-friendly interface designed for creatives of all skill levels\n' +
      '- Perfect tool for artists, designers, and anyone passionate about color\n' +
      '\n' +
      'Empower Your Creativity with Color Palette Picker\n' +
      'Say goodbye to guesswork and time-consuming color matching. "Color Palette Picker" empowers you to create with confidence, offering a seamless blend of technology and creativity. Download the app today and transform the way you engage with color. Whether you\'re working on a professional project or pursuing a personal passion, let "Color Palette Picker" be your guide to the perfect palette.',
    h1: 'Color Palette Picker -unlock Your Creative Potential ',
    h2: 'Intuitive Color Selection for Every Projec',
    h3: 'Seamless Palette Customization',
    h4: 'Why Choose Color Palette Picker',
    h5: 'Customize and Download Your Perfect Color Palett',
    textEndPage: 'color palette app, color scheme generator, gradient creator, color picker tool, creative project colors, design palette customization, digital art tool, graphic design companion, interior design color selection, artist color palette, downloadable color palettes',
    keywords: [
      'color palette app',
      'color scheme generator',
      'gradient creator',
      'color picker tool',
      'creative project colors',
      'design palette customization',
      'digital art tool',
      'graphic design companion',
      'interior design color selection',
      'artist color palette',
      'downloadable color palettes'
    ],
  };
  public site: any

  constructor(public server: ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  getSite() {
    /*  if (this.fromServer) {
        return this.getSiteFromServer();
      } else {
        return this.getSiteStatic();
      }*/

  }

  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }

  public saveSite(site: object) {
    this.site = site
    this.downloadSiteBtn(this.site);
    // let server: any = inject(ServerSiteServices)
    this.server.saveSite(this.DOMAIN, this.site);
  }

  getSiteStatic() {
    return this.site = inject(StaticArtClubServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer() {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: Site): void => {
      this.site = responseSite;
    });
    return this.site;
  }

}
