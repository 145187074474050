<app-hammer-wrapper
  (onClick)="onClick.emit(elementItem)"
  (onDblclick)="onDblclick.emit(elementItem)"
  (onPress)="onPress.emit(elementItem);"
>
  <p-scrollPanel [style]="{height:'160px'}">

    <ng-container *ngFor="let listItem of elementItem.list">
      <div class="flex items-center py-1 bg-gray-700 ">
        <p-checkbox
          class= "focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1"
          [(ngModel)]="listItem.check"
          (ngModelChange)="save()"
          [binary]="true"
        />
        <textarea
          style="padding: 2px!important;font-size: 12px !important; "
          pInputTextarea
          class= "focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1"
          [placeholder]="'Your Text'"
          [(ngModel)]="listItem.text"
          (ngModelChange)="save()"
          [style.min-height.px]="25"
          [style.height.px]="25"
          [style.min-width.px]="75"
        ></textarea>
        <button (click)="deleteFromArray(listItem,elementItem.list); save()"
                class= "focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
          <i [class]="deleteIcon" class="text-gray-300 hover:text-white"></i>
        </button>
      </div>
    </ng-container>
  </p-scrollPanel>

</app-hammer-wrapper>

