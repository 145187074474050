
<div class="flex items-center">
 <!-- <span class="mr-2">
    <svg *ngIf="online; else offlineIcon" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
    </svg>
    <ng-template #offlineIcon>
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
      </svg>
    </ng-template>
  </span>-->
  <span *ngIf="online; else offlineText" class=" text-[12px] text-green-500">online</span>
  <ng-template #offlineText>
    <span class="text-[12px] text-red-500">offline</span>
  </ng-template>
</div>
