import {NgModule} from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import {PageArtClubComponent} from './pages/pages-jome/page-art-club/page-art-club.component';
import {PageAdminComponent} from './pages/pages-jome/page-admin/page-admin.component';
import {PagePaletteGeneratorComponent} from "./pages/pages-jome/page-palette-generator/page-palette-generator.component";
import {PageColorPalettePickerComponent} from "./pages/pages-jome/page-color-palette-picker/page-color-palette-picker.component";
import {PageColorHarmonyComponent} from "./pages/pages-jome/page-color-harmony/page-color-harmony.component";
import {PageImageToSketchComponent} from "./pages/pages-jome/page-image-to-sketch/page-image-to-sketch.component";
import {PageAiBotsComponent} from "./pages/pages-jome/page-ai-bots/page-ai-bots.component";
import {PageImageToInkComponent} from "./pages/pages-jome/page-image-to-ink/page-image-to-ink.component";
import {PageImageToTraceComponent} from "./pages/pages-jome/page-image-to-trace/page-image-to-trace.component";
import {PageMidjourneyComponent} from "./pages/pages-jome/page-midjourney/page-midjourney.component";
import {PageArtistToolsComponent} from "./pages/pages-jome/page-artist-tools/page-artist-tools.component";
import {PageDalle3Component} from "./pages/pages-jome/page-dalle-3/page-dalle-3.component";
import {PageStableDiffusion3Component} from "./pages/pages-jome/page-stable-diffusion-3/page-stable-diffusion-3.component";
import {PageDalleCollageComponent} from "./pages/pages-jome/page-dalle-collage/page-dalle-collage.component";
import {PageRefBoardsComponent} from "./pages/pages-jome/page-ref-boards/page-ref-boards.component";
import { PageImagePalettesLibraryComponent } from './pages/pages-jome/image-palettes-lib/page-image-palettes-library.component';
import {PageRotateHeadComponent} from "./pages/pages-jome/page-rotate-head/page-rotate-head.component";
import {PagePhotoshopPluginsComponent} from "./pages/pages-jome/page-photoshop-plugins/page-photoshop-plugins.component";
import {Page3DReferencesComponent} from "./pages/pages-jome/page-3D-references/page-3D-references.component";
import {PageCharacterHeadsReferencesComponent} from "./pages/pages-jome/page-character-heads-references/page-character-heads-references.component";
import {PageBackgroundsReferencesComponent} from "./pages/pages-jome/page-backgrounds-references/page-backgrounds-references.component";
import {PageCharactersReferencesComponent} from "./pages/pages-jome/page-characters-references/page-characters-references.component";
import {PageCoursesComponent} from "./pages/pages-jome/page-courses/page-courses.component";
import {PageArtasComponent} from "./pages/pages-jome/page-artas/page-artas.component";
import {PageCourseComponent} from "./pages/pages-jome/page-course/page-course.component";
import {PageImageToGrayscaleComponent} from "./pages/pages-jome/page-image-to-grayscale/page-image-to-grayscale.component";
import {PageImageGetColorComponent} from "./pages/page-image-get-color/page-image-get-color.component";
import {PageImageToMosaicComponent} from "./pages/pages-jome/page-image-to-mosaic/page-image-to-mosaic.component";
import {PagePosesNudeComponent} from "./pages/pages-jome/page-poses-nude/page-poses-nude.component";
import {PageRotator360Component} from "./pages/pages-jome/page-rotator-360/page-rotator-360.component";
import {PagePosesLightComponent} from "./pages/pages-jome/page-poses-light/page-poses-light.component";
import {PageCharacterMixerComponent} from "./pages/pages-jome/page-character-mixer/page-character-mixer.component";
import {PageAnatomyComponent} from "./pages/pages-jome/page-anatomy/page-anatomy.component";
import {PageBrushesPhotoshopComponent} from "./pages/pages-jome/page-brushes-photoshop/page-brushes-photoshop.component";
import {PageArtistNotesComponent} from "./pages/pages-jome/page-artist-notes/page-artist-notes.component";
import {
  SeoCaricaturePoPhoto
} from './services/static/seo/seo-caricature';
import {PageArtasProductsComponent} from "./pages/pages-jome/page-artas/page-artas-products/page-artas-products.component";
import {SeoZakazat_caricature} from "./services/static/seo/seo-zakazat_caricature";
import {SeoCaricatureOnline} from "./services/static/seo/seo-caricature-online";

const routes: Routes = [
  {
    path: "",
    component: PageArtClubComponent

  },
  {
    path: 'digital-art-courses',
    component: PageCoursesComponent,
    pathMatch: 'full',
  },
  {
    path: "digital-art-courses/:slug",
    component: PageCourseComponent,
    pathMatch: 'full',

  },
  {
    path: "references-boards",
    component: PageRefBoardsComponent
  },
  {
    path: "artist-tools",
    component: PageArtistToolsComponent
  },
  {
    path: 'photoshop-plugins',
    component: PagePhotoshopPluginsComponent,
  },

  {
    path: "image-palette-generator",
    component: PagePaletteGeneratorComponent
  },
  {
    path: "color-harmony",
    component: PageColorHarmonyComponent
  },
  {
    path: "color-palette-picker",
    component: PageColorPalettePickerComponent
  },
  {
    path: "image-to-sketch",
    component: PageImageToSketchComponent
  },
  {
    path: "image-to-monochrome",
    component: PageImageToGrayscaleComponent
  },
  {
    path: "image-to-mosaic",
    component: PageImageToMosaicComponent
  },
  {
    path: "image-get-color",
    component: PageImageGetColorComponent
  },
  {
    path: "image-to-ink",
    component: PageImageToInkComponent,
  },
  {
    path: "image-to-trace",
    component: PageImageToTraceComponent,
  },

  {
    path: 'rotate-heads-references',
    component: PageRotateHeadComponent,
  },
  {
    path: 'character-heads-references',
    component: PageCharacterHeadsReferencesComponent,
  },
  {
    path: 'backgrounds-references',
    component: PageBackgroundsReferencesComponent,
  },

  {
    path: 'characters-references',
    component: PageCharactersReferencesComponent,
  },
  {
    path: "image-palettes-library",
    component: PageImagePalettesLibraryComponent
  },

  {
    path: '3D-references',
    component: Page3DReferencesComponent,
  },
  {
    path: 'poses-nude',
    component: PagePosesNudeComponent,
  },
  {
    path: 'poses-light',
    component: PagePosesLightComponent,
  },
  {
    path: 'rotator-360',
    component: PageRotator360Component,
  },
  {
    path: 'character-mixer',
    component: PageCharacterMixerComponent,
  },
  {
    path: 'anatomy',
    component: PageAnatomyComponent,
  },
  {
    path: 'step-by-step',
    component: PageAnatomyComponent,
  },
  {
    path: 'brushes-photoshop',
    component: PageBrushesPhotoshopComponent,
  },
  {
    path: 'artist-notes',
    component: PageArtistNotesComponent,
  },
  {
    path: "midjourney",
    component: PageMidjourneyComponent
  },
  {
    path: "dalle-3",
    component: PageDalle3Component
  },
  {
    path: "stable-diffusion-3",
    component: PageStableDiffusion3Component
  },
 /* {
    path: "ai-bots",
    component: PageAiBotsComponent
  },
  */
/*    {
    path: "dalle-collage",
    component: PageDalleCollageComponent
  },*/

  /* {
     path: "photo-storage-on-device",
     component: PagePhotoStorageOnDeviceComponent
   },*/
  {
    path: "zakazat-sharj",
    component: PageArtasComponent,
    pathMatch: 'full',
    data: SeoZakazat_caricature,
  },
  {
    path: "zakazat-sharj/:slug",
    component:PageArtasProductsComponent ,
    pathMatch: 'full',
    data: SeoZakazat_caricature,
  },

  {
    path: "sharj-po-photo",
    component: PageArtasComponent,
    pathMatch: 'full',
    data: SeoCaricaturePoPhoto,
  },
  {
    path: "sharj-po-photo/:slug",
    component:PageArtasProductsComponent ,
    pathMatch: 'full',
    data: SeoCaricaturePoPhoto,
  },
  {
    path: "caricature-online",
    component: PageArtasComponent,
    pathMatch: 'full',
    data: SeoCaricatureOnline,
  },
  {
    path: "caricature-online/:slug",
    component:PageArtasProductsComponent ,
    pathMatch: 'full',
    data: SeoCaricatureOnline,
  },
  {
    path: "admin",
    component: PageAdminComponent

  },


  {
    path: "**",
    component: PageArtClubComponent,

    // redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

/* Collections SQL
todo art-club.artas.org.ua/photo-sharg
todo art-club.artas.org.ua/party-sharg
todo art-club.artas.org.ua/silhouettes-character
todo art-club.artas.org.ua/psd
art-club.artas.org.ua/palettes-gradients
art-club.artas.org.ua/colorizer-character
art-club.artas.org.ua/colorizer-silhouettes
art-club.artas.org.ua/composition
art-club.artas.org.ua/anatomy
art-club.artas.org.ua/sketchbook
art-club.artas.org.ua/palettes-films
art-club.artas.org.ua/palettes-balances
art-club.artas.org.ua/palettes-arts
art-club.artas.org.ua/palettes-colors
art-club.artas.org.ua/books
art-club.artas.org.ua/references
art-club.artas.org.ua/brushes
art-club.artas.org.ua/shorts-tv
art-club.artas.org.ua/home
art-club.artas.org.ua/empty
art-club.artas.org.ua/palettes-photo
art-club.artas.org.ua/palettes-character
art-club.artas.org.ua/palettes-face
art-club.artas.org.ua/ink-character
art-club.artas.org.ua/palettes-swatches
art-club.artas.org.ua/courses-online
art-club.artas.org.ua/artasalex
art-club.artas.org.ua/luminjulia
art-club.artas.org.ua/characters
art-club.artas.org.ua/empty
art-club.artas.org.ua/sharg-creator
art-club.artas.org.ua/fonts
art-club.artas.org.ua/sharg-composer
art-club.artas.org.ua/art-map
art-club.artas.org.ua/art-wall
art-club.artas.org.ua/stickers
art-club.artas.org.ua/common
art-club.artas.org.ua/collages
art-club.artas.org.ua/domain_name
art-club.artas.org.ua/design
art-club.artas.org.ua/konva
art-club.artas.org.ua/art-club
art-club.artas.org.ua/draw-sketches
art-club.artas.org.ua/draw-sketches
*/




/*
*
* Lib SQL
* todo art-club.artas.org.ua/draw-sketches
 todo art-club.artas.org.ua/rotator
todo art-club.artas.org.ua/mixer
todo art-club.artas.org.ua/colorizer
todo art-club.artas.org.ua/posses
todo oart-club.artas.org.ua/backgrounds
todo art-club.artas.org.ua/materials-old
todo art-club.artas.org.ua/elements
todo art-club.artas.org.ua/animals
todo art-club.artas.org.ua/anatomy
todo art-club.artas.org.ua/materials
todo art-club.artas.org.ua/brushes
todo art-club.artas.org.ua/palletes
todo art-club.artas.org.ua/light
todo art-club.artas.org.ua/avatars
todo art-club.artas.org.ua/practicum
todo art-club.artas.org.ua/notes
todo art-club.artas.org.ua/collages
todo art-club.artas.org.ua/artasalex
todo art-club.artas.org.ua/step-by-step
todo art-club.artas.org.ua/coloring-child
*
*
*
art-club.artas.org.ua/background
art-club.artas.org.ua/character-head
art-club.artas.org.ua/image-palettes-library
art-club.artas.org.ua/poses-nude
art-club.artas.org.ua/courses
art-club.artas.org.ua/art-party
art-club.artas.org.ua/artas
art-club.artas.org.ua/art-club
art-club.artas.org.ua/characters
art-club.artas.org.ua/photoshop-plugins
art-club.artas.org.ua/3D-references
art-club.artas.org.ua/art-sites
art-club.artas.org.ua/rotate-head
*
*
*
*
*
*
*
*
*
* */
