<div class="flex justify-center">
  <div class="grid grid-cols-2 md:grid-cols-5 gap-4">
    <ng-container *ngFor="let  skeletonLoader  of [1,2,3,4,5,6,7,8,9,10,11,12].slice(0,sliceCount);">
      <div
        class="bg-zinc-700  relative  inline-block rounded-m  ">
        <p-skeleton
          width="200px"
          height="200px"
          styleClass="rounded-md w-[200px] h-[200px] object-cover  cursor-pointer"/>
      </div>
    </ng-container>
  </div>
</div>
