import {
  Component,
  EventEmitter,
  Inject,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ThemeServices} from '../../../services/common/theme.services';
import { theme } from 'src/app/interfaces/theme';
import {
  DOCUMENT,
  NgFor,
  NgIf
} from '@angular/common';
import {Card} from '../../../interfaces/Card';
import {SortablejsModule} from '@maksim_m/ngx-sortablejs';
import {SortableOptions} from "sortablejs";
import {
  addCloneModel,
  addToStart,
  deleteFromArray
} from "../../../traits/array-tools";
import { cloneModel, randomIdAndCreateAt } from 'refactoring-code/services/traits/model-tools';
import {BadgeModule} from "primeng/badge";
import {
  addIcon,
  editIcon
} from "../../../traits/icons-tools";
import {PaginatorModule} from "primeng/paginator";
import {CdServices} from "../../../services/common/cd.services";
@Component({
  selector: 'app-edit-cards-sort',
  standalone:true,
  imports: [
    NgFor,
    NgIf,
    SortablejsModule,
    BadgeModule,
    PaginatorModule
  ],
  templateUrl: './edit-cards-sort.component.html',


})
export class EditCardsSortComponent implements OnInit {
  @Input() site: any;
  selectCard: Card;
  @Input() cards: Card[];
  public theme: theme = inject(ThemeServices);
  public sortableOptions:SortableOptions = {
    animation: 150,
    group: 'cards',
    handle: '.handle',
    onUpdate: (event: any) => {
      console.log(event);
      this.onSave.emit()
    }
  }
  @Output() onSelect:EventEmitter<any> = new EventEmitter<void>();
  @Output() onSave:EventEmitter<any> = new EventEmitter<void>();



  constructor(public cd:CdServices, @Inject(DOCUMENT) private document: Document) {
  }
   ngOnInit() {
    this.selectCard = this.cards[0];

  }

/*
  public addCloneModel(model: any, cards: any) {
    let modelClone = cloneModel(model, true);
    addToStart(modelClone,cards)
    // cards.push(modelClone);
  }
*/

  public delete(model: any, cards: any) {
    deleteFromArray(model, cards);

     /* this.cd.alert.confirmationService.confirm({
        message: `Remove? ${model.name}`,
        accept: () => {
          this.cd.array.delete(model, cards);

        }
      });*/
  }
  public generateRandomId(model: any) {
   randomIdAndCreateAt(model)
    this.onSave.emit()
      /*this.cd.alert.confirmationService.confirm({
        message: `Generate ID? ${model.name}`,
        accept: () => {
          this.cd.setRandomId(model)

        }
      });*/
  }



  sort() {
    console.log('sort');
  }

  protected readonly addCloneModel = addCloneModel;
  protected readonly editIcon = editIcon;
  protected readonly addIcon = addIcon;

  scrollToTop() {
    this.document.body.scrollTop = 0;
    this.document.documentElement.scrollTop = 0;
  }

  edit(cardItem: Card, cards: Card[]) {
    this.selectCard = cardItem;
    this.onSelect.emit(cardItem);
    this.scrollToTop()

  }
}
