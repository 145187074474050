<!--<button *ngIf="selectCard"
        class="class-controll bg-gradient-to-r from-purple-500 to-pink-500 hover:from-pink-500 hover:to-purple-500 transition duration-500 ease-in-out transform hover:scale-105"
        (click)="generateRandomId(selectCard)"
>
  <i [class]="theme.icon.random" pBadge value="{{selectCard.id}}_{{selectCard.createAt}}"></i>
</button>-->

<div class="p-3 m-2">
  <ul class="grid grid-cols-1  gap-2 "
      [sortablejs]="cards"
      [sortablejsOptions]="sortableOptions"
  >
    <li *ngFor="let cardItem of cards" [ngClass]="{'selected-card': selectCard == cardItem}">
      <div class="p-1 rounded-lg bg-gray-800 shadow-md transition duration-300 ease-in-out transform hover:scale-105">
        <div class="p-1 rounded-lg bg-gray-800 shadow-md transition duration-300 ease-in-out transform hover:scale-105">
          <button *ngIf="cardItem.srcPreview"
                  class="focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
            <img [src]="cardItem.srcPreview" class="w-16 h-16 inline rounded-full" alt="">
          </button>
          <textarea
            style="padding: 2px!important;font-size: 16px !important; "
            pInputTextarea
            [placeholder]="'name'"
            [(ngModel)]="cardItem.name"
            [style.min-height.px]="50"
            [style.height.px]="50"
            [style.min-width.px]="250"
          ></textarea>

        </div>

        <button
          class="focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
          <i *ngIf="cardItem.free === true" [class]="theme.icon.gift" (click)="cardItem.free = false; onSave.emit()"></i>
          <i *ngIf="cardItem.free === false" [class]="theme.icon.premium" (click)="cardItem.free = true;onSave.emit()"></i>
        </button>
        <button
          class="focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
          <i *ngIf="cardItem.sw === true" [class]="theme.icon.eyeClose" (click)="cardItem.sw = false;"></i>
          <i *ngIf="cardItem.sw === false" [class]="theme.icon.eyeOpen" (click)="cardItem.sw = true;"></i>
        </button>
        <button (click)="addCloneModel(cardItem,cards); onSave.emit()"
                class= "focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
          <i [class]="addIcon" class="text-gray-300 hover:text-white"></i>
        </button>
        <button (click)="edit(cardItem, cards);"
                class="focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
          <i [class]="editIcon" class="text-gray-300 hover:text-white"></i>
        </button>
         <button *ngIf="cd.enabledDelete" (click)="delete(cardItem, cards); onSave.emit(cardItem)"
                 class="focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
          <i [class]="theme.icon.delete" class="text-gray-300 hover:text-white"></i>
        </button>
        <button
          class="handle focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
          <i [class]="'fas fa-arrows-alt'"></i>
        </button>
      </div>
    </li>

  </ul>
</div>
<hr>
