@if (btnGoServices.showPremium(model, showPremium)) {

  @if (btnGoServices.showGoGallery(model)) {
    <button
      (click)="showPhotosBoxBtn(model,'photos');setId()"
      class=" text-center font-bold text-zinc-200 uppercase block focus:ring-4 transform active:scale-75 transition-transform mt-4 text-xl w-full  bg-sky-300 py-2 rounded-xl shadow-lg"
    >
      <span class="ml-5 text-xl text-zinc ">Open</span>
      <span class="ml-2 text-md"> <i [class]="giftIcon"></i></span>

    </button>
  }

  @if (model.routerLink) {
    <div *ngIf="model.info" class="font-bold text-xl mb-2">
      <p class="text-amber-500 text-base">{{ model.info }}</p>
    </div>
    <a [routerLink]="model.routerLink" (click)=" onClickRoute.emit(model);setId()"
       class=" text-center font-bold text-zinc-200 uppercase block focus:ring-4  transform active:scale-75 transition-transform mt-4 text-xl w-full  bg-sky-300 py-2 rounded-xl shadow-lg"
    >
      <span class="ml-5 text-xl text-zinc ">{{ 'Open' }}</span>
           <span class="ml-2 text-md"> <i [class]="giftIcon"></i></span>

    </a>
  }

  @if (model.srcUrl) {
    <div *ngIf="model.info" class="font-bold text-xl mb-2">
      <p class="text-amber-500 text-base">{{ model.info }}</p>
    </div>
    <button (click)=" coins.coinService.minusCoin();(!cd.lock || cd.logined)?goToOtherSite():null;setId()"
       class=" text-center font-bold text-zinc-200 uppercase block focus:ring-4  transform active:scale-75 transition-transform mt-4 text-xl w-full  bg-sky-300 py-2 rounded-xl shadow-lg"
    >
       <span [hidden]="coins.coinService.showCoinsFlag()" class="absolute z-30 -top-4 left-3">
       <app-coins #coins [showCoinMinus]="true"></app-coins>
          </span>
      <span class="ml-5 text-xl text-zinc ">
        {{ 'Open' }}
      </span>
     <span class="ml-2 text-md">      <span class="ml-2 text-md"> <i [class]="giftIcon"></i></span>
</span>
    </button>
  }

  @if (model.srcFRAME) {
    <div *ngIf="model.info" class="font-bold text-xl mb-2">
      <p class="text-amber-500 text-base">{{ model.info }}</p>
    </div>
    <button (click)=" coins.coinService.minusCoin();(!cd.lock || cd.logined)?selectFrame(model):null;setId()"
       class="text-center font-bold text-zinc-200 uppercase block focus:ring-4  transform active:scale-75 transition-transform mt-4 text-xl w-full  bg-sky-300 py-2 rounded-xl shadow-lg"
    >
      <span [hidden]="coins.coinService.showCoinsFlag()" class="absolute z-30 -top-4 left-3">
       <app-coins #coins [showCoinMinus]="true"></app-coins>
          </span>
      <span class="ml-5 text-xl text-zinc ">{{ 'Open' }}</span>
           <span class="ml-2 text-md"> <i [class]="giftIcon"></i></span>

    </button>
  }
}
<!--@if (!btnGoServices.showPremium(model, showPremium)) {
  <button
    class=" focus:ring-4 transform active:scale-75 transition-transform mt-4 text-xl w-full text-zinc bg-sky-300 py-2 rounded-xl shadow-lg"
  >
        <span (click)="showLoginForm()"
        >
         {{ textLoginBtn }}
        </span>
  </button>
}-->

<!--@if (model.moreCourse) {
  <button
          (click)="selectCourse(model)"
          class=" focus:ring-4 transform active:scale-75 transition-transform mt-4 text-xl w-full  bg-sky-300 py-2 rounded-xl shadow-lg"
  >
        <span class="flex content-center">

        <span class="ml-5 text-xl text-zinc ">
        {{ courseText }}
        </span>
           <span>
           <img
             class="rounded-lg w-[50px] h-[50px]"
             [src]="'/assets/icons/5053028.png'"
             [alt]="'play'"
           >
             </span>
          </span>
  </button>
}-->
<!-- @if (btnGoServices.showByeMyCoffe(model)) {
    <div *ngIf="model.info" class="font-bold text-xl mb-2">
      <p class="text-amber-500 text-base">{{ model.info }}</p>
    </div>
    <app-by-me-a-coffe [btnShow]="true"></app-by-me-a-coffe>
  }-->

<!--  @if (btnGoServices.showRouterLink(model)) {-->
<!-- <div *ngIf="model.info" class="font-bold text-xl mb-2">
   <p class="text-amber-500 text-base">{{ model.info }}</p>
 </div>
 <a  [routerLink]="model.routerLink" (click)=" onClickRoute.emit(model);setId()"
     class=" block focus:ring-4  transform active:scale-75 transition-transform mt-4 text-xl w-full  bg-sky-300 py-2 rounded-xl shadow-lg"
 >
   <span class="ml-5 text-xl text-zinc ">{{ goBtnText }}</span>
   &lt;!&ndash;   <app-share-url
      ></app-share-url>&ndash;&gt;
 </a>-->

<!--  }-->


<!--
  @if (btnGoServices.showOrderForm(model)) {

    <div *ngIf="model.info" class="font-bold text-xl mb-2">
      <p class="text-amber-500 text-base">{{ model.info }}</p>
    </div>
    <button (click)="showCardOrder = true; selectModel = model; "
            class=" focus:ring-4  transform active:scale-75 transition-transform mt-4 text-xl w-full  bg-sky-300 py-2 rounded-xl shadow-lg"
    >
      <span class="ml-5 text-xl text-zinc ">{{ orderText }}</span>
    </button>
  }
-->
