import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgFor, NgIf} from '@angular/common';
import {CardFeedComponent} from '../card-feed/card-feed.component';
import {Card} from '../../../interfaces/Card';

@Component({
  selector: 'app-list-feed',
  templateUrl: './list-feed.component.html',
standalone:true,
  imports: [NgFor, NgIf, CardFeedComponent]

})
export class ListFeedComponent  {

  @Input() cards: Array<Card>;
  @Input() showFilter: boolean = false;
  public selectItem: object;
  public indexCard: number = 0;
  public maxView: number = 100;
  public selectModelCurrent: any;
  public bgColor: string ='bg-zinc-700';
  public bgImage: string ='assets/background_logo_small.png';
  @Output() onSelect:EventEmitter<any> = new EventEmitter();
  @Output() onSelectCourse:EventEmitter<any> = new EventEmitter();
  @Output() onShowPhotos:EventEmitter<any> = new EventEmitter();
  @Output() onFrameShow:EventEmitter<any> = new EventEmitter();
  @Output() onClickRoute:EventEmitter<any> = new EventEmitter();

}
