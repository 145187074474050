import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  addToEnd,
  addToStart,
  clear,
  countAll,
  countHidden,
  countShowed,
} from "../../../traits/array-tools";
import {
  NgFor,
  NgIf
} from "@angular/common";
import {ThemeServices} from "../../../services/common/theme.services";
import { theme } from 'src/app/interfaces/theme';
import {modelTagNew} from "../../../traits/model-tools";
import {ToggleButtonModule} from "primeng/togglebutton";


@Component({
  selector: 'app-array-panel-tools',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    ToggleButtonModule,
  ],
  templateUrl: './array-panel-tools.component.html',

})
export class ArrayPanelToolsComponent implements OnInit {
  @Input() model: any;
  @Input() showAdds: any = true;
  @Input() showCatFilter: any = true;
  @Input() modelName: any = 'modelTag';
  @Input() listName: any = 'tags';
  public countTotal: any = 0;
  public countShow: any = 0;
  public countArchives: any = 0;
  @Output() onAdd = new EventEmitter();
  @Output() onView = new EventEmitter();
  @Output() onSearchKeyword = new EventEmitter();
  @Output() onFilterRow = new EventEmitter();
  public theme: theme = inject(ThemeServices);

  constructor() {
  }

  ngOnInit() {

  }

  addTags() {
    // @ts-ignore
    let model = modelTagNew();
    this.model[this.listName] = [model];

  }

  public addToStart() {
    // @ts-ignore
    let model = modelTagNew();
    let models = this.model[this.listName];
    !models ? models = [] : null;
    addToStart(model, models);
    this.onAdd.emit(model)
  }

  public addToEnd() {
    // @ts-ignore
    let model = modelTagNew();
    let models = this.model[this.listName];
    !models ? models = [] : null;
    addToEnd(model, models);
    this.onAdd.emit(model)
  }

  public countHidden(list: any): number {
    return countHidden(list);
  }

  public countShowed(list: any): number {
    return countShowed(list);
  }

  public countAll(list: any): number {
    return countAll(list);
  }


  protected readonly clear = clear;
}
