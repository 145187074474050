import {
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import {TabViewModule} from 'primeng/tabview';
import {
  NgFor,
  NgForOf,
  NgIf
} from "@angular/common";
import {BadgeModule} from "primeng/badge";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {ColorsImageComponent} from "../../colors-image/colors-image.component";
import {UploaderPhotoComponent} from "../admin/uploader-photo/uploader-photo.component";
import {SidebarComponent} from "../common/sidebar/sidebar.component";
import {CircleWithImageComponent} from "../circle-with-image/circle-with-image.component";
import {ShareImageComponent} from "../share-image/share-image.component";
import {CropRigionComponent} from "../crop-rigion/crop-rigion.component";
import {ImagesViewerGridSlidderComponent} from "../pages/images-viewer-grid-slidder/images-viewer-grid-slidder.component";
import {EyeDropperComponent} from "../eye-dropper/eye-dropper.component";
import {ImageMosaicComponent} from "../image-mosaic/image-mosaic.component";
import {GrayscaleComponent} from "../grayscale/grayscale.component";
import {CameraComponent} from "../pages/camera/camera.component";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";
import {AlbumComponent} from "../album/album.component";
import {ZipComponent} from "../zip/zip.component";
import {BrowserCloseAlertComponent} from "../browser-close-alert/browser-close-alert.component";
import {PackPalettesComponent} from "../pack-palettes/pack-palettes.component";
import {deleteFromArray} from 'src/app/traits/array-tools';
import {
  deleteIcon,
  dotesHorizontalIcon,
  dotesVerticalIcon
} from 'src/app/traits/icons-tools';
import {dateNow} from 'src/app/traits/datetime-tools';
import {CircleWithColorComponent} from "../circle-with-color/circle-with-color.component";
import {ColorIroComponent} from "../color-iro/color-iro.component";
import {RectangleWithColorComponent} from "../rectangle-with-color/rectangle-with-color.component";
import {ColorHistoryComponent} from "../color-history/color-history.component";
import {ColorPanelComponent} from "../color-panel/color-panel.component";
import {CircleWithHarmonyComponent} from "../circle-with-harmony/circle-with-harmony.component";
import {windowWidth} from "../../traits/window-tools";


@Component({
  selector: 'app-generator-harmony',
  standalone: true,
  imports: [
    TabViewModule,
    SidebarComponent,
    CircleWithImageComponent,
    ColorsImageComponent,
    UploaderPhotoComponent,
    NgIf,
    NgFor,
    FormsModule,
    BadgeModule,
    ShareImageComponent,
    CropRigionComponent,
    ImagesViewerGridSlidderComponent,
    NgForOf,
    EyeDropperComponent,
    ImageMosaicComponent,
    GrayscaleComponent,
    ButtonModule,
    CameraComponent,
    BtnDownloadComponent,
    AlbumComponent,
    ZipComponent,
    BrowserCloseAlertComponent,
    PackPalettesComponent,
    CircleWithColorComponent,
    ColorIroComponent,
    RectangleWithColorComponent,
    ColorHistoryComponent,
    ColorPanelComponent,
    CircleWithHarmonyComponent,
  ],
  templateUrl: './generator-harmony.component.html',
})

export class GeneratorHarmonyComponent {
  @ViewChild('roundPallete') roundPallete: ElementRef<HTMLCanvasElement>;
  @ViewChild('horizontalPallete') horizontalPallete: ElementRef<HTMLCanvasElement>;
  @ViewChild('verticalPallete') verticalPallete: ElementRef<HTMLCanvasElement>;
  @Input() public imageParent: string = null
  public nameHarmony: string ='tetradic';
  public showDownload: boolean = false;
  public selectColor: string = '#d38d43';
  public showPreload: boolean = false;
  showCircleComponent: boolean = true;
  protected readonly deleteFromArray = deleteFromArray;
  protected readonly dateNow = dateNow;
  protected readonly deleteIcon = deleteIcon;
  protected readonly dotesVerticalIcon = dotesVerticalIcon;
  protected readonly dotesHorizontalIcon = dotesHorizontalIcon;
  public imagePallete: string;
public btns:any[] = [
    {name:'tetradic',srcPreview:'assets/params/images/harmony/tetradic.jpg'},
      {name:'triadic',srcPreview:'assets/params/images/harmony/triadic.jpg'},
      {name:'split',srcPreview:'assets/params/images/harmony/split.jpg'},
      {name:'complementary',srcPreview:'assets/params/images/harmony/complementary.jpg'},
      {name:'analogous',srcPreview:'assets/params/images/harmony/analogous.jpg'},
      {name:'monochromatic',srcPreview:'assets/params/images/harmony/monochromatic.jpg'},
    ];
ngOnDestroy(){
  this.nameHarmony = 'tetradic';
  this.showCircleComponent =false;
 }
 public setColor(colorHex: string) {
    this.selectColor = colorHex;
    this.reInitHarmony(this.nameHarmony)
  }
  public reInitHarmony(nameHarmony:string='tetradic') {
  this.nameHarmony = nameHarmony;
    this.showCircleComponent = false;
    setTimeout(() => {
      this.showCircleComponent = true;
      // @ts-ignore
      // this.imagePallete = this.roundPallete.getImage();
    }, 50)
  }

  protected readonly windowWidth = windowWidth;
}
