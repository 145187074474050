import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild
} from '@angular/core';
import {NgFor, NgIf} from "@angular/common";
import { randomId } from '../traits/string-tools';
import  ColorThief from 'src/assets/params/js/color-thief.umd.js'
import {PageMidjourneyComponent} from "../pages/pages-jome/page-midjourney/page-midjourney.component";
import {PageColorHarmonyComponent} from "../pages/pages-jome/page-color-harmony/page-color-harmony.component";
import {PageImageToSketchComponent} from "../pages/pages-jome/page-image-to-sketch/page-image-to-sketch.component";
import {PalleteGeneratorImageComponent} from "../components/pallete-generator-image/pallete-generator-image.component";
import {PagePaletteGeneratorComponent} from "../pages/pages-jome/page-palette-generator/page-palette-generator.component";
import {PageColorPalettePickerComponent} from "../pages/pages-jome/page-color-palette-picker/page-color-palette-picker.component";
import {PageImageToInkComponent} from "../pages/pages-jome/page-image-to-ink/page-image-to-ink.component";
import {PageImageToTraceComponent} from "../pages/pages-jome/page-image-to-trace/page-image-to-trace.component";
import {Page3DReferencesComponent} from "../pages/pages-jome/page-3D-references/page-3D-references.component";
import {PagePhotoshopPluginsComponent} from "../pages/pages-jome/page-photoshop-plugins/page-photoshop-plugins.component";
import {PageRotateHeadComponent} from "../pages/pages-jome/page-rotate-head/page-rotate-head.component";

@Component({
  selector: 'app-modal-product',
  standalone:true,
  imports: [
    NgIf,
    NgFor,
    PageMidjourneyComponent,
    PageColorHarmonyComponent,
    PageImageToSketchComponent,
    PalleteGeneratorImageComponent,
    PagePaletteGeneratorComponent,
    PageColorPalettePickerComponent,
    PageImageToInkComponent,
    PageImageToTraceComponent,
    Page3DReferencesComponent,
    PagePhotoshopPluginsComponent,
    PageRotateHeadComponent
  ],
  templateUrl: './modal-product.component.html',

})

export class ModalProductComponent implements OnInit {
  @Input() idProduct: any;
  constructor() {

  }

  public ngOnInit() {

  }

}
