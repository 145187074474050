<!--<app-browser-close-alert
  (onClose)="saveSite()"
></app-browser-close-alert>-->

<ng-container *ngIf="data.fromServer === true">
<button #copySITE class="btn-controll"
          ngxClipboard
          [cbContent]="objectToString(data.site)"
          [container]="copySITE">

    <i [class]="theme.icon.copy"></i>

  </button>
  <button
    (click)="downloadSiteBtn(data.site)"
    class="btn-controll"
  >
    <i [class]="theme.icon.download"></i>
  </button>
  <button
          class="m-1 p-1 bg-zinc-700 text-white rounded-sm border-4 border-zinc-200 "
          (click)="saveSite()">
    <i [class]="theme.icon.save"></i>
  </button>
</ng-container>
<!--  <p-toggleButton [(ngModel)]="enabledAutosave"
                  onLabel="Enabled AutoSave"
                  offLabel="Disabled AutoSave"
  ></p-toggleButton>-->
  <p-toggleButton [(ngModel)]="cd.enabledDelete"
                  onLabel="Enabled DELETE"
                  offLabel="Disabled DELETE"
  ></p-toggleButton>
  @if(data){
<!--    <p  class="text-white">{{data?.site?.organizer|json}}</p>-->

  }
