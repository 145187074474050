import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {JsonPipe, NgFor, NgIf} from '@angular/common';
import {EditCardSelectComponent} from '../edit-card-select/edit-card-select.component';
import {Card} from '../../../interfaces/Card';
import {EditListCardsComponent} from '../edit-list-cards/edit-list-cards.component';
import {SiteDebugerComponent} from "../site-debuger/site-debuger.component";
import {SidebarComponent} from "../../common/sidebar/sidebar.component";
import {windowWidth} from "../../../traits/window-tools";

@Component({
  selector: 'app-edit-site-select',
  templateUrl: './edit-site-select.component.html',
standalone:true,
    imports: [
        NgFor,
        NgIf,
        JsonPipe,
        EditListCardsComponent,
        EditCardSelectComponent,
        SiteDebugerComponent,
        SidebarComponent
    ]

})
export class EditSiteSelectComponent {
  @ViewChild('select') select: ElementRef<HTMLCanvasElement>;
  card:any;
  @Input() cards: Array<Card>;
  @Input() data: any;
  @Output() onSelect:EventEmitter<any> = new EventEmitter();
   public showSelectCard: boolean =false;
constructor() {
}

  onSelectCard(card: any) {
  this.card = card;
  this.cards = this.data.site.organizer.messenges;
    this.showSelectCard = true;
    // this.select.card = $event;

    // @ts-ignore
    // this.select.card = $event;
    // @ts-ignore
    // this.select.cards = data?.site.organizer.messenges;
    // this.showSelectCard = true;
  }

  protected readonly windowWidth = windowWidth;
}
