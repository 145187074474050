<app-hammer-wrapper
  (onClick)="onClick.emit(elementItem)"
  (onDblclick)="onDblclick.emit(elementItem)"
  (onPress)="onPress.emit(elementItem);"
>
    <img
      [src]="elementItem.srcPreview" alt="upload_image"
      class="object-cover rounded cursor-pointer"
    >


</app-hammer-wrapper>
    <!--<button  (click)="check(uploadItem)"
             class="text-sm absolute top-0 left-2 bg-green-500 text-white p-1 rounded-full transition-transform transform hover:scale-125"
    >
      <i *ngIf="uploadItem.check" [class]="'fas fa-check'"></i>
      <i *ngIf="!uploadItem.check" [class]="'far fa-square'"></i>
    </button>-->
 <!--   <button #targetEl
            class="text-sm absolute top-0 -right-2 bg-red-500 text-white p-1 w-16 rounded-full transition-transform transform hover:scale-125"
            (click)="settings(elementItem)">
      <i [class]="dotesVerticalIcon"></i>
    </button>-->
