import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BtnGoServices} from '../../../services/common/btn-go.services';
import {
  JsonPipe,
  NgIf
} from '@angular/common';
import {Card} from '../../../interfaces/Card';
import {ByMeACoffeComponent} from '../../payment/by-me-a-coffe/by-me-a-coffe.component';
import {RouterLink} from '@angular/router';
import {CdServices} from "../../../services/common/cd.services";
import {ActivityServices} from "../../../services/common/activity.services";
import {ShareUrlComponent} from "../../share-url/share-url.component";
import {
  giftIcon,
  handOpenIcon
} from "../../../traits/icons-tools";
import {CoinsComponent} from "../../coins/coins.component";

@Component({
  selector: 'app-btn-go',
  templateUrl: './btn-go.component.html',
  standalone: true,
  imports: [
    NgIf,
    ByMeACoffeComponent,
    RouterLink,
    ShareUrlComponent,
    JsonPipe,
    CoinsComponent
  ]
})
export class BtnGoComponent {
  @Input() model:any;
  @Input() srcPreview: string;
  @Input() srcIcon: string;
  @Input() srcActive: string;
  @Input() activeText: string;
  @Input() label: string;
  @Input() count: number;
  @Input() animateClass: string;
  @Input() colorClass: string;
  @Input() absoluteClass: string;
  @Input() showPremium: boolean = false;
  @Input() showSrcIcon: any = false;
  @Input() active: boolean = false;
  @Input() showFrame: boolean = false;
  @Input() showCardOrder: boolean = false;
  @Input() showCourseViewer: boolean = false;
  @Input() showRouteBtn: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() onSetId: EventEmitter<any> = new EventEmitter();
  @Output() onFrameShow: EventEmitter<any> = new EventEmitter();
  @Output() onShowPhotosModal: EventEmitter<any> = new EventEmitter();
  @Output() onLoginForm: EventEmitter<any> = new EventEmitter();
  @Output() onShowCourse: EventEmitter<any> = new EventEmitter();
  @Output() onClickRoute: EventEmitter<any> = new EventEmitter();
  public photosText: string = 'Photos';
  public orderText: string = 'Order  🎁';
  public goBtnText: string = 'Open';
  public urlText: string = '';
  public textLoginBtn: string = ' Login with patreon 1$ 👑';
  public courseText: string = 'Start Watching Course 🎁';
  public selectModel: Card;
  constructor(public cd: CdServices,
              public btnGoServices: BtnGoServices,
              public activity:ActivityServices
              ) {
  }

  showPhotosBoxBtn(model: Card, photos: string): void {
    this.onShowPhotosModal.emit(model);
  }

  showLoginForm(): void {
    this.onLoginForm.emit(true);
  }

  selectCourse(model: Card): void {
    this.setId();
    this.onShowCourse.emit(model);
  }

  selectFrame(model: Card) {
    this.selectModel = model; this.showFrame = true;
    this.onFrameShow.emit(model);

  }
  setId(){
    this.activity.data.lastViewId = this.model.id;
    this.activity.save();
    this.onSetId.emit(this.model.id)
  }

    protected readonly giftIcon = giftIcon;

  goToOtherSite() {
    // window.location.href = this.model.srcUrl;
    window.open(this.model.srcUrl, '_blank')
  }
}
