import {saveAs} from 'file-saver';
import {dateNow} from './datetime-tools';

export function fileName(model: any, ext:any = '.jpg') {
  return model.id + '_' + model.createAt + ext;
}

export function saveFile(url: string = '', name: string = '') {
  return saveAs(url, name);
}
export function saveJpg(srcPreview:any) {
  return  saveFile(srcPreview, `(${dateNow()}).jpg`);
}
export  function saveToDevice(model: any, row: any = 'srcPreview') {

  saveFile(model[row], fileName(model));
}
