<app-sidebar [show]="showFrame"
             (onSave)="close()"
             [showClose]="true"
             [height]="100"
             [ext]="'%'"
             [zIndex]="10000"
             [position]="'bottom'"
>

  <p-scrollPanel [style]="{width: windowWidth()+'px', height:windowHeight()+'px'}">
    <app-navbar-top></app-navbar-top>
    <app-preload></app-preload>

    <!--    {{model.srcFRAME}}-->
    <iframe *ngIf="model.srcFRAME && showFrame"
            [src]="urlSafe"
            [title]="model.name"
            frameborder="0"
            [style.width.%]="100"
            [style.height.%]="100"
            >
    </iframe>
  </p-scrollPanel>
</app-sidebar>
