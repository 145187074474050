import {Injectable} from '@angular/core';
declare  const   Hammer;
@Injectable({
  providedIn: 'root'
})
export class HammerServices {
  public elementDom: any;
  public hammer: any;
  public events: any[] = [
    'click',
    'tap',
    'dblclick',
    'doubletap',
    'press',
    'panleft',
    'panright',
    'panmove',
    'pandown',
    'panup',
    'panend',
    'panstart',
    'pancancel',
  ];

  constructor() {}

  public init(elementDom: any) {
    this.elementDom = elementDom;
    this.hammer = new Hammer(elementDom);
  }

  private onEvent(eventType: string = 'click', callbackType: any) {
    this.hammer.on(eventType, (event: any) => {
      /* todo delete jquery*/
      // let data:any = $(event.target).data('val');
      /*event.type*/
      callbackType && callbackType();
    });
  }

  public onClick(callback: any) {
    this.onEvent('click tap', callback);
  }

  public onPress(callback: any) {
    this.onEvent('press', callback);
  }

  public onDblClick(callback: any) {
    this.onEvent('dblclick doubletap', callback);
  }

  public onPanLeft(callback: any) {
    this.onEvent('panleft', callback);
  }

  public onPanRight(callback: any) {
    this.onEvent('panright', callback);
  }

  public onPanMove(callback: any) {
    this.onEvent('panmove', callback);
  }

  public onPanUp(callback: any) {
    this.onEvent('panup', callback);
  }

  public onPanDown(callback: any) {
    this.onEvent('pandown', callback);
  }
}
