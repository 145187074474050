import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {
  NgFor,
  NgIf
} from "@angular/common";
import {dotesVerticalIcon} from "../../traits/icons-tools";
import {HammerWrapperComponent} from "../../../../refactoring-code/components/tools/hammer-wrapper/hammer-wrapper.component";
import {PaginatorModule} from "primeng/paginator";
import {ScrollPanelModule} from "primeng/scrollpanel";

@Component({
  selector: 'app-stage-text',
  standalone:true,
  imports: [
    NgIf,
    NgFor,
    HammerWrapperComponent,
    PaginatorModule,
    ScrollPanelModule
  ],
  templateUrl: './stage-text.component.html'
})
export class StageTextComponent {
  // @ts-ignore
  @ViewChild('accessId') accessId: ElementRef;
  @Input() elementItem: any;
  @Input() elementSelect: any;
  @Output() onClick = new EventEmitter();
  @Output() onSetings = new EventEmitter();
  @Output() onDblclick = new EventEmitter();
  @Output() onPress = new EventEmitter();
  @Output() onSave = new EventEmitter();

  initHammerJs(element: any, callback: any = null) {

  /*  // @ts-ignore
    let mc = new Hammer(element);

    mc.on('panleft panright click dblclick tap press doubletap', (ev: any) => {
      // @ts-ignore
      let atrsData = $(ev.target).data('val');

      // element.textContent = ev.type +" gesture detected.";
      callback && callback(atrsData, ev.type);
    });
*/
  }

  ngAfterViewInit() {

 /*    this.initHammerJs(this.accessId.nativeElement, (atr: any, type: any) => {
      // panleft panright
       if (type == 'press') {
         console.log(type)
         this.onPress.emit(this.model)

         // this.child =this.cd.array.next(this.child,this.set.childs)

       }
       if (type == 'doubletap') {
         console.log(type)
         this.onDblclick.emit(this.model)

         // this.child =this.cd.array.next(this.child,this.set.childs)

       }
      if (type == 'panleft') {
        console.log(type)
        // this.child =this.cd.array.next(this.child,this.set.childs)

      } if (type == 'panright') {
        console.log(type)
        // this.child =this.cd.array.prev(this.child,this.set.childs)

      }
      // console.log(type,'type')
      // console.log(atr,'atr')
      // console.log(this.page,'this.Set')
    });
*/
  }


  ngOnDestroy(): void {
  }

  click(elementItem: any) {
    this.elementSelect = elementItem;
    this.onClick.emit(elementItem) ;
  }
  save() {
    this.onSave.emit(this.elementItem) ;
  }

  protected readonly dotesVerticalIcon = dotesVerticalIcon;

  settings(elementItem) {
    this.elementSelect =elementItem;
    this.onSetings.emit(elementItem) ;
    this.elementSelect = elementItem;

    // opSettings.toggle($event, targetEl)
  }
}
