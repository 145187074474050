import { userAgent } from "./window-tools";

/*
export function customerStartSaveData(patreonMember: any) {
  let place = LocalStorage.getStorageData('place');
  let agent = userAgent();
  let name = patreonMember.name;
  // @ts-ignore
  let ip = place.ip;
  // @ts-ignore
  let language = this.findLanguage(place.flag, this.params.languages);

  return {
    id: patreonMember.id,
    email: patreonMember.email,
    name: name,
    tierId: patreonMember.tierId,
    tierName: patreonMember.tierName,
    tierCost: patreonMember.tierCost,
    isActive: patreonMember.isActive,
    agent: agent,
    src: emptyUserPhoto,
    gender: getGenderByName(name),
    ip: ip,
    // @ts-ignore
    city: place.city,
    // @ts-ignore
    country: place.country,
    // @ts-ignore
    flag: place.flag,
    flagIcon: language.flagIcon,
    time: Date.now(),
    bookFavorites: [],
    pageFavorites: [],
    brushFavorites: [],
    courseFavorites: [],
    fontFavorites: [],
    paletteFavorites: [],
    devices: [{ip: ip, agent: agent}],
  };
}

*/

export  function trimEmail(email: any) {
 return  email.trim().toLowerCase();
}
export function validateEmail(email: any) {
  let re = /\S+@\S+\.\S+/;
  return re.test(email);
}
