export const SeoZakazat_caricature = {
  route: '/zakazat-sharj/',
  title: 'Замовити шарж чи карикатуру з фото',
  description: 'description',
   h1: 'Замовити шарж чи карикатуру з фото',
  h2: 'Замовлення шаржа чи карикатури',
  h3: 'Шарж по фото онлайн чи олівцем пастелью',
  h4: 'h4',
  h5: 'h5',
  textEndPage: 'textEndPage',
  keywords: [],
  text: 'Замовити шарж сюжетний шарж- в якій герой поміщений у сцену, наповнену смисловими нюансами та деталями. Сюжет шаржа, створений за Вашим бажанням, дозволяє яскраво продемонструвати індивідуальність людини, відобразити її захоплення, відтінки натури та розвеселити її цим.\n' +
    '\n' +
    ' Крім підкресленої індивідуальності, шарж може бути наповнений і певною тематикою з нагоди якоїсь події: весілля, Дня народження, закінчення або початку навчання, переїзду в іншу країну і т.д.\n' +
    '\n' +
    ' В одному сюжеті шаржа можуть фігурувати кілька героїв.\n' +
    '\n' +
    ' Формати малюнка також можуть бути різними: А3 (30 * 40 см) А2 (40 * 60 см) А1 (60 * 85 см)\n' +
    '\n' +
    ' Для створення шаржа необхідні фотографії героя (героїв), опис сюжету та деталей, а також ваші побажання.У нас Ви можете замовити шарж (карикатуру) зі фотографії у Києві, Львові, Вінниці, Полтаві, Чернігові, Черкасах, Житомирі, Хмельницькому, Чернівцях, Рівному. Доставка в будь-яке місто України та світу',
}
