<div *ngIf="showAction"

     class="relative text-amber-500 bg-color-custom text-center p-2  shadow-lg  rounded-md"
>
  <!--animate-bounce-->
  <span class="absolute -top-[5px] right-1 z-30 bg-zinc-600/60 text-zinc-100 text-[10px] p-[3px] rounded-md">
    діє до {{date}}
  </span>
  <span class=" font-bold">
    {{textAction}}
  </span>
  <span class=" font-bold  bg-amber-600 text-zinc-900 rounded-full p-1 shadow-amber-800 shadow-2xl  ">
    {{getDiscount(card.cost,discount)}} грн
  </span>
</div>
