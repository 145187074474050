import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
import {Card} from '../../../interfaces/Card';
import {ThemeServices} from '../../../services/common/theme.services';
import {theme} from '../../../interfaces/theme';
import {EditCardViewComponent} from '../edit-card-view/edit-card-view.component';
import {TabViewModule} from 'primeng/tabview';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {EditTextRowComponent} from '../edit-text-row/edit-text-row.component';
import {ConfirmComponent} from '../confirm/confirm.component';
import {EditArrayComponent} from '../edit-array/edit-array.component';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {EditCardsSortComponent} from '../edit-cards-sort/edit-cards-sort.component';
import {BoxUploadComponent} from '../../box/box-upload/box-upload.component';
import {boxFileName} from "../../../traits/string-tools";
import {SidebarComponent} from "../../common/sidebar/sidebar.component";
import {
  addCloneModel,
  deleteFromArray,
  getIndex,
  getIndexLast
} from "../../../traits/array-tools";
import {FormsModule} from "@angular/forms";
import {CdServices} from "../../../services/common/cd.services";
import {AccordionModule} from "primeng/accordion";
import {AvatarModule} from "primeng/avatar";
import {
  arrowBackwardIcon,
  arrowForwardIcon,
  arrowLeftIcon,
  arrowRightIcon,
  editIcon
} from "../../../traits/icons-tools";
import {BoxSortComponent} from "../box-sort/box-sort.component";
import {timeOut} from "../../../traits/datetime-tools";
import {windowWidth} from "../../../traits/window-tools";

@Component({
  selector: 'app-edit-card-select',
  templateUrl: './edit-card-select.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    JsonPipe,
    FormsModule,
    EditCardViewComponent,
    ToggleButtonModule,
    TabViewModule,
    ScrollPanelModule,
    EditTextRowComponent,
    ConfirmComponent,
    EditArrayComponent,
    EditCardsSortComponent,
    BoxUploadComponent,
    SidebarComponent,
    AccordionModule,
    AvatarModule,
    BoxSortComponent,
  ]
})
export class EditCardSelectComponent implements OnInit {
  @ViewChild('uploadSort') uploadSort: ElementRef<HTMLCanvasElement>;
  @ViewChild('uploadBox') uploadBox: ElementRef<HTMLCanvasElement>;
  public viewSortSidebar: string = 'right'
  @Input() card: Card;
  @Input() cardLast: Card;
  @Input() cards: Array<Card>;
  @Input() data: any;

  @Output() onDeleteFromFavorite: EventEmitter<any> = new EventEmitter();
  public theme: theme = inject(ThemeServices);
  public saveChangeSite: boolean = false;
  public selectUpload: any = {rowName: 'photos'};

  @Input() textEditors: any[] = [
    {
      name: 'type',
      type: 'text',
      similar: []
    },
    {
      name: 'status',
      type: 'text',
      similar: []
    },
    {
      name: 'costDiscount',
      type: 'text',
      similar: []
    },
    {
      name: 'action',
      type: 'text',
      similar: []
    },
    {
      name: 'srcPreview',
      type: 'srcPreview',
      similar: []
    },
    {
      name: 'srcIcon',
      type: 'srcIcon',
      similar: []
    },
    {
      name: 'name',
      type: 'text',
      similar: []
    },
    {
      name: 'color',
      type: 'color',
      similar: []
    },
    {
      name: 'desc',
      type: 'text',
      similar: []
    },
    {
      name: 'cat',
      type: 'text',
      similar: []
    },
    {
      name: 'duration',
      type: 'text',
      similar: []
    },
    {
      name: 'level',
      type: 'text',
      similar: []
    },
    {
      name: 'additionally',
      type: 'text',
      similar: []
    },
    {
      name: 'cost',
      type: 'text',
      similar: []
    },
    {
      name: 'info',
      type: 'text',
      similar: []
    },
    {
      name: 'srcUrl',
      type: 'text',
      similar: []
    },
    {
      name: 'srcFRAME',
      type: 'text',
      similar: []
    },
    {
      name: 'srcFile',
      type: 'text',
      similar: []
    },
    {
      name: 'routerLink',
      type: 'text',
      similar: []
    },
    {
      name: 'id',
      type: 'text',
      similar: []
    },

  ];
  @Input() toggleEditors: any[] = [
    // {name: 'free'},
    {name: 'sw'},
    // {name: 'painterEnable'},
    {name: 'moreEnable'},
    {name: 'moreCourse'},
    // {name: 'previewEnable'},
    // {name: 'continueEnable'},
    // {name: 'donateEnable'},
    {name: 'photosEnable'},
    // {name: 'videosEnable'},
    // {name: 'brushesEnable'},
    // {name: 'palletesEnable'},
    // {name: 'referencesEnable'},
    // {name: 'gifsEnable'},
    {name: 'enableGoGallery'},
  ];
  @Input() uploadesEditors: any[] = [
    {rowName: 'photos'},
    // {rowName: 'uploades'},
  ];

  @Input() arrayEditors: any[] = [
    {
      rowName: 'array',
      editRows: [
        'name',
        'desc'
      ],
      view: 'list',
    },
    {
      rowName: 'steps',
      editRows: [
        'name',
        'desc',
        'duration'
      ],
      view: 'list',
    },
    {
      rowName: 'list',
      editRows: [
        'name',
        'desc',
        'icon'
      ],
      view: 'list',
    },
    {
      rowName: 'characteristics',
      editRows: [
        'name',
        'desc',
        'icon'
      ],
      view: 'list',
    },
    {
      rowName: 'tags',
      editRows: ['name',],
      view: 'list',
    },
    {
      rowName: 'price',
      editRows: [
        'name',
        'desc',
        'icon',
        'srcIcon',
        'cost'
      ],
      view: 'list',
    },
    {
      rowName: 'videos',
      editRows: [
        'name',
        'srcPreview',
        'srcVideo'
      ],
      view: 'list',
    },
  ];
  @Output() onSave = new EventEmitter();

  public showSortLeftBlock: boolean = false;
  public showSortRightBlock: boolean = false;
  public modelLast: Card;
  public cardIndex: any = 0;
  tabIndexSelect: any = 0;
  tabIndexCard: number = 0;
  tabIndexCards: number = 1;
  tabIndexBoxPhotos: number = 2;
  tabIndexBoxSort: number = 3;
  tabIndexToggles: number = 4;
  tabIndexFavorites: number = 5;
  activeLeftIndex: any;
  activeRightIndex: any;
  protected readonly addCloneModel = addCloneModel;
  protected readonly getIndex = getIndex;
  protected readonly editIcon = editIcon;
  protected readonly arrowLeftIcon = arrowLeftIcon;
  protected readonly arrowRightIcon = arrowRightIcon;
  protected readonly arrowForwardIcon = arrowForwardIcon;
  protected readonly arrowBackwardIcon = arrowBackwardIcon;
  protected readonly length = length;
  protected readonly getIndexLast = getIndexLast;

  constructor(public cd: CdServices) {
    effect(() => {
      console.log('effect')
    });
  }

  ngOnInit(): void {
    this.cardIndex = getIndex(this.card, this.cards)
    console.log(this.card,'this.card')
  }

 public reInitBoxPhotos() {
        this.tabIndexSelect = this.tabIndexCard;
        timeOut(() => {
          this.tabIndexSelect = this.tabIndexBoxPhotos;
        }, 500);
  }

  ngAfterViewInit() {


  }

  setBoxId(selectCard: Card) {

    console.log(selectCard)
    /*
    "g5b28vsfh"
    1712228036083
    * */
    // selectCard.id = 'su8bhfl4c';
    // selectCard.createAt = 1711873623684;
    // "su8bhfl4c_1711873623684"
    let boxId = boxFileName(selectCard);
    this.card.boxId = boxId;
  }

  public addToFavorite(srcPreview: any) {
    console.log(srcPreview,'srcPreview')
    console.log(this.card.favorites,'this.card.favorites')
    this.card.favorites.push(srcPreview);
    this.saveSite()
  }

  saveSite() {
    this.onSave.emit()
  }

  ngOnDestroy() {
    this.saveSite();
  }

  public delete(model: any, cards: any) {
    deleteFromArray(model, cards);
  }

  public prevCard() {
    this.cardIndex = this.cardIndex - 1;
    this.card = this.cards[this.cardIndex];
    this.reInitBoxPhotos();
  }

  public nextCard() {
    this.cardIndex = this.cardIndex + 1;
    this.card = this.cards[this.cardIndex];
    this.reInitBoxPhotos();
  }

  protected readonly windowWidth = windowWidth;
}
