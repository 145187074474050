
  <app-list-upload
    [showUpload]="showUpload"
    [showEyeDropper]="showEyeDropper"
    [heightItem]="heightItem"
    [widthItem]="widthItem"
    [heightList]="heightList"
    [models]="models"
    [view]="view"
    [showFavorite]="showFavorite"
    [showName]="showName"
    [showCheck]="showCheck"
    [showAdd]="showAdd"
    [showSort]="showSort"
    [showShow]="showShow"
    [showTools]="showTools"
    [showImage]="showImage"
    [showLock]="showLock"
    [showMore]="showMore"
    [sizeView]="sizeView"
    [showList]="showList"
    [showDelete]="showDelete"
    [showDeleteServerImage]="showDeleteServerImage"
    [showToJPG]="showToJPG"
    (onAdd)="onAdd.emit($event);"
    (onEdit)="onEdit.emit($event);"
    (onDelete)="onDelete.emit($event);"
    (onUpload)="onUpload.emit($event);"
    (onSort)="onSort.emit($event);"
    (onShow)="onShow.emit($event);"
    (onCheck)="onCheck.emit($event);"
    (onClick)="onClick.emit($event)"
    (onFavorite)="onFavorite.emit($event)"
    (onUploadedFinish)="onUploadedFinish.emit($event)"
    (onJPG)="onJPG.emit($event)"
  >

  </app-list-upload>
