import {Injectable} from '@angular/core';
declare const  hotkeys;
@Injectable({
  providedIn: 'root',
})

export class HotkeyServices {

 public disableKeys(hotKeysArray: any = ['ctrl+shift+i','ctrl+a'],callback: any = null){
   hotkeys( hotKeysArray.join(','), (event: any, handler: any) => {
     event.preventDefault();
     callback && callback(handler.key);
   });
  }
/* public initD(key: any = 'ctrl+shift+i,ctrl+a', callback: any = null) {

    hotkeys(key, (event: any, handler: any) => {
      switch (handler.key) {
        case 'ctrl+s':
          event.preventDefault();
          callback && callback(handler.key);
          break;
        case 'ctrl+shift+i':
          event.preventDefault();
          callback && callback(handler.key);
          break;
        case 'ctrl+u':
          event.preventDefault();
          callback && callback(handler.key);
          break;
        default:
          event.preventDefault();
          callback && callback(handler.key);
      }
    });
  }*/
}
