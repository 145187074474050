import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtClubServices} from '../static/static-art-club.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {Site} from '../../interfaces/site';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageImageToGrayscaleServices {
  public SERVER_NAME: string = 'image-to-monochrome';
  public DOMAIN: string = 'art-club.artas.org.ua/image-to-monochrome';
  public route: string = 'image-to-monochrome';

  public fromServer: boolean = false;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'Image to grayscale',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'Image to grayscale',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Monochrome Image Converter - Transform Any Photo into Gray Effortlessly',
    description: 'Monochrome transformation is at your fingertips with our app. Convert any image into stunning monochrome gray with ease, perfect for artists, photographers, and grayscale enthusiasts.',
    text: 'Any Image into Monochrome Gray\n' +
      '\n' +
      'Monochrome images have always been popular due to their ability to convey emotions, textures, and contrasts with special expressiveness. In the era of digital technology and color photography, monochrome shots remain a symbol of style and classic appeal. If you want to convert your images into monochrome gray, our app will be your indispensable assistant.\n' +
      '\n' +
      '#### Monochrome Image Converter: Why Choose Our App?\n' +
      '\n' +
      'Monochrome conversions with our app are fast and of high quality. Our development allows you to easily and simply turn any photo into a monochrome gray image, highlighting depth and detail. We’ve created an intuitive interface suitable for both professional photographers and hobbyists.\nMonochrome aesthetics will always be relevant in the world of photography. With it, you can focus on composition, the play of shadows, and light contrasts. Monochrome images often appear more dramatic and captivating than their color counterparts. With our app, you can turn any photo into a work of art that emphasizes your unique perspective on the world.\n' +
      '\n' +
      '#### Monochrome Image Converter: Key Features\n' +
      '\n' +
      'Monochrome image conversion is a process that requires special attention to detail. We’ve created an app that allows you to convert any photo into a monochrome gray image while preserving all the nuances of the original. With our app, you can quickly and efficiently edit photos, turning them into true masterpieces.\n' +
      '\n' +
      'Monochrome conversion is performed through a few simple steps. You just need to upload an image, select conversion settings, and the app will do the rest. The monochrome palette will be chosen to highlight the important elements of your photo. As a result, you will get an image that looks stylish and modern.Monochrome images are especially popular among those who appreciate minimalism and clean lines. Our app allows you to create monochrome images that will stand out among the multitude of color photos. You can use the resulting images for posters, social media posts, website design, or even printing on canvas.\n' +
      '\n' +
      '#### Monochrome Image Converter: The Benefits of Use\n' +
      '\n' +
      'Monochrome images created with our app offer several advantages. First, they always look professional and stylish. Second, monochrome conversion allows you to hide unnecessary details and focus on the main elements of the photo. Third, such images often evoke stronger emotions in viewers.\n' +
      '\n' +
      'Monochrome processing is suitable for any type of photography – from portraits to landscapes. You can experiment with different styles and settings to create unique images that reflect your individuality. Monochrome images can be used for both personal and commercial projects.Monochrome conversion can also be useful for enhancing the quality of old photos. If you have pictures that have faded or lost their colors over time, our app will help breathe new life into them by turning them into stylish monochrome images. The monochrome style will give your old photos new expressiveness and charm.\n' +
      '\n' +
      '#### Monochrome Image Converter: The Perfect Solution for Artists and Designers\n' +
      '\n' +
      'Monochrome art has always attracted the attention of artists and designers with its simplicity and expressiveness. If you are engaged in graphic design, create illustrations, or just want to add elements of monochrome art to your work, our app will be your faithful assistant. Monochrome images easily fit into any design project and can serve as the basis for creating visual masterpieces.\n' +
      '\n' +
      'Monochrome images created with our app can be used in a variety of fields – from web design to printed materials. You can create stylish banners, posters, logos, and other elements that will look modern and attractive.The monochrome style is also great for creating contrasting and memorable illustrations. Our app allows you to quickly and easily convert any image to monochrome, which is especially convenient for those who create comics, book illustrations, or promotional materials.\n' +
      '\n' +
      '#### Monochrome Image Converter: Simplicity and Convenience of Use\n' +
      '\n' +
      'Monochrome image conversion with our app is not only effective but also convenient. We’ve developed an intuitive interface that allows you to convert an image in just a few clicks. You don’t need to be a professional to create an impressive monochrome image – our app will do everything for you.\n' +
      '\n' +
      'Monochrome conversion with our app is available on all devices. You can work with photos on your computer, tablet, or smartphone, and the result will be equally high-quality everywhere. The monochrome style is always at your fingertips, no matter where you are.\nMonochrome images created with our app can be easily saved and used in any project. You can share your work on social media, send it to friends or colleagues, print it on paper, or upload it to websites. Monochrome art will always be with you, thanks to our app.',
    h1: 'Monochrome Your Images to Gray Instantly',
    h2: 'Monochrome Image Conversion Made Easy',
    h3: 'Monochrome Features for Professional-Quality Grayscale',
    h4: 'Monochrome Simplicity in Every Conversion',
    h5: 'Image to grayscale',
    textEndPage:'Monochrome photo editing has never been easier with our app, which allows you to convert any image into timeless gray effortlessly. Monochrome beauty can be achieved in seconds, whether you’re preparing a photo for a project, social media, or just exploring grayscale aesthetics. Monochrome editing is made simple with our user-friendly tools and advanced processing technology, ensuring you achieve the perfect effect with minimal effort. Monochrome design is ideal for photographers, designers, and anyone who appreciates the elegance of grayscale imagery.',
    keywords: [
      'monochrome image to grayscale', 'monochrome photo converter', 'monochrome black and white app', 'monochrome grayscale photo editor', 'monochrome image converter', 'monochrome picture to grayscale', 'monochrome grayscale photo maker', 'monochrome picture converter', 'monochrome black-and-white photography app', 'monochrome gray scale converter',
    ],
  };
  public site: any

  constructor(public server:ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  getSite() {
  /*  if (this.fromServer) {
      return this.getSiteFromServer();
    } else {
      return this.getSiteStatic();
    }*/

  }
  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }
 public saveSite(site:object){
   if (this.fromServer) {

     this.site = site
    this.downloadSiteBtn(this.site);
    // let server: any = inject(ServerSiteServices)
    this.server.saveSite(this.DOMAIN, this.site);
  }
  }
  getSiteStatic() {
    return this.site = inject(StaticArtClubServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer() {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: Site): void => {
      this.site = responseSite;
    });
    return this.site;
  }

}
