<app-sidebar [show]="showSelectTool"
             (onSave)="showSelectTool =false; save()"
             [showClose]="true"
             [height]="100"
             [width]="100"
             [ext]="'%'"
             [zIndex]="100000"
             [position]="'top'"

>
  <ng-container *ngIf="elementSelect">
  {{elementSelect.name}}
    <p>  {{elementSelect.idTool}}</p>


<app-modal-product
  [idProduct]="elementSelect.idTool">

</app-modal-product>
  </ng-container>
</app-sidebar>

<app-sidebar [show]="showReferenceSidebar"
             (onSave)="showReferenceSidebar =false; save()"
             [showClose]="true"
             [height]="100"
             [width]="100"
             [ext]="'%'"
             [zIndex]="100000"
             [position]="'top'"

>
  <app-image-reference
    *ngIf="(showReferenceSidebar && elementSelect && stage.images)"
    [show]="showReferenceSidebar"
    [srcImage]="elementSelect.srcPreview"
    [images]="stage.images"
    (onCrop)="onAddImage.emit($event)"
  ></app-image-reference>
</app-sidebar>
<app-sidebar [show]="showListSidebar"
             (onSave)="showListSidebar =false; save()"
             [showClose]="true"
             [height]="100"
             [width]="100"
             [ext]="'%'"
             [zIndex]="100000"
             [position]="'top'"

>
  <p-scrollPanel [style]="{height:windowHeight()-100+'px'}">
 @if(elementSelect){
   <button (click)="deleteFromArray(elementSelect,stage.images); save()"
           class=" focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
     <i [class]="deleteIcon" class="text-gray-300 hover:text-white"></i>
   </button>
   <textarea
     style="font-size: 12px !important; "
     pInputTextarea
     class="focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1"
     [placeholder]="'Name list'"
     [(ngModel)]="elementSelect.name"
     (ngModelChange)="save()"
     [style.min-height.px]="35"
     [style.height.px]="35"
     [style.min-width.px]="windowWidth()/2"
   ></textarea>
   <button (click)="addCloneModel(listModel(),elementSelect.list); save()"
           class=" m-1    border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
<span class="m-1 w-full ">    Add item</span>
     <i [class]="addIcon" class="p-1 text-gray-300 hover:text-white"></i>
   </button>
   <ng-container *ngFor="let listItem of elementSelect.list">
     <div class="flex items-center py-1 bg-gray-700 ">
       <p-checkbox
         class= "focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1"
         [(ngModel)]="listItem.check"
         (ngModelChange)="save()"
         [binary]="true"
       />
       <textarea
         style="padding: 2px!important;font-size: 12px !important; "
         pInputTextarea
         class= "focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1"
         [placeholder]="'Your Text'"
         [(ngModel)]="listItem.text"
         (ngModelChange)="save()"
         [style.min-height.px]="25"
         [style.height.px]="25"
         [style.min-width.px]="windowWidth()-100"
       ></textarea>

       <button (click)="deleteFromArray(listItem,elementSelect.list); save()"
               class= "focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
         <i [class]="deleteIcon" class="text-gray-300 hover:text-white"></i>
       </button>
     </div>
   </ng-container>
 }
  </p-scrollPanel>
</app-sidebar>
<app-sidebar [show]="showTextSidebar"
             (onSave)="showTextSidebar =false; save()"
             [showClose]="true"
             [height]="100"
             [width]="100"
             [ext]="'%'"
             [zIndex]="100000"
             [position]="'top'"

>
  @if(elementSelect){
    <textarea
      style="font-size: 12px !important; "
      pInputTextarea
      class="focus:outline-none border border-transparent rounded-lg bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1"
      [placeholder]="'Name list'"
      [(ngModel)]="elementSelect.name"
      (ngModelChange)="save()"
      [style.min-height.px]="35"
      [style.height.px]="35"
      [style.min-width.px]="windowWidth()/2"
    ></textarea>

        <button (click)="deleteFromArray(elementSelect,stage.images); save()"
                     class="focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
               <i [class]="deleteIcon" class="text-gray-300 hover:text-white"></i>
             </button>
  <p-scrollPanel [style]="{height:windowHeight()-100+'px'}">



      <div class="flex items-center py-1 bg-gray-700 ">
                    <textarea
                      style="padding: 2px!important;font-size: 12px !important; "
                      pInputTextarea
                      [autofocus]="false"
                      class= "focus:outline-none border border-transparent g-gray-800  shadow-lg p-2"
                      [placeholder]="'Your Text'"
                      [(ngModel)]="elementSelect.text"
                      (ngModelChange)="save()"
                      [style.min-height.px]="600"
                      [style.height.px]="2000"
                      [style.min-width.px]="windowWidth()-100"
                    ></textarea>
      </div>

  </p-scrollPanel>
  }
</app-sidebar>
<div
  [style.height.px]="height"
  [style.width.px]="width"
  id="clip-container">
  <div #gestureArea>
    <div id="zoom-container"
         #scaleElement
         [style.height.px]="stage.height"
         [style.width.px]="stage.width"
         [attr.data-x]="stage.x"
         [attr.data-y]="stage.y"
         [attr.data-scale]="stage.scale"
         [attr.data-angle]="stage.angle"
         [ngStyle]="{'transform': 'translate(' + stage.x + 'px, ' + stage.y + 'px) scale('+stage.scale+')'}"
         [ngClass]="{'drag-handle-container': !expand}"
         class="zoom-container showGrid"
    >


      <ng-container *ngFor="let elementItem of stage.images|filter:filterByColor:'color'">
        <ng-container *ngIf="(elementItem.sw === true )">
          <div
            (click)=" click(elementItem)"
            (dragend)="click(elementItem);  "
            [ngStyle]="{
                 'background-color':elementItem.color,
                 'border-color':elementSelect === elementItem?'#fd8700':'#ccc' ,
                 'transform': 'translate(' + elementItem.x + 'px, ' + elementItem.y + 'px)'
                 }"
            class="  absolute  p-1  draggable text-center  "
            [attr.data-x]="elementItem.x"
            [attr.data-y]="elementItem.y"
            [style.height.px]="200"
            [style.width.px]="200"
          >

            <ng-container *ngIf="elementItem.type ==='image'">
             <textarea
               style="padding: 2px!important;font-size: 12px !important; "
               pInputTextarea
               class=" absolute -top-4 left-6 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1"
               [placeholder]="'Name list'"
               [(ngModel)]="elementItem.name"
               (ngModelChange)="save()"
               [style.min-height.px]="25"
               [style.height.px]="25"
               [style.min-width.px]="75"
             ></textarea>
              <p-overlayPanel #opColorsLib>
                <app-colors-grid
                  [outputColors]="filterColors()"
                  [imageForColors]="elementItem.srcPreview"
                  (onClick)="elementItem.color = $event; save()"
                ></app-colors-grid>
              </p-overlayPanel>
              <button #targetEl
                      [style.background-color]="elementItem.color"

                      (click)="opColorsLib.show($event, targetEl)"
                      class="  text-[30px] w-[30px] h-[30px] absolute -top-4 -left-4 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
              </button>
              <button *ngIf="elementSelect === elementItem"
                      [ngClass]="{'drag-handle ': elementSelect === elementItem}"
                      class=" text-[30px]  absolute -bottom-8 -right-4 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
                <i [class]="moveIcon" class="text-gray-300 hover:text-white"></i>
              </button>
              <button #eleTargetImage
                      (click)="opSettingsElement.toggle($event,eleTargetImage)"
                      class=" w-[30px] h-[30px] absolute -bottom-4 -left-3 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
                <i [class]="dotesVerticalIcon" class="text-gray-300 hover:text-white"></i>
              </button>
          <!--    <button #eleTargetImage
                      (click)="opSettingsElement.toggle($event,eleTargetImage)"
                      class= "focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
                <i [class]="deleteIcon" class="text-gray-300 hover:text-white"></i>
              </button>-->
              <app-stage-image
                [elementSelect]="elementSelect"
                [elementItem]="elementItem"
                (onClick)="elementSelect = elementItem;save()"
                (onDblclick)="elementSelect = elementItem; showReferenceSidebar=true; stageToCenterElement(elementItem)"
                (onPress)="elementSelect = elementItem;"
              ></app-stage-image>
            </ng-container>

            <ng-container *ngIf="elementItem.type ==='text'">
              <div class=" max-w-md mx-auto bg-zinc-600 shadow-lg  overflow-hidden mt-1">
               <textarea
                 style="padding: 2px!important;font-size: 12px !important; "
                 pInputTextarea
                 class=" absolute -top-4 left-6 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1"
                 [placeholder]="'Name list'"
                 [(ngModel)]="elementItem.name"
                 (ngModelChange)="save()"
                 [style.min-height.px]="25"
                 [style.height.px]="25"
                 [style.min-width.px]="75"
               ></textarea>
                <p-overlayPanel #opColorsLib>
                  <app-colors-grid
                    [outputColors]="filterColors()"
                    (onClick)="elementItem.color = $event; save()"
                  ></app-colors-grid>
                </p-overlayPanel>
                <button #targetEl
                        [style.background-color]="elementItem.color"

                        (click)="opColorsLib.show($event, targetEl)"
                        class="  text-[30px] w-[30px] h-[30px] absolute -top-4 -left-4 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
                  <!--              <i [class]="elementItem.icon" class="text-gray-300 hover:text-white"></i>-->

                </button>

                <button (click)="elementSelect = elementItem ;showTextSidebar=true"
                        class="w-[30px] h-[30px] absolute -bottom-4 -left-3 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
                  <i [class]="dotesVerticalIcon" class="text-gray-300 hover:text-white"></i>
                </button>
                <button *ngIf="elementSelect === elementItem"
                        [ngClass]="{'drag-handle ': elementSelect === elementItem}"
                        class=" text-[30px] absolute -bottom-8 -right-4 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
                  <i [class]="moveIcon" class="text-gray-300 hover:text-white"></i>
                </button>
                <app-stage-text
                  [elementSelect]="elementSelect"
                  [elementItem]="elementItem"
                  (onClick)="elementSelect = elementItem;save()"
                  (onDblclick)="elementSelect = elementItem ;showTextSidebar=true"
                  (onPress)="elementSelect = elementItem ;showTextSidebar=true"
                  (onSave)="save()"

                ></app-stage-text>
              </div>


            </ng-container>
            <ng-container *ngIf="elementItem.type ==='list'">
              <div class=" max-w-md mx-auto bg-zinc-600 shadow-lg rounded-lg overflow-hidden mt-1">
               <textarea
                 style="padding: 2px!important;font-size: 12px !important; "
                 pInputTextarea
                 class=" absolute -top-4 left-6 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1"
                 [placeholder]="'Name list'"
                 [(ngModel)]="elementItem.name"
                 (ngModelChange)="save()"
                 [style.min-height.px]="25"
                 [style.height.px]="25"
                 [style.min-width.px]="75"
               ></textarea>
                <p-overlayPanel #opColorsLib>
                  <app-colors-grid
                    [outputColors]="filterColors()"
                    (onClick)="elementItem.color = $event; save()"
                  ></app-colors-grid>
                </p-overlayPanel>
                <button #targetEl
                        [style.background-color]="elementItem.color"

                        (click)="opColorsLib.show($event, targetEl)"
                        class="  text-[30px] w-[30px] h-[30px] absolute -top-4 -left-4 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
                  <!--              <i [class]="elementItem.icon" class="text-gray-300 hover:text-white"></i>-->

                </button>
                <button (click)="addCloneModel(listModel(),elementItem.list); save()"
                        class=" absolute -top-4 -right-3 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
                  <i [class]="addIcon" class="text-gray-300 hover:text-white"></i>
                </button>
                <button (click)="elementSelect = elementItem ;showListSidebar=true"
                        class=" absolute -bottom-4 -left-3 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
                  <i [class]="dotesVerticalIcon" class="text-gray-300 hover:text-white"></i>
                </button>
                <button *ngIf="elementSelect === elementItem"
                        [ngClass]="{'drag-handle ': elementSelect === elementItem}"
                        class=" text-[30px] absolute -bottom-8 -right-4 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
                  <i [class]="moveIcon" class="text-gray-300 hover:text-white"></i>
                </button>
                <app-stage-list
                  [elementSelect]="elementSelect"
                  [elementItem]="elementItem"
                  (onClick)="elementSelect = elementItem;save()"

                  (onSave)="save()"
                ></app-stage-list>
              </div>

            </ng-container>
            <ng-container *ngIf="elementItem.type ==='tool'">
               <textarea
                 style="padding: 2px!important;font-size: 12px !important; "
                 pInputTextarea
                 class=" absolute -top-4 left-6 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1"
                 [placeholder]="'Name list'"
                 [(ngModel)]="elementItem.name"
                 (ngModelChange)="save()"
                 [style.min-height.px]="25"
                 [style.height.px]="25"
                 [style.min-width.px]="75"
               ></textarea>
              <p-overlayPanel #opColorsLib>
                <app-colors-grid
                  [outputColors]="filterColors()"
                  [imageForColors]="elementItem.srcPreview"
                  (onClick)="elementItem.color = $event; save()"
                ></app-colors-grid>
              </p-overlayPanel>
              <button #targetEl
              [style.background-color]="elementItem.color"

              (click)="opColorsLib.show($event, targetEl)"
              class="  text-[30px] w-[30px] h-[30px] absolute -top-4 -left-4 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
              </button>
              <button *ngIf="elementSelect === elementItem"
                      [ngClass]="{'drag-handle ': elementSelect === elementItem}"
                      class=" text-[30px]  absolute -bottom-8 -right-4 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
                <i [class]="moveIcon" class="text-gray-300 hover:text-white"></i>
              </button>
              <button #eleTargetImage
                      (click)="opSettingsElement.toggle($event,eleTargetImage)"
                      class=" w-[30px] h-[30px] absolute -bottom-4 -left-3 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
                <i [class]="dotesVerticalIcon" class="text-gray-300 hover:text-white"></i>
              </button>
              <app-stage-image
                [elementSelect]="elementSelect"
                [elementItem]="elementItem"
                (onClick)="elementSelect = elementItem;save();showSelectTool=true"
                (onDblclick)="elementSelect = elementItem; showReferenceSidebar=true; stageToCenterElement(elementItem)"
                (onPress)="elementSelect = elementItem;"
              ></app-stage-image>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>


<p-overlayPanel #opSettingsElement>

  @if (elementSelect) {
    <div class="  justify-center grid grid-cols-1 md:grid-cols-1 gap-4">
    <textarea
      style="padding: 2px!important;font-size: 16px !important; "
      pInputTextarea
      [placeholder]="'Name'"
      [(ngModel)]="elementSelect.name"
      (ngModelChange)="save()"
      [style.min-height.px]="25"
      [style.height.px]="25"
      [style.min-width.px]="75"
    ></textarea>
      <button (click)="elementSelect.sw =!elementSelect.sw ; save(); opSettingsElement.hide()"
              class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
        <span *ngIf="elementSelect.sw === true" class="mx-1">To Archive</span>
        <span *ngIf="elementSelect.sw === false" class="mx-1">From Archive</span>
        <i [class]="archiveIcon"></i>
      </button>
      <button
        *ngIf="elementSelect.sw === false"
        (click)="deleteFromArray(elementSelect,stage.images); save(); opSettingsElement.hide()"
        class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
        <span class="mx-1">Delete</span>
        <i [class]="deleteIcon"></i>
      </button>

      <!--<button #targetEl
              class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center"

      >
       <span
         class="block w-[25px] h-[25px] mx-1 "
         [style.background-color]="elementSelect.color"
       ></span>
        Color
      </button>-->
      <button (click)="showReferenceSidebar =true"
              class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">

        <span class="mx-1">Reference</span>
        <i [class]="imagePanoramaIcon" class="text-gray-300 hover:text-white"></i>
      </button>
      <app-btn-download
        [srcImage]="elementSelect.srcPreview"
        [disabled]="false"
      ></app-btn-download>
    </div>
  }

</p-overlayPanel>

<!--
<div class="z-20 fixed bottom-4 left-1/2">

</div>
-->

<div class="z-20 fixed bottom-4 left-4">
  <p-overlayPanel #opSettingLib>
    <!--<div *ngIf="(stage.images)"
         [style.width.px]="200"
         class="flex overflow-x-auto py-4 space-x-4">
      <ng-container *ngFor="let  itemElement  of stage.images;">
       <ng-container *ngIf="itemElement.type ==='image'">
        <div class="rounded-md w-[75px] h-[75px] shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse ">
          <img [src]="itemElement.srcPreview" alt="upload_image" class="handle w-16 h-16 object-cover rounded cursor-pointer"
               (click)="renderContainer(itemElement)"
          >
          <button  #elTargetElement
                  class="text-sm absolute top-0 left-2 bg-green-500 text-white p-1 rounded-full transition-transform transform hover:scale-125"
        (click)="opSettingsElement.toggle($event,elTargetElement)"
          >
            <i [class]="dotesVerticalIcon"></i>
          </button>
         &lt;!&ndash; <button
            class="text-sm absolute top-0 -right-2 bg-red-500 text-white p-1 rounded-full transition-transform transform hover:scale-125"
            (click)="deleteFromArray(uploadItem,stage.images);save()">
            <i [class]="deleteIcon"></i>
          </button>&ndash;&gt;
        </div>
       </ng-container>
        <ng-container *ngIf="itemElement.type !=='image'">
          <div class="rounded-md w-[75px] h-[75px] shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse ">
            <button  #elTargetElement
                     class="text-sm absolute top-0 left-2 bg-green-500 text-white p-1 rounded-full transition-transform transform hover:scale-125"
                     (click)="opSettingsElement.toggle($event,elTargetElement)"
            >
              <i [class]="dotesVerticalIcon"></i>
            </button>
            <div  (click)="renderContainer(itemElement)"
           >{{ itemElement.type}}</div>
          </div>
        </ng-container>
      </ng-container>
    </div>-->



<!--    <div class="  justify-center grid grid-cols-1 md:grid-cols-1 gap-4">-->
      <div class=" flex overflow-x-auto py-4 space-x-4">
        <ng-container *ngFor="let  colorHex  of  filterColorsWithCount()">
          <div  (click)="setColorFilter(colorHex.color)"
                [style.background-color]="colorHex.color"
            class=" flex  justify-between w-[50px] h-[50px] rounded-lg mb-1 p-[5px] text-zinc-300 border-amber-300 bg-zinc-800 truncate"
          >
         <span
           class=" w-[25px] rounded-lg h-[25px] bg-zinc-600 text-white text-[15px] text-center
            font-bold"
         >
            {{ colorHex.count }}
         </span>

          </div>
        </ng-container>
        <span *ngIf="filterByColor"
              (click)="filterByColor  = '';save()"
              [style.background-color]="filterByColor"
              class="absolute -top-6 -right-2 w-[25px] h-[25px] rounded-md text-white text-center "
        > <i [class]="closeIcon"></i></span>
      </div>

      <!--<button *ngIf="checkExistArchive()" (click)="showArchived  = !showArchived;save(); "
              class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
        <span class="mx-1">Show Archived</span>
        <span class="text-[10px] bg-zinc-700 w-[15px] px-1 h-[15px] mx-1">
              {{ countArchived() }}
            </span>
        <i [class]="archiveIcon"></i>
      </button>-->
<!--    </div>-->
  </p-overlayPanel>
  <div class="  justify-center grid grid-cols-1 md:grid-cols-1 gap-4">
<!--    <button (click)="save();"
            class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
      <i *ngIf="!saveStatus" [class]="saveIcon"></i>
      <span *ngIf="saveStatus">Saved!</span>
    </button>-->

    <!--<button *ngIf="stage.images.length>1"
            #targetEl
            (click)="opSettingLib.toggle($event, targetEl)"
            class=" relative bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
      <i [class]="filterIcon"></i>

    </button>-->
  </div>
</div>
<div class="z-20 fixed top-14 left-4">
  <p *ngIf="stage.name" class="text-[8px] z-20 bg-zinc-500 text-white p-[1px]">
    {{ stage.name }}
  </p>
  <img [src]="stage.srcPreview" class="w-24 h-24  shadow border-dashed border-amber-200">
  <p-overlayPanel #opCropBoard>
    <div *ngIf="stage.srcPreview"
         class=" w-[300px] ">
      <app-crop-rigion
        [width]="300"
        [height]=300
        [cropZoneHeight]=150
        [cropZoneWidth]=150
        [cropZoneX]="100"
        [cropZoneY]="100"
        [showReset]="true"
        [showPressetsCrop]="false"
        [showZoom]="true"
        [showFlip]="true"
        [showRotate]="false"
        [showAspectRatio]="true"
        [showDownload]="false"
        [showAdd]="true"
        [src]="stage.srcPreview"
        (onCrop)=" stageCropImage = $event;   "
        (onAdd)=" onAddImage.emit($event);save();  "
      ></app-crop-rigion>
      <app-btn-download *ngIf="stageCropImage"
                        [srcImage]="stageCropImage"
                        [disabled]="false"
      ></app-btn-download>
    </div>
        <app-stage-cropper *ngIf="showCropBoard"
                           [stage]="stage"
                           [showResult]="false"
                           (onImageResult)="stage.srcPreivew = $event;"
        ></app-stage-cropper>
  </p-overlayPanel>
  <p-overlayPanel #opSettingStage>
    <div class=" justify-center grid grid-cols-1 md:grid-cols-1 gap-1 w-[120px]">

    <textarea
      style="padding: 2px!important;font-size: 16px !important; "
      pInputTextarea
      [placeholder]="'Name Stage'"
      [(ngModel)]="stage.name"
      (ngModelChange)="save()"
      [style.min-height.px]="25"
      [style.height.px]="25"
      [style.min-width.px]="75"
    ></textarea>
    <!--  <button *ngIf="!expand" (click)="updateTransform(this.stage,true);save(); "
          class=" absolute -bottom-2 -left-2 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
    <i [class]="zoomPlusIcon"></i>
  </button>
  <button *ngIf="expand" (click)="updateTransform(this.stage,false);save(); "
          class=" absolute -bottom-2 -left-2 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">

    <i [class]="zoomMinusIcon"></i>
  </button>-->
     <button #targetElBoard (click)="reInitStageCropper();opCropBoard.toggle($event, targetElBoard)"
              class=" absolute -bottom-2 -right-2 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
         <i [class]="cropIcon"></i>
      </button>

    <button   (click)="exportStage(stage); "
              class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
<!--      <span class="mx-1">Backup to device</span>-->
      <i  class="fa-solid fa-file-export"></i>
    </button>
    </div>
  </p-overlayPanel>
<!--  <button #eleTarget (click)="opSettingStage.toggle($event,eleTarget)"
          class=" absolute -bottom-2 -left-2 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
    <i [class]="dotesVerticalIcon"></i>
  </button>-->

</div>
