import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {
  NgFor,
  NgIf
} from "@angular/common";
import {HammerServices} from "../../../services/sort/hammer.service";

@Component({
  selector: 'app-hammer-wrapper',
  standalone:true,
  imports:[NgIf,NgFor],
  templateUrl: './hammer-wrapper.component.html'
})
export class HammerWrapperComponent implements AfterViewInit, OnDestroy {
  @ViewChild('hammerZone') hammerZone: ElementRef;
  @Input() model: any;
  @Output() onClick = new EventEmitter();
  @Output() onDblclick = new EventEmitter();
  @Output() onPress = new EventEmitter();
  @Output() onMove = new EventEmitter();
  @Output() onLeft = new EventEmitter();
  @Output() onRight = new EventEmitter();
  @Output() onTop = new EventEmitter();
  @Output() onBottom = new EventEmitter();

  constructor(public hammerServices: HammerServices) {
  }
/*  public select(modelItem: any) {
    this.selectModel = modelItem;
  }*/


  ngAfterViewInit() {
    this.initHammer();
  }

  private initHammer() {
    this.hammerServices.init(this.hammerZone.nativeElement);

    this.hammerServices.onClick(() => {
      this.onClick.emit(this.model);
    });

    this.hammerServices.onDblClick(() => {
      this.onDblclick.emit(this.model);
    });

    this.hammerServices.onPress(() => {
      this.onPress.emit(this.model);
    });
  }

  ngOnDestroy() {}
}
