import {Injectable} from '@angular/core';
import {ApiServices} from '../api/api.services';
import {Box} from '../../interfaces/Box';
import {boxFileName} from '../../traits/string-tools';
import {
  modelBoxNew,
  modelNew
} from '../../traits/model-tools';

@Injectable({
  providedIn: 'root',
})

export class BoxServices {
  public flagFind: any = false;
  public preloadBox: any;
  public searchUrl: any = 'filebox/model-search';
  public editUrl: any = 'filebox/model-save';
  public createUrl: any = 'filebox/model-save';
  public pathCreate: any = '/Server/customers/models/';

  constructor(public api: ApiServices,) {
  }

  public initFileBox(rowName, model, callback: any = null) {
    this.pathCreate = '/Server/customers/models/';
    /*this.boxSearch(model, (modelBoxResponse: any) => {
      if(modelBoxResponse[rowName]){
        callback && callback(modelBoxResponse)
      }
    });*/
  }

  /*  public findBoxM(rowName,model,callback:any =null){
      this.pathCreate = '/Server/customers/models/';
      this.boxSearch(model, (modelBoxResponse: any) => {
        if(modelBoxResponse[rowName]){
          callback && callback(modelBoxResponse)
        }
      });
    }*/

  boxArrayCount(boxArray:any[]){
    return boxArray.length;
  }
  public findCardBoxById(idPlusCreateAt: string, callback: any): void {
    this.api.request(`filebox/model-search`,
    {
      fileName: idPlusCreateAt,
        pathCreate: '/Server/customers/models/',
    },
    )
      .subscribe((boxSearchResponse: Box): void => {
        callback && callback(boxSearchResponse);
      });
  }
  public createArrayIsNotExist(box: any,rowName:string) {
    if (box[rowName].length==0) {
      box[rowName] = [modelNew()];
    }
  }


     public boxSearch(model: any, callback: any = null) {
       this.api.request(
         `filebox/model-search`,
         // boxFileName(model)
         {
           fileName: boxFileName(model),
           pathCreate: '/Server/customers/models/',
         },
       )
         .subscribe(
           (boxSearchResponse: any) => {
             callback && callback(boxSearchResponse);
           },
           (error: any) => {
               this.boxCreate(model,
                 (boxAddResponse: any) => {
                   callback && callback(boxAddResponse);
                 });
           },
           () => {
             // console.log('saveBoxModel');
           }
         );
     }
     public  isBoxArray(box:any,rowName:string){
    if((box && rowName && box[rowName].length>0)) return true;
}

  public boxInit(model: any, boxEdit: any, callback: any = null) {
    // this.model = model;
    // this.boxEdit = boxEdit;
  }


  /*  public findBoxLesson(videoName: any = '01.mp4',
                         path: any = '/Server/courses/03-clothes/',
                         callback: any = null
    ) {

    }*/


  public findBoxFileByNameAndPath(
    fileName: any = 'Висцеральный самомассаж живота.mp4',
    filePath: any = 'JULIA/Revitonika/Lessons/Body',
    callback: any = null
  ) {
    this.api.request('dropbox/model-search-custom',
      {path: filePath, fileName: fileName})
      .subscribe((fileBox: any) => {
        callback && callback(fileBox);
      });
  }


   public boxEdit(model: any, box: any = null, callback: any = null) {
     this.api.request(`filebox/model-save`,
       {
         fileName: boxFileName(model),
         model: box,
         pathCreate: '/Server/customers/models/',
       })
       .subscribe((saveBox: any) => {
         callback && callback(saveBox);
       });
   }

  /*public boxNewCreate(callback: any = null) {
    let model = this.model();
    let createModelBox = this.createModelBox(model);
    this.preloadBox = true;
    this.api.request(`${this.editUrl}`,
      {
        fileName: boxFileName(createModelBox),
        model: createModelBox,
      })
      .subscribe(
        (boxSaveResponse: any) => {
          let boxModel = boxSaveResponse.model;
          callback && callback(
            {
              model: model,
              boxModel: boxModel,
            });
          this.preloadBox = false;
        });
  }*/

   public boxCreate(model: any, callback: any = null) {
     let createModelBox = modelBoxNew(model);
     this.api.request(`filebox/model-save`,
       {
         fileName: boxFileName(createModelBox),
         pathCreate: '/Server/customers/models/',
         model: createModelBox,
       })
       .subscribe(
         (boxSaveResponse: any) => {
           callback && callback(boxSaveResponse.model);
         });
   }
  /*  public clearLayers(model: any, box: any, callback: any = null) {
      box.layers = [];
      this.boxEdit(box);
    }*/


    protected createModelBox(model: any) {
      let createModelBox = modelBoxNew(model)
      createModelBox.id = model.id;
      createModelBox.createAt = model.createAt;
      return createModelBox;
    }

  /*  protected addNotExitsRows(box: any) {
      !box.stage ? box.stage = {
        width: 500,
        height: 500,
      } : null;
      !box.layers ? box.layers = [] : null;
      !box.favorites ? box.favorites = [] : null;
      !box.photos ? box.photos = [] : null;
      !box.texts ? box.texts = [] : null;
      !box.uploaded ? box.uploaded = [] : null;
      return box;
    }*/

  /*public autoSaveBox(model: any, box: any, callback: any = null) {
    let timeout = setTimeout(() => {
      this.boxEdit(model, box, callback);
      clearTimeout(timeout);
    }, 3000);
    console.log(timeout);
  }*/


  /* public addBoxModel() {
     let modelBox = this.modelBox();
     let fileName = modelBox.id + '_' + modelBox.createAt;
     this.api.request(`dropbox/model-save`, {fileName: fileName, model: modelBox})
       .subscribe((saveBox: any) => {
         console.log(saveBox);
       });
     return modelBox;
     // this.saveModel[this.arrayName]?this.array.add(modelBox,this.saveModel[this.arrayName]):null;
 // this.saveSite(this.site,'site')
   }*/


  /* public async initPhotosModels(model: any, callback: any = null) {
     await this.initBoxModel(model, (modelBox: any) => {
       callback && callback(modelBox);
       // console.log(modelBox, 'modelBox');

     });
   }*/

  /* public initBoxModel(model: any, callback: any = null) {
     let fileName = model.id + '_' + model.createAt;
     this.api.request(`dropbox/model-search`, fileName)
       .subscribe((customerFlag: any) => {
         callback && callback(customerFlag);
         // console.log(customerFlag, 'this.dropBoxModelSearch');
       });
   }*/

  /* public dropBoxSave(fileName: any, model: any, callback: any = null) {
     // console.log(model, 'saveModelBox')
     this.api.request(`dropbox/model-save`, {fileName: fileName, model: model})
       .subscribe((saveBox: any) => {
         callback && callback(saveBox);

         // console.log(saveBox, 'this.dropBoxModelSave');
       });
   }*/

}
