import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtClubServices} from '../static/static-art-club.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {Site} from '../../interfaces/site';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageImageToMosaicServices {
  public SERVER_NAME: string = 'image-to-mosaic';
  public DOMAIN: string = 'art-club.artas.org.ua/image-to-mosaic';
  public route: string = 'image-to-mosaic';

  public fromServer: boolean = false;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'Image to grayscale',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'Image to grayscale',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Image to Mosaic: Transform Your Photos into Stunning Pixel Mosaics with Our App',
    description: 'Description\n' + 'Image to Mosaic: Download our app to easily convert images into beautiful pixel mosaics. Image to Mosaic: Adjust cell size, explore different styles, and create stunning pixel art in seconds.',
    text: 'Image to Mosaic: Your Ultimate Tool for Stunning Pixel Art\n' +
      '\n' +
      'In the digital age, where visuals dominate, creating unique and eye-catching images has never been more crucial. Whether you are an artist, designer, or simply someone who enjoys playing with visuals, our Image to Mosaic app is the perfect tool for you. This powerful pixel mosaic converter transforms any image into a stunning piece of pixel art, allowing you to explore a new realm of creativity. With the ability to convert images to pixel mosaics and adjust the mosaic cell size, this app is versatile, user-friendly, and incredibly fun to use.\n' +
      '\n' +
      '### What is an Image to Mosaic Converter?\n' +
      '\n' +
      'An image to mosaic converter is a specialized tool designed to transform standard images into pixelated mosaics. This process involves breaking down an image into a grid of small squares, or "cells," that together form a larger, cohesive picture. The magic of pixel art lies in its simplicity—the reduction of complex images into basic elements, yet still conveying the original image’s essence. With our Image to Mosaic app, you can easily convert any photo into a pixel mosaic, providing endless possibilities for artistic expression.\n' +
      '\n' +
      '### Why Choose Our Pixel Mosaic Converter App?\n' +
      '\n' +
      'When it comes to converting images into pixel mosaics, not all tools are created equal. Our Image to Mosaic app stands out for several reasons:\n' +
      '\n' +
      '- User-Friendly Interface: Our app is designed to be intuitive, ensuring that both beginners and professionals can navigate and use it with ease. \n' +
      '- Adjustable Mosaic Cell Size: Unlike other apps, our pixel mosaic converter allows you to adjust the cell size of the mosaic. Whether you want fine, detailed pixels or larger, chunkier squares, the choice is yours.\n' +
      '- High-Quality Output: The Image to Mosaic app produces high-resolution images, ensuring that your pixel art is crisp, clear, and ready for display.\n' +
      '- Quick Processing: Convert images to pixel mosaics in seconds, making it convenient for users who need fast results.\n' +
      '- Creative Flexibility: The app provides various styles and options to experiment with, allowing you to create unique pixel art that stands out.\n' +
      '- Compatibility: Our Image to Mosaic app is compatible with various image formats, so you can work with any photo in your collection.\n' +
      '\n' +
      '### How to Use the Image to Mosaic App\n' +
      '\n' +
      'Creating pixel art has never been easier. Here’s how you can start transforming your photos into pixel mosaics with our app:\n' +
      '\n' +
      '1. Download and Install: Start by downloading the Image to Mosaic app from your preferred app store. The installation process is quick and straightforward.\n' +
      '2. Upload Your Image: Once installed, open the app and upload the image you wish to convert into a pixel mosaic. You can choose from your gallery or take a new photo directly within the app.\n' +
      '3. Adjust the Mosaic Cell Size: Use the adjustable slider to set the cell size for your mosaic. Smaller cells will give you more detailed images, while larger cells will produce a more abstract effect.\n' +
      '4. Choose a Style: Explore different styles and effects within the app. You can customize the appearance of your pixel mosaic to match your artistic vision.\n' +
      '5. Preview and Save: Once satisfied with the adjustments, preview your pixel mosaic. If it meets your expectations, save the image to your device.\n' +
      '6. Share Your Creation: Share your pixel art on social media, print it, or use it in your design projects. The Image to Mosaic app makes it easy to showcase your work to the world.\n' +
      '\n' +
      '### The Benefits of Pixel Art\n' +
      '\n' +
      'Pixel art is not just a trend; it’s a timeless art form that offers numerous benefits:\n' +
      '\n' +
      '- Creative Expression: Pixel art allows for a unique form of creative expression. By simplifying complex images, you can focus on color, shape, and composition in new ways. Nostalgic Appeal: Pixel art often evokes a sense of nostalgia, reminiscent of early video games and digital art from past decades. This retro aesthetic is beloved by many and continues to be popular in modern design.\n' +
      '- Versatility: Pixel art can be used in various applications, from digital designs and game development to creating personalized gifts and home decor.\n' +
      '- Accessibility: With tools like the Image to Mosaic app, anyone can create pixel art, regardless of their artistic background.\n' +
      '\n' +
      '### Start Creating with Image to Mosaic\n' +
      '\n' +
      'Whether you\'re looking to create unique digital artwork, experiment with new design ideas, or simply have fun with your photos, the Image to Mosaic app is the perfect tool. With its adjustable mosaic cell size and easy-to-use interface, you can transform any image into a stunning pixel mosaic in just a few clicks. Download the Image to Mosaic app today and unleash your creativity. Whether you’re a seasoned artist or a beginner, you’ll find endless possibilities with our pixel mosaic converter.\n' +
      '\n' +
      '### Conclusion\n' +
      '\n' +
      'In a world where digital images are everywhere, standing out means creating something truly unique. The Image to Mosaic app gives you the power to do just that—transform ordinary photos into extraordinary pixel art. With its versatile features, including adjustable cell size and high-quality output, this app is a must-have for anyone interested in pixel art. Download it today and start creating your own pixel mosaics that are sure to impress.',
    h1: 'Image to Mosaic: Convert Images into Pixel Mosaics with Adjustable Cell Size',
    h2: 'Image to Mosaic: Why Choose Our Pixel Mosaic Converter App',
    h3: 'Image to Mosaic: Key Features of the App',
    h4: 'Image to Mosaic: How to Use the App to Create Pixel Mosaics',
    h5: 'Image to Mosaic: Download Now and Start Creating Pixel Art Today!',
    textEndPage: 'mage to Mosaic: Discover our app that transforms any image into a pixel mosaic. Image to Mosaic: With adjustable cell sizes, you can create unique and stunning pixel art in just a few clicks. Image to Mosaic: Perfect for artists, designers, and anyone who loves pixel art. Image to Mosaic: Download now and start creating!',
    keywords: [
      'Image to Mosaic',
      'Pixel mosaic converter',
      'Pixel art app',
      'Convert image to pixel art',
      'Adjustable mosaic cell size',
      'Photo to pixel mosaic',
      'Create pixel art',
      'Digital mosaic maker',
      'Mosaic photo app',
      'Image to pixel mosaic download'
    ],
  };
  public site: any

  constructor(public server: ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  getSite() {
    /*  if (this.fromServer) {
        return this.getSiteFromServer();
      } else {
        return this.getSiteStatic();
      }*/

  }

  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }

  public saveSite(site: object) {
    if (this.fromServer) {

      this.site = site
      this.downloadSiteBtn(this.site);
      // let server: any = inject(ServerSiteServices)
      this.server.saveSite(this.DOMAIN, this.site);
    }
  }

  getSiteStatic() {
    return this.site = inject(StaticArtClubServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer() {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: Site): void => {
      this.site = responseSite;
    });
    return this.site;
  }

}
