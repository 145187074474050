  <app-sidebar [show]="showTextTools"
               (onSave)="showTextTools =false;"
               [showClose]="true"
               [modal]="false"
               [height]="100"
               [width]="50"
               [ext]="'%'"
               [zIndex]="10000"
               [position]="'right'"
  >
    <div class="flex justify-center mt-6">
      <div class="grid grid-cols-2 md:grid-cols-2 gap-1">
        <ng-container *ngFor="let  tagItem  of tagsAll;">
          <button
            (click)="addTagToTextArea(tagItem.name);showTextTools =false;"
            class="bg-zinc-700 w-[150px] h-[25px]"
          >
            <i [class]="plusIcon"></i>
            {{ tagItem.name }}
          </button>
        </ng-container>
      </div>
    </div>
    <div class="flex justify-center">
    <button
      (click)="params.prompt = ''; showTextTools =false;"
      class="w-[150px] h-[75px] p-1"
    >
      Clear prompt
      <i [class]="closeSquareIcon"></i>
    </button>
    </div>

    <p-scrollPanel *ngIf="stageDB.selectStage"
                   [style]="{height:300+'px'}">
      <div class="flex justify-center">
        <div class="grid grid-cols-1  gap-4">
          <ng-container *ngFor="let  textItem  of textsAll">
            <div (click)="this.params.prompt = textItem;showTextTools =false;"
              class="bg-zinc-700  relative  inline-block   ">
             <p> {{ textItem }}</p>
            </div>
          </ng-container>
        </div>
        <ng-container>
        </ng-container>
      </div>
    </p-scrollPanel>
  </app-sidebar>


<div class="w-full justify-center">
  <ng-container *ngIf="!generatingFlag">
    <!--<app-slider-btns *ngIf="tagsAll"
      [array]="tagsAll"
      [showArrayJson]="true"
      [textSize]="14"
      [showPopover]="false"
      [label]="'Add tag to text'" [start]="1" [end]="6"
      (onClick)="addTagToTextArea($event.name)"
    ></app-slider-btns>-->
    <div class=" top-[55px] right-2 ">
        <!--<button
(click)="showTextTools =true"
class="w-[25px] bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-1 px-1 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
>
<i [class]="dotesVerticalIcon"></i>
</button>-->
  <textarea
     class="z-20"
    id="prompt-text-area"
    style="padding: 4px!important;font-size: 18px !important; "
    pInputTextarea
    [placeholder]="'Enter you prompt separated by commas (smile girl, run with dog,  ....) '"
    [(ngModel)]="params.prompt"
    [style.min-height.px]="150"
    [style.height.px]="50"
    [style.min-width.px]="windowWidth()-10"
  ></textarea>
    </div>
    <textarea
      style="padding: 4px!important;font-size: 18px !important; "
      pInputTextarea
      [placeholder]="'Enter you negative prompt separated by commas'"
      [(ngModel)]="params.negative_prompt"
      [style.min-height.px]="50"
      [style.height.px]="25"
      [style.min-width.px]="windowWidth()-10"
    ></textarea>
    <div class="flex justify-center">
      <button *ngIf="params.prompt"
              (click)=" (!cd.lock|| cd.logined)?startGenerate():null;coins.coinService.minusCoin()"
              class=" bg-gradient-to-r rounded-sm from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-2  shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
      >
              <span [hidden]="coins.coinService.showCoinsFlag()" class="absolute z-30 -top-4 left-3">
       <app-coins #coins [showCoinMinus]="true"></app-coins>
          </span>
        <span class="mx-1">Generate</span>
        <span>{{ selectQuantityGeneration.name }}</span>
        <span> ({{ dayGenerationMax }} - gen/per day)</span>
      </button>
       <button
             (click)="showHistoryImages = true"
             class=" bg-gradient-to-r rounded-sm from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-2  shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
           >
             History({{imagesCount}})
             <i [class]="listIcon"></i>
           </button>
    </div>

  </ng-container>
  <app-slider-btns
    [select]="selectQuantityGeneration"
    [array]="quantityGenerations"
    [showArrayJson]="true"
    [showPopover]="false"
    [label]="'Generations'" [start]="1" [end]="6"
    (onClick)="selectQuantityGeneration = $event; "
  ></app-slider-btns>



  <app-slider-btns
    [select]="selectStyleGeneration"
    [array]="stylesGenerations"
    [showArrayJson]="true"
    [showImages]="true"
    [showPopover]="true"
    [label]="'Styles'" [start]="1" [end]="6"
    (onClick)="selectStyleGeneration = $event;"
  ></app-slider-btns>
  <app-slider-btns
    [select]="selectSizeGeneration"
    [array]="sizesGenerations"
    [showArrayJson]="true"
    [showPopover]="false"
    [label]="'Sizes'" [start]="1" [end]="6"
    (onClick)="selectSizeGeneration = $event; "
  ></app-slider-btns>

  <div class="flex justify-center" *ngIf="showError">
    <div
      class=" bg-gradient-to-r rounded-sm from-amber-500 to-indigo-500 hover:from-amber-600 text-da font-bold py-2 px-2  shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
    >
      <p class="mx-1">Error:</p>
      <p>{{ errorText|json}}</p>
      <span> Try generate again later/ or turn on VPN / or use other Browser</span>
    </div>
  </div>
    <p-scrollPanel *ngIf="generatingFlag"
      [style]="{height:windowHeight() +'px'}">
      <div class="min-w-full w-full">
        <div class="bg-gray-800 flex items-center justify-center ">
          <div class="relative">
            <svg class="transform -rotate-90" width="120" height="120">
              <circle class="text-gray-700" stroke="currentColor" stroke-width="10" fill="transparent" r="52" cx="60"
                      cy="60"/>
              <circle class="countdown text-blue-600" stroke="currentColor" stroke-width="10" fill="transparent" r="52"
                      cx="60" cy="60" stroke-dasharray="440" stroke-linecap="round"/>
            </svg>
            <div class="absolute top-0 left-0 w-full h-full flex items-center justify-center font-bold text-white">
              <span id="countdown">{{ this.seconds }}</span>
            </div>
          </div>
        </div>
      </div>
    <app-skeleton-grid
      [sliceCount]="this.selectQuantityGeneration.value"
    ></app-skeleton-grid>
    </p-scrollPanel>
    <p-scrollPanel
      [style]="{height:windowHeight()-100+'px'}">

      <div class="flex justify-center">
        <div class="grid grid-cols-2 md:grid-cols-5 gap-4">
          <ng-container *ngFor="let  imageItem  of imagesConcat;">
            <div
              class="bg-zinc-700  relative  inline-block  ">
               <button  *ngIf="imageItem.params.width && imageItem.params.height "
                     class="z-20  text-sm absolute top-0 left-0 bg-zinc-500/20 text-white p-1 rounded-full  h-auto  transition-transform transform hover:scale-125"
             >
                 {{ imageItem.params.style }}
<!--                 {{imageItem.params.width +'X'+ imageItem.params.height}}-->
             </button>
             <!-- <button   *ngIf="imageItem.params.style"
                      class="z-20 text-sm absolute -bottom-6 left-0 bg-red-500 text-white p-1 rounded-full  h-auto  transition-transform transform hover:scale-125"
                      >
               {{imageItem.params.style}}
              </button>-->
              <img
                [src]="imageItem.srcPreview" alt="upload_image"
                class="w-[200px] h-[200px] object-cover  cursor-pointer"
              >
              <app-btn-download
                [srcImage]="imageItem.srcPreview"
                [disabled]="false"
                [showCoin]="false"
                [textBtn]="'Download' "
              ></app-btn-download>
            </div>
          </ng-container>
        </div>
      </div>
    </p-scrollPanel>

</div>

<app-sidebar [show]="showHistoryImages"
             (onSave)="showHistoryImages =false;"
             [showClose]="true"
             [modal]="false"
             [height]="100"
             [width]="100"
             [ext]="'%'"
             [zIndex]="10000"
             [position]="'left'"
>
  <div
    [class]="'bgColor'"
    [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
    class=" bg-[length:50px_50px] bg-opacity-10  bg-repeat flex justify-center items-center flex-col bg-gradient-to-br from-gray-800 to-gray-900 relative animate-gradient-x">
    <div class="m-3 p-3">
      <div class="w-full  p-3 rounded-lg shadow-lg">
        <app-album
          *ngIf="showHistoryImages"
          [showUploadsList]="true"
          [storageKey]="storageKey"
          [showSelect]="false"
        ></app-album>
      </div>
    </div>
  </div>
</app-sidebar>
<!--<app-sidebar [show]="showHistoryImages"
           (onSave)="showHistoryImages =false;"
           [showClose]="true"
           [modal]="false"
           [height]="100"
           [width]="100"
           [ext]="'%'"
           [zIndex]="10000"
           [position]="'left'"
>
<p-scrollPanel *ngIf="stageDB.selectStage"
               [style]="{height:windowHeight()-100+'px'}">
&lt;!&ndash;    <p class="text-white">{{stageDB.selectStage|json}}</p>&ndash;&gt;
  <div class="flex justify-center">
    <div class="grid grid-cols-2 md:grid-cols-5 gap-4">
      <ng-container *ngFor="let  imageItem  of stageDB.selectStage.images;">
        <div
          class="bg-zinc-700  relative  inline-block  ">
          <img

            [src]="imageItem.srcPreview" alt="upload_image"
            class="w-[150px] h-[150px] object-cover  cursor-pointer"
          >
          <button  #targetEl
                   class="text-sm absolute top-0 -right-2 bg-red-500 text-white p-1 w-16 rounded-full transition-transform transform hover:scale-125"
                   (click)="getAiIFaceToAll(imageItem.srcPreview)">
            <i [class]="imagePanoramaIcon"></i>
          </button>  &lt;!&ndash; <button  #targetEl
                       class="text-sm absolute top-0 -right-2 bg-red-500 text-white p-1 w-16 rounded-full transition-transform transform hover:scale-125"
                       (click)="selectStage =stageItem; opSettings.toggle($event, targetEl)">
                <i [class]="dotesVerticalIcon"></i>
              </button>&ndash;&gt;
          &lt;!&ndash; <button *ngIf="(first || cd.logined)"
                   class="w-full bg-gradient-to-r rounded-sm from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-2  shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
                   (click)="setStage(stageItem)"
           >
             <span class="mx-1">Go</span>
             <span class="bg-zinc-700 w-[15px] px-1 h-[15px]" *ngIf="stageItem.images">
                 {{ stageItem.images.length }} - elements
               </span>
           </button>&ndash;&gt;
          &lt;!&ndash; <button *ngIf="(!first && !cd.logined)"
                   class="w-full bg-gradient-to-r rounded-sm from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-2  shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
                   (click)="cd.loginForm = true;"
           >
             Login
           </button>&ndash;&gt;
          <app-btn-download
            [srcImage]="imageItem.srcPreview"
            [disabled]="false"
            [showCoin]="true"
            [textBtn]="'Download' "
          ></app-btn-download>
        </div>
      </ng-container>
    </div>
    <ng-container>
    </ng-container>
  </div>
</p-scrollPanel>

</app-sidebar>-->
<!--<div
  [style.width.px]="windowWidth()" class="flex overflow-x-auto space-x-4">-->

<!--  <p-toggleButton [(ngModel)]="translateEnable"
                  (ngModelChange)="translateEnable"
                  [onIcon]="colorHistoryIcon"
                  [offIcon]="closeSquareIcon"
                  onLabel="Translate"
                  offLabel="Not Translate"
  ></p-toggleButton>-->
<!--
</div>-->



