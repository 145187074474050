<app-preload></app-preload>
<app-sidebar [show]="showReference"
             (onSave)="showReference =false;"
             [showClose]="true"
             [height]="100"
             [width]="100"
             [ext]="'%'"
             [zIndex]="100000"
             [position]="'top'"

>
  <app-image-reference
    *ngIf="(showReference && selectImage && selectStage.images)"
    [show]="showReference"
    [srcImage]="selectImage.srcPreview"
    [images]="selectStage.images"
    (onCrop)="addImageToBoard($event,this.selectStage)"
  ></app-image-reference>
</app-sidebar>


<!-- <div   class=" z-20 fixed top-8 right-4">


 </div>-->
<div class="flex justify-center">

  <!--  <button (click)="selectUnmarked()" class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-blue-500 border border-transparent rounded-l-md hover:bg-gradient-to-r hover:from-blue-600 hover:to-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
      UnCheck
      <i [class]="'far fa-square'"></i>
    </button>

&lt;!&ndash;    <button (click)="selectMarked()" class="button">Выбрать отмеченные</button>&ndash;&gt;
    <button (click)="processSelectedImages()"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-blue-500 border border-transparent rounded-l-md hover:bg-gradient-to-r hover:from-blue-600 hover:to-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    >
      Batch Select
      <i [class]="'fas fa-arrow-alt-circle-down'"></i>

    </button>-->
</div>
<p-scrollPanel [style]="{height:windowHeight() +'px'}">
<!--  <app-navbar-top></app-navbar-top>-->

  <div class="flex">
    @if (showStagesList && stagesList) {
<!--      <div class="w-[100px] bg-zinc m-2">
        <div class="grid grid-cols-1 md:grid-cols-1 gap-4">

          <ng-container *ngFor="let  stageItem  of stagesList">
            @if (stageItem.sw) {
              <div class="text-center relative  inline-block transition-transform transform hover:scale-110">
                <img *ngIf="stageItem.images"
                     [src]="stageItem.images.length>0?stageItem.images[0].srcPreview:stageItem.srcPreview"
                     alt="upload_image"
                     class=" w-[100px] h-[100px] object-cover rounded cursor-pointer"
                     (click)="setStage(stageItem)"
                >
                <button *ngIf="stageItem.name"
                        class="w-[100px] truncate text-[10px] absolute top-[20px] left-0 bg-zinc-500 text-white p-1 rounded-full transition-transform transform hover:scale-125"
                >
                  {{ stageItem.name }}
                </button>
                <div *ngIf="stageItem.images"
                     [sortablejs]="stageItem.images"
                     [sortablejsOptions]="sortableOptionsStageList"
                     class=" text-center  text-white z-20 text-[10px] absolute bottom-[1px] w-100 h-100 ">
                  Drop here({{ stageItem.images.length }})
                </div>

                &lt;!&ndash;              <button &ndash;&gt;
                &lt;!&ndash;                      class="w-[75px] truncate text-[10px] absolute top-[20px] left-0 bg-zinc-500 text-white p-1 rounded-full transition-transform transform hover:scale-125"&ndash;&gt;
                &lt;!&ndash;              >&ndash;&gt;
                &lt;!&ndash;              ({{ stageItem.images.length }})&ndash;&gt;
                &lt;!&ndash;              </button>&ndash;&gt;
                <button #targetEl
                        class="w-[25px] text-sm absolute top-0 -right-2 bg-red-500 text-white p-1 rounded-full transition-transform transform hover:scale-125"
                        (click)="selectStage =stageItem; opSettings.toggle($event, targetEl)">
                  <i [class]="dotesVerticalIcon"></i>
                </button>

              </div>
            }
          </ng-container>
        </div>
      </div>-->
    }
    <div class="bg-zinc ">
      <div *ngIf="selectStage"
           [sortablejs]="selectStage.images"
           [sortablejsOptions]="sortableOptionsStageImages"
           class=" grid grid-cols-4 gap-6">
        <ng-container *ngFor="let  uploadItem  of selectStage.images">
          @if (uploadItem.sw) {

            <div [style.background-color]="uploadItem.color"
                 class="p-1 relative  inline-block transition-transform transform hover:scale-110">
              <img
                [src]="uploadItem.srcPreview" alt="upload_image"
                class=" w-[75px] h-[75px] object-cover rounded cursor-pointer"

              >
              <button

                      class="handle text-[20px]  absolute -bottom-8 -right-4 focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-1">
                <i [class]="moveIcon" class="text-gray-300 hover:text-white"></i>
              </button>
             <!-- <button (click)="check(uploadItem);"
                      class="text-sm absolute top-0 left-2 bg-green-500 text-white p-1 rounded-full transition-transform transform hover:scale-125"
              >
                <i *ngIf="uploadItem.check" [class]="'fas fa-check'"></i>
                <i *ngIf="!uploadItem.check" [class]="'far fa-square'"></i>
              </button>-->
              <button #targetEl
                      class="text-sm absolute top-0 -left-1 bg-red-500 text-white p-1 w-[25px] h-[25px] rounded-full transition-transform transform hover:scale-125"
                      (click)="selectImage =uploadItem; opImageSettings.toggle($event, targetEl)">
                <i [class]="dotesVerticalIcon"></i>
              </button>

              <button #targetEl
                      class="text-sm absolute  bottom-0 -right-1 bg-blue-500 text-white p-1 w-[25px] h-[25px] rounded-full transition-transform transform hover:scale-125"
                      *ngIf="showSelect" (click)="selectImage =uploadItem;select(uploadItem)">
                <i [class]="'fas fa-arrow-alt-circle-down'"></i>
              </button>
            </div>
          }
        </ng-container>
      </div>
    </div>
  </div>
</p-scrollPanel>

<p-overlayPanel #opPlusGreateImport>
  <div class="  justify-center grid grid-cols-1 md:grid-cols-1 gap-4">

    <label
      class=" m-2 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer">
      <i [class]="uploadesIcon"></i>
      Upload Photo
      <input type="file" id="images-base64"
             accept="image/*"
             multiple class="hidden"
             (change)="importImage($event)"
      />
    </label>
    <!-- <button
       class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
       (click)="exportAllStages()"
     >
       Export all stages
       <i [class]="plusIcon"></i>
     </button>
 -->
    <button
      class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
      (click)="addStage()"
    >
      Create stage
      <i [class]="plusIcon"></i>
    </button>
    <label
      class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer">
      Import stage
      <i class="fa-solid fa-file-import"></i>

      <input type="file" id="fileInput" accept=".bin" class="hidden"
             (change)="importBin($event)"/>
    </label>
  </div>
</p-overlayPanel>
<p-overlayPanel #opSettings>
  @if (selectStage) {
    <div class="  justify-center grid grid-cols-1 md:grid-cols-1 gap-4">
       <textarea
         style="padding: 2px!important;font-size: 16px !important; "
         pInputTextarea
         [placeholder]="'Name Stage'"
         [(ngModel)]="selectStage.name"
         [style.height.px]="25"
         [style.width.px]="150"
       ></textarea>
      <button (click)="archiveStage(selectStage); "
              class="focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
        To archive
        <i [class]="archiveIcon" class="text-gray-300 hover:text-white"></i>
      </button>
      <button (click)="exportStage(selectStage); "
              class="focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
        Export
        <i class="fa-solid fa-file-export"></i>
      </button>
    </div>
  }

</p-overlayPanel>
<p-overlayPanel #opImageSettings>
  @if (selectImage) {
    <p-overlayPanel #opTextImage>
      <div class="  justify-center grid grid-cols-1 md:grid-cols-1 gap-4">
       <textarea
         style="padding: 2px!important;font-size: 16px !important; "
         pInputTextarea
         [placeholder]="'Name Image'"
         [(ngModel)]="selectImage.name"
         [style.height.px]="25"
         [style.width.px]="300"
       ></textarea>
        <textarea
          style="padding: 2px!important;font-size: 16px !important; "
          pInputTextarea
          [placeholder]="'Desc'"
          [(ngModel)]="selectImage.desc"
          [style.height.px]="100"
          [style.width.px]="300"
        ></textarea>
      </div>
    </p-overlayPanel>
    <p-overlayPanel #opColorsLib>
      <app-colors-grid
        [imageForColors]="selectImage.srcPreview"
        (onClick)="selectImage.color = $event;    this.updateStage();
    this.save()"
      ></app-colors-grid>
    </p-overlayPanel>
    <div class="  justify-center grid grid-cols-1 md:grid-cols-1 gap-4">

      <button #targetEl
              class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center"
              (click)=" opColorsLib.toggle($event, targetEl)"
              [style.background-color]="selectImage.color"
      >
            <span class="block w-[25px] h-[25px] mx-1 "
              [style.background-color]="selectImage.color"
            ></span>
        Color
      </button>

      <button #targetEl
              (click)="opTextImage.toggle($event, targetEl)"
              class="focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
        <span class="mx-1">Add info</span>
        <i [class]="libsIcon" class="text-gray-300 hover:text-white"></i>
      </button>

      <button (click)="deleteImage(selectImage); save();"
              class="focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">

        <span class="mx-1"> Delete</span>
        <i [class]="archiveIcon" class="text-gray-300 hover:text-white"></i>
      </button>
      <button (click)="showReference =true"
              class="focus:outline-none border border-transparent rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg p-3">
       <span class="mx-1"> Reference</span>
        <i [class]="imagePanoramaIcon" class="text-gray-300 hover:text-white"></i>
      </button>
    </div>
  }

</p-overlayPanel>

<p-overlayPanel #opFilters>
  <div class="  justify-center grid grid-cols-1 md:grid-cols-1 gap-4">
    <!--    filter by color-->
    <!--    filter by category-->
    <!--    filter by icon-->
  </div>
</p-overlayPanel>


<div class="fixed bottom-4 left-4"
>

 <!-- <button (click)="showStagesList = !showStagesList; save()"
          class="m-2 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">


    <span *ngIf="showStagesList"><i class="fa-solid fa-folder-minus"></i></span>
    <span *ngIf="!showStagesList"><i class="fa-solid fa-folder-tree"></i></span>
  </button>-->

  <!--<button #targetEl
          (click)="opFilters.toggle($event, targetEl)"
          class="m-2 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
    <i [class]="filterIcon"></i>
  </button>-->
</div>

<div class="fixed bottom-4 right-4"
>

  <!--<button (click)="save()"
          class="m-2 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
    <i *ngIf="!saveStatus" [class]="saveIcon"></i>
    <span *ngIf="saveStatus">Saved!</span>
  </button>-->

  <button #targetEl *ngIf="showAdd"
          (click)="opPlusGreateImport.toggle($event, targetEl)"
          class="m-2 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-center">
    <i [class]="addIcon"></i>
  </button>
</div>

