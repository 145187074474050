import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtClubServices} from '../static/static-art-club.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {Site} from '../../interfaces/site';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageArtClubServices {
  public SERVER_NAME: string = 'art-club';
  public DOMAIN: string = 'art-club.artas.org.ua/art-club';
  public route: string = 'art-club';

  public fromServer: boolean = true;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'Курсы',
    desc: 'Безлимитный доступ ко всем Курсам',
    additions: [''],
  };
  public free: object = {
    name: 'Курсы',
    desc: 'Безлимитный доступ ко всем Курсам',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Platform for artists: tools, lessons, support | art-club.info 2024',
    description: 'Platform for artists, where you\'ll find a rich selection of tools, lessons, and support to develop your creativity. Get access to exclusive materials for just $1 a month!',
    'text': 'Platform for artists, welcome to the world of creativity, inspired by my personal experience!\n' + '\n' + 'Text of the main page:\n' + 'Greetings to you on my amazing platform, crafted from my own creative journey and experience! Here, I\'ve gathered all the best that helped me become an artist, and now I\'m eager to share it with you.\n' + '\n' + 'You\'ll discover a variety of drawing libraries, color palettes, lessons, and courses, all curated by me with love and passion for art. My aim is to create a space where every artist, regardless of their level of experience or age, can find support and inspiration for their creativity.\n' + '\n' + 'Join my Patreon for just $1 per month, and unlock access to exclusive materials and benefits that will nurture your creative growth. Together, we can create something truly remarkable!\n' + '\n' + 'This text aims to convey more warmth and individuality, emphasizing the personal experience and inspiration of the platform\'s creator. If you have any additional requests or corrections, please let me know!',
    'h1': 'Platform for artistswelcome to the world of creativity, where every artist finds their place!',
    'h2': 'Platform for artists',
    'h3': 'Tools and resources for your creativity',
    'keywords': [
      'Art',
      'Artists',
      'Drawing',
      'DigitalDrawing',
      'Lessons',
      'Courses',
      'Creativity',
      'Palettes',
      'Organizers',
      'Plugins',
      'Photoshop',
      'Brushes',
      'ArtPlatform'
    ],
  };
  public site: any

  constructor(public server:ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  getSite() {
    if (this.fromServer) {
      return this.getSiteFromServer();
    } else {
      return this.getSiteStatic();
    }

  }
  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }
 public saveSite(site:object){
   if (this.fromServer) {

     this.site = site
     // this.downloadSiteBtn(this.site);
     // let server: any = inject(ServerSiteServices)
     this.server.saveSite(this.DOMAIN, this.site);
   }
  }
  getSiteStatic() {
    return this.site = inject(StaticArtClubServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer() {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: Site): void => {
      this.site = responseSite;
    });
    return this.site;
  }
  getCards(){
    return  this.site.organizer.messenges.filter((itemCard)=>{
      return itemCard.sw === true;
    });
  }
  findCard(slug:any){
    console.log(slug)
    let cards = this.getCards();
    let findCard =   cards.filter((itemProduct:any)=>{
      if(itemProduct.id === slug){
        return itemProduct;
      }
    });
    return findCard[0];
  }
}
