import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";
import {
  applyFilters,
  colorizeImage,
  generateSketch,
  removeBackgroundWhite,
  srcToBase64
} from 'src/app/traits/image-tools';

@Component({
  selector: 'app-sketch-converter',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    BtnDownloadComponent,
  ],
  templateUrl: './sketch-converter.component.html',
})

export class SketchConverterComponent implements AfterViewInit {

  @ViewChild('mediumZoomElement') mediumZoomElement: ElementRef<HTMLCanvasElement>;
  @Input() imageSrc: string //= 'https://i.postimg.cc/Wb5CcD8L/corgi2.jpg';
  @Input() imageSrcTemp?: string //= 'https://i.postimg.cc/Wb5CcD8L/corgi2.jpg';
  @Input() showBackground: boolean = true;
  @Input() showResult: boolean = true;
  @Input() showDownload: boolean = false;
  @Input() isBlackAndWhite: boolean = true;
  @Input() scaleCanvas: string = '75%';
  @Input() colorHex: string = '#000000';
  @Input() blurAmount: number = 10;
  @Input() blurFilter: number = 0;
  @Input() opacityFilter: number = 100;
  @Input() invertAmount: number = 1;
  @Input() mode: any = 'color';
  @Input() grayscaleAmount: number = 1;
  @Input() brightness: number = 100;
  @Input() contrast: number = 100;
  @Input() saturation: number = 100;
  @Output() sketchGenerated = new EventEmitter<string>();
  imgResult: string;

  /*ngOnChanges(changes: SimpleChanges): void {
    if (changes['imageSrc'] && this.imageSrc) {
      this.generateSketch();
    }
  }*/
  constructor() {
  }


  ngAfterViewInit(): void {
    this.initSketcher();
  }

  initSketcher() {
    let paramsSketch: any = {
      grayscaleAmount: this.grayscaleAmount,
      invertAmount: this.invertAmount,
      blurAmount: this.blurAmount,
    };
    let paramsColorize: any = {
      mode: this.mode,
      colorHex: this.colorHex,
    };
    let paramsFilters: any = {
      brightness: this.brightness,
      saturation: this.saturation,
      contrast: this.contrast,
      blur: this.blurFilter,
      opacity: this.opacityFilter,
    };
    srcToBase64(this.imageSrc, (imageBase64) => {
      this.imageSrc = imageBase64;
      generateSketch(this.imageSrc, paramsSketch, (base64Sketch: string) => {
        colorizeImage(base64Sketch, paramsColorize, (base64Colorize: string) => {
          applyFilters(base64Colorize, paramsFilters, (base64Filter: string) => {
            if (this.showBackground) {
              this.sketchGenerated.emit(base64Filter);
              this.imgResult = base64Filter
            } else {
              removeBackgroundWhite(base64Filter, (base64RemoveBackgorund: string) => {
                this.sketchGenerated.emit(base64RemoveBackgorund);
                this.imgResult = base64RemoveBackgorund;
              })
            }
          })
        });
      });
    });
  }

}

