import {Component, Input} from '@angular/core';
import {
  RouterLink
} from '@angular/router';
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {MyDataServices} from "../../../../services/my-data.services";
import {BasketServices} from "../../../../services/common/basket.services";
import { basketIcon, telegramIcon } from 'src/app/traits/icons-tools';

@Component({
  selector: 'app-navbar-top-artas',
  templateUrl: './navbar-top-artas.component.html',
  standalone:true,
  imports: [
    NgIf,
    NgForOf,
    RouterLink
  ]
})
export class NavbarTopArtasComponent {
  @Input() logoText: any ='ART-PARTY.KIEV.UA';
  @Input() routeHome: any = '/';
 public orderCard: any;
  protected readonly basketIcon = basketIcon;
  protected readonly telegramIcon = telegramIcon;
  constructor(
              public myData:MyDataServices,
              public basketServices: BasketServices

  ) {
  }
  ngOnInit(){
    this.basketServices.init()
  }
ngAfterViewInit(){
  let scrollDiv = document.getElementById('topScroll').offsetTop - 50;

  window.scrollTo({
    top: scrollDiv,
    behavior: 'smooth'
  });
}
getOrderCardFromStorage(){
}

  goHome() {
    // this.router.navigate([this.routeHome]);
  }

}
