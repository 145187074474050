import {Component, inject} from '@angular/core';
import {EditListCardsComponent} from '../../../components/admin/edit-list-cards/edit-list-cards.component';
import {Card} from '../../../interfaces/Card';
import {DataSites} from '../../../services/pages/data-sites';
import {EditListSitesComponent} from '../../../components/admin/edit-list-sites/edit-list-sites.component';
import {EditSiteSelectComponent} from '../../../components/admin/edit-site-select/edit-site-select.component';
import {ConfirmComponent} from '../../../components/admin/confirm/confirm.component';
import {ConfirmationService} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from 'primeng/toast';
import {SiteDebugerComponent} from "../../../components/admin/site-debuger/site-debuger.component";
import {JsonPipe} from "@angular/common";
import {PageArtClubServices} from "../../../services/pages/page-art-club.services";
import {PageImagePalettesLibraryServices} from "../../../services/pages/page-image-palettes-library.services";
import {CommonHomeComponent} from "../../common-home/common-home.component";
import {HeaderComponent} from "../../../components/pages/header/header.component";
import {NavbarTopComponent} from "../../../components/pages/navbar-top/navbar-top.component";
import {PagePhotoshopPluginsServices} from "../../../services/pages/page-photoshop-plugins.services";
import {Page3DReferencesServices} from "../../../services/pages/page-3D-references.services";
import {PageCharacterHeadsServices} from "../../../services/pages/page-character-heads.services";
import {PageBackgroundsReferencesServices} from "../../../services/pages/page-backgrounds-references.services";

@Component({
  selector: 'app-page-backgrounds-references',
  standalone:true,
  providers:[],
  imports: [
    CommonHomeComponent,
    HeaderComponent,
    NavbarTopComponent
  ],
  templateUrl: './page-backgrounds-references.component.html',
})
export class PageBackgroundsReferencesComponent {
   public site:any;
constructor(public dataPage: PageBackgroundsReferencesServices) {
  this.dataPage.getSite((site:any)=>{
    console.log(site)
    this.site = site;
  });
}
}
