// import moment from 'moment';
declare const moment;
export function  interval(callback:any,time:any = 1000,) {
  return  setInterval(
    () => {
      // clearInterval(intervalCard);
      callback && callback(true);
    },time
  );
}

export function dateNow(){
 return Date.now();
}
export  function  reInit(callback:any,time:any = 1, disabled:any = true) {
  let intervalInit = interval(()=>{
    callback && callback(true);
    disabled ?clearInterval(intervalInit):null;

  },time)

}
export function timeOut(callback:any,time:any = 1){
   setTimeout(()=>{
     callback && callback(true);
   }, time);
}
export function onlineStatusInternetConnection() {
  return navigator.onLine;
}
export  function subscribeRestDaysMonths(months: any = 1) {
  // const d = moment();
  // const currentDay = d.get('date');
  // const daysInMonth = d.daysInMonth();
  // const remainingDays = daysInMonth - currentDay;
  // return remainingDays;
}
export function subscribeLastDayInMonths(months: any = 1) {
  // let thisMoment = moment();
  // let endOfMonth = moment(thisMoment).endOf('month').format('DD-MM-YYYY');
  // // let startOfMonth = moment(thisMoment).startOf('month');
  // return endOfMonth;
}

export function checkEndOfMonth(callback) {
  const today = moment();
  const tomorrow = moment().add(1, 'days');

  // Получаем первый день следующего месяца
  const firstDayNextMonth = today.clone().add(1, 'month').startOf('month');

  // Проверяем, является ли завтра первым числом следующего месяца
  if (tomorrow.isSame(firstDayNextMonth, 'day')) {
    callback  && callback()
    console.log("Сегодня последний день месяца. Колбек выполнится завтра.");
    // setTimeout(callback, moment.duration(1, 'days').asMilliseconds()); // Устанавливаем колбек на выполнение через одни сутки
  } else {
    console.log("Сегодня не последний день месяца, колбек не будет установлен.");
  }
}
export function  strTimeToSecond(timeStr: any) {
  const [hours, minutes, seconds] = timeStr.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}
export function  secondsToMinutes(seconds: any) {
  // Hours, minutes and seconds
  const hrs = ~~(seconds / 3600);
  const mins = ~~((seconds % 3600) / 60);
  const secs = ~~seconds % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '');
  ret += '' + secs;

  return ret;
}
