<div
     [style.width.px]="widthList"
     [style.height.px]="heightList"
     class="scroll-items"
>
  <ng-container *ngFor="let  colorHex of colors; ">
    <div class="item-scroll">
    <app-color-item
      [colorHex]="colorHex"
      (onClick)="onColor.emit(colorHex)"
      (onDblclick)="onDblclick.emit(colorHex)"
      (onPress)="onPress.emit(colorHex)"
    ></app-color-item>
    </div>
  </ng-container>
</div>
