import {
  Component,
  ElementRef,
  Renderer2,
  RendererFactory2,
  ViewChild
} from '@angular/core';
import {PageArtClubServices} from '../../../services/pages/page-art-club.services';
import {TabViewModule} from 'primeng/tabview';
import {SidebarComponent} from '../../../components/common/sidebar/sidebar.component';
import {CommonHomeComponent} from '../../common-home/common-home.component';
import {HeaderComponent} from "../../../components/pages/header/header.component";
import {NavbarTopComponent} from "../../../components/pages/navbar-top/navbar-top.component";
import {CroppieComponent} from "../../../components/croppie/croppie.component";
import {
  deleteIcon,
  favoriteIcon
} from "../../../traits/icons-tools";
import {deleteFromArray} from "../../../traits/array-tools";
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {FormsModule} from "@angular/forms";
@Component({
  selector: 'app-page-art-club',
  standalone:true,
  imports: [
    TabViewModule,
    SidebarComponent,
    CommonHomeComponent,
    HeaderComponent,
    NavbarTopComponent,
    CroppieComponent,
    NgForOf,
    NgIf,
    FormsModule
  ],
  templateUrl: './page-art-club.component.html',
})
export class PageArtClubComponent {

  ngAfterViewInit(): void {
    // this.loadClient();
  }
  constructor(public dataPage: PageArtClubServices,) {
  }



   sendNotification(title, body, icon, clickAction) {
    // Проверяем, поддерживает ли браузер уведомления
    if ("Notification" in window) {
      // Запрашиваем разрешение пользователя на отправку уведомлений
      Notification.requestPermission().then(permission => {
        // Если пользователь дал разрешение, отправляем уведомление
        if (permission === "granted") {
          const notification = new Notification(title, {
            body: body,
            icon: icon // URL иконки
          });

          // Действие при клике на уведомление
          notification.onclick = function() {
            window.open(clickAction);
          };
        }
      });
    } else {
      // Уведомляем пользователя, что уведомления не поддерживаются
      console.log("Браузер не поддерживает уведомления.");
    }
  }


  protected readonly deleteIcon = deleteIcon;
  protected readonly deleteFromArray = deleteFromArray;
  protected readonly favoriteIcon = favoriteIcon;
}
