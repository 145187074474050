
<app-order-form *ngIf="showOrderForm && showForm"
                [selectModel]="selectCard"
                (onClose)="showOrderForm = false"
></app-order-form>

<div class="z-30 fixed bottom-3 right-[20px] ">
  <div
    class="relative bg-color-custom  "
  >
    <nav
      class="flex shrink-0 grow-0 justify-around gap-4 border-t border-gray-200 bg-color-custom/50 p-1 shadow-lg backdrop-blur-lg   min-h-[auto] min-w-[64px] flex-col rounded-lg border"
    >
      <ng-container *ngIf="showContacts">
        <ng-container *ngFor="let item of myData.menuTop.concat(myData.menuBottom).concat(myData.phones);let i = index">

          <a *ngIf="item.href" [target]="item.target"
             [href]="item.href"
             (click)="item.command(item,i)"
             class=" z-30 flex aspect-square min-h-[60px] w-[60px] flex-col items-center justify-center gap-1 rounded-md p-3 bg-color-custom text-amber-600"
          >
            <img *ngIf="item.srcPreview"
                 [src]="item.srcPreview" class="h-[60px] w-[60px]"
                 [alt]="item.label">
            <i *ngIf="item.icon && !item.srcPreview"
               style="font-size:15px" class=" mx-1"
               [class]="item.icon"
            ></i>

            <span *ngIf="item.label">{{item.label}}</span>
          </a>

        </ng-container>
        <button *ngIf="showForm"
                (click)="showOrderForm = !showOrderForm"
                class="z-20 flex aspect-square min-h-[60px] w-[60px] flex-col items-center justify-center gap-1 rounded-md p-3 bg-color-custom text-amber-600"
        >
          <img
            [src]="'assets/icons/3273589.png'"
            alt="Form order"
            class="h-[60px] w-[60px]"
          >
          <small class=" z-30 text-center text-xs font-bold">
             <span>
              Заявка
             </span>
          </small>
        </button>
      </ng-container>

      <button  (click)="showContacts = !showContacts"
               class="z-30 flex aspect-square min-h-[60px] w-[60px] flex-col items-center justify-center gap-1 rounded-md p-3 bg-color-custom text-amber-600"
      >
        <img
          [src]="'assets/icons/4715128.png'"
          alt="Filters"
          class="h-[60px] w-[60px]"
        >
        <small class="  text-center text-xs font-bold">
             <span>
              Контакти
             </span>
        </small>
      </button>
    </nav>
  </div>
</div>
