<app-preload *ngIf="showPreload"></app-preload>
<div style="height:25px; overflow: hidden"
     class="absolute top-0 seo-container text-center z-0">
  <h1>{{ dataPage.seoTexts.h1 }}</h1>
  <h2>{{ dataPage.seoTexts.h2 }}</h2>
  <h3>{{ dataPage.seoTexts.h3 }}</h3>
  <p>{{ dataPage.seoTexts.text }}</p>
  <p>{{ dataPage.seoTexts.textEndPage }}</p>
</div>
<app-navbar-top></app-navbar-top>
<app-header
  [showCounters]="false"
  [bgImage]="'assets/header-palettes-generator.png'"
  [headerText]="dataPage.seoTexts.h1"
  [subHeaderText]="dataPage.seoTexts.h1"
  [actionText]="dataPage.seoTexts.h3"
  [showProductLeft]="true"

></app-header>
<div
  [class]="'bgColor'"
  [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
  class="mt-[75px]  bg-[length:50px_50px] bg-opacity-10  bg-repeat flex justify-center items-center flex-col bg-gradient-to-br from-gray-800 to-gray-900 relative animate-gradient-x">

  <div *ngIf="colorHistory.colors.length>1" class="flex space-x-4">
    <!--<button
      class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
      (click)="switchCircle()"
    >
      <i class="fa-regular fa-circle"></i>
    </button>-->
    <button
      class="bg-gradient-to-r from-purple-500 to-indigo-500  hover:from-zinc-600 hover:to-zinc-700 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse"
      (click)="switchVertical()">
      <i [class]="dotesVerticalIcon"></i>
    </button>
    <button
      class="bg-gradient-to-r from-purple-500 to-indigo-500  hover:from-zinc-600 hover:to-zinc-700 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse"
      (click)="switchHorizontal()">
      <i [class]="dotesHorizontalIcon"></i>
    </button>
  </div>
  <div *ngIf="colorHistory.colors.length>0" class="m-3 p-3">
    <div class="w-full  p-3 rounded-lg shadow-lg">

     <!-- <app-circle-with-color
        #roundPallete
        *ngIf="(showCircleComponent && viewPallete === 'circle')"
        [parentColor]="selectColor"
        (onGetPallete)="imagePallete = $event;
                          "
      ></app-circle-with-color>-->
      <app-colors-rectangale
        *ngIf="(showHorizontalComponent && viewPallete === 'horizontal')"
        [colors]="colorHistory.colors"
        [orientation]="'hor'"
        [widthCanvas]="300"
        [heightCanvas]="135"
      ></app-colors-rectangale>

      <app-colors-rectangale
        *ngIf="(showVerticalComponent && viewPallete === 'vertical')"
        [colors]="colorHistory.colors"
        [orientation]="'vert'"
        [widthCanvas]="120"
        [heightCanvas]="315"
      ></app-colors-rectangale>
    </div>
  </div>
  <app-eye-dropper *ngIf="showSketchConverterComponent"
                   [widthImage]="400"
                   [heightImage]="400"
                   [src]="imageCrop"
                   (onColor)="setColor($event);  colorHistory.addToHistory($event);reInitColorChange(); reInitAllPalletes();"
  >
  </app-eye-dropper>
  <div class="flex overflow-x-auto py-4 space-x-4">
    <app-color-history
      #colorHistory
      (onColor)="setColor($event)"
    ></app-color-history>
  </div>
  <app-uploader-photo
    [showLabel]="true"
    [showListHorisont]="true"
    [showListModal]="true"
    [showUpload]="true"
    [showCounter]="true"
    (onClickItem)="selectUpload($event)"
  ></app-uploader-photo>
</div>
