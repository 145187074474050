import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  NgClass,
  NgFor,
  NgIf
} from '@angular/common';
import {arrayStartEndStep} from "../../traits/params-tools";
import {windowWidth} from "../../traits/window-tools";
import {SliderModule} from "primeng/slider";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {eyeOpenIcon} from "../../traits/icons-tools";

@Component({
  selector: 'app-slider-btns',
  templateUrl: './slider-btns.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    OverlayPanelModule,
  ]


})
export class SliderBtnsComponent implements OnInit {

  @Input() label: string = 'Name';
  @Input() start: number = 1;
  @Input() end: number = 100;
  @Input() textSize: number = 14;
  @Input() step: number = 1;
  @Input() select:any;
  @Input() showArrayJson: boolean = false;
  @Input() showPopover: boolean = false;
  @Input() showImages: boolean = false;
  @Input() array: any[] = [];
  @Output() onClick:EventEmitter<number> = new EventEmitter();

  public ngOnInit(): void {
    if(!this.showArrayJson){
      this.generateArray();
      this.getFirst();
    }

  }

  public click(value:any) {
    this.onClick.emit(value);
    this.select = value;
  }

  public generateArray() {
    this.array = arrayStartEndStep(this.start, this.end, this.step)
  }

  private getFirst() {
    this.select = this.array[0];
  }

  protected readonly windowWidth = windowWidth;
  protected readonly eyeOpenIcon = eyeOpenIcon;
}
