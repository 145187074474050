<app-preload *ngIf="showPreload"></app-preload>
<div style="height:25px; overflow: hidden"
     class="absolute top-0 seo-container text-center z-0">
  <h1>{{ dataPage.seoTexts.h1 }}</h1>
  <h2>{{ dataPage.seoTexts.h2 }}</h2>
  <h3>{{ dataPage.seoTexts.h3 }}</h3>
  <p>{{ dataPage.seoTexts.text }}</p>
  <p>{{ dataPage.seoTexts.textEndPage }}</p>
</div>
<app-navbar-top></app-navbar-top>
<app-header
  [showCounters]="false"
  [bgImage]="'assets/header-palettes-generator.png'"
  [headerText]="dataPage.seoTexts.h1"
  [subHeaderText]="dataPage.seoTexts.h1"
  [actionText]="dataPage.seoTexts.h3"
  [showProductLeft]="true"

></app-header>
  <div
    [class]="'bgColor'"
    [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
    class="mt-[75px]  bg-[length:50px_50px] bg-opacity-10  bg-repeat flex justify-center items-center flex-col bg-gradient-to-br from-gray-800 to-gray-900 relative animate-gradient-x">
  <div class="flex overflow-x-auto py-4 space-x-4">
   <!-- <button *ngIf="showCropRigionBtn" #targetEl
            class="bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
            (click)="showCropPanel();opCrop.toggle($event, targetEl)"
    >
      Crop
      <i *ngIf="!showCropRigion" [class]="editIcon"></i>
    </button>-->

    <!--<button #targetEl
            (click)="

         opColorPanel.toggle($event, targetEl);
"
            class=" bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
    >
      Color
      <i class="fa-solid fa-palette"></i>
    </button>-->


   <!-- <button #targetEl *ngIf="!showCropRigion"
            (click)="showSettings = !showSettings;;
                    opPalleteSettings.toggle($event, targetEl)"
            class="bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
    >
      <i class="fa-solid fa-sliders"></i>
    </button>-->
  </div>
        <app-image-mosaic
          *ngIf="showSketchConverterComponent"
          [heightImage]="300"
          [widthImage]="300"
          [imageSrc]="imageCrop"
          (onCrop)="imageSketch = $event; "
          (onReset)=" imageSketch = $event;  "
        ></app-image-mosaic>
       <!-- <app-grayscale
          *ngIf="showSketchConverterComponent"
          [imageSrc]="imageCrop"
          [isBlackAndWhite]="showSketchConverterComponent"
          (imageConverted)="imageSketch = $event;"
        >
        </app-grayscale>-->

       <!-- <app-sketch-converter
          *ngIf="showSketchConverterComponent  "
          [showBackground]="showBackground"
          [imageSrc]="imageCrop"
          [invertAmount]="invertAmount"
          [blurAmount]="blurAmount"
          [grayscaleAmount]="grayscaleAmount"
          [brightness]="brightness"
          [contrast]="contrast"
          [saturation]="saturation"
          [blurFilter]="blurFilter"
          [opacityFilter]="opacityFilter"
          [colorHex]="selectColor"
          [mode]="mode.value"
          (sketchGenerated)="imageSketch = $event">
        </app-sketch-converter>
        -->
    <app-uploader-photo
      [showLabel]="true"
      [showListHorisont]="true"
      [showListModal]="true"
      [showUpload]="true"
      [showCounter]="true"
      (onClickItem)="selectUpload($event)"
    ></app-uploader-photo>
    <app-btn-download
      [srcImage]="imageSketch"
      [disabled]="false"
      [showCoin]="true"
      [textBtn]="'Download' "
    ></app-btn-download>
  </div>
<p-overlayPanel #opCrop>
  <div
       class=" w-[300px] ">
    <app-crop-rigion
      [width]="300"
      [height]=300
      [cropZoneHeight]=150
      [cropZoneWidth]=150
      [cropZoneX]="100"
      [cropZoneY]="100"
      [showReset]="true"
      [showPressetsCrop]="false"
      [showZoom]="true"
      [showFlip]="true"
      [showRotate]="false"
      [showAspectRatio]="true"
      [showDownload]="false"
      [showAdd]="false"
      [src]="tempImage"
      (onCrop)="cropImage($event); imageCrop = $event;  "
    ></app-crop-rigion>
  </div>
</p-overlayPanel>

<p-overlayPanel #opColorPanel>
  <app-color-panel *ngIf="this.showColorSidebar"
                   (onColor)="setColor($event)"
  ></app-color-panel>
</p-overlayPanel>

<p-overlayPanel #opPalleteSettings>
  <div class="flex flex-col space-x-1 w-[300px] ">
    <button
      (click)="resetCanvas()"
      class="absolute -bottom-[20px] right-[20px] bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
    >
      Reset
      <i class="fa-solid fa-arrows-spin"></i>
    </button>

    <div class="text-white p-1">
      <span>Brightness:{{ brightness }}</span>
      <p-slider [min]="100" [max]="300"
                [(ngModel)]="brightness"
                (ngModelChange)="reInitSketchConverter()">
      </p-slider>
    </div>

    <div class="text-white p-1">
      <span>Contrast:{{ contrast }}</span>
      <p-slider [min]="100" [max]="300"
                [(ngModel)]="contrast"
                (ngModelChange)="reInitSketchConverter()">
      </p-slider>
    </div>

    <div class="text-white p-1">
      <span>Blur:{{ blurFilter }}</span>
      <p-slider [min]="0" [max]="10"
                [(ngModel)]="blurFilter"
                (ngModelChange)="reInitSketchConverter()">
      </p-slider>
    </div>
    <div class="text-white p-1">
      <span>Opacity:{{ opacityFilter }}</span>
      <p-slider [min]="1" [max]="100"
                [(ngModel)]="opacityFilter"
                (ngModelChange)="reInitSketchConverter()">
      </p-slider>
    </div>
    <div class="text-white p-1">
      <span>Saturation:{{ saturation }}</span>
      <p-slider [min]="1" [max]="300"
                [(ngModel)]="saturation"
                (ngModelChange)="reInitSketchConverter()">
      </p-slider>
    </div>
    <div class="text-white p-1 w-[300px]">
      <div [style.width.px]="windowWidth()"
           class="flex overflow-x-auto py-4 space-x-4">
        <ng-container *ngFor=" let modeItem of modes">
          <div (click)="mode =modeItem;reInitSketchConverter()">
            <div
              class="bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out">
              {{ modeItem.name }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</p-overlayPanel>
