<p-tabView>
  <p-tabPanel header="Palletes">
    <app-circle-with-image
      [imageSrc]="srcImage"
      [showBigRounds]="false"
      [showAllColors]="true"
      [showDownload]="true"
    ></app-circle-with-image>
  </p-tabPanel>
  <p-tabPanel header="Brushes">
    <p-scrollPanel [style]="{height:640+'px'}">
    <app-brushes-photoshop></app-brushes-photoshop>
    </p-scrollPanel>
  </p-tabPanel>
  <p-tabPanel header="Line">
    <app-sketch-converter
      [showBackground]="true"
      [imageSrc]="srcImage"
      [showDownload]="true"
      [imageSrcTemp]="srcImage"
      [showResult]="true"
      [isBlackAndWhite]="true">
    </app-sketch-converter>
  </p-tabPanel>
  <p-tabPanel header="Grayscale">
    <app-grayscale
      [imageSrc]="srcImage"
      [isBlackAndWhite]="true"
      [showDownload]="true"
    >
    </app-grayscale>
  </p-tabPanel>
</p-tabView>
