import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  NgFor,
  NgIf
} from "@angular/common";
import {ClientPatreonAuthServices} from "../../../../services/client/client-patreon-auth.services";
import {CdServices} from "../../../../services/common/cd.services";
import {SidebarComponent} from "../../../common/sidebar/sidebar.component";
import {AccordionModule} from "primeng/accordion";
import {
  enterIcon,
  patreonIcon
} from "../../../../traits/icons-tools";
import {trimEmail} from "../../../../traits/client-tools";
import {CardModule} from "primeng/card";
import {FormsModule} from "@angular/forms";
import {TimelineModule} from "primeng/timeline";
import {
  srcHeaderBg,
  srcSketch
} from "../../../../traits/image-tools";

@Component({
  selector: 'app-patreon-email-enter',
  standalone:true,
  imports: [
    NgIf,
    NgFor,
    SidebarComponent,
    AccordionModule,
    CardModule,
    FormsModule,
    TimelineModule,
  ],
  templateUrl: './patreon-email-enter.component.html',

})
export class PatreonEmailEnterComponent implements OnInit {
 public opportunities:any;
  @Input() selectBlock: any;
  @Output() oSrc = new EventEmitter();
  @Output() eventEmit = new EventEmitter();
  @Output() oPhotoLib = new EventEmitter();

  constructor(public  cd: CdServices,
              public clientServices:ClientPatreonAuthServices
  ) {
  }

  public initOpportunities(){
    // this.opportunities  = this.dataSites.list().map((itemData:any)=>{
    //   return   itemData.instance.possibilitiesPremium;
    // })
  }
  async ngOnInit() {
    // this.cd.initTypist('#patreon-desc');
this.initOpportunities();
  }

  ngAfterViewInit() {
  }


  protected readonly patreonIcon = patreonIcon;
  protected readonly enterIcon = enterIcon;
  protected readonly trimEmail = trimEmail;
    protected readonly srcSketch = srcSketch;
  protected readonly srcHeaderBg = srcHeaderBg;
}
