import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {NgFor, NgIf} from '@angular/common';
import {Card} from '../../../interfaces/Card';
import {ThemeServices} from '../../../services/common/theme.services';
import {GifGeneratorComponent} from '../../image/gif-generator/gif-generator.component';
import {SvgIconComponent} from '../../image/svg-icon/svg-icon.component';
import {BtnGoComponent} from '../../bottons/btn-go/btn-go.component';
import {BtnPremiumProductComponent} from '../../bottons/btn-premium-product/btn-premium-product.component';
import {theme} from '../../../interfaces/theme';

@Component({
  selector: 'app-edit-card-view',
  templateUrl: './edit-card-view.component.html',
  standalone: true,
  imports: [NgIf, BtnPremiumProductComponent, GifGeneratorComponent, SvgIconComponent, BtnGoComponent]
})
export class EditCardViewComponent implements OnInit {
  @Input() showImage: boolean = true;
  @Input() card: Card;
  @Input() bgGlobalClass: string = 'bg-zinc-700';
  @Input() bgClass: string = 'bg-zinc-700';
  @Input() borderClass: string = 'border-zinc-700';
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onSelectSelect: EventEmitter<any> = new EventEmitter();
  @Output() onAddFavorite: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteFromFavorite: EventEmitter<any> = new EventEmitter();
  public theme: theme = inject(ThemeServices);

  ngOnInit():void {
  }

  showPhotosBoxBtn(item: Card, uploaded: string): void {
    /*todo show box photos viewer*/
    this.onSelect.emit(item)
  }

  showVideos(item: Card, uploaded: string): void {
    this.onSelect.emit(item)

  }

  showPalletesBlock(item: Card, uploaded: string): void {
    this.onSelect.emit(item)

  }

  showBrushesPhotoshopBlock(item: Card, uploaded: string): void {
    this.onSelect.emit(item)


  }
}
