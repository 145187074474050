import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
import {
  deleteIcon,
  downloadIcon
} from "../../traits/icons-tools";
import {deleteFromArray} from "../../traits/array-tools";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";
import {dateNow} from "../../traits/datetime-tools";

@Component({
  selector: 'app-list-downloads',
  templateUrl: './list-downloads.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    JsonPipe,
  ]


})
export class ListDownloadsComponent {
  @Input() models: any[] = [];
  @Output() onClose = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  protected readonly deleteIcon:string = deleteIcon;
  protected readonly downloadIcon:string = downloadIcon;

  public deleteItem(item:object) {
    this.onDelete.emit(false)
    deleteFromArray(item, this.models)
    this.onDelete.emit(false)
    if (this.models.length < 1) {
      this.onClose.emit(false)
    }
  }
  public downloadImage(srcImage: string) {

    const a = document.createElement('a');
    a.href = srcImage
    a.download = `art-club.info (pallete-${dateNow()}).png`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

}
