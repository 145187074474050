import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {TabViewModule} from "primeng/tabview";
import {SidebarComponent} from "../../../../src/app/components/common/sidebar/sidebar.component";
import {CommonHomeComponent} from "../../pages/common-home/common-home.component";
import {HeaderComponent} from "../../../../src/app/components/pages/header/header.component";
import {NavbarTopComponent} from "../../../../src/app/components/pages/navbar-top/navbar-top.component";
import {CroppieComponent} from "../../../../src/app/components/croppie/croppie.component";
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {
  windowHeight,
  windowWidth
} from "../../traits/window-tools";
import {CDN_GRADIO} from "../../traits/ai-tools";
import {PreloadComponent} from "../pages/preload/preload.component";

@Component({
  selector: 'app-iFrame-site',
  standalone:true,
  imports: [
    NgForOf,
    NgIf,
    FormsModule,
    ScrollPanelModule,
    SidebarComponent,
    HeaderComponent,
    NavbarTopComponent,
    PreloadComponent
  ],
  templateUrl: './iFrame-site.component.html',
})
export class IFrameSiteComponent implements OnInit {
  @Input() model: any;
  @Input()showFrame: boolean = true;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  urlSafe
  constructor(     public sanitizer: DomSanitizer,
  ) {
  }
 async ngOnInit() {
    // @ts-ignore
   const module = await import(/* webpackIgnore: true */ 'https://gradio.s3-us-west-2.amazonaws.com/4.32.2/gradio.js');
    console.log(module)

    this.urlSafe = this.saveUrl(this.model.srcFRAME)
  }
  public saveUrl(url: any) {
    let urlSafe: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return urlSafe;
  }

  close() {
    this.showFrame = false;
    this.onClose.emit()
  }

  protected readonly windowWidth = windowWidth;
  protected readonly windowHeight = windowHeight;
}
