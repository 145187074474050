import Compressor from 'compressorjs';

export function createImageElement(src: any = 'assets/icons/round-brush.png', callback: any =  null) {
}

export function largeImageParams(mimeType: any = 'image/png', ext: any = '.png') {
  return {
    'width': 800,
    'height': 800,
    'quality': 80,
    'mimeType': mimeType,
    'ext': ext
  };
}

export function middleImageParams(mimeType: any = '', ext: any = '.jpg') {
  return {
    'width': 500,
    'height': 500,
    'quality': 80,
    'mimeType': mimeType,
    'ext': ext
  };
}

export function smallImageParams(mimeType: any = '', ext: any = '.jpg') {
  return {
    'width': 80,
    'height': 80,
    'quality': 70,
    'mimeType': mimeType,
    'ext': ext
  };
}

export function switchSizeParams(sizeMode: any = 'middle', mimeType: any = 'image/png', ext: any = '.jpg',
) {
  let sizeParams: any;
  let large = largeImageParams(mimeType, ext);
  let middle = middleImageParams(mimeType, ext);
  let small = smallImageParams(mimeType, ext);

  switch (sizeMode) {
    case 'small':
      sizeParams = small;
      break;
    case 'middle':
      sizeParams = middle;
      break;
    case 'large':
      sizeParams = large;
      break;
  }
  return sizeParams;
}

export function checkSrc(src: any) {
  let result;
  if (src.includes('.png')) {
    result = {name:'PNG',icon:'fa-regular fa-file-image'};
  } else if (src.includes('.jpg')) {
    result = {name:'JPG',icon:'fa-regular fa-file-image'};
  } else if (src.includes('.webp')) {
    result = {name:'WEBP',icon:'fa-regular fa-file-image'};
  } else {
    result = {name:'BASE64',icon:'fas fa-x-ray'};
  }
  return result;
}

export function createImageObject(src: any, layerKonva: any = null, callback: any = null) {
  let img = new window.Image();
  img.crossOrigin = 'Anonymous';
  img.onload = () => {
    callback && callback({width: img.width, height: img.height});
  };
  img.src = src;
  return img;
}

export function getImageSize(src: any, callback: any = null) {
  let img = new window.Image();
  img.crossOrigin = 'Anonymous';

  img.onload = () => {
    callback && callback({width: img.width, height: img.height});
  };
  img.src = src;
  return {img:img,width: img.width, height: img.height};
}
export function resizeImage(src: any,width:any =  200,height:any = 200,callback: any = null) {
  let img = new window.Image();
  img.crossOrigin = 'Anonymous';

  img.onload = () => {
    img.width = width;
    img.height = height;
    callback && callback(img);
  };
  img.src = src;
}

export function fileToBase64(file: any, callback: any) {
  let reader = new FileReader();
  reader.onload = (e) => {
    // @ts-ignore
    let base64 = e.target.result;
    callback && callback(base64);
  };
  reader.readAsDataURL(file);

}

export function compress(src: any, params: any = {quality: 0.1, maxHeight: 100}, callback: any = null) {

  urlToBlob(src,(resSRc:any)=>{
    let res = {
      params: {
        // mimeType: 'image/jpg',
        // strict: true,
        // crop: null,
        // checkOrientation: true,
        // quality: 0,
        // convertSize: 20,
        size: {
          // width: params.maxHeight,
          // height: params.maxHeight,
          // maxWidth: params.maxHeight,
          // maxHeight: params.maxHeight,
          // minWidth: params.maxHeight,
          // minHeight: params.maxHeight,
          // aspect: {
          //   width: 500,
          //   height: 500
          // },
        },
      },
      result: {},
    };
    let img = document.createElement('img');
    img.crossOrigin = 'Anonymous';
    img.src = resSRc;
    img.onload = () => {
      /* const imgWidth = img.naturalWidth;
       const imgHeight = img.naturalHeight;
       let canvas = document.createElement('canvas');
       canvas.width = imgWidth;
       canvas.height = imgHeight;
       // @ts-ignore
       canvas.getContext('2d').drawImage(img, 0, 0);
       let dataUrl = canvas.toDataURL();*/
      let blob = dataURItoBlob(resSRc);
      new Compressor(blob, {
        // strict: res.params.strict,
        // checkOrientation: res.params.checkOrientation,
        maxWidth: params.maxHeight,
        maxHeight: params.maxHeight,
        // minWidth: res.params.size.minWidth,
        // minHeight: res.params.size.minHeight,
        // width: res.params.size.width,
        // height: res.params.size.height,
        quality: params.quality,
        // convertSize: res.params.convertSize,
        // mimeType:'image/jpg', //res.params.mimeType,
        success(result: any) {
          let reader = new FileReader();
          reader.readAsDataURL(result);
          reader.onloadend = () => {
            // @ts-ignore
            callback && callback(reader.result);
          };
        }
      });
    };
    return res;
  });

}

export function color(src: any, callback: any = null) {
  // @ts-ignore
  let colorThief = new ColorThief();

  let res = {
    main: {hex: 'white'},
    all: [],
    colorsGradient: [],
  };
  // const img = new Image();
  let img = document.createElement('img');
  img.crossOrigin = 'Anonymous';
  img.src = src;
  img.onload = () => {

    /* function oppositeColor(col: any) {
       const colors = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
       let inverseColor = '#';
       col.replace('#', '').split('').forEach((i: any) => {
         const index = colors.indexOf(i);
         inverseColor += colors.reverse()[index];
       });
       return inverseColor;
     }

     function saturate(color: any, n: any) {
       return chroma(color).saturate(n).hex();
     }

     function desaturate(color: any, n: any) {
       return chroma(color).desaturate(n).hex();
     }

     function brighten(color: any, n: any) {
       return chroma(color).brighten(n).hex();
     }

     function darken(color: any, n: any) {
       return chroma(color).darken(n).hex();
     }

     function opacity(color: any, n: any) {
       return chroma(color).alpha(n);
     }

     function luminance(color: any, n: any) {
       return chroma(color).luminance(n);
     }

     function next(color: any, n: any) {
       return chroma.scale(color).colors(n);

     }*/

    /*function gradient(color = '#00b89f', color2 = '#00b89f', n = 10) {
      return chroma.scale([color, color2]).mode('lch').colors(n);
    }

    function colorsGradient(array: any = [], n = 10) {
      return chroma.scale(array).mode('lch').colors(n);
    }
*/
    const toColor = (color: any) => {
      // @ts-ignore
      let wcolor = w3color(color);
      return [
        // {name:'toName',value: wcolor.toName()},
        {name: 'toHexString', value: wcolor.toHexString()},
        // {name: 'toCmykString', value: wcolor.toCmykString()},
        // {name: 'toNcolString', value: wcolor.toNcolString()},
        // {name: 'toRgbString', value: wcolor.toRgbString()},
        // {name: 'toHslString', value: wcolor.toHslString()},
        // {name: 'toHwbString', value: wcolor.toHwbString()},
      ];
    };
    // res.colors = colorThief.getPalette(img).map((rgb: any) => {
    //   const rgbToHex = (r: any, g: any, b: any) => '#' + [r, g, b].map(x => {
    //     const hex = x.toString(16);
    //     return hex.length === 1 ? '0' + hex : hex;
    //   }).join('');
    //   let colorHex = rgbToHex(rgb[0], rgb[1], rgb[2]);
    //   return {
    //     main: toColor(colorHex),
    //     // opposite: toColor(oppositeColor(colorHex)),
    //     // saturate: toColor(saturate(colorHex, 3)),
    //     // desaturate: toColor(desaturate(colorHex, 3)),
    //     // brighten: toColor(brighten(colorHex, 3)),
    //     // darken: toColor(darken(colorHex, 3)),
    //     // opacity:toColor(opacity(colorHex,3)),
    //     // luminance:toColor(luminance(colorHex,3)),
    //     // next:toColor(next(colorHex,3)),
    //     // gradientNext: gradient(colorHex, res.colors[i(colorHex, res.colors) + 1], 10),
    //   };
    // });
    res.all = colorThief.getPalette(img).map((rgb: any) => {
      const rgbToHex = (r: any, g: any, b: any) => '#' + [r, g, b].map(x => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      }).join('');
      let colorHex = rgbToHex(rgb[0], rgb[1], rgb[2]);
      return {hex: colorHex};
      // return {
      // main: toColor(colorHex),
      // opposite: toColor(oppositeColor(colorHex)),
      // saturate: toColor(saturate(colorHex, 3)),
      // desaturate: toColor(desaturate(colorHex, 3)),
      // brighten: toColor(brighten(colorHex, 3)),
      // darken: toColor(darken(colorHex, 3)),
      // opacity:toColor(opacity(colorHex,3)),
      // luminance:toColor(luminance(colorHex,3)),
      // next:toColor(next(colorHex,3)),
      // gradientNext: gradient(colorHex, res.colors[i(colorHex, res.colors) + 1], 10),
      // };
    });
    res.main = res.all[0];
    callback && callback(res.all);
    // objectParams.colorsGradient = colorsGradient(objectParams.colors);

  };
  return res;
};

export function crop(src: any) {
  return {};
};

export function compresses(src: any) {
  let compresses = [
    {quality: 0.1, maxHeight: 50, compress: {}},
    {quality: 0.2, maxHeight: 50, compress: {}},
    {quality: 0.3, maxHeight: 50, compress: {}},
    {quality: 0.4, maxHeight: 50, compress: {}},
    {quality: 0.5, maxHeight: 50, compress: {}},
    {quality: 0.5, maxHeight: 50, compress: {}},
    {quality: 0.7, maxHeight: 50, compress: {}},
    {quality: 0.8, maxHeight: 50, compress: {}},
    {quality: 0.9, maxHeight: 50, compress: {}},
    {quality: 1, maxHeight: 50, compress: {}},
  ];
  compresses.forEach((params: any) => {
    params.compress = compress(src, params);
  });

  return compresses;
};

export function bytesToSize(bytes: any) {
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) {
    return '0 Byte';
  }
  // @ts-ignore
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  // @ts-ignore
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function urlToBlob(src: any, callback: any) {

  let res = {url: {src: ''}};

  let request = new XMLHttpRequest();
  request.open('GET', src, true);
  request.responseType = 'blob';

  request.onload = () => {
    let reader = new FileReader();
    reader.readAsDataURL(request.response);
    reader.onload = e => {
      // @ts-ignore
      callback && callback(e.target.result);
      // @ts-ignore
      // res.url.src = e.target.result;
    };
  };

  request.send();

  return res;
}

export function dataURItoBlob(dataURI: any) {
  let byteString = atob(dataURI.split(',')[1]);
  let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type: mimeString});

}

export function aspectRatio(srcWidth: any, srcHeight: any, maxWidth: any, maxHeight: any) {

  let ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return {
    width: srcWidth * ratio,
    height: srcHeight * ratio,
    ratio: ratio
  };

}
