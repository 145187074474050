<div
  [class]="'bg-zinc-700'"
  [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
  class="mt-[75px]  bg-[length:50px_50px]  mb-2 bg-opacity-10  bg-repeat flex justify-center items-center flex-col bg-gradient-to-br from-gray-800 to-gray-900 relative animate-gradient-x">

  <div class="relative flex flex-col items-center mt-16">
    <div *ngIf="!showPreload && !showCameraComponent " class="absolute top-0 -mt-8 flex space-x-4 z-20">
      <button *ngIf="showCropRigionBtn"
              class="bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
              (click)="showCropPanel()"
      >
        Crop
        <i *ngIf="!showCropRigion" [class]="editIcon"></i>
        <i *ngIf="showCropRigion" [class]="closeSquareIcon"></i>
      </button>

      <button *ngIf="showDownloadBtn && !showCropRigion"
              (click)="(!cd.lock|| cd.logined)?generatePack():null;
      coins.coinService.minusCoin()
"
              class="bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
      >
        <span class="absolute -top-4 -left-2">
       <app-coins [showCoinMinus]="true" #coins></app-coins>
          </span>
        Generate
        <i class="fas fa-box"></i>
      </button>

     <!-- <button #targetEl *ngIf="!showCropRigion"
              (click)="showSettings = !showSettings;showUpload = !showUpload;
              opPalleteSettings.toggle($event, targetEl)"
              class="bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
      >
        <i class="fa-solid fa-sliders"></i>
      </button>-->

    </div>
    <p-overlayPanel #opPalleteSettings>

<!--    @if(showSettings && !showPreload && !showCameraComponent  && !showCropRigion){-->
      <div  class="flex flex-col space-x-1 w-[300px] ">
          <div class="flex flex-row justify-around mt-1">
              <p-toggleButton [(ngModel)]="showBigRounds"
                              (ngModelChange)=" resetChildComponent()"
                              [onIcon]="gridIcon"
                              [offIcon]="closeSquareIcon"
                              [offLabel]="'Big Rounds'"
                              [onLabel]="'Only Small'"
              ></p-toggleButton>
              <p-toggleButton [(ngModel)]="showAllColors"
                              (ngModelChange)="switchMaxOpt()"
                              [onIcon]="gridIcon"
                              [offIcon]="closeSquareIcon"
                              [offLabel]="'Maximum'"
                              [onLabel]="'Optimum'"
              ></p-toggleButton>

          </div>
        <div class="text-white p-1">
          <span>Colors: {{ roundsSmallColors }}</span>
          <p-slider [min]="25" [step]="25" [max]="200"
                    [(ngModel)]="roundsSmallColors"
                    (ngModelChange)="roundsSmallColors>100?showBigRounds=false:showBigRounds =true;resetChildComponent()"
          ></p-slider>
        </div>
        <div class="text-white p-1">
          <span>Space:{{ spaceWithColors }}</span>
          <p-slider [min]="1" [max]="300"
                    [(ngModel)]="spaceWithColors"
                    (ngModelChange)="spaceWithColorsTemp =spaceWithColors;resetChildComponent()">
          </p-slider>
        </div>
        <div class="text-white p-1">
          <span>Group:{{ groupWithColors }}</span>
          <p-slider [min]="1" [max]="100"
                    [(ngModel)]="groupWithColors"
                    (ngModelChange)="groupWithColorsTemp =groupWithColors; resetChildComponent()">
          </p-slider>
        </div>
        <div class="text-white p-1">
          <button
            (click)="showBigRounds = true; roundsSmallColors = 100; groupWithColors = 20; groupWithColorsTemp = 20; spaceWithColors = 10; spaceWithColorsTemp = 10; resetChildComponent()"
            class=" bg-gradient-to-r from-purple-500 to-blue-500  hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
          >
            Reset
            <i class="fa-solid fa-arrows-spin"></i>
          </button>
        </div>
      </div>
<!--    }-->

    </p-overlayPanel>

    <div class="m-3"
         *ngIf=" !showPreload  && !showCropRigion && !showCameraComponent && !showSettings && showPackPaletes">
      <app-pack-palettes
        [images]="packTrioPallete"
      ></app-pack-palettes>
    </div>
    <div *ngIf="showPreload"
         class="w-10 h-4 bg-gradient-to-r from-pink-500 to-yellow-500 rounded-full animate-spin">
    </div>
    <div [hidden]="showPreload">
      <div *ngIf="showCropRigion" class=" w-[300px] ">
        <app-crop-rigion
          [width]="300"
          [height]=300
          [cropZoneHeight]=150
          [cropZoneWidth]=150
          [cropZoneX]="100"
          [cropZoneY]="100"
          [showReset]="true"
          [showPressetsCrop]="false"
          [showZoom]="true"
          [showFlip]="true"
          [showRotate]="false"
          [showAspectRatio]="true"
          [showDownload]="false"
          [showAdd]="false"
          [src]="tempImage"
          (onCrop)="cropImage($event); imageCrop = $event;  "
        ></app-crop-rigion>
       <!-- <p-tabView>
          <p-tabPanel header="Crop">
            <app-crop-rigion
              [width]="300"
              [height]=300
              [cropZoneHeight]=150
              [cropZoneWidth]=150
              [cropZoneX]="100"
              [cropZoneY]="100"
              [showReset]="false"
              [showPressetsCrop]="false"
              [showZoom]="false"
              [showFlip]="false"
              [showRotate]="false"
              [showDownload]="false"
              [showAdd]="false"
              [src]="tempImage"
              (onCrop)="cropImage($event); imageCrop = $event; resetColorEditorComponent()  "
            ></app-crop-rigion>
          </p-tabPanel>
          <p-tabPanel header="Values">
            <app-image-editor *ngIf="showColorEditor"
              [imageSrc]="imageCrop"
              (imageEdited)="imageUploaded = $event;  resetChildComponent()"
            >
            </app-image-editor>
          </p-tabPanel>-->
       <!-- </p-tabView>-->



      </div>
      <app-grayscale
        *ngIf="showGrayscale"
        [imageSrc]="imageCrop"
        [isBlackAndWhite]="showGrayscale"
        (imageConverted)="handleImageConverted($event); imageGrayscale = $event;"
      >
      </app-grayscale>
      <app-image-mosaic
        *ngIf="showPixelizer"
        [heightImage]="300"
        [widthImage]="300"
        [imageSrc]="imageCrop"
        (onCrop)="cropImage($event); imagePixel = $event; "
        (onReset)=" cropImage(resetUploaded)  "
      ></app-image-mosaic>
    </div>


    <div class="mt-2">
    <app-circle-with-image #roundPallete
                           *ngIf="showChildComponent"
                           [imageSrc]="imageUploaded"
                           [showBigRounds]="showBigRounds"
                           [roundsSmallColors]="roundsSmallColors"
                           [spaceWithColors]="spaceWithColors"
                           [groupWithColors]="groupWithColors"
                           [showAllColors]="showAllColors"
                           [colorsEyeDropper]="colorsEyeDropper"
                           [scaleCanvas]="palleteSize"
                           (onGetPallete)="imagePallete = $event;
                          addToPackList($event)"
    ></app-circle-with-image>
    </div>
  </div>
  @if (showCamera){
    <button (click)="showCameraBtn()"
            class="m-3 p-3 bg-gradient-to-r from-amber-500 to-yellow-300 hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105  "
    >
      <i *ngIf="!showCameraComponent" [class]="cameraIcon"></i>
      <i *ngIf="showCameraComponent" [class]="closeSquareIcon"></i>
      <app-camera
        *ngIf="showCameraComponent"
        (base64Output)="setImage($event)"
      ></app-camera>
    </button>
  }
      <app-uploader-photo
        [showLabel]="true"
        [showListHorisont]="true"
        [showListModal]="true"
        [showUpload]="true"
        [showCounter]="true"
        (onClickItem)="selectUpload($event)"
      ></app-uploader-photo>
</div>
