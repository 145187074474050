import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
import {ThemeServices} from '../../../services/common/theme.services';
import {theme} from 'src/app/interfaces/theme';
import {SortablejsModule} from '@maksim_m/ngx-sortablejs';
import {SortableOptions} from "sortablejs";
import {EditTextRowComponent} from "../edit-text-row/edit-text-row.component";
import {ArrayPanelToolsComponent} from "../array-panel-tools/array-panel-tools.component";
import {
  addCloneModel,
  deleteFromArray
} from "../../../traits/array-tools";
import {PaginatorModule} from "primeng/paginator";
import {addIcon} from "../../../traits/icons-tools";

@Component({
  selector: 'app-edit-array',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    ScrollPanelModule,
    SortablejsModule,
    JsonPipe,
    EditTextRowComponent,
    ArrayPanelToolsComponent,
    PaginatorModule
  ],
  templateUrl: './edit-array.component.html',

})
export class EditArrayComponent implements OnInit {
  // @ts-ignore
  @ViewChild('accessId') accessId: ElementRef;
  @Input() view: any = 'list';
  // @ts-ignore
  @Input() rows: any;
  @Input() row: any = 'list';
  @Input() data: any;
  @Input() placeholder: any;
  @Input() site: any;
  @Input() model: any;
  @Input() modelsParent: any;
  @Input() outerArray: any = [];
  @Input() direction: any = 'horizontal';
  @Input() heightSwiper: any = 150;
  @Input() widthSwiper: any = 100;
  @Input() heightPage: any = 75;
  @Input() widthPage: any = 50;
  @Input() margin: any = 10;
  @Input() i: any = 0;
  @Input() showDelete: any;
  @Input() showDownload: any;
  @Input() showColorPicker: any;
  @Input() showMore: any;
  @Input() showMove: any;
  @Input() showImage: any = true;
  @Input() showColor: any;
  @Input() showFilter: any;
  @Input() showZoom: any;
  @Input() showAdd: any;
  @Input() showName: any;
  @Input() showFavorite: any;
  @Input() showCopy: any;
  @Input() showScroller: any;
  @Input() showNavigation: any;
  @Input() showPagination: any;
  @Input() showPages: any;
  @Input() showAddClick: any;
  @Input() filterByRow: any = 'page';
  @Input() selected: any;
  @Input() slidesPerView: any = 1;
  @Input() onSearchKeyword: any = '';
  @Input() models: any = [];
  @Input() addModel: any;
  @Output() onClick = new EventEmitter();
  @Output() onSlide = new EventEmitter();
  @Output() onColorPick = new EventEmitter();
  @Output() onDownload = new EventEmitter();
  @Output() onCopy = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onGroup = new EventEmitter();
  @Output() onAdd = new EventEmitter();
  @Output() onMore = new EventEmitter();
  @Output() onAddClick = new EventEmitter();
  @Output() onFavorite = new EventEmitter();
  @Output() onDoubletap = new EventEmitter();
  @Output() onSave = new EventEmitter();
  public swiper: any;
  public siblings: any = [];
  public showSiblings: any = false;
  public theme: theme = inject(ThemeServices);
  public sortableOptions:SortableOptions = {
    animation: 150,
    group: 'list',
    handle: '.handle',
    onUpdate: (event: any) => {
      console.log(event);
    }
  }
  public options: any;
  @Input() change: any;
  buttonToggle: boolean = false;

  constructor() {

  }

  public ngOnInit() {
    this.parentModels();

  }


  public delete(model: any, models: any) {
    deleteFromArray(model,models)
    this.onSave.emit()
    /*this.cd.alert.confirmationService.confirm({
      message: `Remove? ${model.name}`,
      accept: () => {
        this.cd.array.delete(model, models);
      }
    });*/
  }


  public addItem() {
    this.onSave.emit()
    /*
        let model = this.cd.add.modelTag();
        !this.model[this.row] ? this.model[this.row] = [] : null;
        this.cd.array.addToStart(model, this.model[this.row]);
        // this.onAdd.emit(this.addModel)
        this.cd.changeDetect = true;*/
  }

  public parentModels() {

    if (this.modelsParent) {
      let modelsParent = this.modelsParent;

      modelsParent.forEach((parentItemModel: any) => {
        let modelRows = parentItemModel[this.row];
        if (modelRows) {
          let modelNew = {
            name: parentItemModel.name,
            array: modelRows
          };

          modelNew.array.map((listItem: any) => {
            // listItem = cloneModel(listItem);
          });
          this.siblings.push(modelNew);
        }
      });
    }


  }

  public selectItem(item: any) {
    /* this.cd.selectListModel = item;
     this.cd.selectModel = this.model;
     this.cd.showBoxSelect = true;*/
  }

  public deleteTag(model: any) {
    /* this.cd.array.delete(model, this.model[this.row], true);
     this.cd.changeDetect = true;*/
  }


  protected readonly addIcon = addIcon;
  protected readonly addCloneModel = addCloneModel;
}

/*  addList() {
    // let model = this.cd.add.modelTag();
    // let model2 = this.cd.add.modelTag();
    /!*let model3 = this.cd.add.modelTag();
    let model4 = this.cd.add.modelTag();
    let model5 = this.cd.add.modelTag();*!/
    // let model6 = this.cd.add.modelTag();
    // let model7 = this.cd.add.modelTag();
    // let model8 = this.cd.add.modelTag();
    // let model9 = this.cd.add.modelTag();
    // let model10 = this.cd.add.modelTag();
    !this.model[this.row] ? this.model[this.row] = [
      // model,
      // model2,
      /!* model3,
       model4,
       model5,*!/
      // model6,
      // model7,
      // model8,
      // model9,
      // model10,
    ] : null;
  }*/
