import {
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';
import {TabViewModule} from 'primeng/tabview';
import {SidebarComponent} from '../../../components/common/sidebar/sidebar.component';
import {CommonHomeComponent} from '../../common-home/common-home.component';
import {CircleWithImageComponent} from "../../../components/circle-with-image/circle-with-image.component";
import {ColorsImageComponent} from "../../../colors-image/colors-image.component";
import {UploaderPhotoComponent} from "../../../components/admin/uploader-photo/uploader-photo.component";
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {BadgeModule} from "primeng/badge";
import {ShareImageComponent} from "../../../components/share-image/share-image.component";
import {CropRigionComponent} from "../../../components/crop-rigion/crop-rigion.component";
import {ImagesViewerGridSlidderComponent} from "../../../components/pages/images-viewer-grid-slidder/images-viewer-grid-slidder.component";
import {EyeDropperComponent} from "../../../components/eye-dropper/eye-dropper.component";
import {ImageMosaicComponent} from "../../../components/image-mosaic/image-mosaic.component";
import {GrayscaleComponent} from "../../../components/grayscale/grayscale.component";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {HeaderComponent} from "../../../components/pages/header/header.component";
import {NavbarTopComponent} from "../../../components/pages/navbar-top/navbar-top.component";
import {CameraComponent} from "../../../components/pages/camera/camera.component";
import {PreloadComponent} from "../../../components/pages/preload/preload.component";
import {ListDownloadsComponent} from "../../../components/list-downloads/list-downloads.component";
import {BtnDownloadComponent} from "../../../components/btn-download/btn-download.component";
import {AlbumComponent} from "../../../components/album/album.component";
import {ZipComponent} from "../../../components/zip/zip.component";
import {BrowserCloseAlertComponent} from "../../../components/browser-close-alert/browser-close-alert.component";
import {PackPalettesComponent} from "../../../components/pack-palettes/pack-palettes.component";
import {PalleteGeneratorImageComponent} from "../../../components/pallete-generator-image/pallete-generator-image.component";
import {PalleteGeneratorColorComponent} from "../../../components/pallete-generator-color/pallete-generator-color.component";
import {SketchConverterComponent} from "../../../components/sketch-converter/sketch-converter.component";
import {PageAiBotsServices} from "../../../services/pages/page-ai-bots.services";
import {AccordionModule} from "primeng/accordion";
import {
  ScrollPanel,
  ScrollPanelModule
} from "primeng/scrollpanel";
import {
  closeSquareIcon,
  deleteIcon,
  dotesVerticalIcon,
  editIcon,
  eyeOpenIcon,
  lockCloseIcon,
  lockOpenIcon,
  plusIcon
} from "../../../traits/icons-tools";
import {CdServices} from "../../../services/common/cd.services";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {ColorPanelComponent} from "../../../components/color-panel/color-panel.component";
import {SliderModule} from "primeng/slider";
import {ZoomImageComponent} from "../../../components/zoom-image/zoom-image.component";
import {PageImageToTraceServices} from "../../../services/pages/page-image-to-trace.services";
import {ImageTracingComponent} from "../../../components/image-postorizer/image-tracing.component";
import {
  srcLogo,
  srcSketch
} from "../../../traits/image-tools";
import {arrayStartEndStep} from "../../../traits/params-tools";
import {SliderBtnsComponent} from "../../../components/slider-btns/slider-btns.component";
import {windowWidth} from "../../../traits/window-tools";
import {OneReferenceFloatComponent} from "../../../components/one-reference-float/one-reference-float.component";

@Component({
  selector: 'app-page-image-to-trace',
  standalone: true,
    imports: [
      TabViewModule,
      SidebarComponent,
      CommonHomeComponent,
      CircleWithImageComponent,
      ColorsImageComponent,
      UploaderPhotoComponent,
      NgIf,
      FormsModule,
      BadgeModule,
      ShareImageComponent,
      CropRigionComponent,
      ImagesViewerGridSlidderComponent,
      NgForOf,
      EyeDropperComponent,
      ImageMosaicComponent,
      GrayscaleComponent,
      ButtonModule,
      HeaderComponent,
      NavbarTopComponent,
      CameraComponent,
      PreloadComponent,
      ListDownloadsComponent,
      BtnDownloadComponent,
      AlbumComponent,
      ZipComponent,
      BrowserCloseAlertComponent,
      PackPalettesComponent,
      PalleteGeneratorImageComponent,
      PalleteGeneratorColorComponent,
      SketchConverterComponent,
      AccordionModule,
      ScrollPanelModule,
      OverlayPanelModule,
      ColorPanelComponent,
      SliderModule,
      ZoomImageComponent,
      ImageTracingComponent,
      SliderBtnsComponent,
      OneReferenceFloatComponent,
    ],
  templateUrl: './page-image-to-trace.component.html',
})
export class PageImageToTraceComponent {
  @ViewChild('tracerComponent') tracerComponent: ElementRef<HTMLCanvasElement>;

  public showPreload: boolean = true;
  public showTracing: boolean = true;
  public showZoom:  boolean = true;

  public imageUpload: string = srcSketch;
  public imageCrop: string =srcSketch;
  public imageResult: string
  public imagePreview: string =srcSketch;
  public blurAmount: number = 5;
  public grayscaleAmount: number = 1;
  public selectColor: string;
  public brightness:number = 100;
  public contrast:number = 100;
  public saturation:number = 100;
  public opacityFilter: number = 100;
  public blurFilter: number = 0;
  public mode: object ={name:'normal',value:'color'};
  public showBackground: boolean = true;
  public showColorSidebar: boolean = true;
  public showCropRigionBtn: boolean = true;
  public showCropRigion: boolean = false;
  public showSettings: boolean =false;

  constructor(public cd:CdServices,
              public dataPage: PageImageToTraceServices
  ) {
  }

  ngOnInit() {
  }
  public modes:object[] = [
    {name:'normal',value:'color'},
    {name:'soft', value:'screen'},
    {name:'hard',value:'overlay'},
  ];
  public resetCanvas(){
    this.brightness = 100;
    this.contrast = 100;
    this.saturation = 100;
    this.blurFilter = 0;
    this.opacityFilter = 100;
    this.mode =   {name:'normal',value:'color'}
    this.reInitImageTracing();
  }
  public selectUpload(base64: string) {
    this.imageCrop = base64;
    this.imageUpload = base64;
    this.reInitImageTracing()
  }
  public setColor(colorHex: string) {
    this.selectColor = colorHex;
    this.reInitImageTracing()
  }
  public reInitImageTracing() {
    this.showTracing = false;
    setTimeout(() => {
      this.showTracing = true;
    }, 50)
  }

  traceOriginal() {
    // @ts-ignore
    this.tracerComponent.setTraceOriginal()
  }

  protected readonly arrayStartEndStep = arrayStartEndStep;
  paramsBlack:any =  {
    colorsampling: 0,
    numberofcolors: 2,
    blurradius: 0,
    blurdelta: 20
  }
  protected readonly editIcon = editIcon;
  protected readonly eyeOpenIcon = eyeOpenIcon;
  protected readonly closeSquareIcon = closeSquareIcon;
  protected readonly srcSketch = srcSketch;
  protected readonly srcLogo = srcLogo;
  protected readonly windowWidth = windowWidth;
}
