
declare const Dexie;
// declare const db;
export function initDB(nameDB:string = 'infinityBoard',nameTable:string = 'stages',rows:string = '++id,model'){
  let db = new Dexie(nameDB);
  db.version(1).stores({[nameTable]: rows});
  return db;
}
export  function getTableDB(nameDB:string = 'infinityBoard',nameTable:string = 'stages',callback:any = null) {
  let db = initDB(nameDB,nameTable);
  db.table(nameTable).toArray().then((result:any) => {
    callback && callback(result)
  });
}
export  function countTableDB(nameDB:string = 'infinityBoard',nameTable:string = 'stages',callback:any = null) {
  let db = initDB(nameDB,nameTable);
  db[nameTable].count((count:number)=>{
    callback && callback(count)
  })
}
export function addModelDB(nameDB:string = 'infinityBoard',nameTable:string = 'stages',model:any) {
  let db = initDB(nameDB,nameTable);
  db.open();
  db[nameTable].add(model);
}

export function deleteModelDB(nameDB:string = 'infinityBoard',nameTable:string = 'stages',model:any) {
  let db = initDB(nameDB,nameTable);
  db[nameTable].delete(model.id)
}
export  function updateModelDB(nameDB:string = 'infinityBoard',nameTable:string = 'stages',model:any) {
  let db = initDB(nameDB,nameTable);
  db[nameTable].update(model.id, model);
}
export  function updateListDB(nameDB:string = 'infinityBoard',nameTable:string = 'stages',list:any[]) {
  let db = initDB(nameDB,nameTable);
  console.log(list,'list')

  // list.forEach((model)=>{
  //   db[nameTable].update(model.id, model);
  // });
}
