import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtClubServices} from '../static/static-art-club.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {Site} from '../../interfaces/site';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageImageToInkServices {
  public SERVER_NAME: string = 'image-to-ink';
  public DOMAIN: string = 'art-club.artas.org.ua/image-to-ink';
  public route: string = 'image-to-ink';

  public fromServer: boolean = false;
  public imageHeader: string = 'assets/header-background.jpg';
  public premium: object = {
    name: 'Image to ink',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'Image to ink',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Image to Ink: Transform Photos into Stunning Light & Shadow Art',
    description: 'Discover the magic of Image to Ink, the ultimate app for converting your favorite photos into mesmerizing stylized art using advanced ray tracing technology. Experience the elegance of light and shadow at your fingertips.',
    text: 'Engage with the captivating world of digital art through the Image to Ink app, your go-to platform for transforming ordinary photos into extraordinary ink-styled artworks. Leveraging the sophisticated technique of ray tracing, Image to Ink intricately maps out the interaction between light and shadow, presenting a simple yet powerful method to stylize your images with incredible depth and intricacy.\n' +
      '\n' +
      'Why Choose Image to Ink?\n' +
      '\n' +
      '- Advance Ray Tracing Conversion: Utilize cutting-edge technology to create art that highlights the delicate balance of light and shadow.\n' +
      '- Simple and Intuitive Interface: Designed for ease of use, allowing both amateurs and professionals to produce exquisite works of art effortlessly.\n' +
      '- Unique Artistic Expression: Turn your photos into unique ink-style representations, perfect for showcasing personal creativity or enhancing digital projects.\n' +
      '\n' +
      'Embrace the innovative features of Image to Ink to breathe new life into your photos. By converting images into stylized renditions that accentuate light and shadow through ray tracing, Image to Ink empowers you to craft visually stunning digital art that stands out from the crowd.\n' +
      '\n' +
      'Start your creative journey with Image to Ink today and redefine the boundaries of digital artistry. With just a few taps, transform your memories into captivating art pieces that radiate with the beauty of ink stylization. Download Image to Ink and let your artistic vision soar beyond the ordinary.Allow the Image to Ink app to revolutionize your digital art experience, providing a bridge between traditional ink artistry and modern technological innovation. This app is not merely a tool; it\'s your digital canvas, where light and shadow dance at the command of your fingertips, turning everyday images into profound ink-styled masterpieces.\n' +
      '\n' +
      'Revitalize Your Digital Art Portfolio:\n' +
      '- Broaden Your Creative Horizon: With Image to Ink, the possibilities are endless. Each photo can be transformed into a distinct piece of art, reflecting a unique blend of shadow and light.\n' +
      '- Effortless Creativity: The user-friendly interface ensures that creativity flows without interruption, making it suitable for artists at any skill level.\n' +
      '- Celebrate Every Detail: Utilize the precision of ray tracing to capture intricate details, celebrating the beauty hidden within the interplay of light and shadows.\n' +
      '\n' +
      'Ideal for artists, designers, and enthusiasts eager to explore the depths of their creativity, Image to Ink stands as a testament to the boundless potential of combining art with technology. Whether you aim to enhance your digital portfolio, gift someone a personalized piece of art, or simply explore the realms of your creativity, Image to Ink is the perfect companion.\n' +
      '\n' +
      'Transform, Stylize, and Share: With Image to Ink, your artwork is not just confined to your device. Share your stylized ink creations with a community that appreciates the timeless beauty of ink art, enhanced by the precision of modern technology.\n' +
      '\n' +
      'Embark on a unique artistic venture with the Image to Ink app and watch as your images transform into captivating compositions of light and shadow. Download now and let each stroke tell a story, each shadow convey an emotion, and each light reveal the unseen beauty of your world through the lens of ink artistry.',
    h1: 'Image to Ink: Where Light Meets Shadow in Your Photos',
    h2: 'Explore the Advanced Technology Behind Image to Ink',
    h3: 'The Power of Ray Tracing for Image Stylization',
    h4: 'Capture the Essence of Light and Shadow with Image to Ink',
    h5: 'Your Journey from Photo to Stylized Ink Ar',
    textEndPage:'Image to Ink app, photo to ink conversion, ray tracing art, light and shadow stylization, digital art transformation, photo editing tool, advanced image stylization, art creation app.',
    keywords: [
      'Image to Ink app', 'photo to ink conversion', 'ray tracing art', 'light and shadow stylization', 'digital art transformation', 'photo editing tool', 'advanced image stylization', 'art creation app'
    ],
  };
  public site: any

  constructor(public server:ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  getSite() {
  /*  if (this.fromServer) {
      return this.getSiteFromServer();
    } else {
      return this.getSiteStatic();
    }*/

  }
  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }
 public saveSite(site:object){
   if (this.fromServer) {

     this.site = site
    this.downloadSiteBtn(this.site);
    // let server: any = inject(ServerSiteServices)
    this.server.saveSite(this.DOMAIN, this.site);
  }
  }
  getSiteStatic() {
    return this.site = inject(StaticArtClubServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer() {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: Site): void => {
      this.site = responseSite;
    });
    return this.site;
  }

}
