export const arrayLine0_1_10: number[] = [
  0.1,
  0.2,
  0.3,
  0.4,
  0.5,
  0.6,
  0.7,
  0.8,
  0.9,
  1,
];
export const arrayLin1_10: number[] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
];

export function arrayStartEnd(start:number, end:number):number[]{
  let arr = [];
  for (let i = start; i <= end; i += 0.01) {
    arr.push(Math.round(i * 100) / 100);
  }
  return arr;
}

export function arrayStartEndStep(start:number, end:number, step:number):number[] {
  let arr = [];
  for (let i = start; i <= end; i += step) {
    arr.push(i);
  }
  return arr;
}
