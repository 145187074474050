import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import {CommonHomeComponent} from "../../common-home/common-home.component";
import {HeaderComponent} from "../../../components/pages/header/header.component";
import {NavbarTopComponent} from "../../../components/pages/navbar-top/navbar-top.component";
import {PageCoursesServices} from "../../../services/pages/page-courses.services";
import {CourseVideoViewerComponent} from "../../../components/courses/course-video-viewer/course-video-viewer.component";
import {SidebarComponent} from "../../../components/common/sidebar/sidebar.component";
import {
  NgFor,
  NgIf
} from "@angular/common";
import {
  ActivatedRoute,
  Router
} from "@angular/router";
import {BoxServices} from "../../../services/box/box.services";
import {ProgressBarModule} from "primeng/progressbar";
import {CardLessonComponent} from "../../../components/courses/card-lesson/card-lesson.component";
import {ByMeACoffeComponent} from "../../../components/payment/by-me-a-coffe/by-me-a-coffe.component";
import {VideoReaderCourseComponent} from "../../../components/courses/video-reader-course/video-reader-course.component";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {BreadcrumbModule} from "primeng/breadcrumb";
import {MenuItem} from "primeng/api";
import {ProductListRightComponent} from "../../../components/product-list-right/product-list-right.component";
import {CourseResoursesComponent} from "../../../components/course-resourses/course-resourses.component";
import {CoinsComponent} from "../../../components/coins/coins.component";
import {CdServices} from "../../../services/common/cd.services";
import {reInit} from "../../../traits/datetime-tools";

@Component({
  selector: 'app-page-course',
  standalone: true,
  providers: [],
  imports: [
    NgIf,
    NgFor,
    CommonHomeComponent,
    HeaderComponent,
    NavbarTopComponent,
    CourseVideoViewerComponent,
    SidebarComponent,
    ProgressBarModule,
    CardLessonComponent,
    ByMeACoffeComponent,
    VideoReaderCourseComponent,
    ScrollPanelModule,
    BreadcrumbModule,
    ProductListRightComponent,
    CourseResoursesComponent,
    CoinsComponent,
  ],
  templateUrl: './page-course.component.html',
})
export class PageCourseComponent  implements OnInit{
  public home: MenuItem = {icon: 'pi pi-home', routerLink: '/'};
  @Input()  itemsBreadCrumbs: Array<MenuItem>;
  public card: any
  public photos: any[];
  public cards: any[];
  @Input() selectCourse: any;
  @Input() firstLesson: any;
  @Input() lessons: any[];
  /* public boxTools: BoxToolsServices,*/
  public selectStep: any;
  public showSpinner: boolean = false;
  public showVideoWatch: boolean = false;
  public selectLesson: any;
  public showCourseViewer: boolean = true;
  public statusPlay: boolean = false;
  public showBreadChumbs : boolean = true;
  constructor(public cd:CdServices,public dataPage: PageCoursesServices, private router: Router, private route: ActivatedRoute, public boxServices: BoxServices,) {
  }

  ngOnInit() {
    window.scrollTo(0,0)
    this.route.params.subscribe(params => {
      console.log(params)
      this.selectCourse = this.dataPage.findCard(params.slug);
      // this.cd.showScrollPreview = false;
      // this.cd. showFilterCardMenu= false;
      console.log(this.selectCourse,'this.selectCourse')
      this.initCourse();
      this.initBreadcrumbs()

      /* if(this.selectCourse){
         this.initBox(this.selectCourse)
         this.selectCourses = this.dataPage.getCards();
       }*/
    });
  }
initBreadcrumbs(){
    this.itemsBreadCrumbs =[
      {

        label: 'Courses',
        routerLinkActiveOptions: 'active',
        command: () => {
          this.router.navigate([
            'digital-art-courses/'
          ]);
          this.closeLesson();
          this.closeCourse();
        }
      },
      {

        label: this.selectCourse.name,
        command: () => {
          this.router.navigate([
            'digital-art-courses/',
            this.selectCourse.id
          ]);
          this.closeLesson();

        }
      },
      /*{

        label: this.selectLesson?.name,
        command: () => {
          this.router.navigate([
            'digital-art-courses/',
            this.selectCourse.id
          ], {
            queryParams: {
              lesson: this.selectLesson.id,
              duration: this.selectLesson.duration
            }
          });
        }
      }*/
    ];
  this.showBreadChumbs = true;
}
  public initCourse() {
    if (this.selectCourse) {
      this.lessons = this.selectCourse.array;
      this.firstLesson = this.selectCourse.array[0];
      this.showResources = true;
      reInit(()=>{
        this.showResources = false;
      })
    }
  }


  public setSelectLesson(lessonsItem: any) {
    console.log(lessonsItem, 'setSelectLesson')
    this.initBreadcrumbs()
    this.showSpinner = true;
    this.selectLesson = lessonsItem;
    lessonsItem.statusPlay = true;
    this.showVideoWatch = false;
    this.router.navigate([
      'digital-art-courses/',
      this.selectCourse.id
    ], {
      queryParams: {
        lesson: lessonsItem.id,
        duration: lessonsItem.duration
      }
    });

    if (lessonsItem.nameBox && lessonsItem.pathBox) {
      this.boxServices.findBoxFileByNameAndPath(lessonsItem.nameBox, lessonsItem.pathBox, (boxResult: any) => {
        lessonsItem.srcVideo = boxResult.srcOriginal;
        this.selectLesson.srcVideo = boxResult.srcOriginal;
        this.showVideoWatch = true;

      });
    }

  }

  public setSelectStep(stepItem: any) {
    console.log(stepItem, 'stepDuration');
    this.selectStep = stepItem;
    this.showVideoWatch = false;
    /*    reInit(() => {
          this.showVideoWatch = true;

        });*/
    console.log(this.selectLesson, 'this.selectLesson');
  }

  closeCourse() {
    this.showCourseViewer = false;
    this.statusPlay = false;
  }

  getServerUrl(lessonsItem) {
    let serverUrl = lessonsItem.srcVideo = 'https://server-2.artas.org.ua/' + lessonsItem.pathBox + lessonsItem.nameBox
    console.log(serverUrl, 'serverUrl')
  }

  closeLesson() {
    this.showVideoWatch = false;
    this.statusPlay = false;
    this.showSpinner = false;
    this.selectLesson = null;
  }

  /* public initBox(card){
     // boxId = boxId.replace(/\s/g, "")

     let boxId:any = this.boxFileName(card)
     this.boxServices.findCardBoxById(boxId,
       (responseBox: any): void => {
         // this.box = responseBox;
         this.photos =responseBox.photos
         console.log(responseBox,'responseBox')
       });
   }*/

  /* boxFileName(model: any) {
     let boxId = model.id + '_' + model.createAt;
     return boxId.replace(/\s/g, "");
   }*/

  /*setSelect(card: any) {
    this.router.navigate(['zakazat-sharj/',card.id]);
    this.selectCourse = card;
  }*/
 public showResources: any =false;
  showCoursesList: any = true;
  goToCourse(card: any) {
    this.router.navigate(['digital-art-courses/',card.id]);
  }
  goToLesson(card: any) {
    this.router.navigate(['digital-art-courses/',card.id]);
  }
}
