<div class="flex justify-center items-center flex-col "
>
  <div
  >
  <ng-container *ngFor="let  packItemPallete  of images;">
    <div class="relative m-3 inline-block transition-transform transform hover:scale-110">
      <p-overlayPanel #op>
        <img [src]="packItemPallete.srcPreview"
             class="w-64 h-64 shadow-lg"
             alt="product" />
      </p-overlayPanel>

      <p-overlayPanel #opDownload>
        <img [src]="packItemPallete.srcPreview"
             class="w-64 h-64 shadow-lg"
             alt="product" />
        <app-btn-download
          [srcImage]="packItemPallete.srcPreview"
          [disabled]="false"
        ></app-btn-download>
      </p-overlayPanel>

      <img [src]="packItemPallete.srcPreview"
           alt="upload_image"
           class="w-[75px] h-[75px] object-cover rounded cursor-pointer"
           (click)="packItemPallete.check = !packItemPallete.check "
      >

      <button #targetEl
        class=" text-sm absolute top-0 left-0 bg-green-500 text-white p-1 rounded-full transition-transform transform hover:scale-125"
      (click)=" op.toggle($event, targetEl)"
      >

        <i [class]="zoomIcon"></i>
      </button>
      <button
        class="text-sm absolute top-0 -right-2 bg-red-500 text-white p-1 rounded-full transition-transform transform hover:scale-125"
        (click)="opDownload.toggle($event, targetEl)">
        <i [class]="saveIcon"></i>
      </button>

    </div>
  </ng-container>
  </div>
</div>
<div *ngIf="images.length" class="flex justify-center items-center flex-col">
  <app-zip  [images]="images"></app-zip>
</div>
