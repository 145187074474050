import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {TabViewModule} from 'primeng/tabview';
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {BadgeModule} from "primeng/badge";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {ColorsImageComponent} from "../../colors-image/colors-image.component";
import {UploaderPhotoComponent} from "../admin/uploader-photo/uploader-photo.component";
import {SidebarComponent} from "../common/sidebar/sidebar.component";
import {CircleWithImageComponent} from "../circle-with-image/circle-with-image.component";
import {ShareImageComponent} from "../share-image/share-image.component";
import {CropRigionComponent} from "../crop-rigion/crop-rigion.component";
import {ImagesViewerGridSlidderComponent} from "../pages/images-viewer-grid-slidder/images-viewer-grid-slidder.component";
import {EyeDropperComponent} from "../eye-dropper/eye-dropper.component";
import {ImageMosaicComponent} from "../image-mosaic/image-mosaic.component";
import {GrayscaleComponent} from "../grayscale/grayscale.component";
import {CameraComponent} from "../pages/camera/camera.component";
import {BtnDownloadComponent} from "../btn-download/btn-download.component";
import {AlbumComponent} from "../album/album.component";
import {ZipComponent} from "../zip/zip.component";
import {BrowserCloseAlertComponent} from "../browser-close-alert/browser-close-alert.component";
import {PackPalettesComponent} from "../pack-palettes/pack-palettes.component";
import {deleteFromArray} from 'src/app/traits/array-tools';
import {
  deleteIcon,
  dotesHorizontalIcon,
  dotesVerticalIcon,
  uploadesIcon
} from 'src/app/traits/icons-tools';
import {dateNow} from 'src/app/traits/datetime-tools';
import {CircleWithColorComponent} from "../circle-with-color/circle-with-color.component";
import {ColorIroComponent} from "../color-iro/color-iro.component";
import {RectangleWithColorComponent} from "../rectangle-with-color/rectangle-with-color.component";
import {ColorHistoryComponent} from "../color-history/color-history.component";
import {ZoomImageComponent} from "../zoom-image/zoom-image.component";


@Component({
  selector: 'app-color-panel',
  standalone: true,
  imports: [
    TabViewModule,
    SidebarComponent,
    CircleWithImageComponent,
    ColorsImageComponent,
    UploaderPhotoComponent,
    NgIf,
    FormsModule,
    BadgeModule,
    ShareImageComponent,
    CropRigionComponent,
    ImagesViewerGridSlidderComponent,
    NgForOf,
    EyeDropperComponent,
    ImageMosaicComponent,
    GrayscaleComponent,
    ButtonModule,
    CameraComponent,
    BtnDownloadComponent,
    AlbumComponent,
    ZipComponent,
    BrowserCloseAlertComponent,
    PackPalettesComponent,
    CircleWithColorComponent,
    ColorIroComponent,
    RectangleWithColorComponent,
    ColorHistoryComponent,
    ZoomImageComponent,
  ],
  templateUrl: './color-panel.component.html',
})

export class ColorPanelComponent implements OnInit{
    ngOnInit(): void {
      this.reInitPaletteComponent()
    }
  @ViewChild('colorHistory') colorHistory: ElementRef<HTMLCanvasElement>;
  @Output() onColor = new EventEmitter<string>();
  public selectColor: string = '#b60101';
  public showEyeDropper: boolean  =true;
  public srcImage: string = 'assets/sketch.jpg';
  protected readonly deleteFromArray = deleteFromArray;
  protected readonly dateNow = dateNow;
  protected readonly deleteIcon = deleteIcon;
 public setColor(colorHex: string) {
   console.log(colorHex,'setColor')
    this.selectColor = colorHex;
    this.onColor.emit(colorHex)
  }

  public importImage(event: any, ) {
    const files = event.target.files;
    const base64Strings = [];
    const intervalTime = 1000;

    Array.from(files).forEach((file:any, index) => {
      setTimeout(() => {
        const reader = new FileReader();
        reader.onloadend = ()=> {
          const base64String:any = reader.result;
          base64Strings.push(base64String);
          this.srcImage = base64String;
          this.reInitEyeDropper();
          this.reInitPaletteComponent()
        };

        reader.readAsDataURL(file);
      }, intervalTime * index);
    });
  }
  public reInitEyeDropper() {
    this.showEyeDropper = false;
    setTimeout(() => {
      this.showEyeDropper = true;
    }, 50)
  }

  reInitPaletteComponent() {
    this.showPaletteComponent = false;
    setTimeout(() => {
      this.showPaletteComponent = true;
      setTimeout(() => {
        this.showPaletteComponent = false;
      },500)
    },500)

  }
  protected readonly uploadesIcon = uploadesIcon;
  imagePallete: string;
  showPaletteComponent: boolean =false;
}
