import {Component, ElementRef, OnDestroy, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {
  addIcon,
  clockIcon,
  editIcon
} from "../../../traits/icons-tools";
import {
  JsonPipe,
  NgFor,
  NgIf
} from "@angular/common";

import {ScrollPanelModule} from "primeng/scrollpanel";
import {SliderModule} from "primeng/slider";
import {FormsModule} from "@angular/forms";
import {secondsToMinutes, strTimeToSecond} from "../../../traits/datetime-tools";
import {getIndexArray} from "../../../traits/array-tools";
import {ZoomImageComponent} from "../../zoom-image/zoom-image.component";

@Component({
    selector: 'app-video-reader-course',
    templateUrl: './video-reader-course.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    ScrollPanelModule,
    SliderModule,
    JsonPipe,
    FormsModule,
    ZoomImageComponent,
  ],
    encapsulation: ViewEncapsulation.None,
})
export class VideoReaderCourseComponent implements OnInit, OnDestroy {
    // @ts-ignore
    @ViewChild('target', {static: true}) target: ElementRef;

    public mode: any = 'play';
    // @ts-ignore
    @Input() options: any = {
        autoplay: true, controls: true,
    };
    @Input() videoItem: any;
    @Input() selectSpeed: any = 2;
    @Input() selectStepItem: any;
    @Input() videoList: any;
    @Input() selectVideo: any;
    @Input() currentTime: any = 60;
    @Input() timeTotal: any = 0;
    @Input() timeUpdate: any = 0;
    @Input() indexVideo: any = 0;
    @Input() timeStepQuantity: any = 0;
    @Input() timeStepCurrentNumber: any = 0;
    public timeModel: any = {name:'',desc:''};
    public timeStatusFirst: any = true;
    public timeStatusLast: any = false;
    @Input() lessonsQuantity: any = 0;
    @Input() lessonCurrentNumber: any = 1;
    public lessonStatusFirst: any = true;
    public lessonStatusLast: any = false;
    @Input() nextVideo: any;
    @Input() prevVideo: any;
    @Input() timeStep: any = 15;
    @Input() selectTime: any;
    @Input() videoStart: any;
    @Input() videoStop: any;
    @Input() videoFinish: any = false;
    @Input() listFinish: any = false;
    public duration: any;
    public soundEnable: any = false;

    public indexTime: any = 0;
    public showFloatTimesBlock: any = true;
    public showMoreTimeCodes: any = false;

    @Input() nextTime: any;
    @Input() prevTime: any;
    @Input() videosList: any = [];
    @Input() preload: any = true;
    public showStepsList: any = false;
    public showVideosList: any = false;
    @Input() timeList: any = [];
    @Input() autoplay: any = true;
    @Input() controls: any = true;
    @Output() onAddStep = new EventEmitter();
    @Output() onNextVideo = new EventEmitter();
    @Output() onShowList = new EventEmitter();
    @Output() onSave = new EventEmitter();
    // @ts-ignore
    public player: videojs.Player;
  public showPainterTest: boolean;
  public array: any;
  public selectCourse: any;
  public lastView: any;

    constructor(
    ) {
    }


    public ngOnInit() {
      // this.timeList = sortNumberAsc(this.timeList, 'duration');

        // this.initTime();
        this.timeStepQuantity = this.timeList.length;
        this.lessonsQuantity = this.videosList.length;
        this.lessonCurrentNumberBtn();
        // this.initLastViewFromStorage();
        // this.timeList ? this.selectTime = this.timeList[0] : null;
        this.initVideoJs();
        this.play();

    }
ngAfterViewInit(){
  /* $('#patreon-desc')
    .typist({speed: 20})
    .typistAdd('text typist');*/
}
    public initTime() {
        let lastView = this.lastView;
        this.selectSpeed = lastView.speed;
        this.timeUpdate = 0;
        lastView.second;
        this.selectTime = lastView.stepTime;
    }

    public initVideoJs() {
        this.options = {
            autoplay: this.autoplay,
            controls: this.controls,
            controlBar: {
                fullscreenToggle: false,
                CurrentTimeDisplay: true,
                TimeDivider: true,
                DurationDisplay: true,
                remainingTimeDisplay: {displayNegative: true}
            },
            // playbackRates: [0.5, 1, 1.5, 2, 3, 4, 5],
            /* "snapshotname": "test2-[time].png",
             "plugins": {
               "snapshot": {
                 "serverurl": "https://api.electroteque.org/save",
                 "tokenurl": "https://api.electroteque.org/token"
               }
             },*/
        };
        // @ts-ignore
        this.player = videojs(this.target.nativeElement, this.options, () => {
            // console.log('onPlayerReady', this);
        });
        this.player.src(
            {
                type: `video/mp4`,
                src: `${this.videoItem.srcVideo}`,
            }
        );
        // this.videoItem.check = true;
        let that = this;

        function changeVideoTime() {
            // @ts-ignore
            let myPlayer = this;

            myPlayer.on('timeupdate', () => {

                // @ts-ignore
                let duration = Math.ceil(myPlayer.duration());
                // @ts-ignore
                let currentTime = Math.ceil(myPlayer.currentTime());
                that.timeTotal = duration;
                that.timeUpdate = currentTime;
                that.updateTimeTrigger();
                that.timeStepCurrentNumberBtn();
            });
        }

        this.player.ready(changeVideoTime);

        this.preload = false;
    }


    public replay(currentTime: any = 0) {
        this.timeUpdate = currentTime;
        this.play();
        this.updateLastCourseInfo();

        // this.saveLastViewToStorage();

    }


    public soundOnBtn() {
        this.soundEnable = true;
        this.initVoice();
    }

    public soundOffBtn() {
        this.soundEnable = false;
        // this.speakVoiceStop();
    }

    public pictureInPictureBtn() {
        this.player.requestPictureInPicture();
    }

    public speedBtn(speed: any = 2) {
        this.selectSpeed = speed;
        this.player.playbackRate(this.selectSpeed);
        this.saveLastViewToStorage();
    }

    public showPainterBtn() {
        this.showPainterTest = true;
        this.pause();
    }

    public showInfoPanelBtn() {
        this.showInfoPanel = true;
    }

    public hideInfoPanelBtn() {
        this.showInfoPanel = false;
    }

    public addFavoriteBtn(courseItem: any) {
        courseItem.favorite = true;
    }

    public deleteFavoriteBtn(courseItem: any) {
        courseItem.favorite = false;

    }

    public findPreviewVideo(videosList: any) {
        this.indexVideo = getIndexArray(this.selectVideo, videosList);
        this.prevVideo = videosList[this.indexVideo - 1];

    }

    public findNextVideo(videosList: any) {
        this.indexVideo = getIndexArray(this.selectVideo, videosList);
        this.nextVideo = videosList[this.indexVideo + 1];
    }


    public prevVideoBtn(videosList: any) {
        this.findPreviewVideo(videosList);
      // this.preloadGlobal(this.prevVideo);
          this.selectVideo = this.prevVideo;
          this.onNextVideo.emit(this.selectVideo);
        // this.alert.customText('Open preview video - ' + this.prevVideo.name, () => {
        //
        //
        //
        // });
    }


    public nextVideoBtn(videosList: any) {
        this.lessonStatusFirstBtn();
        this.findNextVideo(videosList);
          this.selectVideo = this.nextVideo;
          this.onNextVideo.emit(this.selectVideo);
        // this.alert.customText('Open next video - ' + this.nextVideo.name, () => {
        //     this.preloadGlobal(this.nextVideo);

        //
        //
        // });

    }


    public timeStepCurrentNumberBtn() {
        this.timeStepCurrentNumber = getIndexArray(this.selectTime, this.timeList) + 1;
    }

    public lessonCurrentNumberBtn() {
        this.lessonCurrentNumber = getIndexArray(this.selectVideo, this.videosList) + 1;
    }

    public timeStatusFirstBtn() {
        if (getIndexArray(this.selectTime, this.timeList) === 0) {
            this.timeStatusFirst = true;
        } else {
            this.timeStatusFirst = false;
        }
    }

    public lessonStatusFirstBtn() {
        if (getIndexArray(this.selectVideo, this.videosList) === 0) {
            this.lessonStatusFirst = true;
        } else {
            this.lessonStatusFirst = false;
        }
    }

    public timeStatusLastBtn() {
        if (getIndexArray(this.selectTime, this.timeList) === this.timeList.length - 1) {
            this.timeStatusLast = true;
        } else {
            this.timeStatusLast = false;
        }
    }

    public prevTimeBtn() {
        this.indexTime = getIndexArray(this.selectTime, this.timeList);
        this.nextTime = this.timeList[this.indexTime - 1];
        this.selectTime = this.nextTime;
        this.timeStatusFirstBtn();
        this.timeStatusLastBtn();
        this.timeStepCurrentNumberBtn();
      this.replay(strTimeToSecond(this.nextTime.duration));
        if (this.indexTime === 0) {
            this.selectTime = this.timeList[this.indexTime];
        }
        // this.soundOffBtn();
        // this.updateLastCourseInfo();
    }

    public nextTimeBtn() {
        this.indexTime = getIndexArray(this.selectTime, this.timeList);
        this.nextTime = this.timeList[this.indexTime + 1];
        this.selectTime = this.nextTime;
        this.timeStatusFirstBtn();
        this.timeStatusLastBtn();
        this.timeStepCurrentNumberBtn();

        this.replay(strTimeToSecond(this.nextTime.duration));
        if (this.indexTime === this.timeList.length) {
            this.indexTime = 0;
            this.selectTime = this.timeList[this.indexTime];
        }
        // this.soundOffBtn();
        // this.updateLastCourseInfo();
    }

    public updateLastCourseInfo() {
        // this.saveLastViewToStorage();
        // this.initLastCourseInfo(this);
    }

    public play() {
        this.player.currentTime(this.timeUpdate);
        this.player.play();
        this.mode = 'play';
    }

    public pause() {
        this.player.pause();
        this.mode = 'pause';
        this.updateLastCourseInfo();
    }

    public continue() {
        // this.replay(50);
    }

    public initVoice() {
       /* if ((this.soundEnable && this.selectTime.desc)) {
            // this.speakVoicePlay(this.selectTime.desc);
        }*/
    }

    public selectTimeBtn(timeItem: any) {
    let secondTimeStep =    strTimeToSecond(timeItem.duration)
        this.replay(secondTimeStep);
        this.selectTime = timeItem;
        // this.updateLastCourseInfo();
    }


  public saveLastViewToStorage() {
      /*  let courseId: any = this.selectCourse.id;
        let stepTimeId: any = this.selectTime.id;
        let lessonId: any = this.selectVideo.id;
        let timeUpdate: any = this.timeUpdate;
        setStorageData('coursesLastView', {
            courseId: courseId,
            lessonId: lessonId,
            stepTimeId: stepTimeId,
            second: timeUpdate,
            speed: this.selectSpeed,
        });*/
    }

    public updateTimeTrigger() {
        /*if ((this.timeUpdate > 0 && this.timeUpdate < this.timeStep / 2)) {
            this.videoStart = {
                name: 'videoStart'
            };
        }*/
       if (this.timeUpdate == this.timeTotal && this.lessonCurrentNumber == this.lessonsQuantity) {
           this.listFinish = true;
       }
       if (this.timeUpdate == this.timeTotal) {
           this.videoFinish = true;
       }
       /* if (this.timeUpdate == this.timeTotal - (this.timeStep * 2)) {
            this.videoFinish = {
                name: 'video-finish'
            };
        }*/
       /* if (this.timeUpdate == this.timeTotal) {
            this.videoStop = {
                name: 'video-stop'
            };
        }*/
       /* let flagStep = this.timeList.find((step: any) => {
            let stepTime = Math.ceil(step.duration);
            if (stepTime === this.timeUpdate) {
                return step;
            }
        });*/
       /* if (flagStep?.duration) {
            this.selectTime = flagStep;
            // this.soundOffBtn();
            // this.initVoice();
        }*/
    }
    deleteTimeCurrentBtn(){
        // this.array.delete(this.selectTime,this.timeList);
    }
    addTimeCurrentBtn() {
       /* let model = modelTimeCode();
       let timeModel = cloneModel(this.timeModel)
        model.name = timeModel.name;
        model.desc = timeModel.desc;
        model.second = this.timeUpdate;
        model.duration = this.timeUpdate;
        this.timeList.push(model);
        this.timeStepQuantity = this.timeList.length;
        this.timeModel.name ='';
        this.timeModel.desc ='';
        this.timeStatusFirstBtn();
        this.timeStatusLastBtn();
        this.timeStepCurrentNumberBtn();*/
    }

    public stop(){
      // if (this.player) {
      //   this.player.dispose();
      //   this.player.src('');
      //   this.mode = 'stop';
      //   this.player.currentTime(0);
      // }
    }
    ngOnDestroy() {
      this.videoFinish = false;
      this.listFinish = false;

        // this.updateLastCourseInfo();
// this.stop();
    }

  protected readonly addIcon = addIcon;
  showInfoPanel: boolean = true;

  secondsToMinutes(timeUpdate: any) {
    return   secondsToMinutes(timeUpdate)
  }

  protected readonly clockIcon = clockIcon;
  showEditAll: any;
  protected readonly editIcon = editIcon;
  selectModel: any;
  selectModels: any;
  showExpandModel: boolean;
}

/*public fullScreenBtn() {
  this.player.requestFullscreen();
}*/
// @ts-ignore
// let percentage = Math.floor((100 / duration) * this.currentTime());
// console.log(percentage, 'this.percentage()');
// console.log(currentTime, 'this.currentTime()');
// console.log(duration, 'this.duration()');

// Update the progress bar's value
// progressBar.value = percentage;

/*
  public save() {
    // @ts-ignore

    var canvas = document.getElementById('output').firstChild;
    // console.log(canvas);
    // @ts-ignore
    let base64 = canvas.toDataURL('image/jpg');
    // console.log(base64);

  }
*/
/*  public shoot() {

     var videoId = 'video';
     var scaleFactor = 0.25;
     // @ts-ignore

     var snapshots = [];

     // @ts-ignore

     function capture(video, scaleFactor) {
       if (scaleFactor == null) {
         scaleFactor = 1;
       }
       var w = video.videoWidth * scaleFactor;
       var h = video.videoHeight * scaleFactor;
       var canvas = document.createElement('canvas');
       canvas.width = w;
       canvas.height = h;
       var ctx = canvas.getContext('2d');
       // @ts-ignore
       ctx.drawImage(video, 0, 0, w, h);
       return canvas;
     }

     // var video = document.getElementById(videoId);
     var video = this.target.nativeElement;
     var output = document.getElementById('output');
     var canvas = capture(video, scaleFactor);
     canvas.onclick = function() {

       // @ts-ignore

       window.open(this.toDataURL('image/jpg'));
     };
     snapshots.unshift(canvas);

     // @ts-ignore

     output.innerHTML = '';
     for (var i = 0; i < 4; i++) {
       // @ts-ignore

       output.appendChild(snapshots[i]);

     }
     (function() {
       var captureit = document.getElementById('cit');
       // @ts-ignore
       captureit.click();
     })();
   }*/
