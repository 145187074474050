import {
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';
import {TabViewModule} from 'primeng/tabview';
import {SidebarComponent} from '../../../components/common/sidebar/sidebar.component';
import {CommonHomeComponent} from '../../common-home/common-home.component';
import {CircleWithImageComponent} from "../../../components/circle-with-image/circle-with-image.component";
import {ColorsImageComponent} from "../../../colors-image/colors-image.component";
import {UploaderPhotoComponent} from "../../../components/admin/uploader-photo/uploader-photo.component";
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {BadgeModule} from "primeng/badge";
import {ShareImageComponent} from "../../../components/share-image/share-image.component";
import {CropRigionComponent} from "../../../components/crop-rigion/crop-rigion.component";
import {ImagesViewerGridSlidderComponent} from "../../../components/pages/images-viewer-grid-slidder/images-viewer-grid-slidder.component";
import {EyeDropperComponent} from "../../../components/eye-dropper/eye-dropper.component";
import {ImageMosaicComponent} from "../../../components/image-mosaic/image-mosaic.component";
import {GrayscaleComponent} from "../../../components/grayscale/grayscale.component";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {HeaderComponent} from "../../../components/pages/header/header.component";
import {NavbarTopComponent} from "../../../components/pages/navbar-top/navbar-top.component";
import {CameraComponent} from "../../../components/pages/camera/camera.component";
import {PreloadComponent} from "../../../components/pages/preload/preload.component";
import {ListDownloadsComponent} from "../../../components/list-downloads/list-downloads.component";
import {BtnDownloadComponent} from "../../../components/btn-download/btn-download.component";
import {AlbumComponent} from "../../../components/album/album.component";
import {ZipComponent} from "../../../components/zip/zip.component";
import {BrowserCloseAlertComponent} from "../../../components/browser-close-alert/browser-close-alert.component";
import {PackPalettesComponent} from "../../../components/pack-palettes/pack-palettes.component";
import {PalleteGeneratorImageComponent} from "../../../components/pallete-generator-image/pallete-generator-image.component";
import {PalleteGeneratorColorComponent} from "../../../components/pallete-generator-color/pallete-generator-color.component";
import {SketchConverterComponent} from "../../../components/sketch-converter/sketch-converter.component";
import {PageAiBotsServices} from "../../../services/pages/page-ai-bots.services";
import {AccordionModule} from "primeng/accordion";
import {
  ScrollPanel,
  ScrollPanelModule
} from "primeng/scrollpanel";
import {
    deleteIcon,
    dotesVerticalIcon,
    lockCloseIcon,
    lockOpenIcon,
    plusIcon
} from "../../../traits/icons-tools";
import {CdServices} from "../../../services/common/cd.services";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {PageUpscalerServices} from "../../../services/pages/page-upscaler.services";
import {PageTextToImageServices} from "../../../services/pages/page-text-to-image.services";
import {TextToImageComponent} from "../../../components/text-to-image/text-to-image.component";
import {PageStableDiffusion3Services} from "../../../services/pages/page-stable-diffusion-3.services";
interface BotItem  {
  name: string,
  desc: string,
  srcPreview: string,
  srcVideo: string,
  urlTelegram: string,
  photos:[],
  opportunities:[],
  free:boolean,
}
@Component({
  selector: 'page-stable-diffusion-3',
  standalone: true,
  imports: [
    TabViewModule,
    SidebarComponent,
    CommonHomeComponent,
    CircleWithImageComponent,
    ColorsImageComponent,
    UploaderPhotoComponent,
    NgIf,
    FormsModule,
    BadgeModule,
    ShareImageComponent,
    CropRigionComponent,
    ImagesViewerGridSlidderComponent,
    NgForOf,
    EyeDropperComponent,
    ImageMosaicComponent,
    GrayscaleComponent,
    ButtonModule,
    HeaderComponent,
    NavbarTopComponent,
    CameraComponent,
    PreloadComponent,
    ListDownloadsComponent,
    BtnDownloadComponent,
    AlbumComponent,
    ZipComponent,
    BrowserCloseAlertComponent,
    PackPalettesComponent,
    PalleteGeneratorImageComponent,
    PalleteGeneratorColorComponent,
    SketchConverterComponent,
    AccordionModule,
    ScrollPanelModule,
    OverlayPanelModule,
    TextToImageComponent,
  ],
  templateUrl: './page-stable-diffusion-3.component.html',
})
export class PageStableDiffusion3Component {
  constructor(public cd:CdServices,
              public dataPage: PageStableDiffusion3Services
  ) {
  }

  ngOnInit() {
  }

}
