<!--<div id="canvasWrapper" [style.zoom]="scaleCanvas">
  <canvas #canvas   width="500" height="500"></canvas>
</div>-->
<div id="canvasWrapper" *ngIf="srcResult" [style.zoom]="scaleCanvas"  >
  <img [src]="srcResult"/>
</div>
<app-btn-download *ngIf="showDownload"
  [srcImage]="srcResult"
  [disabled]="false"
></app-btn-download>
