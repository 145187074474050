import {
  inject,
  Injectable
} from '@angular/core';
import {ServerSiteServices} from '../servers/server-site.services';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";
import {StaticCoursesServices} from "../static/static-courses.services";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageCoursesPlansServices {
  public SERVER_NAME: string = 'courses-plans';
  public DOMAIN: string = 'art-club.artas.org.ua/courses-plans';
  public route: string = 'courses';
  public fromServer: boolean = true;
  public imageHeader: string = 'assets/header-courses.jpg';
  public premium: object = {
    name: 'Курси цифрового живопису',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'Курси цифрового живопису',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: 'Курси цифрового живопису та малюнку: персонажі, фони, анатомія та інші етапи для художників',
    description: 'Курси цифрового живопису та малюнку допоможуть вам оволодіти навичками зображення персонажів, тварин, людей, анатомії та фонів. Вивчайте основи цифрового мистецтва та створюйте шедеври за допомогою професійних інструментів та технік.',
    h1: 'Курси цифрового живопису та малюнку для початківців і професіоналів',
    h2: 'Курси цифрового живопису: створення персонажів для книг і ігор',
    h3: 'Як намалювати фони та середовища на курсах цифрового мистецтва',
    h4: 'Анатомія для художників: вивчення малювання людей і тварин',
    h5: 'Онлайн курси живопису для створення унікальних композицій',

    keywords: [
      'курси цифрового живопису',
      'курси з малюнку',
      'персонажі книг',
      'фони',
      'малювання людей',
      'анатомія для художників',
      'цифрове мистецтво',
      'живопис для початківців',
      'малювання тварин',
      'онлайн курси живопису',
      'курси для художників'
    ],
    text: 'Курси цифрового живопису сьогодні набувають все більшої популярності серед художників, як початківців, так і професіоналів. Це сучасний напрямок у мистецтві, який дозволяє використовувати цифрові інструменти для створення неймовірних ілюстрацій, персонажів, фонів, а також реалістичних або фантастичних композицій. Якщо ви прагнете вдосконалити свої навички або ж зробити перші кроки у світі цифрового мистецтва, курси цифрового живопису стануть для вас чудовою можливістю навчитися новому.\n' +
      '\n' +
      'Курси цифрового живопису: для кого вони підходять?\n' +
      '\n' +
      'Цифровий живопис відкриває безліч можливостей для художників будь-якого рівня. Курси підходять як для тих, хто тільки починає свій шлях у мистецтві, так і для досвідчених фахівців, які хочуть освоїти нові техніки або інструменти. На курсах ви зможете вивчити основи роботи з графічними програмами, такими як Adobe Photoshop, Corel Painter або Procreate, а також розробити свій власний стиль малювання.\n' +
      '\n' +
      'Створення персонажів для книг та ігор\n' +
      '\n' +
      'Однією з основних тем на курсах цифрового живопису є створення персонажів. Художники вивчають, як розробляти унікальних героїв для книг, коміксів або відеоігор. На курсах викладають принципи дизайну персонажів, включаючи роботу з анатомією, виразами обличчя, костюмами та кольоровими схемами. Це дозволяє створювати персонажів, які виглядають реалістично або стилізовано залежно від вимог проекту.\n' +
      '\n' +
      'Як малювати фони та середовища\n' +
      '\n' +
      'Окрім персонажів, курси цифрового живопису включають вивчення технік малювання фонів та середовищ. Це особливо важливо для тих, хто хоче працювати в анімації, відеоіграх або ілюстрації книг. Малювання середовища вимагає розуміння перспективи, світла, тіні та кольору. На курсах викладачі допоможуть вам зрозуміти, як створювати реалістичні або фантастичні ландшафти, міські сцени чи інтер\'єри, які будуть гармонійно поєднуватися з вашими персонажами.\n' +
      '\n' +
      'Анатомія для художників: як правильно малювати людей і тварин\n' +
      '\n' +
      'Один з важливих аспектів цифрового живопису – це знання анатомії. Вивчення пропорцій тіла людини та тварин дозволяє створювати реалістичні та правдоподібні ілюстрації. На курсах ви навчитеся основам анатомії для художників, розглянете, як правильно передавати рухи, динаміку тіла та міміку. Це знання дозволить вам зображати різні пози та ракурси, роблячи ваші роботи більш живими та виразними.\n' +
      '\n' +
      'Робота з кольором і світлом\n' +
      '\n' +
      'Опанування цифрового живопису також вимагає розуміння роботи з кольором та світлом. На курсах ви дізнаєтеся, як створювати гармонійні кольорові композиції, використовувати світло та тіні для додавання об\'єму та глибини вашим малюнкам. Це знання допоможе вам робити ілюстрації більш професійними та привабливими для глядача.\n' +
      '\n' +
      'Онлайн курси: зручність та доступність\n' +
      '\n' +
      'Сучасні курси цифрового живопису часто проводяться в онлайн-форматі, що дозволяє вам навчатися з будь-якої точки світу. Це зручно, особливо для тих, хто має насичений графік або живе у віддалених місцях. Ви можете вивчати матеріали у власному темпі, виконувати домашні завдання та отримувати зворотний зв\'язок від викладачів, які допоможуть вам покращити ваші роботи.\n' +
      '\n' +
      'Розвиток власного стилю\n' +
      '\n' +
      'Окрім технічних навичок, курси цифрового живопису допомагають розвивати індивідуальний стиль художника. Ви навчитеся експериментувати з різними інструментами та техніками, щоб створювати унікальні роботи, які відображатимуть ваш творчий підхід. Це особливо важливо для тих, хто прагне працювати в індустрії мистецтва, оскільки наявність впізнаваного стилю робить художника більш конкурентоспроможним.\n' +
      '\n' +
      'Переваги цифрового живопису\n' +
      '\n' +
      'Цифровий живопис має низку переваг порівняно з традиційними техніками. По-перше, він дозволяє швидко виправляти помилки, що значно полегшує процес створення ілюстрацій. По-друге, цифрові інструменти надають доступ до безмежної палітри кольорів та текстур, що дає можливість створювати різноманітні ефекти та експериментувати з формами. Нарешті, цифровий живопис не потребує матеріальних витрат на фарби, пензлі або полотно, що робить його доступним для кожного.\n' +
      'Курси цифрового живопису – це чудова можливість для кожного, хто хоче оволодіти сучасними техніками малювання та створювати власні унікальні роботи. Незалежно від вашого рівня підготовки, ви зможете навчитися основам цифрового мистецтва, розвинути навички створення персонажів, фонів та анатомічних зображень, а також відчути всі переваги роботи в цифровому середовищі. Курси допоможуть вам відкрити нові горизонти у творчості та стати професійним художником у сфері цифрового мистецтва.',
    textEndPage: 'Курси цифрового живопису – це унікальна можливість для кожного художника розвинути свої навички та освоїти нові техніки малювання персонажів, фонів, тварин та людей. Наші курси з малюнку спрямовані на те, щоб навчити вас основам цифрового мистецтва, включаючи анатомію, створення композицій та роботу з різними інструментами.\n' +
      '\n' +
      'Курси цифрового живопису пропонують структуровану програму, яка включає малювання персонажів для книг, ігор та мультфільмів, а також детальне вивчення фонів та середовищ. Наші інструктори допоможуть вам розкрити свій потенціал та навчитися передавати емоції через зображення.\n' +
      '\n' +
      'На курсах ви також дізнаєтесь, як правильно вивчати анатомію для художників, що допоможе вам краще зображати людей і тварин. Освоївши ці навички, ви зможете створювати реалістичні ілюстрації або фантастичні світи.\n' +
      '\n' +
      'Курси з малюнку підійдуть як для початківців, так і для досвідчених художників, які прагнуть підвищити свій рівень. Ми пропонуємо онлайн формат навчання, що дозволить вам вивчати цифровий живопис у зручний для вас час.\n' +
      '\n' +
      'Опануйте мистецтво цифрового живопису разом з нами і створюйте унікальні та виразні роботи!',
  };
  public site: any

  constructor(public server: ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  public getSite(callback: any = null) {
    if (this.fromServer) {
      return this.getSiteFromServer(callback);
    } else {
      return this.getSiteStatic(callback);
    }

  }

  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }

  public saveSite(site: object) {
    if (this.fromServer) {

      this.site = site
      // this.downloadSiteBtn(this.site);
      // let server: any = inject(ServerSiteServices)
      this.server.saveSite(this.DOMAIN, this.site);
    }
  }

  getSiteStatic(callback) {
    this.site = inject(StaticCoursesServices).site;
    callback && callback(this.site)
    return this.site;


  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer(callback: any = null) {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite: any): void => {
      this.site = responseSite;
      callback && callback(responseSite)
    });
    return this.site;
  }

  getCards() {
    if (this.fromServer) {

    } else {
      return this.site.organizer.messenges;
    }
  }

  findCard(slug: any) {
    let cards = this.getCards();
    let findCard = cards.filter((itemProduct: any) => {
      if (itemProduct.id === slug) {
        return itemProduct;
      }
    });
    console.log(findCard)

    return findCard[0];
  }
}
