import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';

import {dateNow} from "../../traits/datetime-tools";
import {
  brightenGradient,
  complementaryArray,
  getAnalogousHexColors,
  splitArray,
  tetradicArray,
  triadicArray
} from "../../services/common/color-tools";

@Component({
  selector: 'app-circle-with-harmony',
  templateUrl: './circle-with-harmony.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    JsonPipe
  ]
})
export class CircleWithHarmonyComponent implements AfterViewInit{
  @ViewChild('canvas', {static: true}) canvas: ElementRef<HTMLCanvasElement>;
  @Input() parentColor: string = '#b60101';
  @Input() nameHarmony: string = 'tetradic';

  @Input() scaleCanvas: string = '75%';
  @Input() textSize: number = 12;
  @Output() onChange = new EventEmitter<string>();
  @Output() onGetPallete = new EventEmitter<string>();
  @Output() colorClicked = new EventEmitter<string>();
  @Output() imageSaved = new EventEmitter<void>();
  ngAfterViewInit() {
    this.init();
  }

  init() {
    this.drawCircle();
  }
  drawCircle() {
    let canvas: any = this.canvas.nativeElement;
    const ctx: any = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    this.drawLogo(ctx, 12, canvas.height - 12);
    this.drawName(this.nameHarmony,ctx, centerX-12, 40);
    let array:any[]
    switch (this.nameHarmony) {
      case 'tetradic':
        array =  tetradicArray(this.parentColor);
        break;
        case 'triadic':
        array =  triadicArray(this.parentColor);
        break;
        case 'split':
        array =  splitArray(this.parentColor);
        break;
      case 'complementary':
        array =  complementaryArray(this.parentColor);
        break;
        case 'monochromatic':
        array =  brightenGradient(this.parentColor,6);
        break;
        case 'analogous':
        array =  getAnalogousHexColors(this.parentColor,30,5);
          console.log(array)
        break;
    }
    this.drawRoundArray(ctx, centerX, centerY, array,60,array.length,50);

    this.drawColorCenter(ctx, centerX, centerY);

    const image: string = canvas.toDataURL('image/png');
    this.onGetPallete.emit(image);
  }
  drawLogo(ctx: CanvasRenderingContext2D, centerX: number, centerY: number) {
    ctx.font = `${12}px Arial`;
    ctx.fillStyle = 'white'//this.colors[0];
    // ctx.globalCompositeOperation = 'source-over'
    ctx.fillText('art-club.info', centerX, centerY)


  }
  drawName(name:string,ctx: CanvasRenderingContext2D, centerX: number, centerY: number) {
    ctx.font = `${16}px Arial`;
    ctx.fillStyle = 'white'//this.colors[0];
    // ctx.globalCompositeOperation = 'source-over'
    ctx.fillText(name, centerX, centerY)


  }
  drawColorCenter(ctx: CanvasRenderingContext2D, centerX: number, centerY: number,callback:any = null) {
    var radius = 25;
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    ctx.fillStyle = this.parentColor;
    ctx.fill();
      callback &&callback()
  }
  drawRoundArray(ctx: CanvasRenderingContext2D,
               centerX: number,
               centerY: number,
               array:string[],
               radiusSaturate: number = 200,
               numSmallCircles:number =15,
               radius:number =10

  ) {
    // let numSmallCircles =array.length;
    for (let i = 0; i < numSmallCircles; i++) {
      const angle = i *   (2 * Math.PI) / numSmallCircles;
      const x = centerX + radiusSaturate * Math.cos(angle);
      const y = centerY + radiusSaturate * Math.sin(angle);
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI);
      ctx.fillStyle = array[i % numSmallCircles];
      ctx.fill();
    }
  }

  public getImage() {
    const canvas = this.canvas.nativeElement;
    const image: string = canvas.toDataURL('image/png');
    return image;
  }
  downloadImage() {
    const a = document.createElement('a');
    a.href = this.getImage();
    a.download = `art-club.info (pallete-${dateNow()}).png`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.imageSaved.emit();
  }
}
