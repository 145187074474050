<div
  [class]="bgColor"
  [style.background-image]="'url(' + bgImage + ')'"
  class="mt-[75px]  bg-[length:50px_50px] bg-opacity-10  bg-repeat flex justify-center items-center flex-col bg-gradient-to-br from-gray-800 to-gray-900 relative animate-gradient-x">
  <app-color-panel
    (onColor)="setColor($event)"
  ></app-color-panel>

  <div   class="flex space-x-4">
    <button
            class="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
            (click)="switchCircle()"
    >
      <i class="fa-regular fa-circle"></i>
    </button>
    <button
            class="bg-gradient-to-r from-purple-500 to-indigo-500  hover:from-zinc-600 hover:to-zinc-700 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse"
            (click)="switchVertical()">
      <i  [class]="dotesVerticalIcon"></i>
    </button>
    <button
             class="bg-gradient-to-r from-purple-500 to-indigo-500  hover:from-zinc-600 hover:to-zinc-700 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse"
             (click)="switchHorizontal()">
      <i  [class]="dotesHorizontalIcon"></i>
    </button>
  </div>
  <div class="m-3 p-3">
    <div class="w-full  p-3 rounded-lg shadow-lg">
      <app-circle-with-color
        #roundPallete
        *ngIf="(showCircleComponent && viewPallete === 'circle')"
        [parentColor]="selectColor"
        (onGetPallete)="imagePallete = $event;
                          "
      ></app-circle-with-color>

      <app-rectangle-with-color
        #horizontalPallete
        [orientation]="'hor'"
        [widthCanvas]="300"
        [heightCanvas]="135"
        *ngIf="(showHorizontalComponent && viewPallete === 'horizontal')"
        [parentColor]="selectColor"
        (onGetPallete)="imagePallete = $event;">
      </app-rectangle-with-color>
      <app-rectangle-with-color
        #verticalPallete
        [orientation]="'vert'"
        [widthCanvas]="120"
        [heightCanvas]="315"
        *ngIf="(showVerticalComponent && viewPallete === 'vertical')"
        [parentColor]="selectColor"
        (onGetPallete)="imagePallete = $event;">
      </app-rectangle-with-color>
    </div>
  </div>
  <div class="mt-1 mb-1">
    <app-btn-download
                      [srcImage]="imagePallete"
                      [disabled]="!imagePallete"
                      [showCoin]="true"
                      [textBtn]="'Download'"
    ></app-btn-download>
  </div>
</div>

