import { Injectable } from "@angular/core";
import {PageArtClubServices} from './page-art-club.services';
import {PageArtistToolsServices} from './page-artist-tools.services';
import {PageImagePalettesLibraryServices} from "./page-image-palettes-library.services";
import {PagePhotoshopPluginsServices} from "./page-photoshop-plugins.services";
import {Page3DReferencesServices} from "./page-3D-references.services";
import {PageRotateHeadServices} from "./page-rotate-head.services";
import {PageCharacterHeadsServices} from "./page-character-heads.services";
import {PageBackgroundsReferencesServices} from "./page-backgrounds-references.services";
import {PageCharacterReferencesServices} from "./page-characters-references.services";
import {PageCoursesServices} from "./page-courses.services";
import { PageArtasServices } from "./page-artas.services";
import {PageArtPartyServices} from "./page-art-party.services";
import {PagePosesNudeServices} from "./page-poses-nude.services";
import {PagePosesLightServices} from "./page-poses-light.services";
import {PageRotator360Services} from "./page-rotator-360.services";
import {PageCharacterMixerServices} from "./page-character-mixer.services";
import {PageAnatomyServices} from "./page-anatomy.services";
import {PageBrushesPhotoshopServices} from "./page-brushes-photoshop.services";
import { PageArtistNotesServices } from "./page-artist-notes.services";
import {PageStepByStepServices} from "./page-step-by-step.services";
import {PageTemplatesShargeServices} from "./page-templates-sharge.services";

@Injectable({
  providedIn: 'root',
})
export class DataSites {

  public getOpportunities(){
    return  this.list().map((itemData:any)=>{
      return   itemData.instance.possibilitiesPremium;
    })
  }
  public getSites(){
    return  this.list().map((itemData:any)=>{
      return   itemData.instance.getSite();
    })
  }
  constructor(
    public  PageArtClub: PageArtClubServices,
    public  PageImagePalettesLibrary: PageImagePalettesLibraryServices,
    public  PageArtSites: PageArtistToolsServices,
    public PagePhotoshopPlugins:PagePhotoshopPluginsServices,
    public Page3DReferences:Page3DReferencesServices,
    public PageRotateHead:PageRotateHeadServices,
    public PageCharacterHeads:PageCharacterHeadsServices,
    public PageBackgroundsReferences:PageBackgroundsReferencesServices,
    public PageCharacterReferences:PageCharacterReferencesServices,
    public PageCourses:PageCoursesServices,
    public  PageArtas: PageArtasServices,
    public  PageArtParty: PageArtPartyServices,
    public  PagePosesNude: PagePosesNudeServices,
    public  PagePosesLight: PagePosesLightServices,
    public  PageRotator360: PageRotator360Services,
    public  PageCharacterMixer: PageCharacterMixerServices,
    public  PageBrushesPhotoshop: PageBrushesPhotoshopServices,
    public  PageArtistNotes: PageArtistNotesServices,
    public  PageAnatomy: PageAnatomyServices,
    public  PageStepByStep: PageStepByStepServices,
    public  PageTemplatesSharge: PageTemplatesShargeServices,
  ) {
  }
 public list(){

  let instances:any = [
      {label:'PageArtClub',nameInstance:'PageArtClub',instance:{}},
      {label:'PageCourses',nameInstance:'PageCourses',instance:{}},
      {label:'PageImagePalettesLibrary',nameInstance:'PageImagePalettesLibrary',instance:{}},
      {label:'PageArtSites',nameInstance:'PageArtSites',instance:{}},
      {label:'PagePhotoshopPlugins',nameInstance:'PagePhotoshopPlugins',instance:{}},
      {label:'Page3DReferences',nameInstance:'Page3DReferences',instance:{}},
      {label:'PageRotateHead',nameInstance:'PageRotateHead',instance:{}},
      {label:'PageCharacterHeads',nameInstance:'PageCharacterHeads',instance:{}},
      {label:'PageBackgroundsReferences',nameInstance:'PageBackgroundsReferences',instance:{}},
      {label:'PageCharacterReferences',nameInstance:'PageCharacterReferences',instance:{}},
    {label:'PagePosesNude',nameInstance:'PagePosesNude',instance:{}},
    {label:'PageArtas',nameInstance:'PageArtas',instance:{}},
    {label:'PageArtParty',nameInstance:'PageArtParty',instance:{}},
    {label:'PagePosesLight',nameInstance:'PagePosesLight',instance:{}},
    {label:'PageRotator360',nameInstance:'PageRotator360',instance:{}},
    {label:'PageCharacterMixer',nameInstance:'PageCharacterMixer',instance:{}},
    {label:'PageBrushesPhotoshop',nameInstance:'PageBrushesPhotoshop',instance:{}},
    {label:'PageArtistNotes',nameInstance:'PageArtistNotes',instance:{}},
    {label:'PageAnatomy',nameInstance:'PageAnatomy',instance:{}},
    {label:'PageStepByStep',nameInstance:'PageStepByStep',instance:{}},
    {label:'PageTemplatesSharge',nameInstance:'PageTemplatesSharge',instance:{}},
      // {label:'Color palletes',nameInstance:'palletes',instance:{}},
      // {label:'Artist notes',nameInstance:'notes',instance:{}},
      // {label:'Fabric Materials',nameInstance:'materials',instance:{}},
      // {label:'Light in art',nameInstance:'light',instance:{}},
      // {label:'Characters references',nameInstance:'characters',instance:{}},
    ];
    instances.map((item:any)=>{
      // @ts-ignore
      item.instance = this[item.nameInstance];
    });
  return  instances.map((item)=>{
    return item.instance;
  });
  }
}
