import {Injectable} from '@angular/core';

import {ApiServices} from '../api/api.services';
@Injectable({
  providedIn: 'root',
})


export class ServerClientServices {

  constructor(public  api: ApiServices,) {}
  public addClientData(clientData:object, callback: any = null) {
    this.api.request(`customer/add`, clientData)
      .subscribe((customerFlag: any) => {
        callback && callback(customerFlag);
      });
  }

  public findClientByEmail(email:any ='',callback:any = null){
    this.api.request('customer/one', {email})
      .subscribe((customerData: any) => {
        callback && callback(customerData)
      })
  }

  public findPatron(email: any = 'luminjulia@gmail.com',
                    callbackResult: any = null,
                    callbackError: any = null,
  ) {
    this.api.request('patreon/member-by-email', {email})
      .subscribe(
        (patreonMember: any) => {
          callbackResult && callbackResult(patreonMember);
        },
        (error) => {
          callbackError && callbackError(error);
        },
        () => {
        }
      );
  }

}
/*public initPatreonMember(email: any = 'luminjulia@gmail.com',
                          callbackResult: any = null,
                          callbackError: any = null,
 ) {
   this.api.request('patreon/member-by-email', {email: email})
     .subscribe(
       (patreonMember: any) => {
         callbackResult && callbackResult(patreonMember);
       },
       (error) => {
         callbackError && callbackError(error);
       },
       () => {
       }
     );
 }*/
/* public saveCustomer(mode: any = 'save', callback: any = null) {
    // console.log(this.customer, 'this.customer');
    this.api.request(`customer/${mode}`, this.customer)
      .subscribe((customerFlag: any) => {
        callback && callback(customerFlag);
        // console.log(this.customer, 'this.customer');
      });

  }*/
/*public initCustomer() {
  // @ts-ignore
  let emailStorage = this.getStorageData('place').email;
  if (emailStorage) {
    this.loginCustomerData = this.getStorageData('place');
  } else {
    this.loginCustomerData = null;
  }
  if (this.loginCustomerData && this.loginCustomerData.email) {
    this.api.findCustomer(this.loginCustomerData.email, (customer: any) => {
      this.customer = customer;
      this.addDeviceCustomer();
      // this.api.findCustomerBox(this.loginCustomerData.email, (customerBox: any) => {
      //     this.customerBox = customerBox;
      // });
    });
  } else {
    this.loginCustomerData;
  }
}*/
