import {Injectable} from '@angular/core';

import {ServerClientServices} from '../servers/server-client.services';
import {ClientLocalstorageServices} from './client-localstorage.services';
import {ClientAlertServices} from './client-alert.services';
import {randomId} from '../../traits/string-tools';
import {
  dateNow,
  timeOut
} from '../../traits/datetime-tools';
import {ClientData} from '../../interfaces/client';
import {CdServices} from "../common/cd.services";
import {validateEmail} from "../../../../refactoring-code/services/traits/client-tools";

@Injectable({
  providedIn: 'root',
})


export class ClientPatreonAuthServices {
  public showLoginForm: boolean = true;
  public showPreload: boolean = true;
  public showCabinet: boolean = false;
  // public email: string = 'artasalex@ya.ru';
  public email: string = 'luminjulia@gmail.com';
  public loginDesc: string = 'enter member patreon email';
  public patreonUrl: string = 'https://www.patreon.com/art_club_info';
  public clientData: ClientData;
  public emailError: string = '';
  public statusCustomerExist: object = this.clientAlert.emailNotExistMessege();

  constructor(public cd: CdServices,
              public clientServer: ServerClientServices,
              public clientLocalStorage: ClientLocalstorageServices,
              public clientAlert: ClientAlertServices,
              ) {
this.initClient()
  }

  public enter(email: any) {
    this.email = email;
    this.clientServer.findPatron(this.email, (patreonMember: any) => {
      if (patreonMember) {
        this.findClientInServer(patreonMember);
      } else {
        this.notExistInPatreon();
      }
    }, (error: any) => {
      this.notExistInPatreon();
    });
  }
  public initClient() {


      this.clientData = this.clientLocalStorage.getStorageData();
    if(this.clientData){
      this.cd.logined =true;
      this.cd.lock =false;

    }
  }
  public isLogined(): boolean {
    return (this.clientData && this.cd.logined);
  }

  public isNotLogined() {
    return !!this.clientData;
  }

  public checkEmail(email: any): boolean {
    return this.showPreload || !email || email === this.emailError || !validateEmail(email);
  }

  private statusEnterPatreonError(): void {
    this.emailError = this.email;
    this.showPreload = false;
    this.statusCustomerExist = this.clientAlert.emailNotExistMessege();
  }

  private notExistInPatreon() {
    timeOut(() => {
      this.statusEnterPatreonError();
    }, 1000);
  }

  private findClientInServer(patreonMember: any) {
    this.clientServer.findClientByEmail(patreonMember.email, (clientDataFromServer: object) => {
      if (clientDataFromServer) {
        this.clientFoundInServer(clientDataFromServer);
        this.clientLocalStorage.setStorageData(this.clientData);
      } else {
        this.clientNotFoundInServer(patreonMember);
        // this.createData();
        // thi s.createSetting();
        // this.clientLocalStorage.setStorageData(this.clientData);
      }
      this.statusCustomerExist = this.clientAlert.successMessege();
    });
  }

  private clientFoundInServer(clientServer: any) {
    this.clientData = clientServer;
    this.showLoginForm = false;
    this.cd.loginForm = false;
    this.cd.logined = true;
  }

  private clientNotFoundInServer(patreonMember: any) {
    const clientNewDataId = {
      id: randomId(),
      createAt: dateNow(),
      email: patreonMember.email,
      avatar: 'assets/icons/user.png',
    };
    this.clientServer.addClientData(clientNewDataId, () => {
      this.clientData = clientNewDataId;
      this.showLoginForm = false;
    });
  }


}
