<app-sidebar
  [show]="cd.loginForm"
  (onSave)="cd.loginForm =false"
                  [showClose]="true"
                  [height]="100"
                  [width]="100"
                  [ext]="'%'"
                  [modal]="false"
                  [zIndex]="10000"
                  [position]="'right'"
>

  <p-accordion  [activeIndex]="0">
    <p-accordionTab >
      <ng-template pTemplate="header">
        <div class="flex align-items-center">
          <i class="pi pi-calendar mr-2"></i>
          <span class="vertical-align-middle">
            Login on patreon donate - 1$/month
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="content">

        <div class="step-2 p-6 bg-gradient-to-r from-green-500 to-teal-600 rounded-xl shadow-xl transform hover:scale-105 transition ease-in-out duration-300">
          <img class="w-full" [src]="srcHeaderBg" alt="">
          <p class="text-white font-semibold text-lg">
            If you joining on
            <a [href]="clientServices.patreonUrl"
               class="mt-3 inline-block bg-gradient-to-r from-amber-500 to-yellow-300 hover:from-amber-600 hover:to-yellow-400 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110"
               target="_blank"
               rel="noopener noreferrer"
            > Patreon link
            </a>
          </p>
          <p class="text-white mt-2">
            Enter your member login email and click Enter!
          </p>
          <div class="p-inputgroup">
            <!--          <span class="p-inputgroup-addon">
                        <i [class]="cd.theme.icon.patreon"></i>
                      </span>-->
            <!--[disabled]="clientServices.showPreload "-->
            <!--                (ngModelChange)="trimEmail(clientServices.email)"-->

            <input
              type="text"
              pInputText
              class="p-inputtext-lg"
              [(ngModel)]="clientServices.email"
              style=" text-align: center;background-color:#4f4b4b"
              tooltipPosition="bottom"
              [placeholder]="'Email'"
              [pTooltip]="clientServices.loginDesc"/>

            <!--[disabled]="clientServices.checkEmail(clientServices.email)"-->
            <button
              class="p-inputgroup-addon text-uppercase"
              (click)="clientServices.enter(clientServices.email);"
            >
              <!--*ngIf="!clientServices.showPreload"-->
              <span >
                    <i [class]="enterIcon"></i>
                    ENTER
                  </span>
              <!--                  <span *ngIf="clientServices.showPreload">...</span>-->
            </button>
          </div>
        </div>


      <!--    <p-card>
            <ng-template pTemplate="title">
              <div id="patreon-desc" class="text-center ">
              </div>
            </ng-template>
            <ng-template pTemplate="subtitle">
            </ng-template>

            <ng-template pTemplate="content">
             &lt;!&ndash; <div class="new-client">
                <p class="text-zinc-100"
                >If you new user login on Patreon</p>
                <a class="m-3 p-3 bg-gradient-to-r from-amber-500 to-yellow-300 hover:from-amber-600 hover:to-yellow-300 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 "
                   [href]="clientServices.patreonUrl"
                   pButton
                   target="_blank"
                >
&lt;!&ndash;*ngIf="!clientServices.showPreload"&ndash;&gt;
         &lt;!&ndash;  <span >
             Go to Patreon
             <i [class]="patreonIcon"></i>
           </span>&ndash;&gt;
                </a>
              </div>&ndash;&gt;
           &lt;!&ndash;   <div class="exist-client">
                <p class="text-zinc-100">If you login patreon, enter your email!</p>
             &lt;!&ndash;   <button [disabled]="clientServices.checkEmail(email,emailError)"
                        class="btn btn-default text-uppercase m-3 w-100"
                        pButton
                        (click)="this.clientServices.enter(this.email);"
                >
          <span *ngIf="!clientServices.showPreload">
            {{clientServices.selectLanguage.login.enter}}</span>
                  <span *ngIf="clientServices.showPreload">...</span>
                </button>&ndash;&gt;
              </div>&ndash;&gt;
            </ng-template>

            <ng-template pTemplate="footer">
              &lt;!&ndash; todo Error 2(brushes load)<p style="font-size:10px!important;">You may connect {{cd.devicesTypes.length}} -devices
                 <ng-container *ngFor=" let deviceType of cd.devicesTypes ">
                 <i [ngStyle]="deviceType.type == cd.parseUserAgent(cd.getAgent()).device.type ?{'color': 'white'}:null"
                    [class]="deviceType.icon"></i> |
               </ng-container>
               </p>&ndash;&gt;

             &lt;!&ndash; <p class="text-amber-300"
                 *animIf="this.clientServices.statusCustomerExist.show;
            info: { startAnim: 'bounceIn', endAnim: 'bounceOut', time: 1000 }">
                <span>{{clientServices.emailError}}</span>
                {{clientServices.statusCustomerExist.text}}
              </p>&ndash;&gt;
            </ng-template>
          </p-card>-->
      </ng-template>
    </p-accordionTab>
    <p-accordionTab >
      <ng-template pTemplate="header">
        <div class="flex align-items-center">
          <i class="pi pi-calendar mr-2"></i>
          <span class="vertical-align-middle">
           How to logined? Steps...
          </span>
        </div>
      </ng-template>

      <ng-template pTemplate="content">
        <div class="space-y-6">
          <!-- Step 1 -->
          <div class="step-1 p-6 bg-gradient-to-r from-purple-500 to-indigo-600 rounded-xl shadow-xl transform hover:scale-105 transition ease-in-out duration-300">
            <p class="text-white font-semibold text-lg">
              Step 1
            </p>
            <p class="text-white mt-2 flex items-center gap-2">
              Go to Patreon and login
              <i [class]="patreonIcon"></i>
            </p>
            <a [href]="clientServices.patreonUrl"
               class="mt-3 inline-block bg-gradient-to-r from-amber-500 to-yellow-300 hover:from-amber-600 hover:to-yellow-400 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110"
               target="_blank"
               rel="noopener noreferrer"
            > Patreon link
            </a>
<!--            <img src="assets/patreon-step-1.jpg" alt="Step 1" class="mt-4 rounded-lg">-->
          </div>

          <!-- Step 2 -->
          <div class="step-2 p-6 bg-gradient-to-r from-green-500 to-teal-600 rounded-xl shadow-xl transform hover:scale-105 transition ease-in-out duration-300">
            <p class="text-white font-semibold text-lg">
              Step 2
            </p>
            <p class="text-white mt-2">
              Click to join as a member
            </p>
            <img src="assets/patreon-step-2.jpg" alt="Step 2" class="mt-4 rounded-lg">
          </div>

          <!-- Step 3 -->
          <div class="step-3 p-6 bg-gradient-to-r from-red-500 to-pink-600 rounded-xl shadow-xl transform hover:scale-105 transition ease-in-out duration-300">
            <p class="text-white font-semibold text-lg">
              Step 3
            </p>
            <p class="text-white mt-2">
              Enter your member login email and click Enter!
            </p>
            <div class="p-inputgroup">
              <!--          <span class="p-inputgroup-addon">
                          <i [class]="cd.theme.icon.patreon"></i>
                        </span>-->
              <!--[disabled]="clientServices.showPreload "-->
              <!--                (ngModelChange)="trimEmail(clientServices.email)"-->

              <input
                type="text"
                pInputText
                class="p-inputtext-lg"
                [(ngModel)]="clientServices.email"
                style=" text-align: center;background-color:#4f4b4b"
                tooltipPosition="bottom"
                [placeholder]="clientServices.loginDesc"
                [pTooltip]="clientServices.loginDesc"/>

              <!--[disabled]="clientServices.checkEmail(clientServices.email)"-->
              <button
                class="p-inputgroup-addon text-uppercase"
                (click)="clientServices.enter(clientServices.email);"
              >
                <!--*ngIf="!clientServices.showPreload"-->
                <span >
                    <i [class]="enterIcon"></i>
                    ENTER
                  </span>
                <!--                  <span *ngIf="clientServices.showPreload">...</span>-->
              </button>
            </div>
          </div>
        </div>

      </ng-template>
    </p-accordionTab>
    <p-accordionTab >
      <ng-template pTemplate="header">
        <div class="flex align-items-center">
          <i class="pi pi-calendar mr-2"></i>
          <span class="vertical-align-middle">
           Premium opportunities
          </span>
        </div>
      </ng-template>

      <ng-template pTemplate="content">
        <div class="rounded overflow-hidden shadow-lg transform hover:scale-110 transition duration-500 cursor-pointer">
          <div class="bg-gradient-to-br from-pink-500 via-blue-500 to-yellow-500 p-5 rounded-t">
            <h2 class="font-bold text-white text-xl mb-2">Premium Package</h2>
            <p class="text-white text-opacity-90 text-sm">
              Gain exclusive access to our best features!
            </p>
          </div>
          <div class="bg-white p-6 rounded-b">
            <ul class="text-gray-700 text-base mb-4 space-y-2">
              <li class="flex items-center">
                <span class="flex-shrink-0 w-4 h-4 mr-2 bg-green-500 rounded-full"></span>
                Unlimited Projects
              </li>
              <li class="flex items-center">
                <span class="flex-shrink-0 w-4 h-4 mr-2 bg-green-500 rounded-full"></span>
                Unlimited Generations
              </li>
              <li class="flex items-center">
                <span class="flex-shrink-0 w-4 h-4 mr-2 bg-green-500 rounded-full"></span>
                Access to Exclusive Content
              </li>
              <li class="flex items-center">
                <span class="flex-shrink-0 w-4 h-4 mr-2 bg-green-500 rounded-full"></span>
                Monthly Updates and Gifts
              </li>
              <li class="flex items-center">
                <span class="flex-shrink-0 w-4 h-4 mr-2 bg-green-500 rounded-full"></span>
                Discounts on Partner Products
              </li>
            </ul>
          </div>
        </div>      </ng-template>
    </p-accordionTab>
  </p-accordion>

 <!--   <p-accordionTab header="Opportunities">
      <div *ngIf="opportunities"
           class="border-transparent rounded-lg text-center p-5 mx-auto md:mx-0 my-2 bg-gradient text-white font-medium z-10 shadow-lg">
        <div class="py-4">
&lt;!&ndash;          <div id="month" class="font-bold text-6xl ">&dollar;1</div>&ndash;&gt;
          <hr>
          <p-scrollPanel [style]="{height:200}">

            <ng-container *ngFor=" let deviceType of opportunities ">
              <div class="text-sm my-3">{{deviceType.desc}}</div>
              <hr>
            </ng-container>
          </p-scrollPanel>
          &lt;!&ndash;   <a href="#" target="_blank">
               <div class="bg-white border border-white hover:bg-transparent text-blue-600 hover:text-white font-bold uppercase text-xs mt-5 py-2 mpx-4 rounded cursor-pointer">Learn More</div>
             </a>&ndash;&gt;
        </div>
      </div>
    </p-accordionTab>-->


</app-sidebar>
