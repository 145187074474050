import {Component, inject} from '@angular/core';
import {EditListCardsComponent} from '../../../components/admin/edit-list-cards/edit-list-cards.component';
import {Card} from '../../../interfaces/Card';
import {DataSites} from '../../../services/pages/data-sites';
import {EditListSitesComponent} from '../../../components/admin/edit-list-sites/edit-list-sites.component';
import {EditSiteSelectComponent} from '../../../components/admin/edit-site-select/edit-site-select.component';
import {ConfirmComponent} from '../../../components/admin/confirm/confirm.component';
import {ConfirmationService} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from 'primeng/toast';
import {SiteDebugerComponent} from "../../../components/admin/site-debuger/site-debuger.component";
import {JsonPipe} from "@angular/common";
import { InputOtpModule } from 'primeng/inputotp';
import {TabViewModule} from "primeng/tabview";
import {FormsModule} from "@angular/forms";
import {PasswordModule} from "primeng/password";
@Component({
  selector: 'app-page-admin',
  standalone:true,
  providers:[ConfirmationService],
  imports: [
    EditListSitesComponent,
    EditSiteSelectComponent,
    ConfirmComponent,
    ConfirmDialogModule,
    SiteDebugerComponent,
    FormsModule,
    PasswordModule,
  ],
  templateUrl: './page-admin.component.html',
})
export class PageAdminComponent {
  password: string  = '2525';
constructor(public confirmationService: ConfirmationService) {
}
  public deleteModel() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      message: 'Please confirm to proceed moving forward.',
      acceptIcon: 'pi pi-check mr-2',
      acceptLabel: 'Save1',
      rejectLabel: 'Close2',
      rejectIcon: 'pi pi-times mr-2',
      rejectButtonStyleClass: 'p-button-sm',
      acceptButtonStyleClass: 'p-button-outlined p-button-sm',
      accept: () => {
        console.warn();
      },
      reject: () => {
      }
    });
  }
}
