<div  class="bg-zinc-700 p-[2px] rounded-lg shadow-lgm m-[2px] w-[170px]"
>


<div *ngIf="similar"
     class=" font-bold mb-1 border-amber-300 bg-zinc-300 text-zinc-300 ">
  <p-scrollPanel [style]="{height:150}">
    <div class="grid grid-cols-2 gap-1">
      <ng-container *ngFor="let  similarRow  of  similar">
        <div
          class="break-all flex  justify-between w-[75px] h-[75px] text-[10px] font-bold mb-1 p-[5px] text-zinc-300 border-amber-300 bg-zinc-800 truncate"
          (click)="select(similarRow)"
        >
          <!--
           <span *ngIf=" (typeRow === 'color' && model.color)"
                 class="block w-[75px] h-[75px] "
                 (click)="model.color =similarRow['name']"
                 [style.background-color]="similarRow['name']"
           >
           </span>-->
         <!-- <span *ngIf="typeRow === 'srcGif'">
              <img class="w-[75px] h-[75px]"
                   *ngIf="model.srcGif"
                   [src]="similarRow['name']"
                   alt="srcIcon">
            </span>-->
       <!--   <span *ngIf="typeRow === 'srcIcon'">
              <img class="w-[75px] h-[75px]"
                   *ngIf="model.srcIcon"
                   [src]="similarRow['name']"
                   alt="srcIcon">
            </span>-->
        <!--  <span *ngIf="typeRow === 'srcPreview'">
              <img class="w-[75px] h-[75px]"
                   *ngIf="model.srcPreview"
                   [src]="similarRow['name']"
                   alt="srcPreview">
            </span>-->
          <!-- <span *ngIf="!showImage"  ngxClipboard
                 (cbOnSuccess)=" cd.array.copyText(similarRow,nameRow,true)"
                 [cbContent]="similarRow['name']"
                 [container]="copyContainer3">
                 <i class="far fa-copy"></i>
          </span>-->
          <!--    <p
                    class="break-all w-[75px] h-[75px]">

              </p>-->
          <!--*ngIf="!showImage"-->
          <textarea
                    style="padding: 2px!important;font-size: 12px !important; "
                    pInputTextarea
                    [readonly]=" true"
                    [placeholder]="nameRow"
                    [autoResize]="true"
                    [ngModel]="  similarRow['name']"
                    [style.height.px]="75"
                    [style.min-width.px]="75"
                    [style.color]="similarRow['name']"

          ></textarea>

        </div>
      </ng-container>
    </div>
  </p-scrollPanel>
</div>
</div>
