{{idProduct}}
<ng-container *ngIf="idProduct ==='a0g2o045p' ">
  <app-page-color-harmony></app-page-color-harmony>
</ng-container>

<ng-container *ngIf="idProduct ==='bx4gitxyu' ">
  <app-page-image-to-sketch></app-page-image-to-sketch>
</ng-container>

<ng-container *ngIf="idProduct ==='201iwkdm4' ">
  <app-page-palette-generator></app-page-palette-generator>
</ng-container>
<ng-container *ngIf="idProduct ==='7ca72g1jx' ">
  <app-page-color-palette-picker></app-page-color-palette-picker>
</ng-container>
<ng-container *ngIf="idProduct ==='iesdcbeso' ">
  <app-page-image-to-ink></app-page-image-to-ink>
</ng-container>
<ng-container *ngIf="idProduct ==='1rw78mgxv' ">
  <app-page-image-to-trace></app-page-image-to-trace>
</ng-container>
<ng-container *ngIf="idProduct ==='z99c76u6t' ">
  <app-page-midjourney></app-page-midjourney>
</ng-container>
<ng-container *ngIf="idProduct ==='k8ji1q6ok' ">
  <app-page-3D-references></app-page-3D-references>
</ng-container>
<ng-container *ngIf="idProduct ==='eptiroir2' ">
  <app-page-photoshop-plugins></app-page-photoshop-plugins>
</ng-container>
<ng-container *ngIf="idProduct ==='sdgtkk3m3' ">
<app-page-rotate-head></app-page-rotate-head>
</ng-container>
