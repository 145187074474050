import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';

declare const Zoomist;

@Component({
  selector: 'app-stage-cropper',
  templateUrl: './stage-cropper.component.html',
  styleUrls: ['./stage-cropper.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    JsonPipe,
  ]


})


export class StageCropperComponent implements AfterViewInit {
  @ViewChild('sliderElement') sliderElement: ElementRef;
  @Input() stage: any;
  @Input() showResult: boolean;
  @Output() onImageResult = new EventEmitter<string>();

  ngAfterViewInit(): void {
    this.initStageCropper(this.stage);
  }

  public initStageCropper(scene: any) {
    let canvas: any = Object.assign(document.createElement('canvas'), {
      width: scene.width,
      height: scene.height
    });
    const ctx: any = canvas.getContext('2d');

    function loadImage(src: string) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.crossOrigin = "Anonymous"; // Для поддержки CORS если нужно
        img.src = src;
      });
    }

    function fillBackground(color: string, x: number, y: number, width: number, height: number) {
      ctx.fillStyle = color;
      ctx.fillRect(x, y, width, height);
    }

    function drawText(text, x, y) {
      ctx.fillStyle = 'black'; // Цвет текста
      ctx.font = '16px Arial'; // Шрифт и размер текста
      ctx.fillText(text, x, y);
    }

    const renderScene = async (scene: any) => {
      // Установка размеров сцены и отрисовка фона
      canvas.width = scene.width;
      canvas.height = scene.height;
      fillBackground(scene.color, 0, 0, scene.width, scene.height);

      for (const element of scene.images) {
        if (element.sw) {
          if (element.type === 'image') {
            const img = await loadImage(element.srcPreview);
            ctx.drawImage(img, element.x, element.y, element.width, element.height);
          }
          if (element.type === 'list') {
            // drawText(element.type, element.x, element.y);
            fillBackground(element.color, element.x, element.y, element.width, element.height);
          }
          if (element.type === 'text') {
            // drawText(element.type, element.x, element.y);
            fillBackground(element.color, element.x, element.y, element.width, element.height);
          }

        }
      }
      return canvas.toDataURL();


    }
    renderScene(scene).then((base64Image: string) => {
      let base64: string = base64Image;
      this.stage.srcPreview = base64;
      this.onImageResult.emit(base64)
    });

  }

}
