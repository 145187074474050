import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  NgFor,
  NgIf
} from '@angular/common';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ListUploadComponent} from '../../admin/list-upload/list-upload.component';

@Component({
  selector: 'app-box-photos',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    ScrollPanelModule,
    ListUploadComponent
  ],
  templateUrl: './box-photos.component.html',
})
export class BoxPhotosComponent implements OnInit {
  @Input() model: any;
  @Input() rowName: any;
  @Input() siteKonva: any;
  @Input() models: any = [];
  @Input() view: any = 'col';
  @Input() showUpload: any = true;
  @Input() heightItem: any = 50;
  @Input() widthItem: any = 50;
  @Input() heightList: any = 300;
  @Input() showName: any = true;
  @Input() showMore: any = false;
  @Input() showFavorite: any = true;
  @Input() showImage: any = true;
  @Input() showAdd: any = true;
  @Input() showTools: any = true;
  @Input() showList: any = true;
  @Input() showSort: any = false;
  @Input() showCheck: any = false;
  @Input() showShow: any = false;
  @Input() showLock: any = false;
  @Input() sizeView: any = 'size';

  @Input() showEyeDropper: any = false;
  @Input() showToJPG: any = false;
  @Input() showDeleteServerImage: any = false;
  @Input() showDelete: any = false;
  public box: any;
  @Output() onAdd = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  @Output() onClick = new EventEmitter();
  @Output() onSort = new EventEmitter();
  @Output() onCheck = new EventEmitter();
  @Output() onShow = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onFavorite = new EventEmitter();
  @Output() onUpload = new EventEmitter();
  @Output() onUploadedFinish = new EventEmitter();
  @Output() onJPG = new EventEmitter();

  constructor() {
  }

  ngOnInit() {

  }


}
