
<div   style="height:25px; overflow: hidden"
       class="absolute top-0 seo-container text-center z-0">
  <h1>{{dataPage.seoTexts.h1}}</h1>
  <h2>{{dataPage.seoTexts.h2}}</h2>
  <h3>{{dataPage.seoTexts.h3}}</h3>
  <p>{{dataPage.seoTexts.text}}</p>
</div>
<app-navbar-top></app-navbar-top>
<app-header
  [bgImage]="dataPage.imageHeader"
  [headerText]="dataPage.seoTexts.h1"
  [subHeaderText]="dataPage.seoTexts.h2"
></app-header>
<div class="bg-zinc-600">

  <app-common-home

    [dataPage]="dataPage"
  ></app-common-home>

</div>
