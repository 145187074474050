import {
  Component,
  OnInit
} from '@angular/core';
import {
  JsonPipe,
  NgForOf,
  NgIf
} from "@angular/common";
import { HeaderComponent } from 'src/app/components/pages/header/header.component';
import {
  ActivatedRoute,
  Router
} from "@angular/router";
import { BoxServices } from 'src/app/services/box/box.services';
import {PageArtasServices} from "../../../../services/pages/page-artas.services";
import {ContactsLeftArtasComponent} from "../contacts-left-artas/contacts-left-artas.component";
import {NavbarTopArtasComponent} from "../navbar-top-artas/navbar-top-artas.component";
import {ProductListRightArtasComponent} from "../product-list-right-artas/product-list-right-artas.component";
import {ProductMoreArtasComponent} from "../product-more-artas/product-more-artas.component";
import {ViewGridArtasComponent} from "../view-grid/view-grid-artas.component";
@Component({
  selector: 'app-page-artas-products',
  templateUrl: './page-artas-products.component.html',
  standalone:true,
  imports: [
    HeaderComponent,
    NgForOf,
    NgIf,
    ContactsLeftArtasComponent,
    NavbarTopArtasComponent,
    ProductListRightArtasComponent,
    ProductMoreArtasComponent,
    ViewGridArtasComponent,
  ],
})
export class PageArtasProductsComponent implements OnInit{
 public card:any
  public photos: any[];
  public cards: any[];

  subRoute:any = ''
  logoText: any = 'ARTAS.ORG.UA';
  showTemlatesSharges: any =false;
  dataSeo:any
  constructor(public dataPage: PageArtasServices,
              private router: Router,
              private route: ActivatedRoute,
              public boxServices: BoxServices,
              ) {}

  ngOnInit() {
    this.initRoute();

}
  initRoute(){
    this.route.data.subscribe((data:any)=>{
      this.dataSeo = data;
    })
    this.route.params.subscribe(params => {

      this.card =   this.dataPage.findCard(params.slug);
      console.log(this.card,'find')
this.findInBox();


    });
  }

  findInBox(){
    if(this.card){
      this.initBox(this.card)
      this.cards = this.dataPage.getCards();
      this.router.navigate([this.dataSeo.route,this.card.id]);
      window.scrollTo(0, 0);
    }else {
      this.card = null;
      this.router.navigate([this.dataSeo.route]);
      window.scrollTo(0, 0);

    }
  }
  public initBox(card){
    // boxId = boxId.replace(/\s/g, "")

    let boxId:any = this.boxFileName(card)
    this.boxServices.findCardBoxById(boxId,
      (responseBox: any): void => {
        // this.box = responseBox;
        this.photos =responseBox.photos
        console.log(responseBox,'responseBox')
      });
  }
  boxFileName(model: any) {
    let boxId = model.id + '_' + model.createAt;
    return boxId.replace(/\s/g, "");
  }


  setSelect(card: any) {
    // 'zakazat-sharj-po-foto'
    // this.card =card;
    // this.findInBox();
    this.router.navigate(['/zakazat-sharj/',card.id]);
    window.scrollTo(0, 0);

  }
}
